@charset "UTF-8";
@import "~simplebar/dist/simplebar.css";
@import "~highlight.js/styles/androidstudio.css";
@font-face {
  font-family: "Inter var";
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url("/assets/fonts/Inter-roman-var.woff2?v=3.19") format("woff2");
  font-named-instance: "Regular";
}
@font-face {
  font-family: "Inter var";
  font-style: italic;
  font-weight: 100 900;
  font-display: swap;
  src: url("/assets/fonts/Inter-italic-var.woff2?v=3.19") format("woff2");
  font-named-instance: "Italic";
}
.dark {
  --vex-sidenav-background: theme("colors.gray.900");
  --vex-sidenav-color: theme("colors.white");
  --vex-sidenav-toolbar-background: theme("colors.gray.900");
  --vex-sidenav-section-divider-color: theme("colors.white / 0.12");
  --vex-sidenav-item-background-hover: theme("colors.gray.950 / 30%");
  --vex-sidenav-item-background-active: theme("colors.gray.950 / 30%");
  --vex-sidenav-item-color: theme("colors.gray.300");
  --vex-sidenav-item-color-hover: theme("colors.white");
  --vex-sidenav-item-color-active: theme("colors.white");
  --vex-sidenav-item-icon-color: theme("colors.primary.600 / 50%");
  --vex-sidenav-item-icon-color-hover: theme("colors.primary.600");
  --vex-sidenav-item-icon-color-active: theme("colors.primary.600");
  --vex-sidenav-item-dropdown-background: var(
    --vex-sidenav-item-background-active
  );
  --vex-sidenav-item-dropdown-background-hover: theme("colors.gray.950 / 80%");
  --vex-sidenav-item-dropdown-color-hover: var(--vex-sidenav-item-color-hover);
  --vex-sidenav-item-dropdown-background-active: var(
    --vex-sidenav-item-dropdown-background-hover
  );
  --vex-sidenav-item-dropdown-color-active: var(
    --vex-sidenav-item-color-active
  );
  --vex-sidenav-item-ripple-color: theme("colors.white / 10%");
  --vex-sidenav-subheading-color: theme("colors.gray.500");
  --vex-toolbar-background: var(--vex-background-background);
  --vex-toolbar-icon-color: rgb(var(--vex-color-primary-600));
  --vex-secondary-toolbar-background: var(--vex-background-background);
  --vex-navigation-color: theme("textColor.default");
  --vex-navigation-background: theme("colors.gray.900");
}

.vex-style-light .vex-sidenav {
  @apply border-r border-r-gray-200;
}
.vex-style-light .vex-sidenav .vex-sidenav-user .vex-sidenav-user__subtitle,
.vex-style-light .vex-sidenav .vex-sidenav-user .vex-sidenav-user__dropdown-icon {
  @apply text-black/50;
}
.vex-style-light .vex-sidenav .vex-sidenav-user:hover {
  @apply bg-gray-100 text-gray-900;
}
.vex-style-light .vex-sidenav .vex-sidenav-search {
  @apply bg-gray-100 text-gray-600;
}
.vex-style-light .vex-sidenav .vex-sidenav-search:hover {
  @apply bg-gray-200 text-gray-900;
}
.vex-style-light .vex-sidenav-user--open {
  @apply bg-gray-100;
}

body {
  --vex-sidenav-background: theme("colors.gray.900");
  --vex-sidenav-color: theme("colors.white");
  --vex-sidenav-toolbar-background: theme("colors.gray.900");
  --vex-sidenav-section-divider-color: theme("colors.white / 0.12");
  --vex-sidenav-item-background-hover: theme("colors.gray.950 / 30%");
  --vex-sidenav-item-background-active: theme("colors.gray.950 / 30%");
  --vex-sidenav-item-color: theme("colors.gray.300");
  --vex-sidenav-item-color-hover: theme("colors.white");
  --vex-sidenav-item-color-active: theme("colors.white");
  --vex-sidenav-item-icon-color: theme("colors.primary.400 / 60%");
  --vex-sidenav-item-icon-color-hover: theme("colors.primary.600");
  --vex-sidenav-item-icon-color-active: theme("colors.primary.600");
  --vex-sidenav-item-dropdown-background: var(
    --vex-sidenav-item-background-active
  );
  --vex-sidenav-item-dropdown-background-hover: theme("colors.gray.950 / 80%");
  --vex-sidenav-item-dropdown-color-hover: var(--vex-sidenav-item-color-hover);
  --vex-sidenav-item-dropdown-background-active: var(
    --vex-sidenav-item-dropdown-background-hover
  );
  --vex-sidenav-item-dropdown-color-active: var(
    --vex-sidenav-item-color-active
  );
  --vex-sidenav-item-ripple-color: theme("colors.white / 10%");
  --vex-sidenav-subheading-color: theme("colors.primary.300 / 80%");
  --vex-toolbar-background: theme("backgroundColor.foreground / 70%");
  --vex-toolbar-icon-color: rgb(var(--vex-color-primary-600));
  --vex-secondary-toolbar-background: var(--vex-toolbar-background);
  --vex-navigation-color: theme("textColor.default");
  --vex-navigation-background: var(--vex-background-card);
}

/** Include the core styles for Angular Material. */
.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

/** Create a base theme to be extended by all themes. */
html {
  --mat-option-label-text-font: var(--vex-font);
  --mat-option-label-text-line-height: 24px;
  --mat-option-label-text-size: 15px;
  --mat-option-label-text-tracking: -0.009em;
  --mat-option-label-text-weight: 400;
}

html {
  --mat-optgroup-label-text-font: var(--vex-font);
  --mat-optgroup-label-text-line-height: 24px;
  --mat-optgroup-label-text-size: 15px;
  --mat-optgroup-label-text-tracking: -0.009em;
  --mat-optgroup-label-text-weight: 400;
}

.mat-mdc-card {
  --mat-card-title-text-font: var(--vex-font);
  --mat-card-title-text-line-height: 26px;
  --mat-card-title-text-size: 18px;
  --mat-card-title-text-tracking: -0.014em;
  --mat-card-title-text-weight: 500;
  --mat-card-subtitle-text-font: var(--vex-font);
  --mat-card-subtitle-text-line-height: 24px;
  --mat-card-subtitle-text-size: 14px;
  --mat-card-subtitle-text-tracking: -0.006em;
  --mat-card-subtitle-text-weight: 500;
}

.mat-mdc-tooltip {
  --mdc-plain-tooltip-supporting-text-font: var(--vex-font);
  --mdc-plain-tooltip-supporting-text-size: 12px;
  --mdc-plain-tooltip-supporting-text-weight: 400;
  --mdc-plain-tooltip-supporting-text-tracking: 0;
}

.mat-mdc-form-field-infix {
  min-height: 56px;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 28px;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -34.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 16px;
  padding-bottom: 16px;
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 24px;
  padding-bottom: 8px;
}

.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 16px;
  padding-bottom: 16px;
}

html {
  --mdc-filled-text-field-label-text-font: var(--vex-font);
  --mdc-filled-text-field-label-text-size: 15px;
  --mdc-filled-text-field-label-text-tracking: -0.009em;
  --mdc-filled-text-field-label-text-weight: 400;
  --mdc-outlined-text-field-label-text-font: var(--vex-font);
  --mdc-outlined-text-field-label-text-size: 15px;
  --mdc-outlined-text-field-label-text-tracking: -0.009em;
  --mdc-outlined-text-field-label-text-weight: 400;
  --mat-form-field-container-text-font: var(--vex-font);
  --mat-form-field-container-text-line-height: 24px;
  --mat-form-field-container-text-size: 15px;
  --mat-form-field-container-text-tracking: -0.009em;
  --mat-form-field-container-text-weight: 400;
  --mat-form-field-outlined-label-text-populated-size: 15px;
  --mat-form-field-subscript-text-font: var(--vex-font);
  --mat-form-field-subscript-text-line-height: 20px;
  --mat-form-field-subscript-text-size: 12px;
  --mat-form-field-subscript-text-tracking: 0;
  --mat-form-field-subscript-text-weight: 400;
}

html {
  --mat-select-trigger-text-font: var(--vex-font);
  --mat-select-trigger-text-line-height: 24px;
  --mat-select-trigger-text-size: 15px;
  --mat-select-trigger-text-tracking: -0.009em;
  --mat-select-trigger-text-weight: 400;
}

.mat-mdc-dialog-container {
  --mdc-dialog-subhead-font: var(--vex-font);
  --mdc-dialog-subhead-line-height: 26px;
  --mdc-dialog-subhead-size: 18px;
  --mdc-dialog-subhead-weight: 500;
  --mdc-dialog-subhead-tracking: -0.014em;
  --mdc-dialog-supporting-text-font: var(--vex-font);
  --mdc-dialog-supporting-text-line-height: 24px;
  --mdc-dialog-supporting-text-size: 15px;
  --mdc-dialog-supporting-text-weight: 400;
  --mdc-dialog-supporting-text-tracking: -0.009em;
}

.mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-container-height: 32px;
}

.mat-mdc-standard-chip {
  --mdc-chip-label-text-font: var(--vex-font);
  --mdc-chip-label-text-line-height: 20px;
  --mdc-chip-label-text-size: 14px;
  --mdc-chip-label-text-tracking: -0.006em;
  --mdc-chip-label-text-weight: 400;
}

.mat-mdc-slide-toggle {
  --mdc-switch-state-layer-size: 48px;
}

.mat-mdc-slide-toggle {
  --mat-slide-toggle-label-text-font: var(--vex-font);
  --mat-slide-toggle-label-text-size: 14px;
  --mat-slide-toggle-label-text-tracking: -0.006em;
  --mat-slide-toggle-label-text-line-height: 20px;
  --mat-slide-toggle-label-text-weight: 400;
}
.mat-mdc-slide-toggle .mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
}

.mat-mdc-radio-button .mdc-radio {
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-radio-button .mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, var(--vex-font)));
  font-size: var(--mdc-typography-body2-font-size, 14px);
  line-height: var(--mdc-typography-body2-line-height, 20px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, -0.006em);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}

.mat-mdc-slider {
  --mdc-slider-label-label-text-font: var(--vex-font);
  --mdc-slider-label-label-text-size: 14px;
  --mdc-slider-label-label-text-line-height: 24px;
  --mdc-slider-label-label-text-tracking: -0.006em;
  --mdc-slider-label-label-text-weight: 500;
}

html {
  --mat-menu-item-label-text-font: var(--vex-font);
  --mat-menu-item-label-text-size: 15px;
  --mat-menu-item-label-text-tracking: -0.009em;
  --mat-menu-item-label-text-line-height: 24px;
  --mat-menu-item-label-text-weight: 400;
}

.mat-mdc-list-base {
  --mdc-list-list-item-one-line-container-height: 48px;
  --mdc-list-list-item-two-line-container-height: 64px;
  --mdc-list-list-item-three-line-container-height: 88px;
}

.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 56px;
}
.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 72px;
}

.mat-mdc-list-base {
  --mdc-list-list-item-label-text-font: var(--vex-font);
  --mdc-list-list-item-label-text-line-height: 24px;
  --mdc-list-list-item-label-text-size: 15px;
  --mdc-list-list-item-label-text-tracking: -0.009em;
  --mdc-list-list-item-label-text-weight: 400;
  --mdc-list-list-item-supporting-text-font: var(--vex-font);
  --mdc-list-list-item-supporting-text-line-height: 20px;
  --mdc-list-list-item-supporting-text-size: 14px;
  --mdc-list-list-item-supporting-text-tracking: -0.006em;
  --mdc-list-list-item-supporting-text-weight: 400;
  --mdc-list-list-item-trailing-supporting-text-font: var(--vex-font);
  --mdc-list-list-item-trailing-supporting-text-line-height: 20px;
  --mdc-list-list-item-trailing-supporting-text-size: 12px;
  --mdc-list-list-item-trailing-supporting-text-tracking: 0;
  --mdc-list-list-item-trailing-supporting-text-weight: 400;
}

.mdc-list-group__subheader {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  font-family: var(--vex-font);
  letter-spacing: -0.011em;
}

html {
  --mat-paginator-container-size: 56px;
}

.mat-mdc-paginator .mat-mdc-form-field-infix {
  min-height: 40px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 20px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -26.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label {
  display: none;
}

html {
  --mat-paginator-container-text-font: var(--vex-font);
  --mat-paginator-container-text-line-height: 20px;
  --mat-paginator-container-text-size: 12px;
  --mat-paginator-container-text-tracking: 0;
  --mat-paginator-container-text-weight: 400;
  --mat-paginator-select-trigger-text-size: 12px;
}

.mat-mdc-tab-header {
  --mdc-secondary-navigation-tab-container-height: 48px;
}

.mat-mdc-tab-header {
  --mat-tab-header-label-text-font: var(--vex-font);
  --mat-tab-header-label-text-size: 14px;
  --mat-tab-header-label-text-tracking: -0.006em;
  --mat-tab-header-label-text-line-height: 14px;
  --mat-tab-header-label-text-weight: 500;
}

html {
  --mdc-checkbox-state-layer-size: 40px;
}

.mat-mdc-checkbox .mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, var(--vex-font)));
  font-size: var(--mdc-typography-body2-font-size, 14px);
  line-height: var(--mdc-typography-body2-line-height, 20px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, -0.006em);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}

.mat-mdc-button.mat-mdc-button-base,
.mat-mdc-raised-button.mat-mdc-button-base,
.mat-mdc-unelevated-button.mat-mdc-button-base,
.mat-mdc-outlined-button.mat-mdc-button-base {
  height: 36px;
}

.mdc-button {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, var(--vex-font)));
  font-size: var(--mdc-typography-button-font-size, 14px);
  line-height: var(--mdc-typography-button-line-height, 14px);
  font-weight: var(--mdc-typography-button-font-weight, 500);
  letter-spacing: var(--mdc-typography-button-letter-spacing, -0.006em);
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: var(--mdc-typography-button-text-transform, none);
}

.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 48px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 12px;
}

.mdc-fab--extended {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, var(--vex-font)));
  font-size: var(--mdc-typography-button-font-size, 14px);
  line-height: var(--mdc-typography-button-line-height, 14px);
  font-weight: var(--mdc-typography-button-font-weight, 500);
  letter-spacing: var(--mdc-typography-button-letter-spacing, -0.006em);
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: var(--mdc-typography-button-text-transform, none);
}
.mat-mdc-extended-fab {
  --mdc-extended-fab-label-text-font: var(--vex-font);
  --mdc-extended-fab-label-text-size: 14px;
  --mdc-extended-fab-label-text-tracking: -0.006em;
  --mdc-extended-fab-label-text-weight: 500;
}

.mat-mdc-snack-bar-container {
  --mdc-snackbar-supporting-text-font: var(--vex-font);
  --mdc-snackbar-supporting-text-line-height: 20px;
  --mdc-snackbar-supporting-text-size: 14px;
  --mdc-snackbar-supporting-text-weight: 400;
}

html {
  --mat-table-header-container-height: 56px;
  --mat-table-footer-container-height: 52px;
  --mat-table-row-item-container-height: 52px;
}

html {
  --mat-table-header-headline-font: var(--vex-font);
  --mat-table-header-headline-line-height: 24px;
  --mat-table-header-headline-size: 14px;
  --mat-table-header-headline-weight: 500;
  --mat-table-header-headline-tracking: -0.006em;
  --mat-table-row-item-label-text-font: var(--vex-font);
  --mat-table-row-item-label-text-line-height: 20px;
  --mat-table-row-item-label-text-size: 14px;
  --mat-table-row-item-label-text-weight: 400;
  --mat-table-row-item-label-text-tracking: -0.006em;
  --mat-table-footer-supporting-text-font: var(--vex-font);
  --mat-table-footer-supporting-text-line-height: 20px;
  --mat-table-footer-supporting-text-size: 14px;
  --mat-table-footer-supporting-text-weight: 400;
  --mat-table-footer-supporting-text-tracking: -0.006em;
}

.mat-badge {
  position: relative;
}
.mat-badge.mat-badge {
  overflow: visible;
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
  background-color: var(--mat-badge-background-color);
  color: var(--mat-badge-text-color);
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mat-badge-text-font, Roboto, sans-serif);
  font-size: 12px;
  /* @alternate */
  font-size: var(--mat-badge-text-size, 12px);
  font-weight: 600;
  /* @alternate */
  font-weight: var(--mat-badge-text-weight, 600);
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-disabled .mat-badge-content {
  background-color: var(--mat-badge-disabled-state-background-color);
  color: var(--mat-badge-disabled-state-text-color);
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
  font-size: 9px;
  /* @alternate */
  font-size: var(--mat-badge-small-size-text-size, 9px);
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
  font-size: 24px;
  /* @alternate */
  font-size: var(--mat-badge-large-size-text-size, 24px);
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

html {
  --mat-badge-text-font: var(--vex-font);
  --mat-badge-text-size: 12px;
  --mat-badge-text-weight: 600;
  --mat-badge-small-size-text-size: 9px;
  --mat-badge-large-size-text-size: 24px;
}

html {
  --mat-bottom-sheet-container-text-font: var(--vex-font);
  --mat-bottom-sheet-container-text-line-height: 20px;
  --mat-bottom-sheet-container-text-size: 14px;
  --mat-bottom-sheet-container-text-tracking: -0.006em;
  --mat-bottom-sheet-container-text-weight: 400;
}

html {
  --mat-standard-button-toggle-height: 48px;
}

html {
  --mat-legacy-button-toggle-text-font: var(--vex-font);
  --mat-standard-button-toggle-text-font: var(--vex-font);
}

.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mat-mdc-button-touch-target {
  display: none;
}

html {
  --mat-datepicker-calendar-text-font: var(--vex-font);
  --mat-datepicker-calendar-text-size: 13px;
  --mat-datepicker-calendar-body-label-text-size: 14px;
  --mat-datepicker-calendar-body-label-text-weight: 500;
  --mat-datepicker-calendar-period-button-text-size: 14px;
  --mat-datepicker-calendar-period-button-text-weight: 500;
  --mat-datepicker-calendar-header-text-size: 11px;
  --mat-datepicker-calendar-header-text-weight: 400;
}

html {
  --mat-expansion-header-collapsed-state-height: 48px;
  --mat-expansion-header-expanded-state-height: 64px;
}

html {
  --mat-expansion-header-text-font: var(--vex-font);
  --mat-expansion-header-text-size: 14px;
  --mat-expansion-header-text-weight: 500;
  --mat-expansion-header-text-line-height: inherit;
  --mat-expansion-header-text-tracking: inherit;
  --mat-expansion-container-text-font: var(--vex-font);
  --mat-expansion-container-text-line-height: 20px;
  --mat-expansion-container-text-size: 14px;
  --mat-expansion-container-text-tracking: -0.006em;
  --mat-expansion-container-text-weight: 400;
}

html {
  --mat-grid-list-tile-header-primary-text-size: 14px;
  --mat-grid-list-tile-header-secondary-text-size: 12px;
  --mat-grid-list-tile-footer-primary-text-size: 14px;
  --mat-grid-list-tile-footer-secondary-text-size: 12px;
}

html {
  --mat-stepper-header-height: 72px;
}

html {
  --mat-stepper-container-text-font: var(--vex-font);
  --mat-stepper-header-label-text-font: var(--vex-font);
  --mat-stepper-header-label-text-size: 14px;
  --mat-stepper-header-label-text-weight: 400;
  --mat-stepper-header-error-state-label-text-size: 15px;
  --mat-stepper-header-selected-state-label-text-size: 15px;
  --mat-stepper-header-selected-state-label-text-weight: 400;
}

html {
  --mat-toolbar-standard-height: 64px;
  --mat-toolbar-mobile-height: 56px;
}

html {
  --mat-toolbar-title-text-font: var(--vex-font);
  --mat-toolbar-title-text-line-height: 26px;
  --mat-toolbar-title-text-size: 18px;
  --mat-toolbar-title-text-tracking: -0.014em;
  --mat-toolbar-title-text-weight: 500;
}

.mat-tree-node {
  min-height: 48px;
}

.mat-tree {
  font-family: var(--vex-font);
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

/**
 * Override the theme foreground/background colors with the Angular Material colors
 */
.vex-theme-default,
.vex-theme-default.light,
.vex-theme-default .light {
  --mat-table-background-color: white;
  --mat-table-header-headline-color: #030712;
  --mat-table-row-item-label-text-color: #030712;
  --mat-table-row-item-outline-color: rgba(0, 0, 0, 0.06);
}
.vex-theme-default .mat-ripple-element,
.vex-theme-default.light .mat-ripple-element,
.vex-theme-default .light .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.vex-theme-default, .vex-theme-default.light, .vex-theme-default .light {
  --mat-option-selected-state-label-text-color: #4f46e5;
  --mat-option-label-text-color: #030712;
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.vex-theme-default .mat-accent,
.vex-theme-default.light .mat-accent,
.vex-theme-default .light .mat-accent {
  --mat-option-selected-state-label-text-color: #f59e0b;
}
.vex-theme-default .mat-warn,
.vex-theme-default.light .mat-warn,
.vex-theme-default .light .mat-warn {
  --mat-option-selected-state-label-text-color: #ef4444;
}
.vex-theme-default, .vex-theme-default.light, .vex-theme-default .light {
  --mat-optgroup-label-text-color: #030712;
}

.vex-theme-default .mat-pseudo-checkbox-full,
.vex-theme-default.light .mat-pseudo-checkbox-full,
.vex-theme-default .light .mat-pseudo-checkbox-full {
  color: #4b5563;
}
.vex-theme-default .mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled,
.vex-theme-default.light .mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled,
.vex-theme-default .light .mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.vex-theme-default .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-default .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-default.light .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-default.light .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-default .light .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-default .light .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #4f46e5;
}
.vex-theme-default .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-default .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-default.light .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-default.light .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-default .light .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-default .light .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #4f46e5;
}
.vex-theme-default .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-default .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after,
.vex-theme-default.light .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-default.light .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after,
.vex-theme-default .light .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-default .light .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #f3f4f6;
}
.vex-theme-default .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-default .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-default.light .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-default.light .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-default .light .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-default .light .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #f59e0b;
}
.vex-theme-default .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-default .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-default.light .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-default.light .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-default .light .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-default .light .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #f59e0b;
}
.vex-theme-default .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-default .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after,
.vex-theme-default.light .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-default.light .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after,
.vex-theme-default .light .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-default .light .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #f3f4f6;
}
.vex-theme-default .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-default .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-default.light .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-default.light .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-default .light .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-default .light .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #f59e0b;
}
.vex-theme-default .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-default .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-default.light .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-default.light .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-default .light .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-default .light .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #f59e0b;
}
.vex-theme-default .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-default .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after,
.vex-theme-default.light .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-default.light .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after,
.vex-theme-default .light .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-default .light .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #f3f4f6;
}
.vex-theme-default .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-default .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-default.light .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-default.light .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-default .light .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-default .light .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #ef4444;
}
.vex-theme-default .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-default .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-default.light .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-default.light .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-default .light .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-default .light .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #ef4444;
}
.vex-theme-default .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-default .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after,
.vex-theme-default.light .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-default.light .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after,
.vex-theme-default .light .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-default .light .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #f3f4f6;
}
.vex-theme-default .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-default .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-default.light .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-default.light .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-default .light .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-default .light .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #b0b0b0;
}
.vex-theme-default .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-default .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-default.light .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-default.light .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-default .light .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-default .light .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #b0b0b0;
}
.vex-theme-default .mat-app-background, .vex-theme-default.mat-app-background,
.vex-theme-default.light .mat-app-background,
.vex-theme-default.light.mat-app-background,
.vex-theme-default .light .mat-app-background,
.vex-theme-default .light.mat-app-background {
  background-color: #f3f4f6;
  color: #030712;
}
.vex-theme-default .mat-elevation-z0, .vex-theme-default .mat-mdc-elevation-specific.mat-elevation-z0,
.vex-theme-default.light .mat-elevation-z0,
.vex-theme-default.light .mat-mdc-elevation-specific.mat-elevation-z0,
.vex-theme-default .light .mat-elevation-z0,
.vex-theme-default .light .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-default .mat-elevation-z1, .vex-theme-default .mat-mdc-elevation-specific.mat-elevation-z1,
.vex-theme-default.light .mat-elevation-z1,
.vex-theme-default.light .mat-mdc-elevation-specific.mat-elevation-z1,
.vex-theme-default .light .mat-elevation-z1,
.vex-theme-default .light .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-default .mat-elevation-z2, .vex-theme-default .mat-mdc-elevation-specific.mat-elevation-z2,
.vex-theme-default.light .mat-elevation-z2,
.vex-theme-default.light .mat-mdc-elevation-specific.mat-elevation-z2,
.vex-theme-default .light .mat-elevation-z2,
.vex-theme-default .light .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-default .mat-elevation-z3, .vex-theme-default .mat-mdc-elevation-specific.mat-elevation-z3,
.vex-theme-default.light .mat-elevation-z3,
.vex-theme-default.light .mat-mdc-elevation-specific.mat-elevation-z3,
.vex-theme-default .light .mat-elevation-z3,
.vex-theme-default .light .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-default .mat-elevation-z4, .vex-theme-default .mat-mdc-elevation-specific.mat-elevation-z4,
.vex-theme-default.light .mat-elevation-z4,
.vex-theme-default.light .mat-mdc-elevation-specific.mat-elevation-z4,
.vex-theme-default .light .mat-elevation-z4,
.vex-theme-default .light .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-default .mat-elevation-z5, .vex-theme-default .mat-mdc-elevation-specific.mat-elevation-z5,
.vex-theme-default.light .mat-elevation-z5,
.vex-theme-default.light .mat-mdc-elevation-specific.mat-elevation-z5,
.vex-theme-default .light .mat-elevation-z5,
.vex-theme-default .light .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-default .mat-elevation-z6, .vex-theme-default .mat-mdc-elevation-specific.mat-elevation-z6,
.vex-theme-default.light .mat-elevation-z6,
.vex-theme-default.light .mat-mdc-elevation-specific.mat-elevation-z6,
.vex-theme-default .light .mat-elevation-z6,
.vex-theme-default .light .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-default .mat-elevation-z7, .vex-theme-default .mat-mdc-elevation-specific.mat-elevation-z7,
.vex-theme-default.light .mat-elevation-z7,
.vex-theme-default.light .mat-mdc-elevation-specific.mat-elevation-z7,
.vex-theme-default .light .mat-elevation-z7,
.vex-theme-default .light .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.vex-theme-default .mat-elevation-z8, .vex-theme-default .mat-mdc-elevation-specific.mat-elevation-z8,
.vex-theme-default.light .mat-elevation-z8,
.vex-theme-default.light .mat-mdc-elevation-specific.mat-elevation-z8,
.vex-theme-default .light .mat-elevation-z8,
.vex-theme-default .light .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.vex-theme-default .mat-elevation-z9, .vex-theme-default .mat-mdc-elevation-specific.mat-elevation-z9,
.vex-theme-default.light .mat-elevation-z9,
.vex-theme-default.light .mat-mdc-elevation-specific.mat-elevation-z9,
.vex-theme-default .light .mat-elevation-z9,
.vex-theme-default .light .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.vex-theme-default .mat-elevation-z10, .vex-theme-default .mat-mdc-elevation-specific.mat-elevation-z10,
.vex-theme-default.light .mat-elevation-z10,
.vex-theme-default.light .mat-mdc-elevation-specific.mat-elevation-z10,
.vex-theme-default .light .mat-elevation-z10,
.vex-theme-default .light .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.vex-theme-default .mat-elevation-z11, .vex-theme-default .mat-mdc-elevation-specific.mat-elevation-z11,
.vex-theme-default.light .mat-elevation-z11,
.vex-theme-default.light .mat-mdc-elevation-specific.mat-elevation-z11,
.vex-theme-default .light .mat-elevation-z11,
.vex-theme-default .light .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.vex-theme-default .mat-elevation-z12, .vex-theme-default .mat-mdc-elevation-specific.mat-elevation-z12,
.vex-theme-default.light .mat-elevation-z12,
.vex-theme-default.light .mat-mdc-elevation-specific.mat-elevation-z12,
.vex-theme-default .light .mat-elevation-z12,
.vex-theme-default .light .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.vex-theme-default .mat-elevation-z13, .vex-theme-default .mat-mdc-elevation-specific.mat-elevation-z13,
.vex-theme-default.light .mat-elevation-z13,
.vex-theme-default.light .mat-mdc-elevation-specific.mat-elevation-z13,
.vex-theme-default .light .mat-elevation-z13,
.vex-theme-default .light .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.vex-theme-default .mat-elevation-z14, .vex-theme-default .mat-mdc-elevation-specific.mat-elevation-z14,
.vex-theme-default.light .mat-elevation-z14,
.vex-theme-default.light .mat-mdc-elevation-specific.mat-elevation-z14,
.vex-theme-default .light .mat-elevation-z14,
.vex-theme-default .light .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.vex-theme-default .mat-elevation-z15, .vex-theme-default .mat-mdc-elevation-specific.mat-elevation-z15,
.vex-theme-default.light .mat-elevation-z15,
.vex-theme-default.light .mat-mdc-elevation-specific.mat-elevation-z15,
.vex-theme-default .light .mat-elevation-z15,
.vex-theme-default .light .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.vex-theme-default .mat-elevation-z16, .vex-theme-default .mat-mdc-elevation-specific.mat-elevation-z16,
.vex-theme-default.light .mat-elevation-z16,
.vex-theme-default.light .mat-mdc-elevation-specific.mat-elevation-z16,
.vex-theme-default .light .mat-elevation-z16,
.vex-theme-default .light .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.vex-theme-default .mat-elevation-z17, .vex-theme-default .mat-mdc-elevation-specific.mat-elevation-z17,
.vex-theme-default.light .mat-elevation-z17,
.vex-theme-default.light .mat-mdc-elevation-specific.mat-elevation-z17,
.vex-theme-default .light .mat-elevation-z17,
.vex-theme-default .light .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.vex-theme-default .mat-elevation-z18, .vex-theme-default .mat-mdc-elevation-specific.mat-elevation-z18,
.vex-theme-default.light .mat-elevation-z18,
.vex-theme-default.light .mat-mdc-elevation-specific.mat-elevation-z18,
.vex-theme-default .light .mat-elevation-z18,
.vex-theme-default .light .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.vex-theme-default .mat-elevation-z19, .vex-theme-default .mat-mdc-elevation-specific.mat-elevation-z19,
.vex-theme-default.light .mat-elevation-z19,
.vex-theme-default.light .mat-mdc-elevation-specific.mat-elevation-z19,
.vex-theme-default .light .mat-elevation-z19,
.vex-theme-default .light .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.vex-theme-default .mat-elevation-z20, .vex-theme-default .mat-mdc-elevation-specific.mat-elevation-z20,
.vex-theme-default.light .mat-elevation-z20,
.vex-theme-default.light .mat-mdc-elevation-specific.mat-elevation-z20,
.vex-theme-default .light .mat-elevation-z20,
.vex-theme-default .light .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.vex-theme-default .mat-elevation-z21, .vex-theme-default .mat-mdc-elevation-specific.mat-elevation-z21,
.vex-theme-default.light .mat-elevation-z21,
.vex-theme-default.light .mat-mdc-elevation-specific.mat-elevation-z21,
.vex-theme-default .light .mat-elevation-z21,
.vex-theme-default .light .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.vex-theme-default .mat-elevation-z22, .vex-theme-default .mat-mdc-elevation-specific.mat-elevation-z22,
.vex-theme-default.light .mat-elevation-z22,
.vex-theme-default.light .mat-mdc-elevation-specific.mat-elevation-z22,
.vex-theme-default .light .mat-elevation-z22,
.vex-theme-default .light .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.vex-theme-default .mat-elevation-z23, .vex-theme-default .mat-mdc-elevation-specific.mat-elevation-z23,
.vex-theme-default.light .mat-elevation-z23,
.vex-theme-default.light .mat-mdc-elevation-specific.mat-elevation-z23,
.vex-theme-default .light .mat-elevation-z23,
.vex-theme-default .light .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.vex-theme-default .mat-elevation-z24, .vex-theme-default .mat-mdc-elevation-specific.mat-elevation-z24,
.vex-theme-default.light .mat-elevation-z24,
.vex-theme-default.light .mat-mdc-elevation-specific.mat-elevation-z24,
.vex-theme-default .light .mat-elevation-z24,
.vex-theme-default .light .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.vex-theme-default .mat-mdc-card,
.vex-theme-default.light .mat-mdc-card,
.vex-theme-default .light .mat-mdc-card {
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: #4b5563;
}
.vex-theme-default .mat-mdc-progress-bar,
.vex-theme-default.light .mat-mdc-progress-bar,
.vex-theme-default .light .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #4f46e5;
  --mdc-linear-progress-track-color: rgba(79, 70, 229, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.vex-theme-default .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots,
.vex-theme-default.light .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots,
.vex-theme-default .light .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-color: rgba(79, 70, 229, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(79, 70, 229, 0.25));
}
@media (forced-colors: active) {
  .vex-theme-default .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots,
  .vex-theme-default.light .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots,
  .vex-theme-default .light .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .vex-theme-default .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots,
  .vex-theme-default.light .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots,
  .vex-theme-default .light .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(79, 70, 229, 0.25)'/%3E%3C/svg%3E");
  }
}
.vex-theme-default .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar,
.vex-theme-default.light .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar,
.vex-theme-default .light .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgba(79, 70, 229, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(79, 70, 229, 0.25));
}
.vex-theme-default .mat-mdc-progress-bar.mat-accent,
.vex-theme-default.light .mat-mdc-progress-bar.mat-accent,
.vex-theme-default .light .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #f59e0b;
  --mdc-linear-progress-track-color: rgba(245, 158, 11, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.vex-theme-default .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots,
.vex-theme-default.light .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots,
.vex-theme-default .light .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-color: rgba(245, 158, 11, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(245, 158, 11, 0.25));
}
@media (forced-colors: active) {
  .vex-theme-default .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots,
  .vex-theme-default.light .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots,
  .vex-theme-default .light .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .vex-theme-default .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots,
  .vex-theme-default.light .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots,
  .vex-theme-default .light .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(245, 158, 11, 0.25)'/%3E%3C/svg%3E");
  }
}
.vex-theme-default .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar,
.vex-theme-default.light .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar,
.vex-theme-default .light .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: rgba(245, 158, 11, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(245, 158, 11, 0.25));
}
.vex-theme-default .mat-mdc-progress-bar.mat-warn,
.vex-theme-default.light .mat-mdc-progress-bar.mat-warn,
.vex-theme-default .light .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #ef4444;
  --mdc-linear-progress-track-color: rgba(239, 68, 68, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.vex-theme-default .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots,
.vex-theme-default.light .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots,
.vex-theme-default .light .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-color: rgba(239, 68, 68, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(239, 68, 68, 0.25));
}
@media (forced-colors: active) {
  .vex-theme-default .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots,
  .vex-theme-default.light .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots,
  .vex-theme-default .light .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .vex-theme-default .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots,
  .vex-theme-default.light .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots,
  .vex-theme-default .light .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(239, 68, 68, 0.25)'/%3E%3C/svg%3E");
  }
}
.vex-theme-default .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar,
.vex-theme-default.light .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar,
.vex-theme-default .light .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: rgba(239, 68, 68, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(239, 68, 68, 0.25));
}
.vex-theme-default .mat-mdc-tooltip,
.vex-theme-default.light .mat-mdc-tooltip,
.vex-theme-default .light .mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #1f2937;
  --mdc-plain-tooltip-supporting-text-color: #fff;
}
.vex-theme-default, .vex-theme-default.light, .vex-theme-default .light {
  --mdc-filled-text-field-caret-color: #4f46e5;
  --mdc-filled-text-field-focus-active-indicator-color: #4f46e5;
  --mdc-filled-text-field-focus-label-text-color: rgba(79, 70, 229, 0.87);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-error-focus-label-text-color: #ef4444;
  --mdc-filled-text-field-error-label-text-color: #ef4444;
  --mdc-filled-text-field-error-caret-color: #ef4444;
  --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #ef4444;
  --mdc-filled-text-field-error-focus-active-indicator-color: #ef4444;
  --mdc-filled-text-field-error-hover-active-indicator-color: #ef4444;
  --mdc-outlined-text-field-caret-color: #4f46e5;
  --mdc-outlined-text-field-focus-outline-color: #4f46e5;
  --mdc-outlined-text-field-focus-label-text-color: rgba(79, 70, 229, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-error-caret-color: #ef4444;
  --mdc-outlined-text-field-error-focus-label-text-color: #ef4444;
  --mdc-outlined-text-field-error-label-text-color: #ef4444;
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #ef4444;
  --mdc-outlined-text-field-error-hover-outline-color: #ef4444;
  --mdc-outlined-text-field-error-outline-color: #ef4444;
  --mat-form-field-disabled-input-text-placeholder-color: rgba(0, 0, 0, 0.38);
}

.vex-theme-default .mat-mdc-form-field-error,
.vex-theme-default.light .mat-mdc-form-field-error,
.vex-theme-default .light .mat-mdc-form-field-error {
  color: var(--mdc-theme-error, #ef4444);
}
.vex-theme-default .mat-mdc-form-field-subscript-wrapper,
.vex-theme-default .mat-mdc-form-field-bottom-align::before,
.vex-theme-default.light .mat-mdc-form-field-subscript-wrapper,
.vex-theme-default.light .mat-mdc-form-field-bottom-align::before,
.vex-theme-default .light .mat-mdc-form-field-subscript-wrapper,
.vex-theme-default .light .mat-mdc-form-field-bottom-align::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mat-form-field-subscript-text-font);
  line-height: var(--mat-form-field-subscript-text-line-height);
  font-size: var(--mat-form-field-subscript-text-size);
  letter-spacing: var(--mat-form-field-subscript-text-tracking);
  font-weight: var(--mat-form-field-subscript-text-weight);
}
.vex-theme-default .mat-mdc-form-field-focus-overlay,
.vex-theme-default.light .mat-mdc-form-field-focus-overlay,
.vex-theme-default .light .mat-mdc-form-field-focus-overlay {
  background-color: rgba(0, 0, 0, 0.87);
}
.vex-theme-default .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay,
.vex-theme-default.light .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay,
.vex-theme-default .light .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0.04;
}
.vex-theme-default .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay,
.vex-theme-default.light .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay,
.vex-theme-default .light .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.12;
}
.vex-theme-default .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after,
.vex-theme-default.light .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after,
.vex-theme-default .light .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.vex-theme-default .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after,
.vex-theme-default.light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after,
.vex-theme-default .light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after {
  color: rgba(79, 70, 229, 0.87);
}
.vex-theme-default .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after,
.vex-theme-default.light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after,
.vex-theme-default .light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after {
  color: rgba(245, 158, 11, 0.87);
}
.vex-theme-default .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after,
.vex-theme-default.light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after,
.vex-theme-default .light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after {
  color: rgba(239, 68, 68, 0.87);
}
.vex-theme-default .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after,
.vex-theme-default.light .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after,
.vex-theme-default .light .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.vex-theme-default .mat-mdc-form-field.mat-accent,
.vex-theme-default.light .mat-mdc-form-field.mat-accent,
.vex-theme-default .light .mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #f59e0b;
  --mdc-filled-text-field-focus-active-indicator-color: #f59e0b;
  --mdc-filled-text-field-focus-label-text-color: rgba(245, 158, 11, 0.87);
  --mdc-outlined-text-field-caret-color: #f59e0b;
  --mdc-outlined-text-field-focus-outline-color: #f59e0b;
  --mdc-outlined-text-field-focus-label-text-color: rgba(245, 158, 11, 0.87);
}
.vex-theme-default .mat-mdc-form-field.mat-warn,
.vex-theme-default.light .mat-mdc-form-field.mat-warn,
.vex-theme-default .light .mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #ef4444;
  --mdc-filled-text-field-focus-active-indicator-color: #ef4444;
  --mdc-filled-text-field-focus-label-text-color: rgba(239, 68, 68, 0.87);
  --mdc-outlined-text-field-caret-color: #ef4444;
  --mdc-outlined-text-field-focus-outline-color: #ef4444;
  --mdc-outlined-text-field-focus-label-text-color: rgba(239, 68, 68, 0.87);
}
.vex-theme-default .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch,
.vex-theme-default.light .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch,
.vex-theme-default .light .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: 1px solid transparent;
}
.vex-theme-default [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch,
.vex-theme-default.light [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch,
.vex-theme-default .light [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none;
  border-right: 1px solid transparent;
}
.vex-theme-default, .vex-theme-default.light, .vex-theme-default .light {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(79, 70, 229, 0.87);
  --mat-select-invalid-arrow-color: rgba(239, 68, 68, 0.87);
}
.vex-theme-default .mat-mdc-form-field.mat-accent, .vex-theme-default.light .mat-mdc-form-field.mat-accent, .vex-theme-default .light .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(245, 158, 11, 0.87);
  --mat-select-invalid-arrow-color: rgba(239, 68, 68, 0.87);
}
.vex-theme-default .mat-mdc-form-field.mat-warn, .vex-theme-default.light .mat-mdc-form-field.mat-warn, .vex-theme-default .light .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(239, 68, 68, 0.87);
  --mat-select-invalid-arrow-color: rgba(239, 68, 68, 0.87);
}

.vex-theme-default, .vex-theme-default.light, .vex-theme-default .light {
  --mat-autocomplete-background-color: white;
}

.vex-theme-default .mat-mdc-dialog-container,
.vex-theme-default.light .mat-mdc-dialog-container,
.vex-theme-default .light .mat-mdc-dialog-container {
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}
.vex-theme-default .mat-mdc-standard-chip,
.vex-theme-default.light .mat-mdc-standard-chip,
.vex-theme-default .light .mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
}
.vex-theme-default .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .vex-theme-default .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary,
.vex-theme-default.light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary,
.vex-theme-default.light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary,
.vex-theme-default .light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary,
.vex-theme-default .light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #4f46e5;
  --mdc-chip-elevated-disabled-container-color: #4f46e5;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.vex-theme-default .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .vex-theme-default .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent,
.vex-theme-default.light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent,
.vex-theme-default.light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent,
.vex-theme-default .light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent,
.vex-theme-default .light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #f59e0b;
  --mdc-chip-elevated-disabled-container-color: #f59e0b;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.vex-theme-default .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .vex-theme-default .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn,
.vex-theme-default.light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn,
.vex-theme-default.light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn,
.vex-theme-default .light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn,
.vex-theme-default .light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #ef4444;
  --mdc-chip-elevated-disabled-container-color: #ef4444;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.vex-theme-default .mat-mdc-slide-toggle,
.vex-theme-default.light .mat-mdc-slide-toggle,
.vex-theme-default .light .mat-mdc-slide-toggle {
  --mdc-switch-selected-focus-state-layer-color: #4f46e5;
  --mdc-switch-selected-handle-color: #4f46e5;
  --mdc-switch-selected-hover-state-layer-color: #4f46e5;
  --mdc-switch-selected-pressed-state-layer-color: #4f46e5;
  --mdc-switch-selected-focus-handle-color: #312e81;
  --mdc-switch-selected-hover-handle-color: #312e81;
  --mdc-switch-selected-pressed-handle-color: #312e81;
  --mdc-switch-selected-focus-track-color: #a5b4fc;
  --mdc-switch-selected-hover-track-color: #a5b4fc;
  --mdc-switch-selected-pressed-track-color: #a5b4fc;
  --mdc-switch-selected-track-color: #a5b4fc;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
}
.vex-theme-default .mat-mdc-slide-toggle .mdc-form-field,
.vex-theme-default.light .mat-mdc-slide-toggle .mdc-form-field,
.vex-theme-default .light .mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.vex-theme-default .mat-mdc-slide-toggle .mdc-switch--disabled + label,
.vex-theme-default.light .mat-mdc-slide-toggle .mdc-switch--disabled + label,
.vex-theme-default .light .mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: #6b7280;
}
.vex-theme-default .mat-mdc-slide-toggle.mat-accent,
.vex-theme-default.light .mat-mdc-slide-toggle.mat-accent,
.vex-theme-default .light .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #d97706;
  --mdc-switch-selected-handle-color: #d97706;
  --mdc-switch-selected-hover-state-layer-color: #d97706;
  --mdc-switch-selected-pressed-state-layer-color: #d97706;
  --mdc-switch-selected-focus-handle-color: #78350f;
  --mdc-switch-selected-hover-handle-color: #78350f;
  --mdc-switch-selected-pressed-handle-color: #78350f;
  --mdc-switch-selected-focus-track-color: #fcd34d;
  --mdc-switch-selected-hover-track-color: #fcd34d;
  --mdc-switch-selected-pressed-track-color: #fcd34d;
  --mdc-switch-selected-track-color: #fcd34d;
}
.vex-theme-default .mat-mdc-slide-toggle.mat-warn,
.vex-theme-default.light .mat-mdc-slide-toggle.mat-warn,
.vex-theme-default .light .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #dc2626;
  --mdc-switch-selected-handle-color: #dc2626;
  --mdc-switch-selected-hover-state-layer-color: #dc2626;
  --mdc-switch-selected-pressed-state-layer-color: #dc2626;
  --mdc-switch-selected-focus-handle-color: #7f1d1d;
  --mdc-switch-selected-hover-handle-color: #7f1d1d;
  --mdc-switch-selected-pressed-handle-color: #7f1d1d;
  --mdc-switch-selected-focus-track-color: #fca5a5;
  --mdc-switch-selected-hover-track-color: #fca5a5;
  --mdc-switch-selected-pressed-track-color: #fca5a5;
  --mdc-switch-selected-track-color: #fca5a5;
}
.vex-theme-default .mat-mdc-radio-button .mdc-form-field,
.vex-theme-default.light .mat-mdc-radio-button .mdc-form-field,
.vex-theme-default .light .mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.vex-theme-default .mat-mdc-radio-button.mat-primary,
.vex-theme-default.light .mat-mdc-radio-button.mat-primary,
.vex-theme-default .light .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #4f46e5;
  --mdc-radio-selected-hover-icon-color: #4f46e5;
  --mdc-radio-selected-icon-color: #4f46e5;
  --mdc-radio-selected-pressed-icon-color: #4f46e5;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #4f46e5;
  --mat-radio-disabled-label-color: #6b7280;
}
.vex-theme-default .mat-mdc-radio-button.mat-accent,
.vex-theme-default.light .mat-mdc-radio-button.mat-accent,
.vex-theme-default .light .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f59e0b;
  --mdc-radio-selected-hover-icon-color: #f59e0b;
  --mdc-radio-selected-icon-color: #f59e0b;
  --mdc-radio-selected-pressed-icon-color: #f59e0b;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #f59e0b;
  --mat-radio-disabled-label-color: #6b7280;
}
.vex-theme-default .mat-mdc-radio-button.mat-warn,
.vex-theme-default.light .mat-mdc-radio-button.mat-warn,
.vex-theme-default .light .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #ef4444;
  --mdc-radio-selected-hover-icon-color: #ef4444;
  --mdc-radio-selected-icon-color: #ef4444;
  --mdc-radio-selected-pressed-icon-color: #ef4444;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #ef4444;
  --mat-radio-disabled-label-color: #6b7280;
}
.vex-theme-default .mat-mdc-slider,
.vex-theme-default.light .mat-mdc-slider,
.vex-theme-default .light .mat-mdc-slider {
  --mdc-slider-label-container-color: black;
  --mdc-slider-label-label-text-color: white;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-mdc-slider-value-indicator-opacity: 0.6;
}
.vex-theme-default .mat-mdc-slider.mat-primary,
.vex-theme-default.light .mat-mdc-slider.mat-primary,
.vex-theme-default .light .mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #4f46e5;
  --mdc-slider-focus-handle-color: #4f46e5;
  --mdc-slider-hover-handle-color: #4f46e5;
  --mdc-slider-active-track-color: #4f46e5;
  --mdc-slider-inactive-track-color: #4f46e5;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #4f46e5;
  --mat-mdc-slider-ripple-color: #4f46e5;
  --mat-mdc-slider-hover-ripple-color: rgba(79, 70, 229, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(79, 70, 229, 0.2);
}
.vex-theme-default .mat-mdc-slider.mat-accent,
.vex-theme-default.light .mat-mdc-slider.mat-accent,
.vex-theme-default .light .mat-mdc-slider.mat-accent {
  --mdc-slider-handle-color: #f59e0b;
  --mdc-slider-focus-handle-color: #f59e0b;
  --mdc-slider-hover-handle-color: #f59e0b;
  --mdc-slider-active-track-color: #f59e0b;
  --mdc-slider-inactive-track-color: #f59e0b;
  --mdc-slider-with-tick-marks-active-container-color: #000;
  --mdc-slider-with-tick-marks-inactive-container-color: #f59e0b;
  --mat-mdc-slider-ripple-color: #f59e0b;
  --mat-mdc-slider-hover-ripple-color: rgba(245, 158, 11, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(245, 158, 11, 0.2);
}
.vex-theme-default .mat-mdc-slider.mat-warn,
.vex-theme-default.light .mat-mdc-slider.mat-warn,
.vex-theme-default .light .mat-mdc-slider.mat-warn {
  --mdc-slider-handle-color: #ef4444;
  --mdc-slider-focus-handle-color: #ef4444;
  --mdc-slider-hover-handle-color: #ef4444;
  --mdc-slider-active-track-color: #ef4444;
  --mdc-slider-inactive-track-color: #ef4444;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #ef4444;
  --mat-mdc-slider-ripple-color: #ef4444;
  --mat-mdc-slider-hover-ripple-color: rgba(239, 68, 68, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(239, 68, 68, 0.2);
}
.vex-theme-default, .vex-theme-default.light, .vex-theme-default .light {
  --mat-menu-item-label-text-color: #030712;
  --mat-menu-item-icon-color: #030712;
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
}

.vex-theme-default .mat-mdc-list-base,
.vex-theme-default.light .mat-mdc-list-base,
.vex-theme-default .light .mat-mdc-list-base {
  --mdc-list-list-item-label-text-color: #030712;
  --mdc-list-list-item-supporting-text-color: #4b5563;
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: #6b7280;
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: #030712;
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: #030712;
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
}
.vex-theme-default .mdc-list-item__start,
.vex-theme-default .mdc-list-item__end,
.vex-theme-default.light .mdc-list-item__start,
.vex-theme-default.light .mdc-list-item__end,
.vex-theme-default .light .mdc-list-item__start,
.vex-theme-default .light .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #4f46e5;
  --mdc-radio-selected-hover-icon-color: #4f46e5;
  --mdc-radio-selected-icon-color: #4f46e5;
  --mdc-radio-selected-pressed-icon-color: #4f46e5;
}
.vex-theme-default .mat-accent .mdc-list-item__start,
.vex-theme-default .mat-accent .mdc-list-item__end,
.vex-theme-default.light .mat-accent .mdc-list-item__start,
.vex-theme-default.light .mat-accent .mdc-list-item__end,
.vex-theme-default .light .mat-accent .mdc-list-item__start,
.vex-theme-default .light .mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f59e0b;
  --mdc-radio-selected-hover-icon-color: #f59e0b;
  --mdc-radio-selected-icon-color: #f59e0b;
  --mdc-radio-selected-pressed-icon-color: #f59e0b;
}
.vex-theme-default .mat-warn .mdc-list-item__start,
.vex-theme-default .mat-warn .mdc-list-item__end,
.vex-theme-default.light .mat-warn .mdc-list-item__start,
.vex-theme-default.light .mat-warn .mdc-list-item__end,
.vex-theme-default .light .mat-warn .mdc-list-item__start,
.vex-theme-default .light .mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #ef4444;
  --mdc-radio-selected-hover-icon-color: #ef4444;
  --mdc-radio-selected-icon-color: #ef4444;
  --mdc-radio-selected-pressed-icon-color: #ef4444;
}
.vex-theme-default .mat-mdc-list-option,
.vex-theme-default.light .mat-mdc-list-option,
.vex-theme-default .light .mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #4f46e5;
  --mdc-checkbox-selected-hover-icon-color: #4f46e5;
  --mdc-checkbox-selected-icon-color: #4f46e5;
  --mdc-checkbox-selected-pressed-icon-color: #4f46e5;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #4f46e5;
  --mdc-checkbox-selected-hover-state-layer-color: #4f46e5;
  --mdc-checkbox-selected-pressed-state-layer-color: #4f46e5;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.vex-theme-default .mat-mdc-list-option.mat-accent,
.vex-theme-default.light .mat-mdc-list-option.mat-accent,
.vex-theme-default .light .mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #f59e0b;
  --mdc-checkbox-selected-hover-icon-color: #f59e0b;
  --mdc-checkbox-selected-icon-color: #f59e0b;
  --mdc-checkbox-selected-pressed-icon-color: #f59e0b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f59e0b;
  --mdc-checkbox-selected-hover-state-layer-color: #f59e0b;
  --mdc-checkbox-selected-pressed-state-layer-color: #f59e0b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.vex-theme-default .mat-mdc-list-option.mat-warn,
.vex-theme-default.light .mat-mdc-list-option.mat-warn,
.vex-theme-default .light .mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #ef4444;
  --mdc-checkbox-selected-hover-icon-color: #ef4444;
  --mdc-checkbox-selected-icon-color: #ef4444;
  --mdc-checkbox-selected-pressed-icon-color: #ef4444;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #ef4444;
  --mdc-checkbox-selected-hover-state-layer-color: #ef4444;
  --mdc-checkbox-selected-pressed-state-layer-color: #ef4444;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.vex-theme-default .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.vex-theme-default .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,
.vex-theme-default.light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.vex-theme-default.light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,
.vex-theme-default .light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.vex-theme-default .light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #4f46e5;
}
.vex-theme-default .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.vex-theme-default .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.vex-theme-default.light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.vex-theme-default.light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.vex-theme-default .light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.vex-theme-default .light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #4f46e5;
}
.vex-theme-default .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.vex-theme-default .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.vex-theme-default .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end,
.vex-theme-default.light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.vex-theme-default.light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.vex-theme-default.light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end,
.vex-theme-default .light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.vex-theme-default .light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.vex-theme-default .light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.vex-theme-default, .vex-theme-default.light, .vex-theme-default .light {
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
}

.vex-theme-default .mat-mdc-tab-group, .vex-theme-default .mat-mdc-tab-nav-bar,
.vex-theme-default.light .mat-mdc-tab-group,
.vex-theme-default.light .mat-mdc-tab-nav-bar,
.vex-theme-default .light .mat-mdc-tab-group,
.vex-theme-default .light .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #4f46e5;
  --mat-tab-header-disabled-ripple-color: #6b7280;
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #4f46e5;
  --mat-tab-header-active-ripple-color: #4f46e5;
  --mat-tab-header-inactive-ripple-color: #4f46e5;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #4f46e5;
  --mat-tab-header-active-hover-label-text-color: #4f46e5;
  --mat-tab-header-active-focus-indicator-color: #4f46e5;
  --mat-tab-header-active-hover-indicator-color: #4f46e5;
}
.vex-theme-default .mat-mdc-tab-group.mat-accent, .vex-theme-default .mat-mdc-tab-nav-bar.mat-accent,
.vex-theme-default.light .mat-mdc-tab-group.mat-accent,
.vex-theme-default.light .mat-mdc-tab-nav-bar.mat-accent,
.vex-theme-default .light .mat-mdc-tab-group.mat-accent,
.vex-theme-default .light .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #f59e0b;
  --mat-tab-header-disabled-ripple-color: #6b7280;
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #f59e0b;
  --mat-tab-header-active-ripple-color: #f59e0b;
  --mat-tab-header-inactive-ripple-color: #f59e0b;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #f59e0b;
  --mat-tab-header-active-hover-label-text-color: #f59e0b;
  --mat-tab-header-active-focus-indicator-color: #f59e0b;
  --mat-tab-header-active-hover-indicator-color: #f59e0b;
}
.vex-theme-default .mat-mdc-tab-group.mat-warn, .vex-theme-default .mat-mdc-tab-nav-bar.mat-warn,
.vex-theme-default.light .mat-mdc-tab-group.mat-warn,
.vex-theme-default.light .mat-mdc-tab-nav-bar.mat-warn,
.vex-theme-default .light .mat-mdc-tab-group.mat-warn,
.vex-theme-default .light .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #ef4444;
  --mat-tab-header-disabled-ripple-color: #6b7280;
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #ef4444;
  --mat-tab-header-active-ripple-color: #ef4444;
  --mat-tab-header-inactive-ripple-color: #ef4444;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #ef4444;
  --mat-tab-header-active-hover-label-text-color: #ef4444;
  --mat-tab-header-active-focus-indicator-color: #ef4444;
  --mat-tab-header-active-hover-indicator-color: #ef4444;
}
.vex-theme-default .mat-mdc-tab-group.mat-background-primary, .vex-theme-default .mat-mdc-tab-nav-bar.mat-background-primary,
.vex-theme-default.light .mat-mdc-tab-group.mat-background-primary,
.vex-theme-default.light .mat-mdc-tab-nav-bar.mat-background-primary,
.vex-theme-default .light .mat-mdc-tab-group.mat-background-primary,
.vex-theme-default .light .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #4f46e5;
  --mat-tab-header-with-background-foreground-color: white;
}
.vex-theme-default .mat-mdc-tab-group.mat-background-accent, .vex-theme-default .mat-mdc-tab-nav-bar.mat-background-accent,
.vex-theme-default.light .mat-mdc-tab-group.mat-background-accent,
.vex-theme-default.light .mat-mdc-tab-nav-bar.mat-background-accent,
.vex-theme-default .light .mat-mdc-tab-group.mat-background-accent,
.vex-theme-default .light .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #f59e0b;
  --mat-tab-header-with-background-foreground-color: white;
}
.vex-theme-default .mat-mdc-tab-group.mat-background-warn, .vex-theme-default .mat-mdc-tab-nav-bar.mat-background-warn,
.vex-theme-default.light .mat-mdc-tab-group.mat-background-warn,
.vex-theme-default.light .mat-mdc-tab-nav-bar.mat-background-warn,
.vex-theme-default .light .mat-mdc-tab-group.mat-background-warn,
.vex-theme-default .light .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #ef4444;
  --mat-tab-header-with-background-foreground-color: white;
}
.vex-theme-default, .vex-theme-default.light, .vex-theme-default .light {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #f59e0b;
  --mdc-checkbox-selected-hover-icon-color: #f59e0b;
  --mdc-checkbox-selected-icon-color: #f59e0b;
  --mdc-checkbox-selected-pressed-icon-color: #f59e0b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f59e0b;
  --mdc-checkbox-selected-hover-state-layer-color: #f59e0b;
  --mdc-checkbox-selected-pressed-state-layer-color: #f59e0b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.vex-theme-default .mat-mdc-checkbox.mat-primary,
.vex-theme-default.light .mat-mdc-checkbox.mat-primary,
.vex-theme-default .light .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #4f46e5;
  --mdc-checkbox-selected-hover-icon-color: #4f46e5;
  --mdc-checkbox-selected-icon-color: #4f46e5;
  --mdc-checkbox-selected-pressed-icon-color: #4f46e5;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #4f46e5;
  --mdc-checkbox-selected-hover-state-layer-color: #4f46e5;
  --mdc-checkbox-selected-pressed-state-layer-color: #4f46e5;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.vex-theme-default .mat-mdc-checkbox.mat-warn,
.vex-theme-default.light .mat-mdc-checkbox.mat-warn,
.vex-theme-default .light .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #ef4444;
  --mdc-checkbox-selected-hover-icon-color: #ef4444;
  --mdc-checkbox-selected-icon-color: #ef4444;
  --mdc-checkbox-selected-pressed-icon-color: #ef4444;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #ef4444;
  --mdc-checkbox-selected-hover-state-layer-color: #ef4444;
  --mdc-checkbox-selected-pressed-state-layer-color: #ef4444;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.vex-theme-default .mat-mdc-checkbox .mdc-form-field,
.vex-theme-default.light .mat-mdc-checkbox .mdc-form-field,
.vex-theme-default .light .mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.vex-theme-default .mat-mdc-checkbox.mat-mdc-checkbox-disabled label,
.vex-theme-default.light .mat-mdc-checkbox.mat-mdc-checkbox-disabled label,
.vex-theme-default .light .mat-mdc-checkbox.mat-mdc-checkbox-disabled label {
  color: #6b7280;
}
.vex-theme-default .mat-mdc-button.mat-unthemed,
.vex-theme-default.light .mat-mdc-button.mat-unthemed,
.vex-theme-default .light .mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #000;
}
.vex-theme-default .mat-mdc-button.mat-primary,
.vex-theme-default.light .mat-mdc-button.mat-primary,
.vex-theme-default .light .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #4f46e5;
}
.vex-theme-default .mat-mdc-button.mat-accent,
.vex-theme-default.light .mat-mdc-button.mat-accent,
.vex-theme-default .light .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #f59e0b;
}
.vex-theme-default .mat-mdc-button.mat-warn,
.vex-theme-default.light .mat-mdc-button.mat-warn,
.vex-theme-default .light .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #ef4444;
}
.vex-theme-default .mat-mdc-button[disabled][disabled],
.vex-theme-default.light .mat-mdc-button[disabled][disabled],
.vex-theme-default .light .mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-text-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.vex-theme-default .mat-mdc-unelevated-button.mat-unthemed,
.vex-theme-default.light .mat-mdc-unelevated-button.mat-unthemed,
.vex-theme-default .light .mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #fff;
  --mdc-filled-button-label-text-color: #000;
}
.vex-theme-default .mat-mdc-unelevated-button.mat-primary,
.vex-theme-default.light .mat-mdc-unelevated-button.mat-primary,
.vex-theme-default .light .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #4f46e5;
  --mdc-filled-button-label-text-color: #fff;
}
.vex-theme-default .mat-mdc-unelevated-button.mat-accent,
.vex-theme-default.light .mat-mdc-unelevated-button.mat-accent,
.vex-theme-default .light .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #f59e0b;
  --mdc-filled-button-label-text-color: #000;
}
.vex-theme-default .mat-mdc-unelevated-button.mat-warn,
.vex-theme-default.light .mat-mdc-unelevated-button.mat-warn,
.vex-theme-default .light .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #ef4444;
  --mdc-filled-button-label-text-color: #fff;
}
.vex-theme-default .mat-mdc-unelevated-button[disabled][disabled],
.vex-theme-default.light .mat-mdc-unelevated-button[disabled][disabled],
.vex-theme-default .light .mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.vex-theme-default .mat-mdc-raised-button.mat-unthemed,
.vex-theme-default.light .mat-mdc-raised-button.mat-unthemed,
.vex-theme-default .light .mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #fff;
  --mdc-protected-button-label-text-color: #000;
}
.vex-theme-default .mat-mdc-raised-button.mat-primary,
.vex-theme-default.light .mat-mdc-raised-button.mat-primary,
.vex-theme-default .light .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #4f46e5;
  --mdc-protected-button-label-text-color: #fff;
}
.vex-theme-default .mat-mdc-raised-button.mat-accent,
.vex-theme-default.light .mat-mdc-raised-button.mat-accent,
.vex-theme-default .light .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #f59e0b;
  --mdc-protected-button-label-text-color: #000;
}
.vex-theme-default .mat-mdc-raised-button.mat-warn,
.vex-theme-default.light .mat-mdc-raised-button.mat-warn,
.vex-theme-default .light .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #ef4444;
  --mdc-protected-button-label-text-color: #fff;
}
.vex-theme-default .mat-mdc-raised-button[disabled][disabled],
.vex-theme-default.light .mat-mdc-raised-button[disabled][disabled],
.vex-theme-default .light .mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation: 0;
}
.vex-theme-default .mat-mdc-outlined-button,
.vex-theme-default.light .mat-mdc-outlined-button,
.vex-theme-default .light .mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.vex-theme-default .mat-mdc-outlined-button.mat-unthemed,
.vex-theme-default.light .mat-mdc-outlined-button.mat-unthemed,
.vex-theme-default .light .mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #000;
}
.vex-theme-default .mat-mdc-outlined-button.mat-primary,
.vex-theme-default.light .mat-mdc-outlined-button.mat-primary,
.vex-theme-default .light .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #4f46e5;
}
.vex-theme-default .mat-mdc-outlined-button.mat-accent,
.vex-theme-default.light .mat-mdc-outlined-button.mat-accent,
.vex-theme-default .light .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #f59e0b;
}
.vex-theme-default .mat-mdc-outlined-button.mat-warn,
.vex-theme-default.light .mat-mdc-outlined-button.mat-warn,
.vex-theme-default .light .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #ef4444;
}
.vex-theme-default .mat-mdc-outlined-button[disabled][disabled],
.vex-theme-default.light .mat-mdc-outlined-button[disabled][disabled],
.vex-theme-default .light .mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
}
.vex-theme-default .mat-mdc-button, .vex-theme-default .mat-mdc-outlined-button,
.vex-theme-default.light .mat-mdc-button,
.vex-theme-default.light .mat-mdc-outlined-button,
.vex-theme-default .light .mat-mdc-button,
.vex-theme-default .light .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.vex-theme-default .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .vex-theme-default .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-default.light .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-default.light .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-default .light .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-default .light .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.vex-theme-default .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-default .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-default .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-default .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-default.light .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-default.light .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-default.light .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-default.light .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-default .light .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-default .light .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-default .light .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-default .light .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.vex-theme-default .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .vex-theme-default .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-default.light .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-default.light .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-default .light .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-default .light .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.vex-theme-default .mat-mdc-button.mat-primary, .vex-theme-default .mat-mdc-outlined-button.mat-primary,
.vex-theme-default.light .mat-mdc-button.mat-primary,
.vex-theme-default.light .mat-mdc-outlined-button.mat-primary,
.vex-theme-default .light .mat-mdc-button.mat-primary,
.vex-theme-default .light .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #4f46e5;
  --mat-mdc-button-ripple-color: rgba(79, 70, 229, 0.1);
}
.vex-theme-default .mat-mdc-button.mat-accent, .vex-theme-default .mat-mdc-outlined-button.mat-accent,
.vex-theme-default.light .mat-mdc-button.mat-accent,
.vex-theme-default.light .mat-mdc-outlined-button.mat-accent,
.vex-theme-default .light .mat-mdc-button.mat-accent,
.vex-theme-default .light .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #f59e0b;
  --mat-mdc-button-ripple-color: rgba(245, 158, 11, 0.1);
}
.vex-theme-default .mat-mdc-button.mat-warn, .vex-theme-default .mat-mdc-outlined-button.mat-warn,
.vex-theme-default.light .mat-mdc-button.mat-warn,
.vex-theme-default.light .mat-mdc-outlined-button.mat-warn,
.vex-theme-default .light .mat-mdc-button.mat-warn,
.vex-theme-default .light .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #ef4444;
  --mat-mdc-button-ripple-color: rgba(239, 68, 68, 0.1);
}
.vex-theme-default .mat-mdc-raised-button, .vex-theme-default .mat-mdc-unelevated-button,
.vex-theme-default.light .mat-mdc-raised-button,
.vex-theme-default.light .mat-mdc-unelevated-button,
.vex-theme-default .light .mat-mdc-raised-button,
.vex-theme-default .light .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.vex-theme-default .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .vex-theme-default .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-default.light .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-default.light .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-default .light .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-default .light .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.vex-theme-default .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-default .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-default .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-default .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-default.light .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-default.light .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-default.light .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-default.light .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-default .light .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-default .light .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-default .light .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-default .light .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.vex-theme-default .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .vex-theme-default .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-default.light .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-default.light .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-default .light .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-default .light .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.vex-theme-default .mat-mdc-raised-button.mat-primary, .vex-theme-default .mat-mdc-unelevated-button.mat-primary,
.vex-theme-default.light .mat-mdc-raised-button.mat-primary,
.vex-theme-default.light .mat-mdc-unelevated-button.mat-primary,
.vex-theme-default .light .mat-mdc-raised-button.mat-primary,
.vex-theme-default .light .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.vex-theme-default .mat-mdc-raised-button.mat-accent, .vex-theme-default .mat-mdc-unelevated-button.mat-accent,
.vex-theme-default.light .mat-mdc-raised-button.mat-accent,
.vex-theme-default.light .mat-mdc-unelevated-button.mat-accent,
.vex-theme-default .light .mat-mdc-raised-button.mat-accent,
.vex-theme-default .light .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.vex-theme-default .mat-mdc-raised-button.mat-warn, .vex-theme-default .mat-mdc-unelevated-button.mat-warn,
.vex-theme-default.light .mat-mdc-raised-button.mat-warn,
.vex-theme-default.light .mat-mdc-unelevated-button.mat-warn,
.vex-theme-default .light .mat-mdc-raised-button.mat-warn,
.vex-theme-default .light .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.vex-theme-default .mat-mdc-icon-button,
.vex-theme-default.light .mat-mdc-icon-button,
.vex-theme-default .light .mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mdc-icon-button-icon-color: inherit;
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.vex-theme-default .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-default.light .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-default .light .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.vex-theme-default .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-default .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-default.light .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-default.light .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-default .light .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-default .light .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.vex-theme-default .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-default.light .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-default .light .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.vex-theme-default .mat-mdc-icon-button.mat-primary,
.vex-theme-default.light .mat-mdc-icon-button.mat-primary,
.vex-theme-default .light .mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #6200ee;
  --mat-mdc-button-ripple-color: rgba(98, 0, 238, 0.1);
}
.vex-theme-default .mat-mdc-icon-button.mat-accent,
.vex-theme-default.light .mat-mdc-icon-button.mat-accent,
.vex-theme-default .light .mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #018786;
  --mat-mdc-button-ripple-color: rgba(1, 135, 134, 0.1);
}
.vex-theme-default .mat-mdc-icon-button.mat-warn,
.vex-theme-default.light .mat-mdc-icon-button.mat-warn,
.vex-theme-default .light .mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #b00020;
  --mat-mdc-button-ripple-color: rgba(176, 0, 32, 0.1);
}
.vex-theme-default .mat-mdc-icon-button.mat-primary,
.vex-theme-default.light .mat-mdc-icon-button.mat-primary,
.vex-theme-default .light .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #4f46e5;
  --mat-mdc-button-persistent-ripple-color: #4f46e5;
  --mat-mdc-button-ripple-color: rgba(79, 70, 229, 0.1);
}
.vex-theme-default .mat-mdc-icon-button.mat-accent,
.vex-theme-default.light .mat-mdc-icon-button.mat-accent,
.vex-theme-default .light .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #f59e0b;
  --mat-mdc-button-persistent-ripple-color: #f59e0b;
  --mat-mdc-button-ripple-color: rgba(245, 158, 11, 0.1);
}
.vex-theme-default .mat-mdc-icon-button.mat-warn,
.vex-theme-default.light .mat-mdc-icon-button.mat-warn,
.vex-theme-default .light .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #ef4444;
  --mat-mdc-button-persistent-ripple-color: #ef4444;
  --mat-mdc-button-ripple-color: rgba(239, 68, 68, 0.1);
}
.vex-theme-default .mat-mdc-icon-button[disabled][disabled],
.vex-theme-default.light .mat-mdc-icon-button[disabled][disabled],
.vex-theme-default .light .mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
}
.vex-theme-default .mat-mdc-fab,
.vex-theme-default .mat-mdc-mini-fab,
.vex-theme-default.light .mat-mdc-fab,
.vex-theme-default.light .mat-mdc-mini-fab,
.vex-theme-default .light .mat-mdc-fab,
.vex-theme-default .light .mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.vex-theme-default .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-default .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-default.light .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-default.light .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-default .light .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-default .light .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.vex-theme-default .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-default .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-default .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-default .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-default.light .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-default.light .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-default.light .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-default.light .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-default .light .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-default .light .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-default .light .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-default .light .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.vex-theme-default .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-default .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-default.light .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-default.light .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-default .light .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-default .light .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.vex-theme-default .mat-mdc-fab.mat-primary,
.vex-theme-default .mat-mdc-mini-fab.mat-primary,
.vex-theme-default.light .mat-mdc-fab.mat-primary,
.vex-theme-default.light .mat-mdc-mini-fab.mat-primary,
.vex-theme-default .light .mat-mdc-fab.mat-primary,
.vex-theme-default .light .mat-mdc-mini-fab.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.vex-theme-default .mat-mdc-fab.mat-accent,
.vex-theme-default .mat-mdc-mini-fab.mat-accent,
.vex-theme-default.light .mat-mdc-fab.mat-accent,
.vex-theme-default.light .mat-mdc-mini-fab.mat-accent,
.vex-theme-default .light .mat-mdc-fab.mat-accent,
.vex-theme-default .light .mat-mdc-mini-fab.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.vex-theme-default .mat-mdc-fab.mat-warn,
.vex-theme-default .mat-mdc-mini-fab.mat-warn,
.vex-theme-default.light .mat-mdc-fab.mat-warn,
.vex-theme-default.light .mat-mdc-mini-fab.mat-warn,
.vex-theme-default .light .mat-mdc-fab.mat-warn,
.vex-theme-default .light .mat-mdc-mini-fab.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.vex-theme-default .mat-mdc-fab[disabled][disabled],
.vex-theme-default .mat-mdc-mini-fab[disabled][disabled],
.vex-theme-default.light .mat-mdc-fab[disabled][disabled],
.vex-theme-default.light .mat-mdc-mini-fab[disabled][disabled],
.vex-theme-default .light .mat-mdc-fab[disabled][disabled],
.vex-theme-default .light .mat-mdc-mini-fab[disabled][disabled] {
  --mdc-fab-container-color: rgba(0, 0, 0, 0.12);
  --mdc-fab-icon-color: rgba(0, 0, 0, 0.38);
  --mat-mdc-fab-color: rgba(0, 0, 0, 0.38);
}
.vex-theme-default .mat-mdc-fab.mat-unthemed,
.vex-theme-default .mat-mdc-mini-fab.mat-unthemed,
.vex-theme-default.light .mat-mdc-fab.mat-unthemed,
.vex-theme-default.light .mat-mdc-mini-fab.mat-unthemed,
.vex-theme-default .light .mat-mdc-fab.mat-unthemed,
.vex-theme-default .light .mat-mdc-mini-fab.mat-unthemed {
  --mdc-fab-container-color: white;
  --mdc-fab-icon-color: black;
  --mat-mdc-fab-color: #000;
}
.vex-theme-default .mat-mdc-fab.mat-primary,
.vex-theme-default .mat-mdc-mini-fab.mat-primary,
.vex-theme-default.light .mat-mdc-fab.mat-primary,
.vex-theme-default.light .mat-mdc-mini-fab.mat-primary,
.vex-theme-default .light .mat-mdc-fab.mat-primary,
.vex-theme-default .light .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #4f46e5;
  --mdc-fab-icon-color: white;
  --mat-mdc-fab-color: #fff;
}
.vex-theme-default .mat-mdc-fab.mat-accent,
.vex-theme-default .mat-mdc-mini-fab.mat-accent,
.vex-theme-default.light .mat-mdc-fab.mat-accent,
.vex-theme-default.light .mat-mdc-mini-fab.mat-accent,
.vex-theme-default .light .mat-mdc-fab.mat-accent,
.vex-theme-default .light .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: #f59e0b;
  --mdc-fab-icon-color: black;
  --mat-mdc-fab-color: #000;
}
.vex-theme-default .mat-mdc-fab.mat-warn,
.vex-theme-default .mat-mdc-mini-fab.mat-warn,
.vex-theme-default.light .mat-mdc-fab.mat-warn,
.vex-theme-default.light .mat-mdc-mini-fab.mat-warn,
.vex-theme-default .light .mat-mdc-fab.mat-warn,
.vex-theme-default .light .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: #ef4444;
  --mdc-fab-icon-color: white;
  --mat-mdc-fab-color: #fff;
}
.vex-theme-default .mat-mdc-snack-bar-container,
.vex-theme-default.light .mat-mdc-snack-bar-container,
.vex-theme-default .light .mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #f59e0b;
}
.vex-theme-default .mat-mdc-progress-spinner,
.vex-theme-default.light .mat-mdc-progress-spinner,
.vex-theme-default .light .mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #4f46e5;
}
.vex-theme-default .mat-mdc-progress-spinner.mat-accent,
.vex-theme-default.light .mat-mdc-progress-spinner.mat-accent,
.vex-theme-default .light .mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: #f59e0b;
}
.vex-theme-default .mat-mdc-progress-spinner.mat-warn,
.vex-theme-default.light .mat-mdc-progress-spinner.mat-warn,
.vex-theme-default .light .mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: #ef4444;
}
.vex-theme-default, .vex-theme-default.light, .vex-theme-default .light {
  --mat-badge-background-color: #4f46e5;
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: #9ca3af;
  --mat-badge-disabled-state-text-color: #6b7280;
}

.vex-theme-default .mat-badge-accent,
.vex-theme-default.light .mat-badge-accent,
.vex-theme-default .light .mat-badge-accent {
  --mat-badge-background-color: #f59e0b;
  --mat-badge-text-color: white;
}
.vex-theme-default .mat-badge-warn,
.vex-theme-default.light .mat-badge-warn,
.vex-theme-default .light .mat-badge-warn {
  --mat-badge-background-color: #ef4444;
  --mat-badge-text-color: white;
}
.vex-theme-default, .vex-theme-default.light, .vex-theme-default .light {
  --mat-bottom-sheet-container-text-color: #030712;
  --mat-bottom-sheet-container-background-color: white;
}

.vex-theme-default, .vex-theme-default.light, .vex-theme-default .light {
  --mat-legacy-button-toggle-text-color: #6b7280;
  --mat-legacy-button-toggle-state-layer-color: #e5e7eb;
  --mat-legacy-button-toggle-selected-state-text-color: #4b5563;
  --mat-legacy-button-toggle-selected-state-background-color: #d1d5db;
  --mat-legacy-button-toggle-disabled-state-text-color: #9ca3af;
  --mat-legacy-button-toggle-disabled-state-background-color: #e5e7eb;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #9ca3af;
  --mat-standard-button-toggle-text-color: #030712;
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: #e5e7eb;
  --mat-standard-button-toggle-selected-state-background-color: #d1d5db;
  --mat-standard-button-toggle-selected-state-text-color: #030712;
  --mat-standard-button-toggle-disabled-state-text-color: #9ca3af;
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: #030712;
  --mat-standard-button-toggle-disabled-selected-state-background-color: #9ca3af;
  --mat-standard-button-toggle-divider-color: #f0f0f0;
}

.vex-theme-default, .vex-theme-default.light, .vex-theme-default .light {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #4f46e5;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(79, 70, 229, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(79, 70, 229, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(79, 70, 229, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #4f46e5;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(79, 70, 229, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: #1f2937;
  --mat-datepicker-calendar-body-label-text-color: #4b5563;
  --mat-datepicker-calendar-period-button-icon-color: #1f2937;
  --mat-datepicker-calendar-navigation-button-icon-color: #1f2937;
  --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.06);
  --mat-datepicker-calendar-header-text-color: #4b5563;
  --mat-datepicker-calendar-date-today-outline-color: #6b7280;
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(107, 114, 128, 0.8);
  --mat-datepicker-calendar-date-text-color: #030712;
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: #6b7280;
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, 0.12);
  --mat-datepicker-range-input-separator-color: #030712;
  --mat-datepicker-range-input-disabled-state-separator-color: #6b7280;
  --mat-datepicker-range-input-disabled-state-text-color: #6b7280;
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: #030712;
}

.vex-theme-default .mat-datepicker-content.mat-accent,
.vex-theme-default.light .mat-datepicker-content.mat-accent,
.vex-theme-default .light .mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #f59e0b;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(245, 158, 11, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(245, 158, 11, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(245, 158, 11, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(245, 158, 11, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.vex-theme-default .mat-datepicker-content.mat-warn,
.vex-theme-default.light .mat-datepicker-content.mat-warn,
.vex-theme-default .light .mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #ef4444;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(239, 68, 68, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(239, 68, 68, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(239, 68, 68, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(239, 68, 68, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.vex-theme-default .mat-datepicker-toggle-active.mat-accent,
.vex-theme-default.light .mat-datepicker-toggle-active.mat-accent,
.vex-theme-default .light .mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #f59e0b;
}
.vex-theme-default .mat-datepicker-toggle-active.mat-warn,
.vex-theme-default.light .mat-datepicker-toggle-active.mat-warn,
.vex-theme-default .light .mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #ef4444;
}
.vex-theme-default, .vex-theme-default.light, .vex-theme-default .light {
  --mat-divider-color: rgba(0, 0, 0, 0.06);
}

.vex-theme-default, .vex-theme-default.light, .vex-theme-default .light {
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: #030712;
  --mat-expansion-actions-divider-color: rgba(0, 0, 0, 0.06);
  --mat-expansion-header-hover-state-layer-color: rgba(17, 24, 39, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(17, 24, 39, 0.04);
  --mat-expansion-header-disabled-state-text-color: #9ca3af;
  --mat-expansion-header-text-color: #030712;
  --mat-expansion-header-description-color: #4b5563;
  --mat-expansion-header-indicator-color: #4b5563;
}

.vex-theme-default, .vex-theme-default.light, .vex-theme-default .light {
  --mat-icon-color: inherit;
}

.vex-theme-default .mat-icon.mat-primary,
.vex-theme-default.light .mat-icon.mat-primary,
.vex-theme-default .light .mat-icon.mat-primary {
  --mat-icon-color: #4f46e5;
}
.vex-theme-default .mat-icon.mat-accent,
.vex-theme-default.light .mat-icon.mat-accent,
.vex-theme-default .light .mat-icon.mat-accent {
  --mat-icon-color: #f59e0b;
}
.vex-theme-default .mat-icon.mat-warn,
.vex-theme-default.light .mat-icon.mat-warn,
.vex-theme-default .light .mat-icon.mat-warn {
  --mat-icon-color: #ef4444;
}
.vex-theme-default, .vex-theme-default.light, .vex-theme-default .light {
  --mat-sidenav-container-divider-color: rgba(0, 0, 0, 0.06);
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: #030712;
  --mat-sidenav-content-background-color: #f3f4f6;
  --mat-sidenav-content-text-color: #030712;
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
}

.vex-theme-default, .vex-theme-default.light, .vex-theme-default .light {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #4f46e5;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #4f46e5;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #4f46e5;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: rgba(0, 0, 0, 0.06);
  --mat-stepper-header-hover-state-layer-color: rgba(17, 24, 39, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(17, 24, 39, 0.04);
  --mat-stepper-header-label-text-color: #4b5563;
  --mat-stepper-header-optional-label-text-color: #4b5563;
  --mat-stepper-header-selected-state-label-text-color: #030712;
  --mat-stepper-header-error-state-label-text-color: #ef4444;
  --mat-stepper-header-icon-background-color: #4b5563;
  --mat-stepper-header-error-state-icon-foreground-color: #ef4444;
  --mat-stepper-header-error-state-icon-background-color: transparent;
}
.vex-theme-default .mat-step-header.mat-accent, .vex-theme-default.light .mat-step-header.mat-accent, .vex-theme-default .light .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #f59e0b;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #f59e0b;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #f59e0b;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.vex-theme-default .mat-step-header.mat-warn, .vex-theme-default.light .mat-step-header.mat-warn, .vex-theme-default .light .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #ef4444;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #ef4444;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #ef4444;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}

.vex-theme-default .mat-sort-header-arrow,
.vex-theme-default.light .mat-sort-header-arrow,
.vex-theme-default .light .mat-sort-header-arrow {
  color: #4b5563;
}
.vex-theme-default, .vex-theme-default.light, .vex-theme-default .light {
  --mat-toolbar-container-background-color: #e5e7eb;
  --mat-toolbar-container-text-color: #030712;
}

.vex-theme-default .mat-toolbar.mat-primary,
.vex-theme-default.light .mat-toolbar.mat-primary,
.vex-theme-default .light .mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #4f46e5;
  --mat-toolbar-container-text-color: white;
}
.vex-theme-default .mat-toolbar.mat-accent,
.vex-theme-default.light .mat-toolbar.mat-accent,
.vex-theme-default .light .mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #f59e0b;
  --mat-toolbar-container-text-color: white;
}
.vex-theme-default .mat-toolbar.mat-warn,
.vex-theme-default.light .mat-toolbar.mat-warn,
.vex-theme-default .light .mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #ef4444;
  --mat-toolbar-container-text-color: white;
}
.vex-theme-default .mat-tree,
.vex-theme-default.light .mat-tree,
.vex-theme-default .light .mat-tree {
  background: white;
}
.vex-theme-default .mat-tree-node,
.vex-theme-default .mat-nested-tree-node,
.vex-theme-default.light .mat-tree-node,
.vex-theme-default.light .mat-nested-tree-node,
.vex-theme-default .light .mat-tree-node,
.vex-theme-default .light .mat-nested-tree-node {
  color: #030712;
}

/**
 * Override the theme foreground/background colors with the Angular Material colors
 */
.vex-theme-default.dark,
.vex-theme-default .dark {
  --mat-table-background-color: #1f2937;
  --mat-table-header-headline-color: white;
  --mat-table-row-item-label-text-color: white;
  --mat-table-row-item-outline-color: rgba(255, 255, 255, 0.12);
}
.vex-theme-default.dark .mat-ripple-element,
.vex-theme-default .dark .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.vex-theme-default.dark, .vex-theme-default .dark {
  --mat-option-selected-state-label-text-color: #6366f1;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
}

.vex-theme-default.dark .mat-accent,
.vex-theme-default .dark .mat-accent {
  --mat-option-selected-state-label-text-color: #f59e0b;
}
.vex-theme-default.dark .mat-warn,
.vex-theme-default .dark .mat-warn {
  --mat-option-selected-state-label-text-color: #ef4444;
}
.vex-theme-default.dark, .vex-theme-default .dark {
  --mat-optgroup-label-text-color: white;
}

.vex-theme-default.dark .mat-pseudo-checkbox-full,
.vex-theme-default .dark .mat-pseudo-checkbox-full {
  color: #9ca3af;
}
.vex-theme-default.dark .mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled,
.vex-theme-default .dark .mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled {
  color: #686868;
}
.vex-theme-default.dark .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-default.dark .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-default .dark .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-default .dark .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #6366f1;
}
.vex-theme-default.dark .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-default.dark .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-default .dark .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-default .dark .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #6366f1;
}
.vex-theme-default.dark .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-default.dark .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after,
.vex-theme-default .dark .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-default .dark .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #111827;
}
.vex-theme-default.dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-default.dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-default .dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-default .dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #f59e0b;
}
.vex-theme-default.dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-default.dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-default .dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-default .dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #f59e0b;
}
.vex-theme-default.dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-default.dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after,
.vex-theme-default .dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-default .dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #111827;
}
.vex-theme-default.dark .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-default.dark .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-default .dark .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-default .dark .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #f59e0b;
}
.vex-theme-default.dark .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-default.dark .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-default .dark .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-default .dark .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #f59e0b;
}
.vex-theme-default.dark .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-default.dark .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after,
.vex-theme-default .dark .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-default .dark .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #111827;
}
.vex-theme-default.dark .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-default.dark .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-default .dark .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-default .dark .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #ef4444;
}
.vex-theme-default.dark .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-default.dark .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-default .dark .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-default .dark .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #ef4444;
}
.vex-theme-default.dark .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-default.dark .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after,
.vex-theme-default .dark .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-default .dark .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #111827;
}
.vex-theme-default.dark .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-default.dark .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-default .dark .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-default .dark .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #686868;
}
.vex-theme-default.dark .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-default.dark .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-default .dark .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-default .dark .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #686868;
}
.vex-theme-default.dark .mat-app-background, .vex-theme-default.dark.mat-app-background,
.vex-theme-default .dark .mat-app-background,
.vex-theme-default .dark.mat-app-background {
  background-color: #111827;
  color: white;
}
.vex-theme-default.dark .mat-elevation-z0, .vex-theme-default.dark .mat-mdc-elevation-specific.mat-elevation-z0,
.vex-theme-default .dark .mat-elevation-z0,
.vex-theme-default .dark .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-default.dark .mat-elevation-z1, .vex-theme-default.dark .mat-mdc-elevation-specific.mat-elevation-z1,
.vex-theme-default .dark .mat-elevation-z1,
.vex-theme-default .dark .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-default.dark .mat-elevation-z2, .vex-theme-default.dark .mat-mdc-elevation-specific.mat-elevation-z2,
.vex-theme-default .dark .mat-elevation-z2,
.vex-theme-default .dark .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-default.dark .mat-elevation-z3, .vex-theme-default.dark .mat-mdc-elevation-specific.mat-elevation-z3,
.vex-theme-default .dark .mat-elevation-z3,
.vex-theme-default .dark .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-default.dark .mat-elevation-z4, .vex-theme-default.dark .mat-mdc-elevation-specific.mat-elevation-z4,
.vex-theme-default .dark .mat-elevation-z4,
.vex-theme-default .dark .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-default.dark .mat-elevation-z5, .vex-theme-default.dark .mat-mdc-elevation-specific.mat-elevation-z5,
.vex-theme-default .dark .mat-elevation-z5,
.vex-theme-default .dark .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-default.dark .mat-elevation-z6, .vex-theme-default.dark .mat-mdc-elevation-specific.mat-elevation-z6,
.vex-theme-default .dark .mat-elevation-z6,
.vex-theme-default .dark .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-default.dark .mat-elevation-z7, .vex-theme-default.dark .mat-mdc-elevation-specific.mat-elevation-z7,
.vex-theme-default .dark .mat-elevation-z7,
.vex-theme-default .dark .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.vex-theme-default.dark .mat-elevation-z8, .vex-theme-default.dark .mat-mdc-elevation-specific.mat-elevation-z8,
.vex-theme-default .dark .mat-elevation-z8,
.vex-theme-default .dark .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.vex-theme-default.dark .mat-elevation-z9, .vex-theme-default.dark .mat-mdc-elevation-specific.mat-elevation-z9,
.vex-theme-default .dark .mat-elevation-z9,
.vex-theme-default .dark .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.vex-theme-default.dark .mat-elevation-z10, .vex-theme-default.dark .mat-mdc-elevation-specific.mat-elevation-z10,
.vex-theme-default .dark .mat-elevation-z10,
.vex-theme-default .dark .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.vex-theme-default.dark .mat-elevation-z11, .vex-theme-default.dark .mat-mdc-elevation-specific.mat-elevation-z11,
.vex-theme-default .dark .mat-elevation-z11,
.vex-theme-default .dark .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.vex-theme-default.dark .mat-elevation-z12, .vex-theme-default.dark .mat-mdc-elevation-specific.mat-elevation-z12,
.vex-theme-default .dark .mat-elevation-z12,
.vex-theme-default .dark .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.vex-theme-default.dark .mat-elevation-z13, .vex-theme-default.dark .mat-mdc-elevation-specific.mat-elevation-z13,
.vex-theme-default .dark .mat-elevation-z13,
.vex-theme-default .dark .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.vex-theme-default.dark .mat-elevation-z14, .vex-theme-default.dark .mat-mdc-elevation-specific.mat-elevation-z14,
.vex-theme-default .dark .mat-elevation-z14,
.vex-theme-default .dark .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.vex-theme-default.dark .mat-elevation-z15, .vex-theme-default.dark .mat-mdc-elevation-specific.mat-elevation-z15,
.vex-theme-default .dark .mat-elevation-z15,
.vex-theme-default .dark .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.vex-theme-default.dark .mat-elevation-z16, .vex-theme-default.dark .mat-mdc-elevation-specific.mat-elevation-z16,
.vex-theme-default .dark .mat-elevation-z16,
.vex-theme-default .dark .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.vex-theme-default.dark .mat-elevation-z17, .vex-theme-default.dark .mat-mdc-elevation-specific.mat-elevation-z17,
.vex-theme-default .dark .mat-elevation-z17,
.vex-theme-default .dark .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.vex-theme-default.dark .mat-elevation-z18, .vex-theme-default.dark .mat-mdc-elevation-specific.mat-elevation-z18,
.vex-theme-default .dark .mat-elevation-z18,
.vex-theme-default .dark .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.vex-theme-default.dark .mat-elevation-z19, .vex-theme-default.dark .mat-mdc-elevation-specific.mat-elevation-z19,
.vex-theme-default .dark .mat-elevation-z19,
.vex-theme-default .dark .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.vex-theme-default.dark .mat-elevation-z20, .vex-theme-default.dark .mat-mdc-elevation-specific.mat-elevation-z20,
.vex-theme-default .dark .mat-elevation-z20,
.vex-theme-default .dark .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.vex-theme-default.dark .mat-elevation-z21, .vex-theme-default.dark .mat-mdc-elevation-specific.mat-elevation-z21,
.vex-theme-default .dark .mat-elevation-z21,
.vex-theme-default .dark .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.vex-theme-default.dark .mat-elevation-z22, .vex-theme-default.dark .mat-mdc-elevation-specific.mat-elevation-z22,
.vex-theme-default .dark .mat-elevation-z22,
.vex-theme-default .dark .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.vex-theme-default.dark .mat-elevation-z23, .vex-theme-default.dark .mat-mdc-elevation-specific.mat-elevation-z23,
.vex-theme-default .dark .mat-elevation-z23,
.vex-theme-default .dark .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.vex-theme-default.dark .mat-elevation-z24, .vex-theme-default.dark .mat-mdc-elevation-specific.mat-elevation-z24,
.vex-theme-default .dark .mat-elevation-z24,
.vex-theme-default .dark .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.vex-theme-default.dark .mat-mdc-card,
.vex-theme-default .dark .mat-mdc-card {
  --mdc-elevated-card-container-color: #1f2937;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: #1f2937;
  --mdc-outlined-card-outline-color: rgba(255, 255, 255, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: #9ca3af;
}
.vex-theme-default.dark .mat-mdc-progress-bar,
.vex-theme-default .dark .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #6366f1;
  --mdc-linear-progress-track-color: rgba(99, 102, 241, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.vex-theme-default.dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots,
.vex-theme-default .dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-color: rgba(99, 102, 241, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(99, 102, 241, 0.25));
}
@media (forced-colors: active) {
  .vex-theme-default.dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots,
  .vex-theme-default .dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .vex-theme-default.dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots,
  .vex-theme-default .dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(99, 102, 241, 0.25)'/%3E%3C/svg%3E");
  }
}
.vex-theme-default.dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar,
.vex-theme-default .dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgba(99, 102, 241, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(99, 102, 241, 0.25));
}
.vex-theme-default.dark .mat-mdc-progress-bar.mat-accent,
.vex-theme-default .dark .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #f59e0b;
  --mdc-linear-progress-track-color: rgba(245, 158, 11, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.vex-theme-default.dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots,
.vex-theme-default .dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-color: rgba(245, 158, 11, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(245, 158, 11, 0.25));
}
@media (forced-colors: active) {
  .vex-theme-default.dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots,
  .vex-theme-default .dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .vex-theme-default.dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots,
  .vex-theme-default .dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(245, 158, 11, 0.25)'/%3E%3C/svg%3E");
  }
}
.vex-theme-default.dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar,
.vex-theme-default .dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: rgba(245, 158, 11, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(245, 158, 11, 0.25));
}
.vex-theme-default.dark .mat-mdc-progress-bar.mat-warn,
.vex-theme-default .dark .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #ef4444;
  --mdc-linear-progress-track-color: rgba(239, 68, 68, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.vex-theme-default.dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots,
.vex-theme-default .dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-color: rgba(239, 68, 68, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(239, 68, 68, 0.25));
}
@media (forced-colors: active) {
  .vex-theme-default.dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots,
  .vex-theme-default .dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .vex-theme-default.dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots,
  .vex-theme-default .dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(239, 68, 68, 0.25)'/%3E%3C/svg%3E");
  }
}
.vex-theme-default.dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar,
.vex-theme-default .dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: rgba(239, 68, 68, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(239, 68, 68, 0.25));
}
.vex-theme-default.dark .mat-mdc-tooltip,
.vex-theme-default .dark .mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #374151;
  --mdc-plain-tooltip-supporting-text-color: #fff;
}
.vex-theme-default.dark, .vex-theme-default .dark {
  --mdc-filled-text-field-caret-color: #6366f1;
  --mdc-filled-text-field-focus-active-indicator-color: #6366f1;
  --mdc-filled-text-field-focus-label-text-color: rgba(99, 102, 241, 0.87);
  --mdc-filled-text-field-container-color: #28323f;
  --mdc-filled-text-field-disabled-container-color: #232d3b;
  --mdc-filled-text-field-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(255, 255, 255, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(255, 255, 255, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(255, 255, 255, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-error-focus-label-text-color: #ef4444;
  --mdc-filled-text-field-error-label-text-color: #ef4444;
  --mdc-filled-text-field-error-caret-color: #ef4444;
  --mdc-filled-text-field-active-indicator-color: rgba(255, 255, 255, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(255, 255, 255, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(255, 255, 255, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #ef4444;
  --mdc-filled-text-field-error-focus-active-indicator-color: #ef4444;
  --mdc-filled-text-field-error-hover-active-indicator-color: #ef4444;
  --mdc-outlined-text-field-caret-color: #6366f1;
  --mdc-outlined-text-field-focus-outline-color: #6366f1;
  --mdc-outlined-text-field-focus-label-text-color: rgba(99, 102, 241, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(255, 255, 255, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(255, 255, 255, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(255, 255, 255, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-error-caret-color: #ef4444;
  --mdc-outlined-text-field-error-focus-label-text-color: #ef4444;
  --mdc-outlined-text-field-error-label-text-color: #ef4444;
  --mdc-outlined-text-field-outline-color: rgba(255, 255, 255, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(255, 255, 255, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(255, 255, 255, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #ef4444;
  --mdc-outlined-text-field-error-hover-outline-color: #ef4444;
  --mdc-outlined-text-field-error-outline-color: #ef4444;
  --mat-form-field-disabled-input-text-placeholder-color: rgba(255, 255, 255, 0.38);
}

.vex-theme-default.dark .mat-mdc-form-field-error,
.vex-theme-default .dark .mat-mdc-form-field-error {
  color: var(--mdc-theme-error, #ef4444);
}
.vex-theme-default.dark .mat-mdc-form-field-subscript-wrapper,
.vex-theme-default.dark .mat-mdc-form-field-bottom-align::before,
.vex-theme-default .dark .mat-mdc-form-field-subscript-wrapper,
.vex-theme-default .dark .mat-mdc-form-field-bottom-align::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mat-form-field-subscript-text-font);
  line-height: var(--mat-form-field-subscript-text-line-height);
  font-size: var(--mat-form-field-subscript-text-size);
  letter-spacing: var(--mat-form-field-subscript-text-tracking);
  font-weight: var(--mat-form-field-subscript-text-weight);
}
.vex-theme-default.dark .mat-mdc-form-field-focus-overlay,
.vex-theme-default .dark .mat-mdc-form-field-focus-overlay {
  background-color: rgba(255, 255, 255, 0.87);
}
.vex-theme-default.dark .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay,
.vex-theme-default .dark .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0.08;
}
.vex-theme-default.dark .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay,
.vex-theme-default .dark .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.24;
}
.vex-theme-default.dark select.mat-mdc-form-field-input-control:not(.mat-mdc-native-select-inline) option,
.vex-theme-default .dark select.mat-mdc-form-field-input-control:not(.mat-mdc-native-select-inline) option {
  color: rgba(0, 0, 0, 0.87);
}
.vex-theme-default.dark select.mat-mdc-form-field-input-control:not(.mat-mdc-native-select-inline) option:disabled,
.vex-theme-default .dark select.mat-mdc-form-field-input-control:not(.mat-mdc-native-select-inline) option:disabled {
  color: rgba(0, 0, 0, 0.38);
}
.vex-theme-default.dark .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after,
.vex-theme-default .dark .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
  color: rgba(255, 255, 255, 0.54);
}
.vex-theme-default.dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after,
.vex-theme-default .dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after {
  color: rgba(99, 102, 241, 0.87);
}
.vex-theme-default.dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after,
.vex-theme-default .dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after {
  color: rgba(245, 158, 11, 0.87);
}
.vex-theme-default.dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after,
.vex-theme-default .dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after {
  color: rgba(239, 68, 68, 0.87);
}
.vex-theme-default.dark .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after,
.vex-theme-default .dark .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  color: rgba(255, 255, 255, 0.38);
}
.vex-theme-default.dark .mat-mdc-form-field.mat-accent,
.vex-theme-default .dark .mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #f59e0b;
  --mdc-filled-text-field-focus-active-indicator-color: #f59e0b;
  --mdc-filled-text-field-focus-label-text-color: rgba(245, 158, 11, 0.87);
  --mdc-outlined-text-field-caret-color: #f59e0b;
  --mdc-outlined-text-field-focus-outline-color: #f59e0b;
  --mdc-outlined-text-field-focus-label-text-color: rgba(245, 158, 11, 0.87);
}
.vex-theme-default.dark .mat-mdc-form-field.mat-warn,
.vex-theme-default .dark .mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #ef4444;
  --mdc-filled-text-field-focus-active-indicator-color: #ef4444;
  --mdc-filled-text-field-focus-label-text-color: rgba(239, 68, 68, 0.87);
  --mdc-outlined-text-field-caret-color: #ef4444;
  --mdc-outlined-text-field-focus-outline-color: #ef4444;
  --mdc-outlined-text-field-focus-label-text-color: rgba(239, 68, 68, 0.87);
}
.vex-theme-default.dark .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch,
.vex-theme-default .dark .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: 1px solid transparent;
}
.vex-theme-default.dark [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch,
.vex-theme-default .dark [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none;
  border-right: 1px solid transparent;
}
.vex-theme-default.dark, .vex-theme-default .dark {
  --mat-select-panel-background-color: #1f2937;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(255, 255, 255, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(255, 255, 255, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(99, 102, 241, 0.87);
  --mat-select-invalid-arrow-color: rgba(239, 68, 68, 0.87);
}
.vex-theme-default.dark .mat-mdc-form-field.mat-accent, .vex-theme-default .dark .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: #1f2937;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(255, 255, 255, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(255, 255, 255, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(245, 158, 11, 0.87);
  --mat-select-invalid-arrow-color: rgba(239, 68, 68, 0.87);
}
.vex-theme-default.dark .mat-mdc-form-field.mat-warn, .vex-theme-default .dark .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: #1f2937;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(255, 255, 255, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(255, 255, 255, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(239, 68, 68, 0.87);
  --mat-select-invalid-arrow-color: rgba(239, 68, 68, 0.87);
}

.vex-theme-default.dark, .vex-theme-default .dark {
  --mat-autocomplete-background-color: #1f2937;
}

.vex-theme-default.dark .mat-mdc-dialog-container,
.vex-theme-default .dark .mat-mdc-dialog-container {
  --mdc-dialog-container-color: #1f2937;
  --mdc-dialog-subhead-color: rgba(255, 255, 255, 0.87);
  --mdc-dialog-supporting-text-color: rgba(255, 255, 255, 0.6);
}
.vex-theme-default.dark .mat-mdc-standard-chip,
.vex-theme-default .dark .mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #fafafa;
  --mdc-chip-elevated-container-color: #3a434f;
  --mdc-chip-elevated-disabled-container-color: #3a434f;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #fafafa;
  --mdc-chip-with-icon-icon-color: #fafafa;
  --mdc-chip-with-icon-disabled-icon-color: #fafafa;
  --mdc-chip-with-icon-selected-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #fafafa;
}
.vex-theme-default.dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .vex-theme-default.dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary,
.vex-theme-default .dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary,
.vex-theme-default .dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #6366f1;
  --mdc-chip-elevated-disabled-container-color: #6366f1;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.vex-theme-default.dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .vex-theme-default.dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent,
.vex-theme-default .dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent,
.vex-theme-default .dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #f59e0b;
  --mdc-chip-elevated-disabled-container-color: #f59e0b;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.vex-theme-default.dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .vex-theme-default.dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn,
.vex-theme-default .dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn,
.vex-theme-default .dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #ef4444;
  --mdc-chip-elevated-disabled-container-color: #ef4444;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.vex-theme-default.dark .mat-mdc-slide-toggle,
.vex-theme-default .dark .mat-mdc-slide-toggle {
  --mdc-switch-selected-focus-state-layer-color: #a5b4fc;
  --mdc-switch-selected-handle-color: #a5b4fc;
  --mdc-switch-selected-hover-state-layer-color: #a5b4fc;
  --mdc-switch-selected-pressed-state-layer-color: #a5b4fc;
  --mdc-switch-selected-focus-handle-color: #c7d2fe;
  --mdc-switch-selected-hover-handle-color: #c7d2fe;
  --mdc-switch-selected-pressed-handle-color: #c7d2fe;
  --mdc-switch-selected-focus-track-color: #4f46e5;
  --mdc-switch-selected-hover-track-color: #4f46e5;
  --mdc-switch-selected-pressed-track-color: #4f46e5;
  --mdc-switch-selected-track-color: #4f46e5;
  --mdc-switch-disabled-selected-handle-color: #000;
  --mdc-switch-disabled-selected-icon-color: #212121;
  --mdc-switch-disabled-selected-track-color: #f5f5f5;
  --mdc-switch-disabled-unselected-handle-color: #000;
  --mdc-switch-disabled-unselected-icon-color: #212121;
  --mdc-switch-disabled-unselected-track-color: #f5f5f5;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #212121;
  --mdc-switch-unselected-focus-handle-color: #fafafa;
  --mdc-switch-unselected-focus-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-focus-track-color: #616161;
  --mdc-switch-unselected-handle-color: #9e9e9e;
  --mdc-switch-unselected-hover-handle-color: #fafafa;
  --mdc-switch-unselected-hover-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-hover-track-color: #616161;
  --mdc-switch-unselected-icon-color: #212121;
  --mdc-switch-unselected-pressed-handle-color: #fafafa;
  --mdc-switch-unselected-pressed-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-pressed-track-color: #616161;
  --mdc-switch-unselected-track-color: #616161;
}
.vex-theme-default.dark .mat-mdc-slide-toggle .mdc-form-field,
.vex-theme-default .dark .mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.vex-theme-default.dark .mat-mdc-slide-toggle .mdc-switch--disabled + label,
.vex-theme-default .dark .mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: #6b7280;
}
.vex-theme-default.dark .mat-mdc-slide-toggle.mat-accent,
.vex-theme-default .dark .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #fcd34d;
  --mdc-switch-selected-handle-color: #fcd34d;
  --mdc-switch-selected-hover-state-layer-color: #fcd34d;
  --mdc-switch-selected-pressed-state-layer-color: #fcd34d;
  --mdc-switch-selected-focus-handle-color: #fde68a;
  --mdc-switch-selected-hover-handle-color: #fde68a;
  --mdc-switch-selected-pressed-handle-color: #fde68a;
  --mdc-switch-selected-focus-track-color: #d97706;
  --mdc-switch-selected-hover-track-color: #d97706;
  --mdc-switch-selected-pressed-track-color: #d97706;
  --mdc-switch-selected-track-color: #d97706;
}
.vex-theme-default.dark .mat-mdc-slide-toggle.mat-warn,
.vex-theme-default .dark .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #fca5a5;
  --mdc-switch-selected-handle-color: #fca5a5;
  --mdc-switch-selected-hover-state-layer-color: #fca5a5;
  --mdc-switch-selected-pressed-state-layer-color: #fca5a5;
  --mdc-switch-selected-focus-handle-color: #fecaca;
  --mdc-switch-selected-hover-handle-color: #fecaca;
  --mdc-switch-selected-pressed-handle-color: #fecaca;
  --mdc-switch-selected-focus-track-color: #dc2626;
  --mdc-switch-selected-hover-track-color: #dc2626;
  --mdc-switch-selected-pressed-track-color: #dc2626;
  --mdc-switch-selected-track-color: #dc2626;
}
.vex-theme-default.dark .mat-mdc-radio-button .mdc-form-field,
.vex-theme-default .dark .mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.vex-theme-default.dark .mat-mdc-radio-button.mat-primary,
.vex-theme-default .dark .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #6366f1;
  --mdc-radio-selected-hover-icon-color: #6366f1;
  --mdc-radio-selected-icon-color: #6366f1;
  --mdc-radio-selected-pressed-icon-color: #6366f1;
  --mat-radio-ripple-color: #fff;
  --mat-radio-checked-ripple-color: #6366f1;
  --mat-radio-disabled-label-color: #6b7280;
}
.vex-theme-default.dark .mat-mdc-radio-button.mat-accent,
.vex-theme-default .dark .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #f59e0b;
  --mdc-radio-selected-hover-icon-color: #f59e0b;
  --mdc-radio-selected-icon-color: #f59e0b;
  --mdc-radio-selected-pressed-icon-color: #f59e0b;
  --mat-radio-ripple-color: #fff;
  --mat-radio-checked-ripple-color: #f59e0b;
  --mat-radio-disabled-label-color: #6b7280;
}
.vex-theme-default.dark .mat-mdc-radio-button.mat-warn,
.vex-theme-default .dark .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #ef4444;
  --mdc-radio-selected-hover-icon-color: #ef4444;
  --mdc-radio-selected-icon-color: #ef4444;
  --mdc-radio-selected-pressed-icon-color: #ef4444;
  --mat-radio-ripple-color: #fff;
  --mat-radio-checked-ripple-color: #ef4444;
  --mat-radio-disabled-label-color: #6b7280;
}
.vex-theme-default.dark .mat-mdc-slider,
.vex-theme-default .dark .mat-mdc-slider {
  --mdc-slider-label-container-color: white;
  --mdc-slider-label-label-text-color: black;
  --mdc-slider-disabled-handle-color: #fff;
  --mdc-slider-disabled-active-track-color: #fff;
  --mdc-slider-disabled-inactive-track-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #fff;
  --mat-mdc-slider-value-indicator-opacity: 0.9;
}
.vex-theme-default.dark .mat-mdc-slider.mat-primary,
.vex-theme-default .dark .mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #6366f1;
  --mdc-slider-focus-handle-color: #6366f1;
  --mdc-slider-hover-handle-color: #6366f1;
  --mdc-slider-active-track-color: #6366f1;
  --mdc-slider-inactive-track-color: #6366f1;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #6366f1;
  --mat-mdc-slider-ripple-color: #6366f1;
  --mat-mdc-slider-hover-ripple-color: rgba(99, 102, 241, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(99, 102, 241, 0.2);
}
.vex-theme-default.dark .mat-mdc-slider.mat-accent,
.vex-theme-default .dark .mat-mdc-slider.mat-accent {
  --mdc-slider-handle-color: #f59e0b;
  --mdc-slider-focus-handle-color: #f59e0b;
  --mdc-slider-hover-handle-color: #f59e0b;
  --mdc-slider-active-track-color: #f59e0b;
  --mdc-slider-inactive-track-color: #f59e0b;
  --mdc-slider-with-tick-marks-active-container-color: #000;
  --mdc-slider-with-tick-marks-inactive-container-color: #f59e0b;
  --mat-mdc-slider-ripple-color: #f59e0b;
  --mat-mdc-slider-hover-ripple-color: rgba(245, 158, 11, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(245, 158, 11, 0.2);
}
.vex-theme-default.dark .mat-mdc-slider.mat-warn,
.vex-theme-default .dark .mat-mdc-slider.mat-warn {
  --mdc-slider-handle-color: #ef4444;
  --mdc-slider-focus-handle-color: #ef4444;
  --mdc-slider-hover-handle-color: #ef4444;
  --mdc-slider-active-track-color: #ef4444;
  --mdc-slider-inactive-track-color: #ef4444;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #ef4444;
  --mat-mdc-slider-ripple-color: #ef4444;
  --mat-mdc-slider-hover-ripple-color: rgba(239, 68, 68, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(239, 68, 68, 0.2);
}
.vex-theme-default.dark, .vex-theme-default .dark {
  --mat-menu-item-label-text-color: white;
  --mat-menu-item-icon-color: white;
  --mat-menu-item-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-menu-item-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-menu-container-color: #1f2937;
}

.vex-theme-default.dark .mat-mdc-list-base,
.vex-theme-default .dark .mat-mdc-list-base {
  --mdc-list-list-item-label-text-color: white;
  --mdc-list-list-item-supporting-text-color: #9ca3af;
  --mdc-list-list-item-leading-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-trailing-supporting-text-color: #6b7280;
  --mdc-list-list-item-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-disabled-label-text-color: white;
  --mdc-list-list-item-disabled-leading-icon-color: white;
  --mdc-list-list-item-disabled-trailing-icon-color: white;
  --mdc-list-list-item-hover-label-text-color: white;
  --mdc-list-list-item-hover-leading-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-focus-label-text-color: white;
  --mdc-list-list-item-hover-state-layer-color: white;
  --mdc-list-list-item-hover-state-layer-opacity: 0.08;
  --mdc-list-list-item-focus-state-layer-color: white;
  --mdc-list-list-item-focus-state-layer-opacity: 0.24;
}
.vex-theme-default.dark .mdc-list-item__start,
.vex-theme-default.dark .mdc-list-item__end,
.vex-theme-default .dark .mdc-list-item__start,
.vex-theme-default .dark .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #6366f1;
  --mdc-radio-selected-hover-icon-color: #6366f1;
  --mdc-radio-selected-icon-color: #6366f1;
  --mdc-radio-selected-pressed-icon-color: #6366f1;
}
.vex-theme-default.dark .mat-accent .mdc-list-item__start,
.vex-theme-default.dark .mat-accent .mdc-list-item__end,
.vex-theme-default .dark .mat-accent .mdc-list-item__start,
.vex-theme-default .dark .mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #f59e0b;
  --mdc-radio-selected-hover-icon-color: #f59e0b;
  --mdc-radio-selected-icon-color: #f59e0b;
  --mdc-radio-selected-pressed-icon-color: #f59e0b;
}
.vex-theme-default.dark .mat-warn .mdc-list-item__start,
.vex-theme-default.dark .mat-warn .mdc-list-item__end,
.vex-theme-default .dark .mat-warn .mdc-list-item__start,
.vex-theme-default .dark .mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #ef4444;
  --mdc-radio-selected-hover-icon-color: #ef4444;
  --mdc-radio-selected-icon-color: #ef4444;
  --mdc-radio-selected-pressed-icon-color: #ef4444;
}
.vex-theme-default.dark .mat-mdc-list-option,
.vex-theme-default .dark .mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #6366f1;
  --mdc-checkbox-selected-hover-icon-color: #6366f1;
  --mdc-checkbox-selected-icon-color: #6366f1;
  --mdc-checkbox-selected-pressed-icon-color: #6366f1;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #6366f1;
  --mdc-checkbox-selected-hover-state-layer-color: #6366f1;
  --mdc-checkbox-selected-pressed-state-layer-color: #6366f1;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.vex-theme-default.dark .mat-mdc-list-option.mat-accent,
.vex-theme-default .dark .mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #f59e0b;
  --mdc-checkbox-selected-hover-icon-color: #f59e0b;
  --mdc-checkbox-selected-icon-color: #f59e0b;
  --mdc-checkbox-selected-pressed-icon-color: #f59e0b;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f59e0b;
  --mdc-checkbox-selected-hover-state-layer-color: #f59e0b;
  --mdc-checkbox-selected-pressed-state-layer-color: #f59e0b;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.vex-theme-default.dark .mat-mdc-list-option.mat-warn,
.vex-theme-default .dark .mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #ef4444;
  --mdc-checkbox-selected-hover-icon-color: #ef4444;
  --mdc-checkbox-selected-icon-color: #ef4444;
  --mdc-checkbox-selected-pressed-icon-color: #ef4444;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #ef4444;
  --mdc-checkbox-selected-hover-state-layer-color: #ef4444;
  --mdc-checkbox-selected-pressed-state-layer-color: #ef4444;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.vex-theme-default.dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.vex-theme-default.dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,
.vex-theme-default .dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.vex-theme-default .dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #6366f1;
}
.vex-theme-default.dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.vex-theme-default.dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.vex-theme-default .dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.vex-theme-default .dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #6366f1;
}
.vex-theme-default.dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.vex-theme-default.dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.vex-theme-default.dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end,
.vex-theme-default .dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.vex-theme-default .dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.vex-theme-default .dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.vex-theme-default.dark, .vex-theme-default .dark {
  --mat-paginator-container-text-color: rgba(255, 255, 255, 0.87);
  --mat-paginator-container-background-color: #1f2937;
  --mat-paginator-enabled-icon-color: rgba(255, 255, 255, 0.54);
  --mat-paginator-disabled-icon-color: rgba(255, 255, 255, 0.12);
}

.vex-theme-default.dark .mat-mdc-tab-group, .vex-theme-default.dark .mat-mdc-tab-nav-bar,
.vex-theme-default .dark .mat-mdc-tab-group,
.vex-theme-default .dark .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #6366f1;
  --mat-tab-header-disabled-ripple-color: #6b7280;
  --mat-tab-header-pagination-icon-color: #fff;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #6366f1;
  --mat-tab-header-active-ripple-color: #6366f1;
  --mat-tab-header-inactive-ripple-color: #6366f1;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #6366f1;
  --mat-tab-header-active-hover-label-text-color: #6366f1;
  --mat-tab-header-active-focus-indicator-color: #6366f1;
  --mat-tab-header-active-hover-indicator-color: #6366f1;
}
.vex-theme-default.dark .mat-mdc-tab-group.mat-accent, .vex-theme-default.dark .mat-mdc-tab-nav-bar.mat-accent,
.vex-theme-default .dark .mat-mdc-tab-group.mat-accent,
.vex-theme-default .dark .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #f59e0b;
  --mat-tab-header-disabled-ripple-color: #6b7280;
  --mat-tab-header-pagination-icon-color: #fff;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #f59e0b;
  --mat-tab-header-active-ripple-color: #f59e0b;
  --mat-tab-header-inactive-ripple-color: #f59e0b;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #f59e0b;
  --mat-tab-header-active-hover-label-text-color: #f59e0b;
  --mat-tab-header-active-focus-indicator-color: #f59e0b;
  --mat-tab-header-active-hover-indicator-color: #f59e0b;
}
.vex-theme-default.dark .mat-mdc-tab-group.mat-warn, .vex-theme-default.dark .mat-mdc-tab-nav-bar.mat-warn,
.vex-theme-default .dark .mat-mdc-tab-group.mat-warn,
.vex-theme-default .dark .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #ef4444;
  --mat-tab-header-disabled-ripple-color: #6b7280;
  --mat-tab-header-pagination-icon-color: #fff;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #ef4444;
  --mat-tab-header-active-ripple-color: #ef4444;
  --mat-tab-header-inactive-ripple-color: #ef4444;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #ef4444;
  --mat-tab-header-active-hover-label-text-color: #ef4444;
  --mat-tab-header-active-focus-indicator-color: #ef4444;
  --mat-tab-header-active-hover-indicator-color: #ef4444;
}
.vex-theme-default.dark .mat-mdc-tab-group.mat-background-primary, .vex-theme-default.dark .mat-mdc-tab-nav-bar.mat-background-primary,
.vex-theme-default .dark .mat-mdc-tab-group.mat-background-primary,
.vex-theme-default .dark .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #6366f1;
  --mat-tab-header-with-background-foreground-color: white;
}
.vex-theme-default.dark .mat-mdc-tab-group.mat-background-accent, .vex-theme-default.dark .mat-mdc-tab-nav-bar.mat-background-accent,
.vex-theme-default .dark .mat-mdc-tab-group.mat-background-accent,
.vex-theme-default .dark .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #f59e0b;
  --mat-tab-header-with-background-foreground-color: white;
}
.vex-theme-default.dark .mat-mdc-tab-group.mat-background-warn, .vex-theme-default.dark .mat-mdc-tab-nav-bar.mat-background-warn,
.vex-theme-default .dark .mat-mdc-tab-group.mat-background-warn,
.vex-theme-default .dark .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #ef4444;
  --mat-tab-header-with-background-foreground-color: white;
}
.vex-theme-default.dark, .vex-theme-default .dark {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #f59e0b;
  --mdc-checkbox-selected-hover-icon-color: #f59e0b;
  --mdc-checkbox-selected-icon-color: #f59e0b;
  --mdc-checkbox-selected-pressed-icon-color: #f59e0b;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f59e0b;
  --mdc-checkbox-selected-hover-state-layer-color: #f59e0b;
  --mdc-checkbox-selected-pressed-state-layer-color: #f59e0b;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}

.vex-theme-default.dark .mat-mdc-checkbox.mat-primary,
.vex-theme-default .dark .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #6366f1;
  --mdc-checkbox-selected-hover-icon-color: #6366f1;
  --mdc-checkbox-selected-icon-color: #6366f1;
  --mdc-checkbox-selected-pressed-icon-color: #6366f1;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #6366f1;
  --mdc-checkbox-selected-hover-state-layer-color: #6366f1;
  --mdc-checkbox-selected-pressed-state-layer-color: #6366f1;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.vex-theme-default.dark .mat-mdc-checkbox.mat-warn,
.vex-theme-default .dark .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #ef4444;
  --mdc-checkbox-selected-hover-icon-color: #ef4444;
  --mdc-checkbox-selected-icon-color: #ef4444;
  --mdc-checkbox-selected-pressed-icon-color: #ef4444;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #ef4444;
  --mdc-checkbox-selected-hover-state-layer-color: #ef4444;
  --mdc-checkbox-selected-pressed-state-layer-color: #ef4444;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.vex-theme-default.dark .mat-mdc-checkbox .mdc-form-field,
.vex-theme-default .dark .mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.vex-theme-default.dark .mat-mdc-checkbox.mat-mdc-checkbox-disabled label,
.vex-theme-default .dark .mat-mdc-checkbox.mat-mdc-checkbox-disabled label {
  color: #6b7280;
}
.vex-theme-default.dark .mat-mdc-button.mat-unthemed,
.vex-theme-default .dark .mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #fff;
}
.vex-theme-default.dark .mat-mdc-button.mat-primary,
.vex-theme-default .dark .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #6366f1;
}
.vex-theme-default.dark .mat-mdc-button.mat-accent,
.vex-theme-default .dark .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #f59e0b;
}
.vex-theme-default.dark .mat-mdc-button.mat-warn,
.vex-theme-default .dark .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #ef4444;
}
.vex-theme-default.dark .mat-mdc-button[disabled][disabled],
.vex-theme-default .dark .mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-text-button-label-text-color: rgba(255, 255, 255, 0.5);
}
.vex-theme-default.dark .mat-mdc-unelevated-button.mat-unthemed,
.vex-theme-default .dark .mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #1f2937;
  --mdc-filled-button-label-text-color: #fff;
}
.vex-theme-default.dark .mat-mdc-unelevated-button.mat-primary,
.vex-theme-default .dark .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #6366f1;
  --mdc-filled-button-label-text-color: #fff;
}
.vex-theme-default.dark .mat-mdc-unelevated-button.mat-accent,
.vex-theme-default .dark .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #f59e0b;
  --mdc-filled-button-label-text-color: #000;
}
.vex-theme-default.dark .mat-mdc-unelevated-button.mat-warn,
.vex-theme-default .dark .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #ef4444;
  --mdc-filled-button-label-text-color: #fff;
}
.vex-theme-default.dark .mat-mdc-unelevated-button[disabled][disabled],
.vex-theme-default .dark .mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(255, 255, 255, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-filled-button-container-color: rgba(255, 255, 255, 0.12);
  --mdc-filled-button-label-text-color: rgba(255, 255, 255, 0.5);
}
.vex-theme-default.dark .mat-mdc-raised-button.mat-unthemed,
.vex-theme-default .dark .mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #1f2937;
  --mdc-protected-button-label-text-color: #fff;
}
.vex-theme-default.dark .mat-mdc-raised-button.mat-primary,
.vex-theme-default .dark .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #6366f1;
  --mdc-protected-button-label-text-color: #fff;
}
.vex-theme-default.dark .mat-mdc-raised-button.mat-accent,
.vex-theme-default .dark .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #f59e0b;
  --mdc-protected-button-label-text-color: #000;
}
.vex-theme-default.dark .mat-mdc-raised-button.mat-warn,
.vex-theme-default .dark .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #ef4444;
  --mdc-protected-button-label-text-color: #fff;
}
.vex-theme-default.dark .mat-mdc-raised-button[disabled][disabled],
.vex-theme-default .dark .mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(255, 255, 255, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-protected-button-container-color: rgba(255, 255, 255, 0.12);
  --mdc-protected-button-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-protected-button-container-elevation: 0;
}
.vex-theme-default.dark .mat-mdc-outlined-button,
.vex-theme-default .dark .mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
}
.vex-theme-default.dark .mat-mdc-outlined-button.mat-unthemed,
.vex-theme-default .dark .mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #fff;
}
.vex-theme-default.dark .mat-mdc-outlined-button.mat-primary,
.vex-theme-default .dark .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #6366f1;
}
.vex-theme-default.dark .mat-mdc-outlined-button.mat-accent,
.vex-theme-default .dark .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #f59e0b;
}
.vex-theme-default.dark .mat-mdc-outlined-button.mat-warn,
.vex-theme-default .dark .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #ef4444;
}
.vex-theme-default.dark .mat-mdc-outlined-button[disabled][disabled],
.vex-theme-default .dark .mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-outlined-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(255, 255, 255, 0.12);
}
.vex-theme-default.dark .mat-mdc-button, .vex-theme-default.dark .mat-mdc-outlined-button,
.vex-theme-default .dark .mat-mdc-button,
.vex-theme-default .dark .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.vex-theme-default.dark .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .vex-theme-default.dark .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-default .dark .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-default .dark .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
.vex-theme-default.dark .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-default.dark .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-default.dark .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-default.dark .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-default .dark .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-default .dark .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-default .dark .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-default .dark .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.vex-theme-default.dark .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .vex-theme-default.dark .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-default .dark .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-default .dark .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.vex-theme-default.dark .mat-mdc-button.mat-primary, .vex-theme-default.dark .mat-mdc-outlined-button.mat-primary,
.vex-theme-default .dark .mat-mdc-button.mat-primary,
.vex-theme-default .dark .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #6366f1;
  --mat-mdc-button-ripple-color: rgba(99, 102, 241, 0.1);
}
.vex-theme-default.dark .mat-mdc-button.mat-accent, .vex-theme-default.dark .mat-mdc-outlined-button.mat-accent,
.vex-theme-default .dark .mat-mdc-button.mat-accent,
.vex-theme-default .dark .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #f59e0b;
  --mat-mdc-button-ripple-color: rgba(245, 158, 11, 0.1);
}
.vex-theme-default.dark .mat-mdc-button.mat-warn, .vex-theme-default.dark .mat-mdc-outlined-button.mat-warn,
.vex-theme-default .dark .mat-mdc-button.mat-warn,
.vex-theme-default .dark .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #ef4444;
  --mat-mdc-button-ripple-color: rgba(239, 68, 68, 0.1);
}
.vex-theme-default.dark .mat-mdc-raised-button, .vex-theme-default.dark .mat-mdc-unelevated-button,
.vex-theme-default .dark .mat-mdc-raised-button,
.vex-theme-default .dark .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.vex-theme-default.dark .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .vex-theme-default.dark .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-default .dark .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-default .dark .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
.vex-theme-default.dark .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-default.dark .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-default.dark .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-default.dark .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-default .dark .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-default .dark .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-default .dark .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-default .dark .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.vex-theme-default.dark .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .vex-theme-default.dark .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-default .dark .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-default .dark .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.vex-theme-default.dark .mat-mdc-raised-button.mat-primary, .vex-theme-default.dark .mat-mdc-unelevated-button.mat-primary,
.vex-theme-default .dark .mat-mdc-raised-button.mat-primary,
.vex-theme-default .dark .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.vex-theme-default.dark .mat-mdc-raised-button.mat-accent, .vex-theme-default.dark .mat-mdc-unelevated-button.mat-accent,
.vex-theme-default .dark .mat-mdc-raised-button.mat-accent,
.vex-theme-default .dark .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.vex-theme-default.dark .mat-mdc-raised-button.mat-warn, .vex-theme-default.dark .mat-mdc-unelevated-button.mat-warn,
.vex-theme-default .dark .mat-mdc-raised-button.mat-warn,
.vex-theme-default .dark .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.vex-theme-default.dark .mat-mdc-icon-button,
.vex-theme-default .dark .mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mdc-icon-button-icon-color: inherit;
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.vex-theme-default.dark .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-default .dark .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
.vex-theme-default.dark .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-default.dark .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-default .dark .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-default .dark .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.vex-theme-default.dark .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-default .dark .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.vex-theme-default.dark .mat-mdc-icon-button.mat-primary,
.vex-theme-default .dark .mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #6200ee;
  --mat-mdc-button-ripple-color: rgba(98, 0, 238, 0.1);
}
.vex-theme-default.dark .mat-mdc-icon-button.mat-accent,
.vex-theme-default .dark .mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #018786;
  --mat-mdc-button-ripple-color: rgba(1, 135, 134, 0.1);
}
.vex-theme-default.dark .mat-mdc-icon-button.mat-warn,
.vex-theme-default .dark .mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #b00020;
  --mat-mdc-button-ripple-color: rgba(176, 0, 32, 0.1);
}
.vex-theme-default.dark .mat-mdc-icon-button.mat-primary,
.vex-theme-default .dark .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #6366f1;
  --mat-mdc-button-persistent-ripple-color: #6366f1;
  --mat-mdc-button-ripple-color: rgba(99, 102, 241, 0.1);
}
.vex-theme-default.dark .mat-mdc-icon-button.mat-accent,
.vex-theme-default .dark .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #f59e0b;
  --mat-mdc-button-persistent-ripple-color: #f59e0b;
  --mat-mdc-button-ripple-color: rgba(245, 158, 11, 0.1);
}
.vex-theme-default.dark .mat-mdc-icon-button.mat-warn,
.vex-theme-default .dark .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #ef4444;
  --mat-mdc-button-persistent-ripple-color: #ef4444;
  --mat-mdc-button-ripple-color: rgba(239, 68, 68, 0.1);
}
.vex-theme-default.dark .mat-mdc-icon-button[disabled][disabled],
.vex-theme-default .dark .mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-icon-button-disabled-icon-color: rgba(255, 255, 255, 0.5);
}
.vex-theme-default.dark .mat-mdc-fab,
.vex-theme-default.dark .mat-mdc-mini-fab,
.vex-theme-default .dark .mat-mdc-fab,
.vex-theme-default .dark .mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.vex-theme-default.dark .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-default.dark .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-default .dark .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-default .dark .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
.vex-theme-default.dark .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-default.dark .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-default.dark .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-default.dark .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-default .dark .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-default .dark .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-default .dark .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-default .dark .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.vex-theme-default.dark .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-default.dark .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-default .dark .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-default .dark .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.vex-theme-default.dark .mat-mdc-fab.mat-primary,
.vex-theme-default.dark .mat-mdc-mini-fab.mat-primary,
.vex-theme-default .dark .mat-mdc-fab.mat-primary,
.vex-theme-default .dark .mat-mdc-mini-fab.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.vex-theme-default.dark .mat-mdc-fab.mat-accent,
.vex-theme-default.dark .mat-mdc-mini-fab.mat-accent,
.vex-theme-default .dark .mat-mdc-fab.mat-accent,
.vex-theme-default .dark .mat-mdc-mini-fab.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.vex-theme-default.dark .mat-mdc-fab.mat-warn,
.vex-theme-default.dark .mat-mdc-mini-fab.mat-warn,
.vex-theme-default .dark .mat-mdc-fab.mat-warn,
.vex-theme-default .dark .mat-mdc-mini-fab.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.vex-theme-default.dark .mat-mdc-fab[disabled][disabled],
.vex-theme-default.dark .mat-mdc-mini-fab[disabled][disabled],
.vex-theme-default .dark .mat-mdc-fab[disabled][disabled],
.vex-theme-default .dark .mat-mdc-mini-fab[disabled][disabled] {
  --mdc-fab-container-color: rgba(255, 255, 255, 0.12);
  --mdc-fab-icon-color: rgba(255, 255, 255, 0.5);
  --mat-mdc-fab-color: rgba(255, 255, 255, 0.5);
}
.vex-theme-default.dark .mat-mdc-fab.mat-unthemed,
.vex-theme-default.dark .mat-mdc-mini-fab.mat-unthemed,
.vex-theme-default .dark .mat-mdc-fab.mat-unthemed,
.vex-theme-default .dark .mat-mdc-mini-fab.mat-unthemed {
  --mdc-fab-container-color: #1f2937;
  --mdc-fab-icon-color: white;
  --mat-mdc-fab-color: #fff;
}
.vex-theme-default.dark .mat-mdc-fab.mat-primary,
.vex-theme-default.dark .mat-mdc-mini-fab.mat-primary,
.vex-theme-default .dark .mat-mdc-fab.mat-primary,
.vex-theme-default .dark .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #6366f1;
  --mdc-fab-icon-color: white;
  --mat-mdc-fab-color: #fff;
}
.vex-theme-default.dark .mat-mdc-fab.mat-accent,
.vex-theme-default.dark .mat-mdc-mini-fab.mat-accent,
.vex-theme-default .dark .mat-mdc-fab.mat-accent,
.vex-theme-default .dark .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: #f59e0b;
  --mdc-fab-icon-color: black;
  --mat-mdc-fab-color: #000;
}
.vex-theme-default.dark .mat-mdc-fab.mat-warn,
.vex-theme-default.dark .mat-mdc-mini-fab.mat-warn,
.vex-theme-default .dark .mat-mdc-fab.mat-warn,
.vex-theme-default .dark .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: #ef4444;
  --mdc-fab-icon-color: white;
  --mat-mdc-fab-color: #fff;
}
.vex-theme-default.dark .mat-mdc-snack-bar-container,
.vex-theme-default .dark .mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: #d2d4d7;
  --mdc-snackbar-supporting-text-color: rgba(31, 41, 55, 0.87);
  --mat-snack-bar-button-color: rgba(0, 0, 0, 0.87);
}
.vex-theme-default.dark .mat-mdc-progress-spinner,
.vex-theme-default .dark .mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #6366f1;
}
.vex-theme-default.dark .mat-mdc-progress-spinner.mat-accent,
.vex-theme-default .dark .mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: #f59e0b;
}
.vex-theme-default.dark .mat-mdc-progress-spinner.mat-warn,
.vex-theme-default .dark .mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: #ef4444;
}
.vex-theme-default.dark, .vex-theme-default .dark {
  --mat-badge-background-color: #6366f1;
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: #4b5563;
  --mat-badge-disabled-state-text-color: #6b7280;
}

.vex-theme-default.dark .mat-badge-accent,
.vex-theme-default .dark .mat-badge-accent {
  --mat-badge-background-color: #f59e0b;
  --mat-badge-text-color: white;
}
.vex-theme-default.dark .mat-badge-warn,
.vex-theme-default .dark .mat-badge-warn {
  --mat-badge-background-color: #ef4444;
  --mat-badge-text-color: white;
}
.vex-theme-default.dark, .vex-theme-default .dark {
  --mat-bottom-sheet-container-text-color: white;
  --mat-bottom-sheet-container-background-color: #1f2937;
}

.vex-theme-default.dark, .vex-theme-default .dark {
  --mat-legacy-button-toggle-text-color: #6b7280;
  --mat-legacy-button-toggle-state-layer-color: rgba(255, 255, 255, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: #9ca3af;
  --mat-legacy-button-toggle-selected-state-background-color: #111827;
  --mat-legacy-button-toggle-disabled-state-text-color: #4b5563;
  --mat-legacy-button-toggle-disabled-state-background-color: black;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #1f2937;
  --mat-standard-button-toggle-text-color: white;
  --mat-standard-button-toggle-background-color: #1f2937;
  --mat-standard-button-toggle-state-layer-color: white;
  --mat-standard-button-toggle-selected-state-background-color: #111827;
  --mat-standard-button-toggle-selected-state-text-color: white;
  --mat-standard-button-toggle-disabled-state-text-color: #4b5563;
  --mat-standard-button-toggle-disabled-state-background-color: #1f2937;
  --mat-standard-button-toggle-disabled-selected-state-text-color: white;
  --mat-standard-button-toggle-disabled-selected-state-background-color: #1f2937;
  --mat-standard-button-toggle-divider-color: #3a434f;
}

.vex-theme-default.dark, .vex-theme-default .dark {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #6366f1;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(99, 102, 241, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(99, 102, 241, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(99, 102, 241, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #6366f1;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(99, 102, 241, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: white;
  --mat-datepicker-calendar-body-label-text-color: #9ca3af;
  --mat-datepicker-calendar-period-button-icon-color: white;
  --mat-datepicker-calendar-navigation-button-icon-color: white;
  --mat-datepicker-calendar-header-divider-color: rgba(255, 255, 255, 0.12);
  --mat-datepicker-calendar-header-text-color: #9ca3af;
  --mat-datepicker-calendar-date-today-outline-color: #6b7280;
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(107, 114, 128, 0.8);
  --mat-datepicker-calendar-date-text-color: white;
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: #6b7280;
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(255, 255, 255, 0.24);
  --mat-datepicker-range-input-separator-color: white;
  --mat-datepicker-range-input-disabled-state-separator-color: #6b7280;
  --mat-datepicker-range-input-disabled-state-text-color: #6b7280;
  --mat-datepicker-calendar-container-background-color: #1f2937;
  --mat-datepicker-calendar-container-text-color: white;
}

.vex-theme-default.dark .mat-datepicker-content.mat-accent,
.vex-theme-default .dark .mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #f59e0b;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(245, 158, 11, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(245, 158, 11, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(245, 158, 11, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(245, 158, 11, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.vex-theme-default.dark .mat-datepicker-content.mat-warn,
.vex-theme-default .dark .mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #ef4444;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(239, 68, 68, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(239, 68, 68, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(239, 68, 68, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(239, 68, 68, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.vex-theme-default.dark .mat-datepicker-toggle-active.mat-accent,
.vex-theme-default .dark .mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #f59e0b;
}
.vex-theme-default.dark .mat-datepicker-toggle-active.mat-warn,
.vex-theme-default .dark .mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #ef4444;
}
.vex-theme-default.dark, .vex-theme-default .dark {
  --mat-divider-color: rgba(255, 255, 255, 0.12);
}

.vex-theme-default.dark, .vex-theme-default .dark {
  --mat-expansion-container-background-color: #1f2937;
  --mat-expansion-container-text-color: white;
  --mat-expansion-actions-divider-color: rgba(255, 255, 255, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(255, 255, 255, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(255, 255, 255, 0.04);
  --mat-expansion-header-disabled-state-text-color: #4b5563;
  --mat-expansion-header-text-color: white;
  --mat-expansion-header-description-color: #9ca3af;
  --mat-expansion-header-indicator-color: #9ca3af;
}

.vex-theme-default.dark, .vex-theme-default .dark {
  --mat-icon-color: inherit;
}

.vex-theme-default.dark .mat-icon.mat-primary,
.vex-theme-default .dark .mat-icon.mat-primary {
  --mat-icon-color: #6366f1;
}
.vex-theme-default.dark .mat-icon.mat-accent,
.vex-theme-default .dark .mat-icon.mat-accent {
  --mat-icon-color: #f59e0b;
}
.vex-theme-default.dark .mat-icon.mat-warn,
.vex-theme-default .dark .mat-icon.mat-warn {
  --mat-icon-color: #ef4444;
}
.vex-theme-default.dark, .vex-theme-default .dark {
  --mat-sidenav-container-divider-color: rgba(255, 255, 255, 0.12);
  --mat-sidenav-container-background-color: #1f2937;
  --mat-sidenav-container-text-color: white;
  --mat-sidenav-content-background-color: #111827;
  --mat-sidenav-content-text-color: white;
  --mat-sidenav-scrim-color: rgba(224, 214, 200, 0.6);
}

.vex-theme-default.dark, .vex-theme-default .dark {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #6366f1;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #6366f1;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #6366f1;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: #1f2937;
  --mat-stepper-line-color: rgba(255, 255, 255, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(255, 255, 255, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(255, 255, 255, 0.04);
  --mat-stepper-header-label-text-color: #9ca3af;
  --mat-stepper-header-optional-label-text-color: #9ca3af;
  --mat-stepper-header-selected-state-label-text-color: white;
  --mat-stepper-header-error-state-label-text-color: #ef4444;
  --mat-stepper-header-icon-background-color: #9ca3af;
  --mat-stepper-header-error-state-icon-foreground-color: #ef4444;
  --mat-stepper-header-error-state-icon-background-color: transparent;
}
.vex-theme-default.dark .mat-step-header.mat-accent, .vex-theme-default .dark .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #f59e0b;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #f59e0b;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #f59e0b;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.vex-theme-default.dark .mat-step-header.mat-warn, .vex-theme-default .dark .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #ef4444;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #ef4444;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #ef4444;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}

.vex-theme-default.dark .mat-sort-header-arrow,
.vex-theme-default .dark .mat-sort-header-arrow {
  color: #9ca3af;
}
.vex-theme-default.dark, .vex-theme-default .dark {
  --mat-toolbar-container-background-color: #1f2937;
  --mat-toolbar-container-text-color: white;
}

.vex-theme-default.dark .mat-toolbar.mat-primary,
.vex-theme-default .dark .mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #6366f1;
  --mat-toolbar-container-text-color: white;
}
.vex-theme-default.dark .mat-toolbar.mat-accent,
.vex-theme-default .dark .mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #f59e0b;
  --mat-toolbar-container-text-color: white;
}
.vex-theme-default.dark .mat-toolbar.mat-warn,
.vex-theme-default .dark .mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #ef4444;
  --mat-toolbar-container-text-color: white;
}
.vex-theme-default.dark .mat-tree,
.vex-theme-default .dark .mat-tree {
  background: #1f2937;
}
.vex-theme-default.dark .mat-tree-node,
.vex-theme-default.dark .mat-nested-tree-node,
.vex-theme-default .dark .mat-tree-node,
.vex-theme-default .dark .mat-nested-tree-node {
  color: white;
}

/**
 * Override the theme foreground/background colors with the Angular Material colors
 */
.vex-theme-teal,
.vex-theme-teal.light,
.vex-theme-teal .light {
  --mat-table-background-color: white;
  --mat-table-header-headline-color: #030712;
  --mat-table-row-item-label-text-color: #030712;
  --mat-table-row-item-outline-color: rgba(0, 0, 0, 0.06);
}
.vex-theme-teal .mat-ripple-element,
.vex-theme-teal.light .mat-ripple-element,
.vex-theme-teal .light .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.vex-theme-teal, .vex-theme-teal.light, .vex-theme-teal .light {
  --mat-option-selected-state-label-text-color: #0d9488;
  --mat-option-label-text-color: #030712;
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.vex-theme-teal .mat-accent,
.vex-theme-teal.light .mat-accent,
.vex-theme-teal .light .mat-accent {
  --mat-option-selected-state-label-text-color: #f59e0b;
}
.vex-theme-teal .mat-warn,
.vex-theme-teal.light .mat-warn,
.vex-theme-teal .light .mat-warn {
  --mat-option-selected-state-label-text-color: #ef4444;
}
.vex-theme-teal, .vex-theme-teal.light, .vex-theme-teal .light {
  --mat-optgroup-label-text-color: #030712;
}

.vex-theme-teal .mat-pseudo-checkbox-full,
.vex-theme-teal.light .mat-pseudo-checkbox-full,
.vex-theme-teal .light .mat-pseudo-checkbox-full {
  color: #4b5563;
}
.vex-theme-teal .mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled,
.vex-theme-teal.light .mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled,
.vex-theme-teal .light .mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.vex-theme-teal .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-teal .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-teal.light .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-teal.light .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-teal .light .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-teal .light .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #0d9488;
}
.vex-theme-teal .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-teal .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-teal.light .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-teal.light .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-teal .light .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-teal .light .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #0d9488;
}
.vex-theme-teal .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-teal .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after,
.vex-theme-teal.light .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-teal.light .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after,
.vex-theme-teal .light .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-teal .light .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #f3f4f6;
}
.vex-theme-teal .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-teal .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-teal.light .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-teal.light .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-teal .light .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-teal .light .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #f59e0b;
}
.vex-theme-teal .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-teal .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-teal.light .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-teal.light .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-teal .light .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-teal .light .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #f59e0b;
}
.vex-theme-teal .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-teal .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after,
.vex-theme-teal.light .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-teal.light .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after,
.vex-theme-teal .light .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-teal .light .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #f3f4f6;
}
.vex-theme-teal .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-teal .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-teal.light .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-teal.light .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-teal .light .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-teal .light .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #f59e0b;
}
.vex-theme-teal .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-teal .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-teal.light .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-teal.light .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-teal .light .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-teal .light .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #f59e0b;
}
.vex-theme-teal .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-teal .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after,
.vex-theme-teal.light .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-teal.light .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after,
.vex-theme-teal .light .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-teal .light .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #f3f4f6;
}
.vex-theme-teal .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-teal .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-teal.light .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-teal.light .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-teal .light .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-teal .light .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #ef4444;
}
.vex-theme-teal .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-teal .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-teal.light .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-teal.light .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-teal .light .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-teal .light .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #ef4444;
}
.vex-theme-teal .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-teal .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after,
.vex-theme-teal.light .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-teal.light .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after,
.vex-theme-teal .light .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-teal .light .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #f3f4f6;
}
.vex-theme-teal .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-teal .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-teal.light .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-teal.light .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-teal .light .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-teal .light .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #b0b0b0;
}
.vex-theme-teal .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-teal .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-teal.light .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-teal.light .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-teal .light .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-teal .light .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #b0b0b0;
}
.vex-theme-teal .mat-app-background, .vex-theme-teal.mat-app-background,
.vex-theme-teal.light .mat-app-background,
.vex-theme-teal.light.mat-app-background,
.vex-theme-teal .light .mat-app-background,
.vex-theme-teal .light.mat-app-background {
  background-color: #f3f4f6;
  color: #030712;
}
.vex-theme-teal .mat-elevation-z0, .vex-theme-teal .mat-mdc-elevation-specific.mat-elevation-z0,
.vex-theme-teal.light .mat-elevation-z0,
.vex-theme-teal.light .mat-mdc-elevation-specific.mat-elevation-z0,
.vex-theme-teal .light .mat-elevation-z0,
.vex-theme-teal .light .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-teal .mat-elevation-z1, .vex-theme-teal .mat-mdc-elevation-specific.mat-elevation-z1,
.vex-theme-teal.light .mat-elevation-z1,
.vex-theme-teal.light .mat-mdc-elevation-specific.mat-elevation-z1,
.vex-theme-teal .light .mat-elevation-z1,
.vex-theme-teal .light .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-teal .mat-elevation-z2, .vex-theme-teal .mat-mdc-elevation-specific.mat-elevation-z2,
.vex-theme-teal.light .mat-elevation-z2,
.vex-theme-teal.light .mat-mdc-elevation-specific.mat-elevation-z2,
.vex-theme-teal .light .mat-elevation-z2,
.vex-theme-teal .light .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-teal .mat-elevation-z3, .vex-theme-teal .mat-mdc-elevation-specific.mat-elevation-z3,
.vex-theme-teal.light .mat-elevation-z3,
.vex-theme-teal.light .mat-mdc-elevation-specific.mat-elevation-z3,
.vex-theme-teal .light .mat-elevation-z3,
.vex-theme-teal .light .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-teal .mat-elevation-z4, .vex-theme-teal .mat-mdc-elevation-specific.mat-elevation-z4,
.vex-theme-teal.light .mat-elevation-z4,
.vex-theme-teal.light .mat-mdc-elevation-specific.mat-elevation-z4,
.vex-theme-teal .light .mat-elevation-z4,
.vex-theme-teal .light .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-teal .mat-elevation-z5, .vex-theme-teal .mat-mdc-elevation-specific.mat-elevation-z5,
.vex-theme-teal.light .mat-elevation-z5,
.vex-theme-teal.light .mat-mdc-elevation-specific.mat-elevation-z5,
.vex-theme-teal .light .mat-elevation-z5,
.vex-theme-teal .light .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-teal .mat-elevation-z6, .vex-theme-teal .mat-mdc-elevation-specific.mat-elevation-z6,
.vex-theme-teal.light .mat-elevation-z6,
.vex-theme-teal.light .mat-mdc-elevation-specific.mat-elevation-z6,
.vex-theme-teal .light .mat-elevation-z6,
.vex-theme-teal .light .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-teal .mat-elevation-z7, .vex-theme-teal .mat-mdc-elevation-specific.mat-elevation-z7,
.vex-theme-teal.light .mat-elevation-z7,
.vex-theme-teal.light .mat-mdc-elevation-specific.mat-elevation-z7,
.vex-theme-teal .light .mat-elevation-z7,
.vex-theme-teal .light .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.vex-theme-teal .mat-elevation-z8, .vex-theme-teal .mat-mdc-elevation-specific.mat-elevation-z8,
.vex-theme-teal.light .mat-elevation-z8,
.vex-theme-teal.light .mat-mdc-elevation-specific.mat-elevation-z8,
.vex-theme-teal .light .mat-elevation-z8,
.vex-theme-teal .light .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.vex-theme-teal .mat-elevation-z9, .vex-theme-teal .mat-mdc-elevation-specific.mat-elevation-z9,
.vex-theme-teal.light .mat-elevation-z9,
.vex-theme-teal.light .mat-mdc-elevation-specific.mat-elevation-z9,
.vex-theme-teal .light .mat-elevation-z9,
.vex-theme-teal .light .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.vex-theme-teal .mat-elevation-z10, .vex-theme-teal .mat-mdc-elevation-specific.mat-elevation-z10,
.vex-theme-teal.light .mat-elevation-z10,
.vex-theme-teal.light .mat-mdc-elevation-specific.mat-elevation-z10,
.vex-theme-teal .light .mat-elevation-z10,
.vex-theme-teal .light .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.vex-theme-teal .mat-elevation-z11, .vex-theme-teal .mat-mdc-elevation-specific.mat-elevation-z11,
.vex-theme-teal.light .mat-elevation-z11,
.vex-theme-teal.light .mat-mdc-elevation-specific.mat-elevation-z11,
.vex-theme-teal .light .mat-elevation-z11,
.vex-theme-teal .light .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.vex-theme-teal .mat-elevation-z12, .vex-theme-teal .mat-mdc-elevation-specific.mat-elevation-z12,
.vex-theme-teal.light .mat-elevation-z12,
.vex-theme-teal.light .mat-mdc-elevation-specific.mat-elevation-z12,
.vex-theme-teal .light .mat-elevation-z12,
.vex-theme-teal .light .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.vex-theme-teal .mat-elevation-z13, .vex-theme-teal .mat-mdc-elevation-specific.mat-elevation-z13,
.vex-theme-teal.light .mat-elevation-z13,
.vex-theme-teal.light .mat-mdc-elevation-specific.mat-elevation-z13,
.vex-theme-teal .light .mat-elevation-z13,
.vex-theme-teal .light .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.vex-theme-teal .mat-elevation-z14, .vex-theme-teal .mat-mdc-elevation-specific.mat-elevation-z14,
.vex-theme-teal.light .mat-elevation-z14,
.vex-theme-teal.light .mat-mdc-elevation-specific.mat-elevation-z14,
.vex-theme-teal .light .mat-elevation-z14,
.vex-theme-teal .light .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.vex-theme-teal .mat-elevation-z15, .vex-theme-teal .mat-mdc-elevation-specific.mat-elevation-z15,
.vex-theme-teal.light .mat-elevation-z15,
.vex-theme-teal.light .mat-mdc-elevation-specific.mat-elevation-z15,
.vex-theme-teal .light .mat-elevation-z15,
.vex-theme-teal .light .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.vex-theme-teal .mat-elevation-z16, .vex-theme-teal .mat-mdc-elevation-specific.mat-elevation-z16,
.vex-theme-teal.light .mat-elevation-z16,
.vex-theme-teal.light .mat-mdc-elevation-specific.mat-elevation-z16,
.vex-theme-teal .light .mat-elevation-z16,
.vex-theme-teal .light .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.vex-theme-teal .mat-elevation-z17, .vex-theme-teal .mat-mdc-elevation-specific.mat-elevation-z17,
.vex-theme-teal.light .mat-elevation-z17,
.vex-theme-teal.light .mat-mdc-elevation-specific.mat-elevation-z17,
.vex-theme-teal .light .mat-elevation-z17,
.vex-theme-teal .light .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.vex-theme-teal .mat-elevation-z18, .vex-theme-teal .mat-mdc-elevation-specific.mat-elevation-z18,
.vex-theme-teal.light .mat-elevation-z18,
.vex-theme-teal.light .mat-mdc-elevation-specific.mat-elevation-z18,
.vex-theme-teal .light .mat-elevation-z18,
.vex-theme-teal .light .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.vex-theme-teal .mat-elevation-z19, .vex-theme-teal .mat-mdc-elevation-specific.mat-elevation-z19,
.vex-theme-teal.light .mat-elevation-z19,
.vex-theme-teal.light .mat-mdc-elevation-specific.mat-elevation-z19,
.vex-theme-teal .light .mat-elevation-z19,
.vex-theme-teal .light .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.vex-theme-teal .mat-elevation-z20, .vex-theme-teal .mat-mdc-elevation-specific.mat-elevation-z20,
.vex-theme-teal.light .mat-elevation-z20,
.vex-theme-teal.light .mat-mdc-elevation-specific.mat-elevation-z20,
.vex-theme-teal .light .mat-elevation-z20,
.vex-theme-teal .light .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.vex-theme-teal .mat-elevation-z21, .vex-theme-teal .mat-mdc-elevation-specific.mat-elevation-z21,
.vex-theme-teal.light .mat-elevation-z21,
.vex-theme-teal.light .mat-mdc-elevation-specific.mat-elevation-z21,
.vex-theme-teal .light .mat-elevation-z21,
.vex-theme-teal .light .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.vex-theme-teal .mat-elevation-z22, .vex-theme-teal .mat-mdc-elevation-specific.mat-elevation-z22,
.vex-theme-teal.light .mat-elevation-z22,
.vex-theme-teal.light .mat-mdc-elevation-specific.mat-elevation-z22,
.vex-theme-teal .light .mat-elevation-z22,
.vex-theme-teal .light .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.vex-theme-teal .mat-elevation-z23, .vex-theme-teal .mat-mdc-elevation-specific.mat-elevation-z23,
.vex-theme-teal.light .mat-elevation-z23,
.vex-theme-teal.light .mat-mdc-elevation-specific.mat-elevation-z23,
.vex-theme-teal .light .mat-elevation-z23,
.vex-theme-teal .light .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.vex-theme-teal .mat-elevation-z24, .vex-theme-teal .mat-mdc-elevation-specific.mat-elevation-z24,
.vex-theme-teal.light .mat-elevation-z24,
.vex-theme-teal.light .mat-mdc-elevation-specific.mat-elevation-z24,
.vex-theme-teal .light .mat-elevation-z24,
.vex-theme-teal .light .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.vex-theme-teal .mat-mdc-card,
.vex-theme-teal.light .mat-mdc-card,
.vex-theme-teal .light .mat-mdc-card {
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: #4b5563;
}
.vex-theme-teal .mat-mdc-progress-bar,
.vex-theme-teal.light .mat-mdc-progress-bar,
.vex-theme-teal .light .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #0d9488;
  --mdc-linear-progress-track-color: rgba(13, 148, 136, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.vex-theme-teal .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots,
.vex-theme-teal.light .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots,
.vex-theme-teal .light .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-color: rgba(13, 148, 136, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(13, 148, 136, 0.25));
}
@media (forced-colors: active) {
  .vex-theme-teal .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots,
  .vex-theme-teal.light .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots,
  .vex-theme-teal .light .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .vex-theme-teal .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots,
  .vex-theme-teal.light .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots,
  .vex-theme-teal .light .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(13, 148, 136, 0.25)'/%3E%3C/svg%3E");
  }
}
.vex-theme-teal .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar,
.vex-theme-teal.light .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar,
.vex-theme-teal .light .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgba(13, 148, 136, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(13, 148, 136, 0.25));
}
.vex-theme-teal .mat-mdc-progress-bar.mat-accent,
.vex-theme-teal.light .mat-mdc-progress-bar.mat-accent,
.vex-theme-teal .light .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #f59e0b;
  --mdc-linear-progress-track-color: rgba(245, 158, 11, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.vex-theme-teal .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots,
.vex-theme-teal.light .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots,
.vex-theme-teal .light .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-color: rgba(245, 158, 11, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(245, 158, 11, 0.25));
}
@media (forced-colors: active) {
  .vex-theme-teal .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots,
  .vex-theme-teal.light .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots,
  .vex-theme-teal .light .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .vex-theme-teal .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots,
  .vex-theme-teal.light .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots,
  .vex-theme-teal .light .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(245, 158, 11, 0.25)'/%3E%3C/svg%3E");
  }
}
.vex-theme-teal .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar,
.vex-theme-teal.light .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar,
.vex-theme-teal .light .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: rgba(245, 158, 11, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(245, 158, 11, 0.25));
}
.vex-theme-teal .mat-mdc-progress-bar.mat-warn,
.vex-theme-teal.light .mat-mdc-progress-bar.mat-warn,
.vex-theme-teal .light .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #ef4444;
  --mdc-linear-progress-track-color: rgba(239, 68, 68, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.vex-theme-teal .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots,
.vex-theme-teal.light .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots,
.vex-theme-teal .light .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-color: rgba(239, 68, 68, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(239, 68, 68, 0.25));
}
@media (forced-colors: active) {
  .vex-theme-teal .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots,
  .vex-theme-teal.light .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots,
  .vex-theme-teal .light .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .vex-theme-teal .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots,
  .vex-theme-teal.light .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots,
  .vex-theme-teal .light .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(239, 68, 68, 0.25)'/%3E%3C/svg%3E");
  }
}
.vex-theme-teal .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar,
.vex-theme-teal.light .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar,
.vex-theme-teal .light .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: rgba(239, 68, 68, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(239, 68, 68, 0.25));
}
.vex-theme-teal .mat-mdc-tooltip,
.vex-theme-teal.light .mat-mdc-tooltip,
.vex-theme-teal .light .mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #1f2937;
  --mdc-plain-tooltip-supporting-text-color: #fff;
}
.vex-theme-teal, .vex-theme-teal.light, .vex-theme-teal .light {
  --mdc-filled-text-field-caret-color: #0d9488;
  --mdc-filled-text-field-focus-active-indicator-color: #0d9488;
  --mdc-filled-text-field-focus-label-text-color: rgba(13, 148, 136, 0.87);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-error-focus-label-text-color: #ef4444;
  --mdc-filled-text-field-error-label-text-color: #ef4444;
  --mdc-filled-text-field-error-caret-color: #ef4444;
  --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #ef4444;
  --mdc-filled-text-field-error-focus-active-indicator-color: #ef4444;
  --mdc-filled-text-field-error-hover-active-indicator-color: #ef4444;
  --mdc-outlined-text-field-caret-color: #0d9488;
  --mdc-outlined-text-field-focus-outline-color: #0d9488;
  --mdc-outlined-text-field-focus-label-text-color: rgba(13, 148, 136, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-error-caret-color: #ef4444;
  --mdc-outlined-text-field-error-focus-label-text-color: #ef4444;
  --mdc-outlined-text-field-error-label-text-color: #ef4444;
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #ef4444;
  --mdc-outlined-text-field-error-hover-outline-color: #ef4444;
  --mdc-outlined-text-field-error-outline-color: #ef4444;
  --mat-form-field-disabled-input-text-placeholder-color: rgba(0, 0, 0, 0.38);
}

.vex-theme-teal .mat-mdc-form-field-error,
.vex-theme-teal.light .mat-mdc-form-field-error,
.vex-theme-teal .light .mat-mdc-form-field-error {
  color: var(--mdc-theme-error, #ef4444);
}
.vex-theme-teal .mat-mdc-form-field-subscript-wrapper,
.vex-theme-teal .mat-mdc-form-field-bottom-align::before,
.vex-theme-teal.light .mat-mdc-form-field-subscript-wrapper,
.vex-theme-teal.light .mat-mdc-form-field-bottom-align::before,
.vex-theme-teal .light .mat-mdc-form-field-subscript-wrapper,
.vex-theme-teal .light .mat-mdc-form-field-bottom-align::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mat-form-field-subscript-text-font);
  line-height: var(--mat-form-field-subscript-text-line-height);
  font-size: var(--mat-form-field-subscript-text-size);
  letter-spacing: var(--mat-form-field-subscript-text-tracking);
  font-weight: var(--mat-form-field-subscript-text-weight);
}
.vex-theme-teal .mat-mdc-form-field-focus-overlay,
.vex-theme-teal.light .mat-mdc-form-field-focus-overlay,
.vex-theme-teal .light .mat-mdc-form-field-focus-overlay {
  background-color: rgba(0, 0, 0, 0.87);
}
.vex-theme-teal .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay,
.vex-theme-teal.light .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay,
.vex-theme-teal .light .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0.04;
}
.vex-theme-teal .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay,
.vex-theme-teal.light .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay,
.vex-theme-teal .light .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.12;
}
.vex-theme-teal .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after,
.vex-theme-teal.light .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after,
.vex-theme-teal .light .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.vex-theme-teal .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after,
.vex-theme-teal.light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after,
.vex-theme-teal .light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after {
  color: rgba(13, 148, 136, 0.87);
}
.vex-theme-teal .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after,
.vex-theme-teal.light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after,
.vex-theme-teal .light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after {
  color: rgba(245, 158, 11, 0.87);
}
.vex-theme-teal .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after,
.vex-theme-teal.light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after,
.vex-theme-teal .light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after {
  color: rgba(239, 68, 68, 0.87);
}
.vex-theme-teal .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after,
.vex-theme-teal.light .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after,
.vex-theme-teal .light .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.vex-theme-teal .mat-mdc-form-field.mat-accent,
.vex-theme-teal.light .mat-mdc-form-field.mat-accent,
.vex-theme-teal .light .mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #f59e0b;
  --mdc-filled-text-field-focus-active-indicator-color: #f59e0b;
  --mdc-filled-text-field-focus-label-text-color: rgba(245, 158, 11, 0.87);
  --mdc-outlined-text-field-caret-color: #f59e0b;
  --mdc-outlined-text-field-focus-outline-color: #f59e0b;
  --mdc-outlined-text-field-focus-label-text-color: rgba(245, 158, 11, 0.87);
}
.vex-theme-teal .mat-mdc-form-field.mat-warn,
.vex-theme-teal.light .mat-mdc-form-field.mat-warn,
.vex-theme-teal .light .mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #ef4444;
  --mdc-filled-text-field-focus-active-indicator-color: #ef4444;
  --mdc-filled-text-field-focus-label-text-color: rgba(239, 68, 68, 0.87);
  --mdc-outlined-text-field-caret-color: #ef4444;
  --mdc-outlined-text-field-focus-outline-color: #ef4444;
  --mdc-outlined-text-field-focus-label-text-color: rgba(239, 68, 68, 0.87);
}
.vex-theme-teal .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch,
.vex-theme-teal.light .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch,
.vex-theme-teal .light .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: 1px solid transparent;
}
.vex-theme-teal [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch,
.vex-theme-teal.light [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch,
.vex-theme-teal .light [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none;
  border-right: 1px solid transparent;
}
.vex-theme-teal, .vex-theme-teal.light, .vex-theme-teal .light {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(13, 148, 136, 0.87);
  --mat-select-invalid-arrow-color: rgba(239, 68, 68, 0.87);
}
.vex-theme-teal .mat-mdc-form-field.mat-accent, .vex-theme-teal.light .mat-mdc-form-field.mat-accent, .vex-theme-teal .light .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(245, 158, 11, 0.87);
  --mat-select-invalid-arrow-color: rgba(239, 68, 68, 0.87);
}
.vex-theme-teal .mat-mdc-form-field.mat-warn, .vex-theme-teal.light .mat-mdc-form-field.mat-warn, .vex-theme-teal .light .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(239, 68, 68, 0.87);
  --mat-select-invalid-arrow-color: rgba(239, 68, 68, 0.87);
}

.vex-theme-teal, .vex-theme-teal.light, .vex-theme-teal .light {
  --mat-autocomplete-background-color: white;
}

.vex-theme-teal .mat-mdc-dialog-container,
.vex-theme-teal.light .mat-mdc-dialog-container,
.vex-theme-teal .light .mat-mdc-dialog-container {
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}
.vex-theme-teal .mat-mdc-standard-chip,
.vex-theme-teal.light .mat-mdc-standard-chip,
.vex-theme-teal .light .mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
}
.vex-theme-teal .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .vex-theme-teal .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary,
.vex-theme-teal.light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary,
.vex-theme-teal.light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary,
.vex-theme-teal .light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary,
.vex-theme-teal .light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #0d9488;
  --mdc-chip-elevated-disabled-container-color: #0d9488;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.vex-theme-teal .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .vex-theme-teal .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent,
.vex-theme-teal.light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent,
.vex-theme-teal.light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent,
.vex-theme-teal .light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent,
.vex-theme-teal .light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #f59e0b;
  --mdc-chip-elevated-disabled-container-color: #f59e0b;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.vex-theme-teal .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .vex-theme-teal .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn,
.vex-theme-teal.light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn,
.vex-theme-teal.light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn,
.vex-theme-teal .light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn,
.vex-theme-teal .light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #ef4444;
  --mdc-chip-elevated-disabled-container-color: #ef4444;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.vex-theme-teal .mat-mdc-slide-toggle,
.vex-theme-teal.light .mat-mdc-slide-toggle,
.vex-theme-teal .light .mat-mdc-slide-toggle {
  --mdc-switch-selected-focus-state-layer-color: #0d9488;
  --mdc-switch-selected-handle-color: #0d9488;
  --mdc-switch-selected-hover-state-layer-color: #0d9488;
  --mdc-switch-selected-pressed-state-layer-color: #0d9488;
  --mdc-switch-selected-focus-handle-color: #134e4a;
  --mdc-switch-selected-hover-handle-color: #134e4a;
  --mdc-switch-selected-pressed-handle-color: #134e4a;
  --mdc-switch-selected-focus-track-color: #5eead4;
  --mdc-switch-selected-hover-track-color: #5eead4;
  --mdc-switch-selected-pressed-track-color: #5eead4;
  --mdc-switch-selected-track-color: #5eead4;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
}
.vex-theme-teal .mat-mdc-slide-toggle .mdc-form-field,
.vex-theme-teal.light .mat-mdc-slide-toggle .mdc-form-field,
.vex-theme-teal .light .mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.vex-theme-teal .mat-mdc-slide-toggle .mdc-switch--disabled + label,
.vex-theme-teal.light .mat-mdc-slide-toggle .mdc-switch--disabled + label,
.vex-theme-teal .light .mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: #6b7280;
}
.vex-theme-teal .mat-mdc-slide-toggle.mat-accent,
.vex-theme-teal.light .mat-mdc-slide-toggle.mat-accent,
.vex-theme-teal .light .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #d97706;
  --mdc-switch-selected-handle-color: #d97706;
  --mdc-switch-selected-hover-state-layer-color: #d97706;
  --mdc-switch-selected-pressed-state-layer-color: #d97706;
  --mdc-switch-selected-focus-handle-color: #78350f;
  --mdc-switch-selected-hover-handle-color: #78350f;
  --mdc-switch-selected-pressed-handle-color: #78350f;
  --mdc-switch-selected-focus-track-color: #fcd34d;
  --mdc-switch-selected-hover-track-color: #fcd34d;
  --mdc-switch-selected-pressed-track-color: #fcd34d;
  --mdc-switch-selected-track-color: #fcd34d;
}
.vex-theme-teal .mat-mdc-slide-toggle.mat-warn,
.vex-theme-teal.light .mat-mdc-slide-toggle.mat-warn,
.vex-theme-teal .light .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #dc2626;
  --mdc-switch-selected-handle-color: #dc2626;
  --mdc-switch-selected-hover-state-layer-color: #dc2626;
  --mdc-switch-selected-pressed-state-layer-color: #dc2626;
  --mdc-switch-selected-focus-handle-color: #7f1d1d;
  --mdc-switch-selected-hover-handle-color: #7f1d1d;
  --mdc-switch-selected-pressed-handle-color: #7f1d1d;
  --mdc-switch-selected-focus-track-color: #fca5a5;
  --mdc-switch-selected-hover-track-color: #fca5a5;
  --mdc-switch-selected-pressed-track-color: #fca5a5;
  --mdc-switch-selected-track-color: #fca5a5;
}
.vex-theme-teal .mat-mdc-radio-button .mdc-form-field,
.vex-theme-teal.light .mat-mdc-radio-button .mdc-form-field,
.vex-theme-teal .light .mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.vex-theme-teal .mat-mdc-radio-button.mat-primary,
.vex-theme-teal.light .mat-mdc-radio-button.mat-primary,
.vex-theme-teal .light .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #0d9488;
  --mdc-radio-selected-hover-icon-color: #0d9488;
  --mdc-radio-selected-icon-color: #0d9488;
  --mdc-radio-selected-pressed-icon-color: #0d9488;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #0d9488;
  --mat-radio-disabled-label-color: #6b7280;
}
.vex-theme-teal .mat-mdc-radio-button.mat-accent,
.vex-theme-teal.light .mat-mdc-radio-button.mat-accent,
.vex-theme-teal .light .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f59e0b;
  --mdc-radio-selected-hover-icon-color: #f59e0b;
  --mdc-radio-selected-icon-color: #f59e0b;
  --mdc-radio-selected-pressed-icon-color: #f59e0b;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #f59e0b;
  --mat-radio-disabled-label-color: #6b7280;
}
.vex-theme-teal .mat-mdc-radio-button.mat-warn,
.vex-theme-teal.light .mat-mdc-radio-button.mat-warn,
.vex-theme-teal .light .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #ef4444;
  --mdc-radio-selected-hover-icon-color: #ef4444;
  --mdc-radio-selected-icon-color: #ef4444;
  --mdc-radio-selected-pressed-icon-color: #ef4444;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #ef4444;
  --mat-radio-disabled-label-color: #6b7280;
}
.vex-theme-teal .mat-mdc-slider,
.vex-theme-teal.light .mat-mdc-slider,
.vex-theme-teal .light .mat-mdc-slider {
  --mdc-slider-label-container-color: black;
  --mdc-slider-label-label-text-color: white;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-mdc-slider-value-indicator-opacity: 0.6;
}
.vex-theme-teal .mat-mdc-slider.mat-primary,
.vex-theme-teal.light .mat-mdc-slider.mat-primary,
.vex-theme-teal .light .mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #0d9488;
  --mdc-slider-focus-handle-color: #0d9488;
  --mdc-slider-hover-handle-color: #0d9488;
  --mdc-slider-active-track-color: #0d9488;
  --mdc-slider-inactive-track-color: #0d9488;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #0d9488;
  --mat-mdc-slider-ripple-color: #0d9488;
  --mat-mdc-slider-hover-ripple-color: rgba(13, 148, 136, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(13, 148, 136, 0.2);
}
.vex-theme-teal .mat-mdc-slider.mat-accent,
.vex-theme-teal.light .mat-mdc-slider.mat-accent,
.vex-theme-teal .light .mat-mdc-slider.mat-accent {
  --mdc-slider-handle-color: #f59e0b;
  --mdc-slider-focus-handle-color: #f59e0b;
  --mdc-slider-hover-handle-color: #f59e0b;
  --mdc-slider-active-track-color: #f59e0b;
  --mdc-slider-inactive-track-color: #f59e0b;
  --mdc-slider-with-tick-marks-active-container-color: #000;
  --mdc-slider-with-tick-marks-inactive-container-color: #f59e0b;
  --mat-mdc-slider-ripple-color: #f59e0b;
  --mat-mdc-slider-hover-ripple-color: rgba(245, 158, 11, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(245, 158, 11, 0.2);
}
.vex-theme-teal .mat-mdc-slider.mat-warn,
.vex-theme-teal.light .mat-mdc-slider.mat-warn,
.vex-theme-teal .light .mat-mdc-slider.mat-warn {
  --mdc-slider-handle-color: #ef4444;
  --mdc-slider-focus-handle-color: #ef4444;
  --mdc-slider-hover-handle-color: #ef4444;
  --mdc-slider-active-track-color: #ef4444;
  --mdc-slider-inactive-track-color: #ef4444;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #ef4444;
  --mat-mdc-slider-ripple-color: #ef4444;
  --mat-mdc-slider-hover-ripple-color: rgba(239, 68, 68, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(239, 68, 68, 0.2);
}
.vex-theme-teal, .vex-theme-teal.light, .vex-theme-teal .light {
  --mat-menu-item-label-text-color: #030712;
  --mat-menu-item-icon-color: #030712;
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
}

.vex-theme-teal .mat-mdc-list-base,
.vex-theme-teal.light .mat-mdc-list-base,
.vex-theme-teal .light .mat-mdc-list-base {
  --mdc-list-list-item-label-text-color: #030712;
  --mdc-list-list-item-supporting-text-color: #4b5563;
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: #6b7280;
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: #030712;
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: #030712;
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
}
.vex-theme-teal .mdc-list-item__start,
.vex-theme-teal .mdc-list-item__end,
.vex-theme-teal.light .mdc-list-item__start,
.vex-theme-teal.light .mdc-list-item__end,
.vex-theme-teal .light .mdc-list-item__start,
.vex-theme-teal .light .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #0d9488;
  --mdc-radio-selected-hover-icon-color: #0d9488;
  --mdc-radio-selected-icon-color: #0d9488;
  --mdc-radio-selected-pressed-icon-color: #0d9488;
}
.vex-theme-teal .mat-accent .mdc-list-item__start,
.vex-theme-teal .mat-accent .mdc-list-item__end,
.vex-theme-teal.light .mat-accent .mdc-list-item__start,
.vex-theme-teal.light .mat-accent .mdc-list-item__end,
.vex-theme-teal .light .mat-accent .mdc-list-item__start,
.vex-theme-teal .light .mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f59e0b;
  --mdc-radio-selected-hover-icon-color: #f59e0b;
  --mdc-radio-selected-icon-color: #f59e0b;
  --mdc-radio-selected-pressed-icon-color: #f59e0b;
}
.vex-theme-teal .mat-warn .mdc-list-item__start,
.vex-theme-teal .mat-warn .mdc-list-item__end,
.vex-theme-teal.light .mat-warn .mdc-list-item__start,
.vex-theme-teal.light .mat-warn .mdc-list-item__end,
.vex-theme-teal .light .mat-warn .mdc-list-item__start,
.vex-theme-teal .light .mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #ef4444;
  --mdc-radio-selected-hover-icon-color: #ef4444;
  --mdc-radio-selected-icon-color: #ef4444;
  --mdc-radio-selected-pressed-icon-color: #ef4444;
}
.vex-theme-teal .mat-mdc-list-option,
.vex-theme-teal.light .mat-mdc-list-option,
.vex-theme-teal .light .mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #0d9488;
  --mdc-checkbox-selected-hover-icon-color: #0d9488;
  --mdc-checkbox-selected-icon-color: #0d9488;
  --mdc-checkbox-selected-pressed-icon-color: #0d9488;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #0d9488;
  --mdc-checkbox-selected-hover-state-layer-color: #0d9488;
  --mdc-checkbox-selected-pressed-state-layer-color: #0d9488;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.vex-theme-teal .mat-mdc-list-option.mat-accent,
.vex-theme-teal.light .mat-mdc-list-option.mat-accent,
.vex-theme-teal .light .mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #f59e0b;
  --mdc-checkbox-selected-hover-icon-color: #f59e0b;
  --mdc-checkbox-selected-icon-color: #f59e0b;
  --mdc-checkbox-selected-pressed-icon-color: #f59e0b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f59e0b;
  --mdc-checkbox-selected-hover-state-layer-color: #f59e0b;
  --mdc-checkbox-selected-pressed-state-layer-color: #f59e0b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.vex-theme-teal .mat-mdc-list-option.mat-warn,
.vex-theme-teal.light .mat-mdc-list-option.mat-warn,
.vex-theme-teal .light .mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #ef4444;
  --mdc-checkbox-selected-hover-icon-color: #ef4444;
  --mdc-checkbox-selected-icon-color: #ef4444;
  --mdc-checkbox-selected-pressed-icon-color: #ef4444;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #ef4444;
  --mdc-checkbox-selected-hover-state-layer-color: #ef4444;
  --mdc-checkbox-selected-pressed-state-layer-color: #ef4444;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.vex-theme-teal .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.vex-theme-teal .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,
.vex-theme-teal.light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.vex-theme-teal.light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,
.vex-theme-teal .light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.vex-theme-teal .light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #0d9488;
}
.vex-theme-teal .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.vex-theme-teal .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.vex-theme-teal.light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.vex-theme-teal.light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.vex-theme-teal .light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.vex-theme-teal .light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #0d9488;
}
.vex-theme-teal .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.vex-theme-teal .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.vex-theme-teal .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end,
.vex-theme-teal.light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.vex-theme-teal.light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.vex-theme-teal.light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end,
.vex-theme-teal .light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.vex-theme-teal .light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.vex-theme-teal .light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.vex-theme-teal, .vex-theme-teal.light, .vex-theme-teal .light {
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
}

.vex-theme-teal .mat-mdc-tab-group, .vex-theme-teal .mat-mdc-tab-nav-bar,
.vex-theme-teal.light .mat-mdc-tab-group,
.vex-theme-teal.light .mat-mdc-tab-nav-bar,
.vex-theme-teal .light .mat-mdc-tab-group,
.vex-theme-teal .light .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #0d9488;
  --mat-tab-header-disabled-ripple-color: #6b7280;
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #0d9488;
  --mat-tab-header-active-ripple-color: #0d9488;
  --mat-tab-header-inactive-ripple-color: #0d9488;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #0d9488;
  --mat-tab-header-active-hover-label-text-color: #0d9488;
  --mat-tab-header-active-focus-indicator-color: #0d9488;
  --mat-tab-header-active-hover-indicator-color: #0d9488;
}
.vex-theme-teal .mat-mdc-tab-group.mat-accent, .vex-theme-teal .mat-mdc-tab-nav-bar.mat-accent,
.vex-theme-teal.light .mat-mdc-tab-group.mat-accent,
.vex-theme-teal.light .mat-mdc-tab-nav-bar.mat-accent,
.vex-theme-teal .light .mat-mdc-tab-group.mat-accent,
.vex-theme-teal .light .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #f59e0b;
  --mat-tab-header-disabled-ripple-color: #6b7280;
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #f59e0b;
  --mat-tab-header-active-ripple-color: #f59e0b;
  --mat-tab-header-inactive-ripple-color: #f59e0b;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #f59e0b;
  --mat-tab-header-active-hover-label-text-color: #f59e0b;
  --mat-tab-header-active-focus-indicator-color: #f59e0b;
  --mat-tab-header-active-hover-indicator-color: #f59e0b;
}
.vex-theme-teal .mat-mdc-tab-group.mat-warn, .vex-theme-teal .mat-mdc-tab-nav-bar.mat-warn,
.vex-theme-teal.light .mat-mdc-tab-group.mat-warn,
.vex-theme-teal.light .mat-mdc-tab-nav-bar.mat-warn,
.vex-theme-teal .light .mat-mdc-tab-group.mat-warn,
.vex-theme-teal .light .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #ef4444;
  --mat-tab-header-disabled-ripple-color: #6b7280;
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #ef4444;
  --mat-tab-header-active-ripple-color: #ef4444;
  --mat-tab-header-inactive-ripple-color: #ef4444;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #ef4444;
  --mat-tab-header-active-hover-label-text-color: #ef4444;
  --mat-tab-header-active-focus-indicator-color: #ef4444;
  --mat-tab-header-active-hover-indicator-color: #ef4444;
}
.vex-theme-teal .mat-mdc-tab-group.mat-background-primary, .vex-theme-teal .mat-mdc-tab-nav-bar.mat-background-primary,
.vex-theme-teal.light .mat-mdc-tab-group.mat-background-primary,
.vex-theme-teal.light .mat-mdc-tab-nav-bar.mat-background-primary,
.vex-theme-teal .light .mat-mdc-tab-group.mat-background-primary,
.vex-theme-teal .light .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #0d9488;
  --mat-tab-header-with-background-foreground-color: white;
}
.vex-theme-teal .mat-mdc-tab-group.mat-background-accent, .vex-theme-teal .mat-mdc-tab-nav-bar.mat-background-accent,
.vex-theme-teal.light .mat-mdc-tab-group.mat-background-accent,
.vex-theme-teal.light .mat-mdc-tab-nav-bar.mat-background-accent,
.vex-theme-teal .light .mat-mdc-tab-group.mat-background-accent,
.vex-theme-teal .light .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #f59e0b;
  --mat-tab-header-with-background-foreground-color: white;
}
.vex-theme-teal .mat-mdc-tab-group.mat-background-warn, .vex-theme-teal .mat-mdc-tab-nav-bar.mat-background-warn,
.vex-theme-teal.light .mat-mdc-tab-group.mat-background-warn,
.vex-theme-teal.light .mat-mdc-tab-nav-bar.mat-background-warn,
.vex-theme-teal .light .mat-mdc-tab-group.mat-background-warn,
.vex-theme-teal .light .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #ef4444;
  --mat-tab-header-with-background-foreground-color: white;
}
.vex-theme-teal, .vex-theme-teal.light, .vex-theme-teal .light {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #f59e0b;
  --mdc-checkbox-selected-hover-icon-color: #f59e0b;
  --mdc-checkbox-selected-icon-color: #f59e0b;
  --mdc-checkbox-selected-pressed-icon-color: #f59e0b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f59e0b;
  --mdc-checkbox-selected-hover-state-layer-color: #f59e0b;
  --mdc-checkbox-selected-pressed-state-layer-color: #f59e0b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.vex-theme-teal .mat-mdc-checkbox.mat-primary,
.vex-theme-teal.light .mat-mdc-checkbox.mat-primary,
.vex-theme-teal .light .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #0d9488;
  --mdc-checkbox-selected-hover-icon-color: #0d9488;
  --mdc-checkbox-selected-icon-color: #0d9488;
  --mdc-checkbox-selected-pressed-icon-color: #0d9488;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #0d9488;
  --mdc-checkbox-selected-hover-state-layer-color: #0d9488;
  --mdc-checkbox-selected-pressed-state-layer-color: #0d9488;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.vex-theme-teal .mat-mdc-checkbox.mat-warn,
.vex-theme-teal.light .mat-mdc-checkbox.mat-warn,
.vex-theme-teal .light .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #ef4444;
  --mdc-checkbox-selected-hover-icon-color: #ef4444;
  --mdc-checkbox-selected-icon-color: #ef4444;
  --mdc-checkbox-selected-pressed-icon-color: #ef4444;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #ef4444;
  --mdc-checkbox-selected-hover-state-layer-color: #ef4444;
  --mdc-checkbox-selected-pressed-state-layer-color: #ef4444;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.vex-theme-teal .mat-mdc-checkbox .mdc-form-field,
.vex-theme-teal.light .mat-mdc-checkbox .mdc-form-field,
.vex-theme-teal .light .mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.vex-theme-teal .mat-mdc-checkbox.mat-mdc-checkbox-disabled label,
.vex-theme-teal.light .mat-mdc-checkbox.mat-mdc-checkbox-disabled label,
.vex-theme-teal .light .mat-mdc-checkbox.mat-mdc-checkbox-disabled label {
  color: #6b7280;
}
.vex-theme-teal .mat-mdc-button.mat-unthemed,
.vex-theme-teal.light .mat-mdc-button.mat-unthemed,
.vex-theme-teal .light .mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #000;
}
.vex-theme-teal .mat-mdc-button.mat-primary,
.vex-theme-teal.light .mat-mdc-button.mat-primary,
.vex-theme-teal .light .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #0d9488;
}
.vex-theme-teal .mat-mdc-button.mat-accent,
.vex-theme-teal.light .mat-mdc-button.mat-accent,
.vex-theme-teal .light .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #f59e0b;
}
.vex-theme-teal .mat-mdc-button.mat-warn,
.vex-theme-teal.light .mat-mdc-button.mat-warn,
.vex-theme-teal .light .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #ef4444;
}
.vex-theme-teal .mat-mdc-button[disabled][disabled],
.vex-theme-teal.light .mat-mdc-button[disabled][disabled],
.vex-theme-teal .light .mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-text-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.vex-theme-teal .mat-mdc-unelevated-button.mat-unthemed,
.vex-theme-teal.light .mat-mdc-unelevated-button.mat-unthemed,
.vex-theme-teal .light .mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #fff;
  --mdc-filled-button-label-text-color: #000;
}
.vex-theme-teal .mat-mdc-unelevated-button.mat-primary,
.vex-theme-teal.light .mat-mdc-unelevated-button.mat-primary,
.vex-theme-teal .light .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #0d9488;
  --mdc-filled-button-label-text-color: #fff;
}
.vex-theme-teal .mat-mdc-unelevated-button.mat-accent,
.vex-theme-teal.light .mat-mdc-unelevated-button.mat-accent,
.vex-theme-teal .light .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #f59e0b;
  --mdc-filled-button-label-text-color: #000;
}
.vex-theme-teal .mat-mdc-unelevated-button.mat-warn,
.vex-theme-teal.light .mat-mdc-unelevated-button.mat-warn,
.vex-theme-teal .light .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #ef4444;
  --mdc-filled-button-label-text-color: #fff;
}
.vex-theme-teal .mat-mdc-unelevated-button[disabled][disabled],
.vex-theme-teal.light .mat-mdc-unelevated-button[disabled][disabled],
.vex-theme-teal .light .mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.vex-theme-teal .mat-mdc-raised-button.mat-unthemed,
.vex-theme-teal.light .mat-mdc-raised-button.mat-unthemed,
.vex-theme-teal .light .mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #fff;
  --mdc-protected-button-label-text-color: #000;
}
.vex-theme-teal .mat-mdc-raised-button.mat-primary,
.vex-theme-teal.light .mat-mdc-raised-button.mat-primary,
.vex-theme-teal .light .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #0d9488;
  --mdc-protected-button-label-text-color: #fff;
}
.vex-theme-teal .mat-mdc-raised-button.mat-accent,
.vex-theme-teal.light .mat-mdc-raised-button.mat-accent,
.vex-theme-teal .light .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #f59e0b;
  --mdc-protected-button-label-text-color: #000;
}
.vex-theme-teal .mat-mdc-raised-button.mat-warn,
.vex-theme-teal.light .mat-mdc-raised-button.mat-warn,
.vex-theme-teal .light .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #ef4444;
  --mdc-protected-button-label-text-color: #fff;
}
.vex-theme-teal .mat-mdc-raised-button[disabled][disabled],
.vex-theme-teal.light .mat-mdc-raised-button[disabled][disabled],
.vex-theme-teal .light .mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation: 0;
}
.vex-theme-teal .mat-mdc-outlined-button,
.vex-theme-teal.light .mat-mdc-outlined-button,
.vex-theme-teal .light .mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.vex-theme-teal .mat-mdc-outlined-button.mat-unthemed,
.vex-theme-teal.light .mat-mdc-outlined-button.mat-unthemed,
.vex-theme-teal .light .mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #000;
}
.vex-theme-teal .mat-mdc-outlined-button.mat-primary,
.vex-theme-teal.light .mat-mdc-outlined-button.mat-primary,
.vex-theme-teal .light .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #0d9488;
}
.vex-theme-teal .mat-mdc-outlined-button.mat-accent,
.vex-theme-teal.light .mat-mdc-outlined-button.mat-accent,
.vex-theme-teal .light .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #f59e0b;
}
.vex-theme-teal .mat-mdc-outlined-button.mat-warn,
.vex-theme-teal.light .mat-mdc-outlined-button.mat-warn,
.vex-theme-teal .light .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #ef4444;
}
.vex-theme-teal .mat-mdc-outlined-button[disabled][disabled],
.vex-theme-teal.light .mat-mdc-outlined-button[disabled][disabled],
.vex-theme-teal .light .mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
}
.vex-theme-teal .mat-mdc-button, .vex-theme-teal .mat-mdc-outlined-button,
.vex-theme-teal.light .mat-mdc-button,
.vex-theme-teal.light .mat-mdc-outlined-button,
.vex-theme-teal .light .mat-mdc-button,
.vex-theme-teal .light .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.vex-theme-teal .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .vex-theme-teal .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal.light .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal.light .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal .light .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal .light .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.vex-theme-teal .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-teal .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-teal .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-teal .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal.light .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal.light .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal.light .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal.light .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal .light .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal .light .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal .light .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal .light .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.vex-theme-teal .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .vex-theme-teal .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal.light .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal.light .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal .light .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal .light .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.vex-theme-teal .mat-mdc-button.mat-primary, .vex-theme-teal .mat-mdc-outlined-button.mat-primary,
.vex-theme-teal.light .mat-mdc-button.mat-primary,
.vex-theme-teal.light .mat-mdc-outlined-button.mat-primary,
.vex-theme-teal .light .mat-mdc-button.mat-primary,
.vex-theme-teal .light .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #0d9488;
  --mat-mdc-button-ripple-color: rgba(13, 148, 136, 0.1);
}
.vex-theme-teal .mat-mdc-button.mat-accent, .vex-theme-teal .mat-mdc-outlined-button.mat-accent,
.vex-theme-teal.light .mat-mdc-button.mat-accent,
.vex-theme-teal.light .mat-mdc-outlined-button.mat-accent,
.vex-theme-teal .light .mat-mdc-button.mat-accent,
.vex-theme-teal .light .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #f59e0b;
  --mat-mdc-button-ripple-color: rgba(245, 158, 11, 0.1);
}
.vex-theme-teal .mat-mdc-button.mat-warn, .vex-theme-teal .mat-mdc-outlined-button.mat-warn,
.vex-theme-teal.light .mat-mdc-button.mat-warn,
.vex-theme-teal.light .mat-mdc-outlined-button.mat-warn,
.vex-theme-teal .light .mat-mdc-button.mat-warn,
.vex-theme-teal .light .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #ef4444;
  --mat-mdc-button-ripple-color: rgba(239, 68, 68, 0.1);
}
.vex-theme-teal .mat-mdc-raised-button, .vex-theme-teal .mat-mdc-unelevated-button,
.vex-theme-teal.light .mat-mdc-raised-button,
.vex-theme-teal.light .mat-mdc-unelevated-button,
.vex-theme-teal .light .mat-mdc-raised-button,
.vex-theme-teal .light .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.vex-theme-teal .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .vex-theme-teal .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal.light .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal.light .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal .light .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal .light .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.vex-theme-teal .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-teal .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-teal .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-teal .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal.light .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal.light .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal.light .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal.light .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal .light .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal .light .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal .light .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal .light .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.vex-theme-teal .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .vex-theme-teal .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal.light .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal.light .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal .light .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal .light .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.vex-theme-teal .mat-mdc-raised-button.mat-primary, .vex-theme-teal .mat-mdc-unelevated-button.mat-primary,
.vex-theme-teal.light .mat-mdc-raised-button.mat-primary,
.vex-theme-teal.light .mat-mdc-unelevated-button.mat-primary,
.vex-theme-teal .light .mat-mdc-raised-button.mat-primary,
.vex-theme-teal .light .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.vex-theme-teal .mat-mdc-raised-button.mat-accent, .vex-theme-teal .mat-mdc-unelevated-button.mat-accent,
.vex-theme-teal.light .mat-mdc-raised-button.mat-accent,
.vex-theme-teal.light .mat-mdc-unelevated-button.mat-accent,
.vex-theme-teal .light .mat-mdc-raised-button.mat-accent,
.vex-theme-teal .light .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.vex-theme-teal .mat-mdc-raised-button.mat-warn, .vex-theme-teal .mat-mdc-unelevated-button.mat-warn,
.vex-theme-teal.light .mat-mdc-raised-button.mat-warn,
.vex-theme-teal.light .mat-mdc-unelevated-button.mat-warn,
.vex-theme-teal .light .mat-mdc-raised-button.mat-warn,
.vex-theme-teal .light .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.vex-theme-teal .mat-mdc-icon-button,
.vex-theme-teal.light .mat-mdc-icon-button,
.vex-theme-teal .light .mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mdc-icon-button-icon-color: inherit;
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.vex-theme-teal .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal.light .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal .light .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.vex-theme-teal .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-teal .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal.light .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal.light .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal .light .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal .light .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.vex-theme-teal .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal.light .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal .light .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.vex-theme-teal .mat-mdc-icon-button.mat-primary,
.vex-theme-teal.light .mat-mdc-icon-button.mat-primary,
.vex-theme-teal .light .mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #6200ee;
  --mat-mdc-button-ripple-color: rgba(98, 0, 238, 0.1);
}
.vex-theme-teal .mat-mdc-icon-button.mat-accent,
.vex-theme-teal.light .mat-mdc-icon-button.mat-accent,
.vex-theme-teal .light .mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #018786;
  --mat-mdc-button-ripple-color: rgba(1, 135, 134, 0.1);
}
.vex-theme-teal .mat-mdc-icon-button.mat-warn,
.vex-theme-teal.light .mat-mdc-icon-button.mat-warn,
.vex-theme-teal .light .mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #b00020;
  --mat-mdc-button-ripple-color: rgba(176, 0, 32, 0.1);
}
.vex-theme-teal .mat-mdc-icon-button.mat-primary,
.vex-theme-teal.light .mat-mdc-icon-button.mat-primary,
.vex-theme-teal .light .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #0d9488;
  --mat-mdc-button-persistent-ripple-color: #0d9488;
  --mat-mdc-button-ripple-color: rgba(13, 148, 136, 0.1);
}
.vex-theme-teal .mat-mdc-icon-button.mat-accent,
.vex-theme-teal.light .mat-mdc-icon-button.mat-accent,
.vex-theme-teal .light .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #f59e0b;
  --mat-mdc-button-persistent-ripple-color: #f59e0b;
  --mat-mdc-button-ripple-color: rgba(245, 158, 11, 0.1);
}
.vex-theme-teal .mat-mdc-icon-button.mat-warn,
.vex-theme-teal.light .mat-mdc-icon-button.mat-warn,
.vex-theme-teal .light .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #ef4444;
  --mat-mdc-button-persistent-ripple-color: #ef4444;
  --mat-mdc-button-ripple-color: rgba(239, 68, 68, 0.1);
}
.vex-theme-teal .mat-mdc-icon-button[disabled][disabled],
.vex-theme-teal.light .mat-mdc-icon-button[disabled][disabled],
.vex-theme-teal .light .mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
}
.vex-theme-teal .mat-mdc-fab,
.vex-theme-teal .mat-mdc-mini-fab,
.vex-theme-teal.light .mat-mdc-fab,
.vex-theme-teal.light .mat-mdc-mini-fab,
.vex-theme-teal .light .mat-mdc-fab,
.vex-theme-teal .light .mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.vex-theme-teal .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal.light .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal.light .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal .light .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal .light .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.vex-theme-teal .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-teal .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal.light .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal.light .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal.light .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal.light .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal .light .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal .light .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal .light .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal .light .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.vex-theme-teal .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal.light .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal.light .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal .light .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal .light .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.vex-theme-teal .mat-mdc-fab.mat-primary,
.vex-theme-teal .mat-mdc-mini-fab.mat-primary,
.vex-theme-teal.light .mat-mdc-fab.mat-primary,
.vex-theme-teal.light .mat-mdc-mini-fab.mat-primary,
.vex-theme-teal .light .mat-mdc-fab.mat-primary,
.vex-theme-teal .light .mat-mdc-mini-fab.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.vex-theme-teal .mat-mdc-fab.mat-accent,
.vex-theme-teal .mat-mdc-mini-fab.mat-accent,
.vex-theme-teal.light .mat-mdc-fab.mat-accent,
.vex-theme-teal.light .mat-mdc-mini-fab.mat-accent,
.vex-theme-teal .light .mat-mdc-fab.mat-accent,
.vex-theme-teal .light .mat-mdc-mini-fab.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.vex-theme-teal .mat-mdc-fab.mat-warn,
.vex-theme-teal .mat-mdc-mini-fab.mat-warn,
.vex-theme-teal.light .mat-mdc-fab.mat-warn,
.vex-theme-teal.light .mat-mdc-mini-fab.mat-warn,
.vex-theme-teal .light .mat-mdc-fab.mat-warn,
.vex-theme-teal .light .mat-mdc-mini-fab.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.vex-theme-teal .mat-mdc-fab[disabled][disabled],
.vex-theme-teal .mat-mdc-mini-fab[disabled][disabled],
.vex-theme-teal.light .mat-mdc-fab[disabled][disabled],
.vex-theme-teal.light .mat-mdc-mini-fab[disabled][disabled],
.vex-theme-teal .light .mat-mdc-fab[disabled][disabled],
.vex-theme-teal .light .mat-mdc-mini-fab[disabled][disabled] {
  --mdc-fab-container-color: rgba(0, 0, 0, 0.12);
  --mdc-fab-icon-color: rgba(0, 0, 0, 0.38);
  --mat-mdc-fab-color: rgba(0, 0, 0, 0.38);
}
.vex-theme-teal .mat-mdc-fab.mat-unthemed,
.vex-theme-teal .mat-mdc-mini-fab.mat-unthemed,
.vex-theme-teal.light .mat-mdc-fab.mat-unthemed,
.vex-theme-teal.light .mat-mdc-mini-fab.mat-unthemed,
.vex-theme-teal .light .mat-mdc-fab.mat-unthemed,
.vex-theme-teal .light .mat-mdc-mini-fab.mat-unthemed {
  --mdc-fab-container-color: white;
  --mdc-fab-icon-color: black;
  --mat-mdc-fab-color: #000;
}
.vex-theme-teal .mat-mdc-fab.mat-primary,
.vex-theme-teal .mat-mdc-mini-fab.mat-primary,
.vex-theme-teal.light .mat-mdc-fab.mat-primary,
.vex-theme-teal.light .mat-mdc-mini-fab.mat-primary,
.vex-theme-teal .light .mat-mdc-fab.mat-primary,
.vex-theme-teal .light .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #0d9488;
  --mdc-fab-icon-color: white;
  --mat-mdc-fab-color: #fff;
}
.vex-theme-teal .mat-mdc-fab.mat-accent,
.vex-theme-teal .mat-mdc-mini-fab.mat-accent,
.vex-theme-teal.light .mat-mdc-fab.mat-accent,
.vex-theme-teal.light .mat-mdc-mini-fab.mat-accent,
.vex-theme-teal .light .mat-mdc-fab.mat-accent,
.vex-theme-teal .light .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: #f59e0b;
  --mdc-fab-icon-color: black;
  --mat-mdc-fab-color: #000;
}
.vex-theme-teal .mat-mdc-fab.mat-warn,
.vex-theme-teal .mat-mdc-mini-fab.mat-warn,
.vex-theme-teal.light .mat-mdc-fab.mat-warn,
.vex-theme-teal.light .mat-mdc-mini-fab.mat-warn,
.vex-theme-teal .light .mat-mdc-fab.mat-warn,
.vex-theme-teal .light .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: #ef4444;
  --mdc-fab-icon-color: white;
  --mat-mdc-fab-color: #fff;
}
.vex-theme-teal .mat-mdc-snack-bar-container,
.vex-theme-teal.light .mat-mdc-snack-bar-container,
.vex-theme-teal .light .mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #f59e0b;
}
.vex-theme-teal .mat-mdc-progress-spinner,
.vex-theme-teal.light .mat-mdc-progress-spinner,
.vex-theme-teal .light .mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #0d9488;
}
.vex-theme-teal .mat-mdc-progress-spinner.mat-accent,
.vex-theme-teal.light .mat-mdc-progress-spinner.mat-accent,
.vex-theme-teal .light .mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: #f59e0b;
}
.vex-theme-teal .mat-mdc-progress-spinner.mat-warn,
.vex-theme-teal.light .mat-mdc-progress-spinner.mat-warn,
.vex-theme-teal .light .mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: #ef4444;
}
.vex-theme-teal, .vex-theme-teal.light, .vex-theme-teal .light {
  --mat-badge-background-color: #0d9488;
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: #9ca3af;
  --mat-badge-disabled-state-text-color: #6b7280;
}

.vex-theme-teal .mat-badge-accent,
.vex-theme-teal.light .mat-badge-accent,
.vex-theme-teal .light .mat-badge-accent {
  --mat-badge-background-color: #f59e0b;
  --mat-badge-text-color: white;
}
.vex-theme-teal .mat-badge-warn,
.vex-theme-teal.light .mat-badge-warn,
.vex-theme-teal .light .mat-badge-warn {
  --mat-badge-background-color: #ef4444;
  --mat-badge-text-color: white;
}
.vex-theme-teal, .vex-theme-teal.light, .vex-theme-teal .light {
  --mat-bottom-sheet-container-text-color: #030712;
  --mat-bottom-sheet-container-background-color: white;
}

.vex-theme-teal, .vex-theme-teal.light, .vex-theme-teal .light {
  --mat-legacy-button-toggle-text-color: #6b7280;
  --mat-legacy-button-toggle-state-layer-color: #e5e7eb;
  --mat-legacy-button-toggle-selected-state-text-color: #4b5563;
  --mat-legacy-button-toggle-selected-state-background-color: #d1d5db;
  --mat-legacy-button-toggle-disabled-state-text-color: #9ca3af;
  --mat-legacy-button-toggle-disabled-state-background-color: #e5e7eb;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #9ca3af;
  --mat-standard-button-toggle-text-color: #030712;
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: #e5e7eb;
  --mat-standard-button-toggle-selected-state-background-color: #d1d5db;
  --mat-standard-button-toggle-selected-state-text-color: #030712;
  --mat-standard-button-toggle-disabled-state-text-color: #9ca3af;
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: #030712;
  --mat-standard-button-toggle-disabled-selected-state-background-color: #9ca3af;
  --mat-standard-button-toggle-divider-color: #f0f0f0;
}

.vex-theme-teal, .vex-theme-teal.light, .vex-theme-teal .light {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #0d9488;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(13, 148, 136, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(13, 148, 136, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(13, 148, 136, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #0d9488;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(13, 148, 136, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: #1f2937;
  --mat-datepicker-calendar-body-label-text-color: #4b5563;
  --mat-datepicker-calendar-period-button-icon-color: #1f2937;
  --mat-datepicker-calendar-navigation-button-icon-color: #1f2937;
  --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.06);
  --mat-datepicker-calendar-header-text-color: #4b5563;
  --mat-datepicker-calendar-date-today-outline-color: #6b7280;
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(107, 114, 128, 0.8);
  --mat-datepicker-calendar-date-text-color: #030712;
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: #6b7280;
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, 0.12);
  --mat-datepicker-range-input-separator-color: #030712;
  --mat-datepicker-range-input-disabled-state-separator-color: #6b7280;
  --mat-datepicker-range-input-disabled-state-text-color: #6b7280;
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: #030712;
}

.vex-theme-teal .mat-datepicker-content.mat-accent,
.vex-theme-teal.light .mat-datepicker-content.mat-accent,
.vex-theme-teal .light .mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #f59e0b;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(245, 158, 11, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(245, 158, 11, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(245, 158, 11, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(245, 158, 11, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.vex-theme-teal .mat-datepicker-content.mat-warn,
.vex-theme-teal.light .mat-datepicker-content.mat-warn,
.vex-theme-teal .light .mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #ef4444;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(239, 68, 68, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(239, 68, 68, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(239, 68, 68, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(239, 68, 68, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.vex-theme-teal .mat-datepicker-toggle-active.mat-accent,
.vex-theme-teal.light .mat-datepicker-toggle-active.mat-accent,
.vex-theme-teal .light .mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #f59e0b;
}
.vex-theme-teal .mat-datepicker-toggle-active.mat-warn,
.vex-theme-teal.light .mat-datepicker-toggle-active.mat-warn,
.vex-theme-teal .light .mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #ef4444;
}
.vex-theme-teal, .vex-theme-teal.light, .vex-theme-teal .light {
  --mat-divider-color: rgba(0, 0, 0, 0.06);
}

.vex-theme-teal, .vex-theme-teal.light, .vex-theme-teal .light {
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: #030712;
  --mat-expansion-actions-divider-color: rgba(0, 0, 0, 0.06);
  --mat-expansion-header-hover-state-layer-color: rgba(17, 24, 39, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(17, 24, 39, 0.04);
  --mat-expansion-header-disabled-state-text-color: #9ca3af;
  --mat-expansion-header-text-color: #030712;
  --mat-expansion-header-description-color: #4b5563;
  --mat-expansion-header-indicator-color: #4b5563;
}

.vex-theme-teal, .vex-theme-teal.light, .vex-theme-teal .light {
  --mat-icon-color: inherit;
}

.vex-theme-teal .mat-icon.mat-primary,
.vex-theme-teal.light .mat-icon.mat-primary,
.vex-theme-teal .light .mat-icon.mat-primary {
  --mat-icon-color: #0d9488;
}
.vex-theme-teal .mat-icon.mat-accent,
.vex-theme-teal.light .mat-icon.mat-accent,
.vex-theme-teal .light .mat-icon.mat-accent {
  --mat-icon-color: #f59e0b;
}
.vex-theme-teal .mat-icon.mat-warn,
.vex-theme-teal.light .mat-icon.mat-warn,
.vex-theme-teal .light .mat-icon.mat-warn {
  --mat-icon-color: #ef4444;
}
.vex-theme-teal, .vex-theme-teal.light, .vex-theme-teal .light {
  --mat-sidenav-container-divider-color: rgba(0, 0, 0, 0.06);
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: #030712;
  --mat-sidenav-content-background-color: #f3f4f6;
  --mat-sidenav-content-text-color: #030712;
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
}

.vex-theme-teal, .vex-theme-teal.light, .vex-theme-teal .light {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #0d9488;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #0d9488;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #0d9488;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: rgba(0, 0, 0, 0.06);
  --mat-stepper-header-hover-state-layer-color: rgba(17, 24, 39, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(17, 24, 39, 0.04);
  --mat-stepper-header-label-text-color: #4b5563;
  --mat-stepper-header-optional-label-text-color: #4b5563;
  --mat-stepper-header-selected-state-label-text-color: #030712;
  --mat-stepper-header-error-state-label-text-color: #ef4444;
  --mat-stepper-header-icon-background-color: #4b5563;
  --mat-stepper-header-error-state-icon-foreground-color: #ef4444;
  --mat-stepper-header-error-state-icon-background-color: transparent;
}
.vex-theme-teal .mat-step-header.mat-accent, .vex-theme-teal.light .mat-step-header.mat-accent, .vex-theme-teal .light .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #f59e0b;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #f59e0b;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #f59e0b;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.vex-theme-teal .mat-step-header.mat-warn, .vex-theme-teal.light .mat-step-header.mat-warn, .vex-theme-teal .light .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #ef4444;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #ef4444;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #ef4444;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}

.vex-theme-teal .mat-sort-header-arrow,
.vex-theme-teal.light .mat-sort-header-arrow,
.vex-theme-teal .light .mat-sort-header-arrow {
  color: #4b5563;
}
.vex-theme-teal, .vex-theme-teal.light, .vex-theme-teal .light {
  --mat-toolbar-container-background-color: #e5e7eb;
  --mat-toolbar-container-text-color: #030712;
}

.vex-theme-teal .mat-toolbar.mat-primary,
.vex-theme-teal.light .mat-toolbar.mat-primary,
.vex-theme-teal .light .mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #0d9488;
  --mat-toolbar-container-text-color: white;
}
.vex-theme-teal .mat-toolbar.mat-accent,
.vex-theme-teal.light .mat-toolbar.mat-accent,
.vex-theme-teal .light .mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #f59e0b;
  --mat-toolbar-container-text-color: white;
}
.vex-theme-teal .mat-toolbar.mat-warn,
.vex-theme-teal.light .mat-toolbar.mat-warn,
.vex-theme-teal .light .mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #ef4444;
  --mat-toolbar-container-text-color: white;
}
.vex-theme-teal .mat-tree,
.vex-theme-teal.light .mat-tree,
.vex-theme-teal .light .mat-tree {
  background: white;
}
.vex-theme-teal .mat-tree-node,
.vex-theme-teal .mat-nested-tree-node,
.vex-theme-teal.light .mat-tree-node,
.vex-theme-teal.light .mat-nested-tree-node,
.vex-theme-teal .light .mat-tree-node,
.vex-theme-teal .light .mat-nested-tree-node {
  color: #030712;
}

/**
 * Override the theme foreground/background colors with the Angular Material colors
 */
.vex-theme-teal.dark,
.vex-theme-teal .dark {
  --mat-table-background-color: #1f2937;
  --mat-table-header-headline-color: white;
  --mat-table-row-item-label-text-color: white;
  --mat-table-row-item-outline-color: rgba(255, 255, 255, 0.12);
}
.vex-theme-teal.dark .mat-ripple-element,
.vex-theme-teal .dark .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.vex-theme-teal.dark, .vex-theme-teal .dark {
  --mat-option-selected-state-label-text-color: #14b8a6;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
}

.vex-theme-teal.dark .mat-accent,
.vex-theme-teal .dark .mat-accent {
  --mat-option-selected-state-label-text-color: #f59e0b;
}
.vex-theme-teal.dark .mat-warn,
.vex-theme-teal .dark .mat-warn {
  --mat-option-selected-state-label-text-color: #ef4444;
}
.vex-theme-teal.dark, .vex-theme-teal .dark {
  --mat-optgroup-label-text-color: white;
}

.vex-theme-teal.dark .mat-pseudo-checkbox-full,
.vex-theme-teal .dark .mat-pseudo-checkbox-full {
  color: #9ca3af;
}
.vex-theme-teal.dark .mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled,
.vex-theme-teal .dark .mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled {
  color: #686868;
}
.vex-theme-teal.dark .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-teal.dark .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-teal .dark .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-teal .dark .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #14b8a6;
}
.vex-theme-teal.dark .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-teal.dark .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-teal .dark .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-teal .dark .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #14b8a6;
}
.vex-theme-teal.dark .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-teal.dark .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after,
.vex-theme-teal .dark .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-teal .dark .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #111827;
}
.vex-theme-teal.dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-teal.dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-teal .dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-teal .dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #f59e0b;
}
.vex-theme-teal.dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-teal.dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-teal .dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-teal .dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #f59e0b;
}
.vex-theme-teal.dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-teal.dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after,
.vex-theme-teal .dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-teal .dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #111827;
}
.vex-theme-teal.dark .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-teal.dark .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-teal .dark .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-teal .dark .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #f59e0b;
}
.vex-theme-teal.dark .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-teal.dark .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-teal .dark .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-teal .dark .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #f59e0b;
}
.vex-theme-teal.dark .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-teal.dark .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after,
.vex-theme-teal .dark .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-teal .dark .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #111827;
}
.vex-theme-teal.dark .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-teal.dark .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-teal .dark .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-teal .dark .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #ef4444;
}
.vex-theme-teal.dark .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-teal.dark .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-teal .dark .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-teal .dark .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #ef4444;
}
.vex-theme-teal.dark .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-teal.dark .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after,
.vex-theme-teal .dark .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-teal .dark .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #111827;
}
.vex-theme-teal.dark .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-teal.dark .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-teal .dark .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-teal .dark .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #686868;
}
.vex-theme-teal.dark .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-teal.dark .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-teal .dark .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-teal .dark .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #686868;
}
.vex-theme-teal.dark .mat-app-background, .vex-theme-teal.dark.mat-app-background,
.vex-theme-teal .dark .mat-app-background,
.vex-theme-teal .dark.mat-app-background {
  background-color: #111827;
  color: white;
}
.vex-theme-teal.dark .mat-elevation-z0, .vex-theme-teal.dark .mat-mdc-elevation-specific.mat-elevation-z0,
.vex-theme-teal .dark .mat-elevation-z0,
.vex-theme-teal .dark .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-teal.dark .mat-elevation-z1, .vex-theme-teal.dark .mat-mdc-elevation-specific.mat-elevation-z1,
.vex-theme-teal .dark .mat-elevation-z1,
.vex-theme-teal .dark .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-teal.dark .mat-elevation-z2, .vex-theme-teal.dark .mat-mdc-elevation-specific.mat-elevation-z2,
.vex-theme-teal .dark .mat-elevation-z2,
.vex-theme-teal .dark .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-teal.dark .mat-elevation-z3, .vex-theme-teal.dark .mat-mdc-elevation-specific.mat-elevation-z3,
.vex-theme-teal .dark .mat-elevation-z3,
.vex-theme-teal .dark .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-teal.dark .mat-elevation-z4, .vex-theme-teal.dark .mat-mdc-elevation-specific.mat-elevation-z4,
.vex-theme-teal .dark .mat-elevation-z4,
.vex-theme-teal .dark .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-teal.dark .mat-elevation-z5, .vex-theme-teal.dark .mat-mdc-elevation-specific.mat-elevation-z5,
.vex-theme-teal .dark .mat-elevation-z5,
.vex-theme-teal .dark .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-teal.dark .mat-elevation-z6, .vex-theme-teal.dark .mat-mdc-elevation-specific.mat-elevation-z6,
.vex-theme-teal .dark .mat-elevation-z6,
.vex-theme-teal .dark .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-teal.dark .mat-elevation-z7, .vex-theme-teal.dark .mat-mdc-elevation-specific.mat-elevation-z7,
.vex-theme-teal .dark .mat-elevation-z7,
.vex-theme-teal .dark .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.vex-theme-teal.dark .mat-elevation-z8, .vex-theme-teal.dark .mat-mdc-elevation-specific.mat-elevation-z8,
.vex-theme-teal .dark .mat-elevation-z8,
.vex-theme-teal .dark .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.vex-theme-teal.dark .mat-elevation-z9, .vex-theme-teal.dark .mat-mdc-elevation-specific.mat-elevation-z9,
.vex-theme-teal .dark .mat-elevation-z9,
.vex-theme-teal .dark .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.vex-theme-teal.dark .mat-elevation-z10, .vex-theme-teal.dark .mat-mdc-elevation-specific.mat-elevation-z10,
.vex-theme-teal .dark .mat-elevation-z10,
.vex-theme-teal .dark .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.vex-theme-teal.dark .mat-elevation-z11, .vex-theme-teal.dark .mat-mdc-elevation-specific.mat-elevation-z11,
.vex-theme-teal .dark .mat-elevation-z11,
.vex-theme-teal .dark .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.vex-theme-teal.dark .mat-elevation-z12, .vex-theme-teal.dark .mat-mdc-elevation-specific.mat-elevation-z12,
.vex-theme-teal .dark .mat-elevation-z12,
.vex-theme-teal .dark .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.vex-theme-teal.dark .mat-elevation-z13, .vex-theme-teal.dark .mat-mdc-elevation-specific.mat-elevation-z13,
.vex-theme-teal .dark .mat-elevation-z13,
.vex-theme-teal .dark .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.vex-theme-teal.dark .mat-elevation-z14, .vex-theme-teal.dark .mat-mdc-elevation-specific.mat-elevation-z14,
.vex-theme-teal .dark .mat-elevation-z14,
.vex-theme-teal .dark .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.vex-theme-teal.dark .mat-elevation-z15, .vex-theme-teal.dark .mat-mdc-elevation-specific.mat-elevation-z15,
.vex-theme-teal .dark .mat-elevation-z15,
.vex-theme-teal .dark .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.vex-theme-teal.dark .mat-elevation-z16, .vex-theme-teal.dark .mat-mdc-elevation-specific.mat-elevation-z16,
.vex-theme-teal .dark .mat-elevation-z16,
.vex-theme-teal .dark .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.vex-theme-teal.dark .mat-elevation-z17, .vex-theme-teal.dark .mat-mdc-elevation-specific.mat-elevation-z17,
.vex-theme-teal .dark .mat-elevation-z17,
.vex-theme-teal .dark .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.vex-theme-teal.dark .mat-elevation-z18, .vex-theme-teal.dark .mat-mdc-elevation-specific.mat-elevation-z18,
.vex-theme-teal .dark .mat-elevation-z18,
.vex-theme-teal .dark .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.vex-theme-teal.dark .mat-elevation-z19, .vex-theme-teal.dark .mat-mdc-elevation-specific.mat-elevation-z19,
.vex-theme-teal .dark .mat-elevation-z19,
.vex-theme-teal .dark .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.vex-theme-teal.dark .mat-elevation-z20, .vex-theme-teal.dark .mat-mdc-elevation-specific.mat-elevation-z20,
.vex-theme-teal .dark .mat-elevation-z20,
.vex-theme-teal .dark .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.vex-theme-teal.dark .mat-elevation-z21, .vex-theme-teal.dark .mat-mdc-elevation-specific.mat-elevation-z21,
.vex-theme-teal .dark .mat-elevation-z21,
.vex-theme-teal .dark .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.vex-theme-teal.dark .mat-elevation-z22, .vex-theme-teal.dark .mat-mdc-elevation-specific.mat-elevation-z22,
.vex-theme-teal .dark .mat-elevation-z22,
.vex-theme-teal .dark .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.vex-theme-teal.dark .mat-elevation-z23, .vex-theme-teal.dark .mat-mdc-elevation-specific.mat-elevation-z23,
.vex-theme-teal .dark .mat-elevation-z23,
.vex-theme-teal .dark .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.vex-theme-teal.dark .mat-elevation-z24, .vex-theme-teal.dark .mat-mdc-elevation-specific.mat-elevation-z24,
.vex-theme-teal .dark .mat-elevation-z24,
.vex-theme-teal .dark .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.vex-theme-teal.dark .mat-mdc-card,
.vex-theme-teal .dark .mat-mdc-card {
  --mdc-elevated-card-container-color: #1f2937;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: #1f2937;
  --mdc-outlined-card-outline-color: rgba(255, 255, 255, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: #9ca3af;
}
.vex-theme-teal.dark .mat-mdc-progress-bar,
.vex-theme-teal .dark .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #14b8a6;
  --mdc-linear-progress-track-color: rgba(20, 184, 166, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.vex-theme-teal.dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots,
.vex-theme-teal .dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-color: rgba(20, 184, 166, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(20, 184, 166, 0.25));
}
@media (forced-colors: active) {
  .vex-theme-teal.dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots,
  .vex-theme-teal .dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .vex-theme-teal.dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots,
  .vex-theme-teal .dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(20, 184, 166, 0.25)'/%3E%3C/svg%3E");
  }
}
.vex-theme-teal.dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar,
.vex-theme-teal .dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgba(20, 184, 166, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(20, 184, 166, 0.25));
}
.vex-theme-teal.dark .mat-mdc-progress-bar.mat-accent,
.vex-theme-teal .dark .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #f59e0b;
  --mdc-linear-progress-track-color: rgba(245, 158, 11, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.vex-theme-teal.dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots,
.vex-theme-teal .dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-color: rgba(245, 158, 11, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(245, 158, 11, 0.25));
}
@media (forced-colors: active) {
  .vex-theme-teal.dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots,
  .vex-theme-teal .dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .vex-theme-teal.dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots,
  .vex-theme-teal .dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(245, 158, 11, 0.25)'/%3E%3C/svg%3E");
  }
}
.vex-theme-teal.dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar,
.vex-theme-teal .dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: rgba(245, 158, 11, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(245, 158, 11, 0.25));
}
.vex-theme-teal.dark .mat-mdc-progress-bar.mat-warn,
.vex-theme-teal .dark .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #ef4444;
  --mdc-linear-progress-track-color: rgba(239, 68, 68, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.vex-theme-teal.dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots,
.vex-theme-teal .dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-color: rgba(239, 68, 68, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(239, 68, 68, 0.25));
}
@media (forced-colors: active) {
  .vex-theme-teal.dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots,
  .vex-theme-teal .dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .vex-theme-teal.dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots,
  .vex-theme-teal .dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(239, 68, 68, 0.25)'/%3E%3C/svg%3E");
  }
}
.vex-theme-teal.dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar,
.vex-theme-teal .dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: rgba(239, 68, 68, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(239, 68, 68, 0.25));
}
.vex-theme-teal.dark .mat-mdc-tooltip,
.vex-theme-teal .dark .mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #374151;
  --mdc-plain-tooltip-supporting-text-color: #fff;
}
.vex-theme-teal.dark, .vex-theme-teal .dark {
  --mdc-filled-text-field-caret-color: #14b8a6;
  --mdc-filled-text-field-focus-active-indicator-color: #14b8a6;
  --mdc-filled-text-field-focus-label-text-color: rgba(20, 184, 166, 0.87);
  --mdc-filled-text-field-container-color: #28323f;
  --mdc-filled-text-field-disabled-container-color: #232d3b;
  --mdc-filled-text-field-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(255, 255, 255, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(255, 255, 255, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(255, 255, 255, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-error-focus-label-text-color: #ef4444;
  --mdc-filled-text-field-error-label-text-color: #ef4444;
  --mdc-filled-text-field-error-caret-color: #ef4444;
  --mdc-filled-text-field-active-indicator-color: rgba(255, 255, 255, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(255, 255, 255, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(255, 255, 255, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #ef4444;
  --mdc-filled-text-field-error-focus-active-indicator-color: #ef4444;
  --mdc-filled-text-field-error-hover-active-indicator-color: #ef4444;
  --mdc-outlined-text-field-caret-color: #14b8a6;
  --mdc-outlined-text-field-focus-outline-color: #14b8a6;
  --mdc-outlined-text-field-focus-label-text-color: rgba(20, 184, 166, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(255, 255, 255, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(255, 255, 255, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(255, 255, 255, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-error-caret-color: #ef4444;
  --mdc-outlined-text-field-error-focus-label-text-color: #ef4444;
  --mdc-outlined-text-field-error-label-text-color: #ef4444;
  --mdc-outlined-text-field-outline-color: rgba(255, 255, 255, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(255, 255, 255, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(255, 255, 255, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #ef4444;
  --mdc-outlined-text-field-error-hover-outline-color: #ef4444;
  --mdc-outlined-text-field-error-outline-color: #ef4444;
  --mat-form-field-disabled-input-text-placeholder-color: rgba(255, 255, 255, 0.38);
}

.vex-theme-teal.dark .mat-mdc-form-field-error,
.vex-theme-teal .dark .mat-mdc-form-field-error {
  color: var(--mdc-theme-error, #ef4444);
}
.vex-theme-teal.dark .mat-mdc-form-field-subscript-wrapper,
.vex-theme-teal.dark .mat-mdc-form-field-bottom-align::before,
.vex-theme-teal .dark .mat-mdc-form-field-subscript-wrapper,
.vex-theme-teal .dark .mat-mdc-form-field-bottom-align::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mat-form-field-subscript-text-font);
  line-height: var(--mat-form-field-subscript-text-line-height);
  font-size: var(--mat-form-field-subscript-text-size);
  letter-spacing: var(--mat-form-field-subscript-text-tracking);
  font-weight: var(--mat-form-field-subscript-text-weight);
}
.vex-theme-teal.dark .mat-mdc-form-field-focus-overlay,
.vex-theme-teal .dark .mat-mdc-form-field-focus-overlay {
  background-color: rgba(255, 255, 255, 0.87);
}
.vex-theme-teal.dark .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay,
.vex-theme-teal .dark .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0.08;
}
.vex-theme-teal.dark .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay,
.vex-theme-teal .dark .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.24;
}
.vex-theme-teal.dark select.mat-mdc-form-field-input-control:not(.mat-mdc-native-select-inline) option,
.vex-theme-teal .dark select.mat-mdc-form-field-input-control:not(.mat-mdc-native-select-inline) option {
  color: rgba(0, 0, 0, 0.87);
}
.vex-theme-teal.dark select.mat-mdc-form-field-input-control:not(.mat-mdc-native-select-inline) option:disabled,
.vex-theme-teal .dark select.mat-mdc-form-field-input-control:not(.mat-mdc-native-select-inline) option:disabled {
  color: rgba(0, 0, 0, 0.38);
}
.vex-theme-teal.dark .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after,
.vex-theme-teal .dark .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
  color: rgba(255, 255, 255, 0.54);
}
.vex-theme-teal.dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after,
.vex-theme-teal .dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after {
  color: rgba(20, 184, 166, 0.87);
}
.vex-theme-teal.dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after,
.vex-theme-teal .dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after {
  color: rgba(245, 158, 11, 0.87);
}
.vex-theme-teal.dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after,
.vex-theme-teal .dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after {
  color: rgba(239, 68, 68, 0.87);
}
.vex-theme-teal.dark .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after,
.vex-theme-teal .dark .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  color: rgba(255, 255, 255, 0.38);
}
.vex-theme-teal.dark .mat-mdc-form-field.mat-accent,
.vex-theme-teal .dark .mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #f59e0b;
  --mdc-filled-text-field-focus-active-indicator-color: #f59e0b;
  --mdc-filled-text-field-focus-label-text-color: rgba(245, 158, 11, 0.87);
  --mdc-outlined-text-field-caret-color: #f59e0b;
  --mdc-outlined-text-field-focus-outline-color: #f59e0b;
  --mdc-outlined-text-field-focus-label-text-color: rgba(245, 158, 11, 0.87);
}
.vex-theme-teal.dark .mat-mdc-form-field.mat-warn,
.vex-theme-teal .dark .mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #ef4444;
  --mdc-filled-text-field-focus-active-indicator-color: #ef4444;
  --mdc-filled-text-field-focus-label-text-color: rgba(239, 68, 68, 0.87);
  --mdc-outlined-text-field-caret-color: #ef4444;
  --mdc-outlined-text-field-focus-outline-color: #ef4444;
  --mdc-outlined-text-field-focus-label-text-color: rgba(239, 68, 68, 0.87);
}
.vex-theme-teal.dark .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch,
.vex-theme-teal .dark .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: 1px solid transparent;
}
.vex-theme-teal.dark [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch,
.vex-theme-teal .dark [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none;
  border-right: 1px solid transparent;
}
.vex-theme-teal.dark, .vex-theme-teal .dark {
  --mat-select-panel-background-color: #1f2937;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(255, 255, 255, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(255, 255, 255, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(20, 184, 166, 0.87);
  --mat-select-invalid-arrow-color: rgba(239, 68, 68, 0.87);
}
.vex-theme-teal.dark .mat-mdc-form-field.mat-accent, .vex-theme-teal .dark .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: #1f2937;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(255, 255, 255, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(255, 255, 255, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(245, 158, 11, 0.87);
  --mat-select-invalid-arrow-color: rgba(239, 68, 68, 0.87);
}
.vex-theme-teal.dark .mat-mdc-form-field.mat-warn, .vex-theme-teal .dark .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: #1f2937;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(255, 255, 255, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(255, 255, 255, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(239, 68, 68, 0.87);
  --mat-select-invalid-arrow-color: rgba(239, 68, 68, 0.87);
}

.vex-theme-teal.dark, .vex-theme-teal .dark {
  --mat-autocomplete-background-color: #1f2937;
}

.vex-theme-teal.dark .mat-mdc-dialog-container,
.vex-theme-teal .dark .mat-mdc-dialog-container {
  --mdc-dialog-container-color: #1f2937;
  --mdc-dialog-subhead-color: rgba(255, 255, 255, 0.87);
  --mdc-dialog-supporting-text-color: rgba(255, 255, 255, 0.6);
}
.vex-theme-teal.dark .mat-mdc-standard-chip,
.vex-theme-teal .dark .mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #fafafa;
  --mdc-chip-elevated-container-color: #3a434f;
  --mdc-chip-elevated-disabled-container-color: #3a434f;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #fafafa;
  --mdc-chip-with-icon-icon-color: #fafafa;
  --mdc-chip-with-icon-disabled-icon-color: #fafafa;
  --mdc-chip-with-icon-selected-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #fafafa;
}
.vex-theme-teal.dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .vex-theme-teal.dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary,
.vex-theme-teal .dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary,
.vex-theme-teal .dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #14b8a6;
  --mdc-chip-elevated-disabled-container-color: #14b8a6;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.vex-theme-teal.dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .vex-theme-teal.dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent,
.vex-theme-teal .dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent,
.vex-theme-teal .dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #f59e0b;
  --mdc-chip-elevated-disabled-container-color: #f59e0b;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.vex-theme-teal.dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .vex-theme-teal.dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn,
.vex-theme-teal .dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn,
.vex-theme-teal .dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #ef4444;
  --mdc-chip-elevated-disabled-container-color: #ef4444;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.vex-theme-teal.dark .mat-mdc-slide-toggle,
.vex-theme-teal .dark .mat-mdc-slide-toggle {
  --mdc-switch-selected-focus-state-layer-color: #5eead4;
  --mdc-switch-selected-handle-color: #5eead4;
  --mdc-switch-selected-hover-state-layer-color: #5eead4;
  --mdc-switch-selected-pressed-state-layer-color: #5eead4;
  --mdc-switch-selected-focus-handle-color: #99f6e4;
  --mdc-switch-selected-hover-handle-color: #99f6e4;
  --mdc-switch-selected-pressed-handle-color: #99f6e4;
  --mdc-switch-selected-focus-track-color: #0d9488;
  --mdc-switch-selected-hover-track-color: #0d9488;
  --mdc-switch-selected-pressed-track-color: #0d9488;
  --mdc-switch-selected-track-color: #0d9488;
  --mdc-switch-disabled-selected-handle-color: #000;
  --mdc-switch-disabled-selected-icon-color: #212121;
  --mdc-switch-disabled-selected-track-color: #f5f5f5;
  --mdc-switch-disabled-unselected-handle-color: #000;
  --mdc-switch-disabled-unselected-icon-color: #212121;
  --mdc-switch-disabled-unselected-track-color: #f5f5f5;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #212121;
  --mdc-switch-unselected-focus-handle-color: #fafafa;
  --mdc-switch-unselected-focus-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-focus-track-color: #616161;
  --mdc-switch-unselected-handle-color: #9e9e9e;
  --mdc-switch-unselected-hover-handle-color: #fafafa;
  --mdc-switch-unselected-hover-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-hover-track-color: #616161;
  --mdc-switch-unselected-icon-color: #212121;
  --mdc-switch-unselected-pressed-handle-color: #fafafa;
  --mdc-switch-unselected-pressed-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-pressed-track-color: #616161;
  --mdc-switch-unselected-track-color: #616161;
}
.vex-theme-teal.dark .mat-mdc-slide-toggle .mdc-form-field,
.vex-theme-teal .dark .mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.vex-theme-teal.dark .mat-mdc-slide-toggle .mdc-switch--disabled + label,
.vex-theme-teal .dark .mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: #6b7280;
}
.vex-theme-teal.dark .mat-mdc-slide-toggle.mat-accent,
.vex-theme-teal .dark .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #fcd34d;
  --mdc-switch-selected-handle-color: #fcd34d;
  --mdc-switch-selected-hover-state-layer-color: #fcd34d;
  --mdc-switch-selected-pressed-state-layer-color: #fcd34d;
  --mdc-switch-selected-focus-handle-color: #fde68a;
  --mdc-switch-selected-hover-handle-color: #fde68a;
  --mdc-switch-selected-pressed-handle-color: #fde68a;
  --mdc-switch-selected-focus-track-color: #d97706;
  --mdc-switch-selected-hover-track-color: #d97706;
  --mdc-switch-selected-pressed-track-color: #d97706;
  --mdc-switch-selected-track-color: #d97706;
}
.vex-theme-teal.dark .mat-mdc-slide-toggle.mat-warn,
.vex-theme-teal .dark .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #fca5a5;
  --mdc-switch-selected-handle-color: #fca5a5;
  --mdc-switch-selected-hover-state-layer-color: #fca5a5;
  --mdc-switch-selected-pressed-state-layer-color: #fca5a5;
  --mdc-switch-selected-focus-handle-color: #fecaca;
  --mdc-switch-selected-hover-handle-color: #fecaca;
  --mdc-switch-selected-pressed-handle-color: #fecaca;
  --mdc-switch-selected-focus-track-color: #dc2626;
  --mdc-switch-selected-hover-track-color: #dc2626;
  --mdc-switch-selected-pressed-track-color: #dc2626;
  --mdc-switch-selected-track-color: #dc2626;
}
.vex-theme-teal.dark .mat-mdc-radio-button .mdc-form-field,
.vex-theme-teal .dark .mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.vex-theme-teal.dark .mat-mdc-radio-button.mat-primary,
.vex-theme-teal .dark .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #14b8a6;
  --mdc-radio-selected-hover-icon-color: #14b8a6;
  --mdc-radio-selected-icon-color: #14b8a6;
  --mdc-radio-selected-pressed-icon-color: #14b8a6;
  --mat-radio-ripple-color: #fff;
  --mat-radio-checked-ripple-color: #14b8a6;
  --mat-radio-disabled-label-color: #6b7280;
}
.vex-theme-teal.dark .mat-mdc-radio-button.mat-accent,
.vex-theme-teal .dark .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #f59e0b;
  --mdc-radio-selected-hover-icon-color: #f59e0b;
  --mdc-radio-selected-icon-color: #f59e0b;
  --mdc-radio-selected-pressed-icon-color: #f59e0b;
  --mat-radio-ripple-color: #fff;
  --mat-radio-checked-ripple-color: #f59e0b;
  --mat-radio-disabled-label-color: #6b7280;
}
.vex-theme-teal.dark .mat-mdc-radio-button.mat-warn,
.vex-theme-teal .dark .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #ef4444;
  --mdc-radio-selected-hover-icon-color: #ef4444;
  --mdc-radio-selected-icon-color: #ef4444;
  --mdc-radio-selected-pressed-icon-color: #ef4444;
  --mat-radio-ripple-color: #fff;
  --mat-radio-checked-ripple-color: #ef4444;
  --mat-radio-disabled-label-color: #6b7280;
}
.vex-theme-teal.dark .mat-mdc-slider,
.vex-theme-teal .dark .mat-mdc-slider {
  --mdc-slider-label-container-color: white;
  --mdc-slider-label-label-text-color: black;
  --mdc-slider-disabled-handle-color: #fff;
  --mdc-slider-disabled-active-track-color: #fff;
  --mdc-slider-disabled-inactive-track-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #fff;
  --mat-mdc-slider-value-indicator-opacity: 0.9;
}
.vex-theme-teal.dark .mat-mdc-slider.mat-primary,
.vex-theme-teal .dark .mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #14b8a6;
  --mdc-slider-focus-handle-color: #14b8a6;
  --mdc-slider-hover-handle-color: #14b8a6;
  --mdc-slider-active-track-color: #14b8a6;
  --mdc-slider-inactive-track-color: #14b8a6;
  --mdc-slider-with-tick-marks-active-container-color: #000;
  --mdc-slider-with-tick-marks-inactive-container-color: #14b8a6;
  --mat-mdc-slider-ripple-color: #14b8a6;
  --mat-mdc-slider-hover-ripple-color: rgba(20, 184, 166, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(20, 184, 166, 0.2);
}
.vex-theme-teal.dark .mat-mdc-slider.mat-accent,
.vex-theme-teal .dark .mat-mdc-slider.mat-accent {
  --mdc-slider-handle-color: #f59e0b;
  --mdc-slider-focus-handle-color: #f59e0b;
  --mdc-slider-hover-handle-color: #f59e0b;
  --mdc-slider-active-track-color: #f59e0b;
  --mdc-slider-inactive-track-color: #f59e0b;
  --mdc-slider-with-tick-marks-active-container-color: #000;
  --mdc-slider-with-tick-marks-inactive-container-color: #f59e0b;
  --mat-mdc-slider-ripple-color: #f59e0b;
  --mat-mdc-slider-hover-ripple-color: rgba(245, 158, 11, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(245, 158, 11, 0.2);
}
.vex-theme-teal.dark .mat-mdc-slider.mat-warn,
.vex-theme-teal .dark .mat-mdc-slider.mat-warn {
  --mdc-slider-handle-color: #ef4444;
  --mdc-slider-focus-handle-color: #ef4444;
  --mdc-slider-hover-handle-color: #ef4444;
  --mdc-slider-active-track-color: #ef4444;
  --mdc-slider-inactive-track-color: #ef4444;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #ef4444;
  --mat-mdc-slider-ripple-color: #ef4444;
  --mat-mdc-slider-hover-ripple-color: rgba(239, 68, 68, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(239, 68, 68, 0.2);
}
.vex-theme-teal.dark, .vex-theme-teal .dark {
  --mat-menu-item-label-text-color: white;
  --mat-menu-item-icon-color: white;
  --mat-menu-item-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-menu-item-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-menu-container-color: #1f2937;
}

.vex-theme-teal.dark .mat-mdc-list-base,
.vex-theme-teal .dark .mat-mdc-list-base {
  --mdc-list-list-item-label-text-color: white;
  --mdc-list-list-item-supporting-text-color: #9ca3af;
  --mdc-list-list-item-leading-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-trailing-supporting-text-color: #6b7280;
  --mdc-list-list-item-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-disabled-label-text-color: white;
  --mdc-list-list-item-disabled-leading-icon-color: white;
  --mdc-list-list-item-disabled-trailing-icon-color: white;
  --mdc-list-list-item-hover-label-text-color: white;
  --mdc-list-list-item-hover-leading-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-focus-label-text-color: white;
  --mdc-list-list-item-hover-state-layer-color: white;
  --mdc-list-list-item-hover-state-layer-opacity: 0.08;
  --mdc-list-list-item-focus-state-layer-color: white;
  --mdc-list-list-item-focus-state-layer-opacity: 0.24;
}
.vex-theme-teal.dark .mdc-list-item__start,
.vex-theme-teal.dark .mdc-list-item__end,
.vex-theme-teal .dark .mdc-list-item__start,
.vex-theme-teal .dark .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #14b8a6;
  --mdc-radio-selected-hover-icon-color: #14b8a6;
  --mdc-radio-selected-icon-color: #14b8a6;
  --mdc-radio-selected-pressed-icon-color: #14b8a6;
}
.vex-theme-teal.dark .mat-accent .mdc-list-item__start,
.vex-theme-teal.dark .mat-accent .mdc-list-item__end,
.vex-theme-teal .dark .mat-accent .mdc-list-item__start,
.vex-theme-teal .dark .mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #f59e0b;
  --mdc-radio-selected-hover-icon-color: #f59e0b;
  --mdc-radio-selected-icon-color: #f59e0b;
  --mdc-radio-selected-pressed-icon-color: #f59e0b;
}
.vex-theme-teal.dark .mat-warn .mdc-list-item__start,
.vex-theme-teal.dark .mat-warn .mdc-list-item__end,
.vex-theme-teal .dark .mat-warn .mdc-list-item__start,
.vex-theme-teal .dark .mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #ef4444;
  --mdc-radio-selected-hover-icon-color: #ef4444;
  --mdc-radio-selected-icon-color: #ef4444;
  --mdc-radio-selected-pressed-icon-color: #ef4444;
}
.vex-theme-teal.dark .mat-mdc-list-option,
.vex-theme-teal .dark .mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #14b8a6;
  --mdc-checkbox-selected-hover-icon-color: #14b8a6;
  --mdc-checkbox-selected-icon-color: #14b8a6;
  --mdc-checkbox-selected-pressed-icon-color: #14b8a6;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #14b8a6;
  --mdc-checkbox-selected-hover-state-layer-color: #14b8a6;
  --mdc-checkbox-selected-pressed-state-layer-color: #14b8a6;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.vex-theme-teal.dark .mat-mdc-list-option.mat-accent,
.vex-theme-teal .dark .mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #f59e0b;
  --mdc-checkbox-selected-hover-icon-color: #f59e0b;
  --mdc-checkbox-selected-icon-color: #f59e0b;
  --mdc-checkbox-selected-pressed-icon-color: #f59e0b;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f59e0b;
  --mdc-checkbox-selected-hover-state-layer-color: #f59e0b;
  --mdc-checkbox-selected-pressed-state-layer-color: #f59e0b;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.vex-theme-teal.dark .mat-mdc-list-option.mat-warn,
.vex-theme-teal .dark .mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #ef4444;
  --mdc-checkbox-selected-hover-icon-color: #ef4444;
  --mdc-checkbox-selected-icon-color: #ef4444;
  --mdc-checkbox-selected-pressed-icon-color: #ef4444;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #ef4444;
  --mdc-checkbox-selected-hover-state-layer-color: #ef4444;
  --mdc-checkbox-selected-pressed-state-layer-color: #ef4444;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.vex-theme-teal.dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.vex-theme-teal.dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,
.vex-theme-teal .dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.vex-theme-teal .dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #14b8a6;
}
.vex-theme-teal.dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.vex-theme-teal.dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.vex-theme-teal .dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.vex-theme-teal .dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #14b8a6;
}
.vex-theme-teal.dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.vex-theme-teal.dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.vex-theme-teal.dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end,
.vex-theme-teal .dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.vex-theme-teal .dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.vex-theme-teal .dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.vex-theme-teal.dark, .vex-theme-teal .dark {
  --mat-paginator-container-text-color: rgba(255, 255, 255, 0.87);
  --mat-paginator-container-background-color: #1f2937;
  --mat-paginator-enabled-icon-color: rgba(255, 255, 255, 0.54);
  --mat-paginator-disabled-icon-color: rgba(255, 255, 255, 0.12);
}

.vex-theme-teal.dark .mat-mdc-tab-group, .vex-theme-teal.dark .mat-mdc-tab-nav-bar,
.vex-theme-teal .dark .mat-mdc-tab-group,
.vex-theme-teal .dark .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #14b8a6;
  --mat-tab-header-disabled-ripple-color: #6b7280;
  --mat-tab-header-pagination-icon-color: #fff;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #14b8a6;
  --mat-tab-header-active-ripple-color: #14b8a6;
  --mat-tab-header-inactive-ripple-color: #14b8a6;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #14b8a6;
  --mat-tab-header-active-hover-label-text-color: #14b8a6;
  --mat-tab-header-active-focus-indicator-color: #14b8a6;
  --mat-tab-header-active-hover-indicator-color: #14b8a6;
}
.vex-theme-teal.dark .mat-mdc-tab-group.mat-accent, .vex-theme-teal.dark .mat-mdc-tab-nav-bar.mat-accent,
.vex-theme-teal .dark .mat-mdc-tab-group.mat-accent,
.vex-theme-teal .dark .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #f59e0b;
  --mat-tab-header-disabled-ripple-color: #6b7280;
  --mat-tab-header-pagination-icon-color: #fff;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #f59e0b;
  --mat-tab-header-active-ripple-color: #f59e0b;
  --mat-tab-header-inactive-ripple-color: #f59e0b;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #f59e0b;
  --mat-tab-header-active-hover-label-text-color: #f59e0b;
  --mat-tab-header-active-focus-indicator-color: #f59e0b;
  --mat-tab-header-active-hover-indicator-color: #f59e0b;
}
.vex-theme-teal.dark .mat-mdc-tab-group.mat-warn, .vex-theme-teal.dark .mat-mdc-tab-nav-bar.mat-warn,
.vex-theme-teal .dark .mat-mdc-tab-group.mat-warn,
.vex-theme-teal .dark .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #ef4444;
  --mat-tab-header-disabled-ripple-color: #6b7280;
  --mat-tab-header-pagination-icon-color: #fff;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #ef4444;
  --mat-tab-header-active-ripple-color: #ef4444;
  --mat-tab-header-inactive-ripple-color: #ef4444;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #ef4444;
  --mat-tab-header-active-hover-label-text-color: #ef4444;
  --mat-tab-header-active-focus-indicator-color: #ef4444;
  --mat-tab-header-active-hover-indicator-color: #ef4444;
}
.vex-theme-teal.dark .mat-mdc-tab-group.mat-background-primary, .vex-theme-teal.dark .mat-mdc-tab-nav-bar.mat-background-primary,
.vex-theme-teal .dark .mat-mdc-tab-group.mat-background-primary,
.vex-theme-teal .dark .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #14b8a6;
  --mat-tab-header-with-background-foreground-color: white;
}
.vex-theme-teal.dark .mat-mdc-tab-group.mat-background-accent, .vex-theme-teal.dark .mat-mdc-tab-nav-bar.mat-background-accent,
.vex-theme-teal .dark .mat-mdc-tab-group.mat-background-accent,
.vex-theme-teal .dark .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #f59e0b;
  --mat-tab-header-with-background-foreground-color: white;
}
.vex-theme-teal.dark .mat-mdc-tab-group.mat-background-warn, .vex-theme-teal.dark .mat-mdc-tab-nav-bar.mat-background-warn,
.vex-theme-teal .dark .mat-mdc-tab-group.mat-background-warn,
.vex-theme-teal .dark .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #ef4444;
  --mat-tab-header-with-background-foreground-color: white;
}
.vex-theme-teal.dark, .vex-theme-teal .dark {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #f59e0b;
  --mdc-checkbox-selected-hover-icon-color: #f59e0b;
  --mdc-checkbox-selected-icon-color: #f59e0b;
  --mdc-checkbox-selected-pressed-icon-color: #f59e0b;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f59e0b;
  --mdc-checkbox-selected-hover-state-layer-color: #f59e0b;
  --mdc-checkbox-selected-pressed-state-layer-color: #f59e0b;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}

.vex-theme-teal.dark .mat-mdc-checkbox.mat-primary,
.vex-theme-teal .dark .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #14b8a6;
  --mdc-checkbox-selected-hover-icon-color: #14b8a6;
  --mdc-checkbox-selected-icon-color: #14b8a6;
  --mdc-checkbox-selected-pressed-icon-color: #14b8a6;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #14b8a6;
  --mdc-checkbox-selected-hover-state-layer-color: #14b8a6;
  --mdc-checkbox-selected-pressed-state-layer-color: #14b8a6;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.vex-theme-teal.dark .mat-mdc-checkbox.mat-warn,
.vex-theme-teal .dark .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #ef4444;
  --mdc-checkbox-selected-hover-icon-color: #ef4444;
  --mdc-checkbox-selected-icon-color: #ef4444;
  --mdc-checkbox-selected-pressed-icon-color: #ef4444;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #ef4444;
  --mdc-checkbox-selected-hover-state-layer-color: #ef4444;
  --mdc-checkbox-selected-pressed-state-layer-color: #ef4444;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.vex-theme-teal.dark .mat-mdc-checkbox .mdc-form-field,
.vex-theme-teal .dark .mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.vex-theme-teal.dark .mat-mdc-checkbox.mat-mdc-checkbox-disabled label,
.vex-theme-teal .dark .mat-mdc-checkbox.mat-mdc-checkbox-disabled label {
  color: #6b7280;
}
.vex-theme-teal.dark .mat-mdc-button.mat-unthemed,
.vex-theme-teal .dark .mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #fff;
}
.vex-theme-teal.dark .mat-mdc-button.mat-primary,
.vex-theme-teal .dark .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #14b8a6;
}
.vex-theme-teal.dark .mat-mdc-button.mat-accent,
.vex-theme-teal .dark .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #f59e0b;
}
.vex-theme-teal.dark .mat-mdc-button.mat-warn,
.vex-theme-teal .dark .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #ef4444;
}
.vex-theme-teal.dark .mat-mdc-button[disabled][disabled],
.vex-theme-teal .dark .mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-text-button-label-text-color: rgba(255, 255, 255, 0.5);
}
.vex-theme-teal.dark .mat-mdc-unelevated-button.mat-unthemed,
.vex-theme-teal .dark .mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #1f2937;
  --mdc-filled-button-label-text-color: #fff;
}
.vex-theme-teal.dark .mat-mdc-unelevated-button.mat-primary,
.vex-theme-teal .dark .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #14b8a6;
  --mdc-filled-button-label-text-color: #000;
}
.vex-theme-teal.dark .mat-mdc-unelevated-button.mat-accent,
.vex-theme-teal .dark .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #f59e0b;
  --mdc-filled-button-label-text-color: #000;
}
.vex-theme-teal.dark .mat-mdc-unelevated-button.mat-warn,
.vex-theme-teal .dark .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #ef4444;
  --mdc-filled-button-label-text-color: #fff;
}
.vex-theme-teal.dark .mat-mdc-unelevated-button[disabled][disabled],
.vex-theme-teal .dark .mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(255, 255, 255, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-filled-button-container-color: rgba(255, 255, 255, 0.12);
  --mdc-filled-button-label-text-color: rgba(255, 255, 255, 0.5);
}
.vex-theme-teal.dark .mat-mdc-raised-button.mat-unthemed,
.vex-theme-teal .dark .mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #1f2937;
  --mdc-protected-button-label-text-color: #fff;
}
.vex-theme-teal.dark .mat-mdc-raised-button.mat-primary,
.vex-theme-teal .dark .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #14b8a6;
  --mdc-protected-button-label-text-color: #000;
}
.vex-theme-teal.dark .mat-mdc-raised-button.mat-accent,
.vex-theme-teal .dark .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #f59e0b;
  --mdc-protected-button-label-text-color: #000;
}
.vex-theme-teal.dark .mat-mdc-raised-button.mat-warn,
.vex-theme-teal .dark .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #ef4444;
  --mdc-protected-button-label-text-color: #fff;
}
.vex-theme-teal.dark .mat-mdc-raised-button[disabled][disabled],
.vex-theme-teal .dark .mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(255, 255, 255, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-protected-button-container-color: rgba(255, 255, 255, 0.12);
  --mdc-protected-button-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-protected-button-container-elevation: 0;
}
.vex-theme-teal.dark .mat-mdc-outlined-button,
.vex-theme-teal .dark .mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
}
.vex-theme-teal.dark .mat-mdc-outlined-button.mat-unthemed,
.vex-theme-teal .dark .mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #fff;
}
.vex-theme-teal.dark .mat-mdc-outlined-button.mat-primary,
.vex-theme-teal .dark .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #14b8a6;
}
.vex-theme-teal.dark .mat-mdc-outlined-button.mat-accent,
.vex-theme-teal .dark .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #f59e0b;
}
.vex-theme-teal.dark .mat-mdc-outlined-button.mat-warn,
.vex-theme-teal .dark .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #ef4444;
}
.vex-theme-teal.dark .mat-mdc-outlined-button[disabled][disabled],
.vex-theme-teal .dark .mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-outlined-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(255, 255, 255, 0.12);
}
.vex-theme-teal.dark .mat-mdc-button, .vex-theme-teal.dark .mat-mdc-outlined-button,
.vex-theme-teal .dark .mat-mdc-button,
.vex-theme-teal .dark .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.vex-theme-teal.dark .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .vex-theme-teal.dark .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal .dark .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal .dark .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
.vex-theme-teal.dark .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-teal.dark .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-teal.dark .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-teal.dark .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal .dark .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal .dark .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal .dark .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal .dark .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.vex-theme-teal.dark .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .vex-theme-teal.dark .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal .dark .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal .dark .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.vex-theme-teal.dark .mat-mdc-button.mat-primary, .vex-theme-teal.dark .mat-mdc-outlined-button.mat-primary,
.vex-theme-teal .dark .mat-mdc-button.mat-primary,
.vex-theme-teal .dark .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #14b8a6;
  --mat-mdc-button-ripple-color: rgba(20, 184, 166, 0.1);
}
.vex-theme-teal.dark .mat-mdc-button.mat-accent, .vex-theme-teal.dark .mat-mdc-outlined-button.mat-accent,
.vex-theme-teal .dark .mat-mdc-button.mat-accent,
.vex-theme-teal .dark .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #f59e0b;
  --mat-mdc-button-ripple-color: rgba(245, 158, 11, 0.1);
}
.vex-theme-teal.dark .mat-mdc-button.mat-warn, .vex-theme-teal.dark .mat-mdc-outlined-button.mat-warn,
.vex-theme-teal .dark .mat-mdc-button.mat-warn,
.vex-theme-teal .dark .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #ef4444;
  --mat-mdc-button-ripple-color: rgba(239, 68, 68, 0.1);
}
.vex-theme-teal.dark .mat-mdc-raised-button, .vex-theme-teal.dark .mat-mdc-unelevated-button,
.vex-theme-teal .dark .mat-mdc-raised-button,
.vex-theme-teal .dark .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.vex-theme-teal.dark .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .vex-theme-teal.dark .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal .dark .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal .dark .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
.vex-theme-teal.dark .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-teal.dark .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-teal.dark .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-teal.dark .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal .dark .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal .dark .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal .dark .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal .dark .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.vex-theme-teal.dark .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .vex-theme-teal.dark .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal .dark .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal .dark .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.vex-theme-teal.dark .mat-mdc-raised-button.mat-primary, .vex-theme-teal.dark .mat-mdc-unelevated-button.mat-primary,
.vex-theme-teal .dark .mat-mdc-raised-button.mat-primary,
.vex-theme-teal .dark .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.vex-theme-teal.dark .mat-mdc-raised-button.mat-accent, .vex-theme-teal.dark .mat-mdc-unelevated-button.mat-accent,
.vex-theme-teal .dark .mat-mdc-raised-button.mat-accent,
.vex-theme-teal .dark .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.vex-theme-teal.dark .mat-mdc-raised-button.mat-warn, .vex-theme-teal.dark .mat-mdc-unelevated-button.mat-warn,
.vex-theme-teal .dark .mat-mdc-raised-button.mat-warn,
.vex-theme-teal .dark .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.vex-theme-teal.dark .mat-mdc-icon-button,
.vex-theme-teal .dark .mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mdc-icon-button-icon-color: inherit;
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.vex-theme-teal.dark .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal .dark .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
.vex-theme-teal.dark .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-teal.dark .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal .dark .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal .dark .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.vex-theme-teal.dark .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal .dark .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.vex-theme-teal.dark .mat-mdc-icon-button.mat-primary,
.vex-theme-teal .dark .mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #6200ee;
  --mat-mdc-button-ripple-color: rgba(98, 0, 238, 0.1);
}
.vex-theme-teal.dark .mat-mdc-icon-button.mat-accent,
.vex-theme-teal .dark .mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #018786;
  --mat-mdc-button-ripple-color: rgba(1, 135, 134, 0.1);
}
.vex-theme-teal.dark .mat-mdc-icon-button.mat-warn,
.vex-theme-teal .dark .mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #b00020;
  --mat-mdc-button-ripple-color: rgba(176, 0, 32, 0.1);
}
.vex-theme-teal.dark .mat-mdc-icon-button.mat-primary,
.vex-theme-teal .dark .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #14b8a6;
  --mat-mdc-button-persistent-ripple-color: #14b8a6;
  --mat-mdc-button-ripple-color: rgba(20, 184, 166, 0.1);
}
.vex-theme-teal.dark .mat-mdc-icon-button.mat-accent,
.vex-theme-teal .dark .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #f59e0b;
  --mat-mdc-button-persistent-ripple-color: #f59e0b;
  --mat-mdc-button-ripple-color: rgba(245, 158, 11, 0.1);
}
.vex-theme-teal.dark .mat-mdc-icon-button.mat-warn,
.vex-theme-teal .dark .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #ef4444;
  --mat-mdc-button-persistent-ripple-color: #ef4444;
  --mat-mdc-button-ripple-color: rgba(239, 68, 68, 0.1);
}
.vex-theme-teal.dark .mat-mdc-icon-button[disabled][disabled],
.vex-theme-teal .dark .mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-icon-button-disabled-icon-color: rgba(255, 255, 255, 0.5);
}
.vex-theme-teal.dark .mat-mdc-fab,
.vex-theme-teal.dark .mat-mdc-mini-fab,
.vex-theme-teal .dark .mat-mdc-fab,
.vex-theme-teal .dark .mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.vex-theme-teal.dark .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal.dark .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal .dark .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal .dark .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
.vex-theme-teal.dark .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-teal.dark .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal.dark .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal.dark .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal .dark .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal .dark .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal .dark .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal .dark .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.vex-theme-teal.dark .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal.dark .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal .dark .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-teal .dark .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.vex-theme-teal.dark .mat-mdc-fab.mat-primary,
.vex-theme-teal.dark .mat-mdc-mini-fab.mat-primary,
.vex-theme-teal .dark .mat-mdc-fab.mat-primary,
.vex-theme-teal .dark .mat-mdc-mini-fab.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.vex-theme-teal.dark .mat-mdc-fab.mat-accent,
.vex-theme-teal.dark .mat-mdc-mini-fab.mat-accent,
.vex-theme-teal .dark .mat-mdc-fab.mat-accent,
.vex-theme-teal .dark .mat-mdc-mini-fab.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.vex-theme-teal.dark .mat-mdc-fab.mat-warn,
.vex-theme-teal.dark .mat-mdc-mini-fab.mat-warn,
.vex-theme-teal .dark .mat-mdc-fab.mat-warn,
.vex-theme-teal .dark .mat-mdc-mini-fab.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.vex-theme-teal.dark .mat-mdc-fab[disabled][disabled],
.vex-theme-teal.dark .mat-mdc-mini-fab[disabled][disabled],
.vex-theme-teal .dark .mat-mdc-fab[disabled][disabled],
.vex-theme-teal .dark .mat-mdc-mini-fab[disabled][disabled] {
  --mdc-fab-container-color: rgba(255, 255, 255, 0.12);
  --mdc-fab-icon-color: rgba(255, 255, 255, 0.5);
  --mat-mdc-fab-color: rgba(255, 255, 255, 0.5);
}
.vex-theme-teal.dark .mat-mdc-fab.mat-unthemed,
.vex-theme-teal.dark .mat-mdc-mini-fab.mat-unthemed,
.vex-theme-teal .dark .mat-mdc-fab.mat-unthemed,
.vex-theme-teal .dark .mat-mdc-mini-fab.mat-unthemed {
  --mdc-fab-container-color: #1f2937;
  --mdc-fab-icon-color: white;
  --mat-mdc-fab-color: #fff;
}
.vex-theme-teal.dark .mat-mdc-fab.mat-primary,
.vex-theme-teal.dark .mat-mdc-mini-fab.mat-primary,
.vex-theme-teal .dark .mat-mdc-fab.mat-primary,
.vex-theme-teal .dark .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #14b8a6;
  --mdc-fab-icon-color: black;
  --mat-mdc-fab-color: #000;
}
.vex-theme-teal.dark .mat-mdc-fab.mat-accent,
.vex-theme-teal.dark .mat-mdc-mini-fab.mat-accent,
.vex-theme-teal .dark .mat-mdc-fab.mat-accent,
.vex-theme-teal .dark .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: #f59e0b;
  --mdc-fab-icon-color: black;
  --mat-mdc-fab-color: #000;
}
.vex-theme-teal.dark .mat-mdc-fab.mat-warn,
.vex-theme-teal.dark .mat-mdc-mini-fab.mat-warn,
.vex-theme-teal .dark .mat-mdc-fab.mat-warn,
.vex-theme-teal .dark .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: #ef4444;
  --mdc-fab-icon-color: white;
  --mat-mdc-fab-color: #fff;
}
.vex-theme-teal.dark .mat-mdc-snack-bar-container,
.vex-theme-teal .dark .mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: #d2d4d7;
  --mdc-snackbar-supporting-text-color: rgba(31, 41, 55, 0.87);
  --mat-snack-bar-button-color: rgba(0, 0, 0, 0.87);
}
.vex-theme-teal.dark .mat-mdc-progress-spinner,
.vex-theme-teal .dark .mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #14b8a6;
}
.vex-theme-teal.dark .mat-mdc-progress-spinner.mat-accent,
.vex-theme-teal .dark .mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: #f59e0b;
}
.vex-theme-teal.dark .mat-mdc-progress-spinner.mat-warn,
.vex-theme-teal .dark .mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: #ef4444;
}
.vex-theme-teal.dark, .vex-theme-teal .dark {
  --mat-badge-background-color: #14b8a6;
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: #4b5563;
  --mat-badge-disabled-state-text-color: #6b7280;
}

.vex-theme-teal.dark .mat-badge-accent,
.vex-theme-teal .dark .mat-badge-accent {
  --mat-badge-background-color: #f59e0b;
  --mat-badge-text-color: white;
}
.vex-theme-teal.dark .mat-badge-warn,
.vex-theme-teal .dark .mat-badge-warn {
  --mat-badge-background-color: #ef4444;
  --mat-badge-text-color: white;
}
.vex-theme-teal.dark, .vex-theme-teal .dark {
  --mat-bottom-sheet-container-text-color: white;
  --mat-bottom-sheet-container-background-color: #1f2937;
}

.vex-theme-teal.dark, .vex-theme-teal .dark {
  --mat-legacy-button-toggle-text-color: #6b7280;
  --mat-legacy-button-toggle-state-layer-color: rgba(255, 255, 255, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: #9ca3af;
  --mat-legacy-button-toggle-selected-state-background-color: #111827;
  --mat-legacy-button-toggle-disabled-state-text-color: #4b5563;
  --mat-legacy-button-toggle-disabled-state-background-color: black;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #1f2937;
  --mat-standard-button-toggle-text-color: white;
  --mat-standard-button-toggle-background-color: #1f2937;
  --mat-standard-button-toggle-state-layer-color: white;
  --mat-standard-button-toggle-selected-state-background-color: #111827;
  --mat-standard-button-toggle-selected-state-text-color: white;
  --mat-standard-button-toggle-disabled-state-text-color: #4b5563;
  --mat-standard-button-toggle-disabled-state-background-color: #1f2937;
  --mat-standard-button-toggle-disabled-selected-state-text-color: white;
  --mat-standard-button-toggle-disabled-selected-state-background-color: #1f2937;
  --mat-standard-button-toggle-divider-color: #3a434f;
}

.vex-theme-teal.dark, .vex-theme-teal .dark {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #14b8a6;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(20, 184, 166, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(20, 184, 166, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(20, 184, 166, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #14b8a6;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(20, 184, 166, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: white;
  --mat-datepicker-calendar-body-label-text-color: #9ca3af;
  --mat-datepicker-calendar-period-button-icon-color: white;
  --mat-datepicker-calendar-navigation-button-icon-color: white;
  --mat-datepicker-calendar-header-divider-color: rgba(255, 255, 255, 0.12);
  --mat-datepicker-calendar-header-text-color: #9ca3af;
  --mat-datepicker-calendar-date-today-outline-color: #6b7280;
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(107, 114, 128, 0.8);
  --mat-datepicker-calendar-date-text-color: white;
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: #6b7280;
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(255, 255, 255, 0.24);
  --mat-datepicker-range-input-separator-color: white;
  --mat-datepicker-range-input-disabled-state-separator-color: #6b7280;
  --mat-datepicker-range-input-disabled-state-text-color: #6b7280;
  --mat-datepicker-calendar-container-background-color: #1f2937;
  --mat-datepicker-calendar-container-text-color: white;
}

.vex-theme-teal.dark .mat-datepicker-content.mat-accent,
.vex-theme-teal .dark .mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #f59e0b;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(245, 158, 11, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(245, 158, 11, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(245, 158, 11, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(245, 158, 11, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.vex-theme-teal.dark .mat-datepicker-content.mat-warn,
.vex-theme-teal .dark .mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #ef4444;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(239, 68, 68, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(239, 68, 68, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(239, 68, 68, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(239, 68, 68, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.vex-theme-teal.dark .mat-datepicker-toggle-active.mat-accent,
.vex-theme-teal .dark .mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #f59e0b;
}
.vex-theme-teal.dark .mat-datepicker-toggle-active.mat-warn,
.vex-theme-teal .dark .mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #ef4444;
}
.vex-theme-teal.dark, .vex-theme-teal .dark {
  --mat-divider-color: rgba(255, 255, 255, 0.12);
}

.vex-theme-teal.dark, .vex-theme-teal .dark {
  --mat-expansion-container-background-color: #1f2937;
  --mat-expansion-container-text-color: white;
  --mat-expansion-actions-divider-color: rgba(255, 255, 255, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(255, 255, 255, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(255, 255, 255, 0.04);
  --mat-expansion-header-disabled-state-text-color: #4b5563;
  --mat-expansion-header-text-color: white;
  --mat-expansion-header-description-color: #9ca3af;
  --mat-expansion-header-indicator-color: #9ca3af;
}

.vex-theme-teal.dark, .vex-theme-teal .dark {
  --mat-icon-color: inherit;
}

.vex-theme-teal.dark .mat-icon.mat-primary,
.vex-theme-teal .dark .mat-icon.mat-primary {
  --mat-icon-color: #14b8a6;
}
.vex-theme-teal.dark .mat-icon.mat-accent,
.vex-theme-teal .dark .mat-icon.mat-accent {
  --mat-icon-color: #f59e0b;
}
.vex-theme-teal.dark .mat-icon.mat-warn,
.vex-theme-teal .dark .mat-icon.mat-warn {
  --mat-icon-color: #ef4444;
}
.vex-theme-teal.dark, .vex-theme-teal .dark {
  --mat-sidenav-container-divider-color: rgba(255, 255, 255, 0.12);
  --mat-sidenav-container-background-color: #1f2937;
  --mat-sidenav-container-text-color: white;
  --mat-sidenav-content-background-color: #111827;
  --mat-sidenav-content-text-color: white;
  --mat-sidenav-scrim-color: rgba(224, 214, 200, 0.6);
}

.vex-theme-teal.dark, .vex-theme-teal .dark {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #14b8a6;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #14b8a6;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #14b8a6;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: #1f2937;
  --mat-stepper-line-color: rgba(255, 255, 255, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(255, 255, 255, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(255, 255, 255, 0.04);
  --mat-stepper-header-label-text-color: #9ca3af;
  --mat-stepper-header-optional-label-text-color: #9ca3af;
  --mat-stepper-header-selected-state-label-text-color: white;
  --mat-stepper-header-error-state-label-text-color: #ef4444;
  --mat-stepper-header-icon-background-color: #9ca3af;
  --mat-stepper-header-error-state-icon-foreground-color: #ef4444;
  --mat-stepper-header-error-state-icon-background-color: transparent;
}
.vex-theme-teal.dark .mat-step-header.mat-accent, .vex-theme-teal .dark .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #f59e0b;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #f59e0b;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #f59e0b;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.vex-theme-teal.dark .mat-step-header.mat-warn, .vex-theme-teal .dark .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #ef4444;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #ef4444;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #ef4444;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}

.vex-theme-teal.dark .mat-sort-header-arrow,
.vex-theme-teal .dark .mat-sort-header-arrow {
  color: #9ca3af;
}
.vex-theme-teal.dark, .vex-theme-teal .dark {
  --mat-toolbar-container-background-color: #1f2937;
  --mat-toolbar-container-text-color: white;
}

.vex-theme-teal.dark .mat-toolbar.mat-primary,
.vex-theme-teal .dark .mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #14b8a6;
  --mat-toolbar-container-text-color: white;
}
.vex-theme-teal.dark .mat-toolbar.mat-accent,
.vex-theme-teal .dark .mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #f59e0b;
  --mat-toolbar-container-text-color: white;
}
.vex-theme-teal.dark .mat-toolbar.mat-warn,
.vex-theme-teal .dark .mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #ef4444;
  --mat-toolbar-container-text-color: white;
}
.vex-theme-teal.dark .mat-tree,
.vex-theme-teal .dark .mat-tree {
  background: #1f2937;
}
.vex-theme-teal.dark .mat-tree-node,
.vex-theme-teal.dark .mat-nested-tree-node,
.vex-theme-teal .dark .mat-tree-node,
.vex-theme-teal .dark .mat-nested-tree-node {
  color: white;
}

/**
 * Override the theme foreground/background colors with the Angular Material colors
 */
.vex-theme-green,
.vex-theme-green.light,
.vex-theme-green .light {
  --mat-table-background-color: white;
  --mat-table-header-headline-color: #030712;
  --mat-table-row-item-label-text-color: #030712;
  --mat-table-row-item-outline-color: rgba(0, 0, 0, 0.06);
}
.vex-theme-green .mat-ripple-element,
.vex-theme-green.light .mat-ripple-element,
.vex-theme-green .light .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.vex-theme-green, .vex-theme-green.light, .vex-theme-green .light {
  --mat-option-selected-state-label-text-color: #16a34a;
  --mat-option-label-text-color: #030712;
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.vex-theme-green .mat-accent,
.vex-theme-green.light .mat-accent,
.vex-theme-green .light .mat-accent {
  --mat-option-selected-state-label-text-color: #f59e0b;
}
.vex-theme-green .mat-warn,
.vex-theme-green.light .mat-warn,
.vex-theme-green .light .mat-warn {
  --mat-option-selected-state-label-text-color: #ef4444;
}
.vex-theme-green, .vex-theme-green.light, .vex-theme-green .light {
  --mat-optgroup-label-text-color: #030712;
}

.vex-theme-green .mat-pseudo-checkbox-full,
.vex-theme-green.light .mat-pseudo-checkbox-full,
.vex-theme-green .light .mat-pseudo-checkbox-full {
  color: #4b5563;
}
.vex-theme-green .mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled,
.vex-theme-green.light .mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled,
.vex-theme-green .light .mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.vex-theme-green .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-green .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-green.light .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-green.light .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-green .light .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-green .light .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #16a34a;
}
.vex-theme-green .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-green .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-green.light .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-green.light .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-green .light .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-green .light .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #16a34a;
}
.vex-theme-green .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-green .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after,
.vex-theme-green.light .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-green.light .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after,
.vex-theme-green .light .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-green .light .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #f3f4f6;
}
.vex-theme-green .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-green .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-green.light .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-green.light .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-green .light .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-green .light .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #f59e0b;
}
.vex-theme-green .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-green .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-green.light .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-green.light .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-green .light .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-green .light .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #f59e0b;
}
.vex-theme-green .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-green .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after,
.vex-theme-green.light .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-green.light .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after,
.vex-theme-green .light .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-green .light .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #f3f4f6;
}
.vex-theme-green .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-green .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-green.light .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-green.light .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-green .light .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-green .light .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #f59e0b;
}
.vex-theme-green .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-green .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-green.light .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-green.light .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-green .light .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-green .light .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #f59e0b;
}
.vex-theme-green .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-green .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after,
.vex-theme-green.light .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-green.light .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after,
.vex-theme-green .light .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-green .light .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #f3f4f6;
}
.vex-theme-green .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-green .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-green.light .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-green.light .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-green .light .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-green .light .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #ef4444;
}
.vex-theme-green .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-green .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-green.light .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-green.light .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-green .light .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-green .light .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #ef4444;
}
.vex-theme-green .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-green .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after,
.vex-theme-green.light .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-green.light .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after,
.vex-theme-green .light .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-green .light .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #f3f4f6;
}
.vex-theme-green .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-green .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-green.light .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-green.light .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-green .light .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-green .light .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #b0b0b0;
}
.vex-theme-green .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-green .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-green.light .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-green.light .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-green .light .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-green .light .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #b0b0b0;
}
.vex-theme-green .mat-app-background, .vex-theme-green.mat-app-background,
.vex-theme-green.light .mat-app-background,
.vex-theme-green.light.mat-app-background,
.vex-theme-green .light .mat-app-background,
.vex-theme-green .light.mat-app-background {
  background-color: #f3f4f6;
  color: #030712;
}
.vex-theme-green .mat-elevation-z0, .vex-theme-green .mat-mdc-elevation-specific.mat-elevation-z0,
.vex-theme-green.light .mat-elevation-z0,
.vex-theme-green.light .mat-mdc-elevation-specific.mat-elevation-z0,
.vex-theme-green .light .mat-elevation-z0,
.vex-theme-green .light .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-green .mat-elevation-z1, .vex-theme-green .mat-mdc-elevation-specific.mat-elevation-z1,
.vex-theme-green.light .mat-elevation-z1,
.vex-theme-green.light .mat-mdc-elevation-specific.mat-elevation-z1,
.vex-theme-green .light .mat-elevation-z1,
.vex-theme-green .light .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-green .mat-elevation-z2, .vex-theme-green .mat-mdc-elevation-specific.mat-elevation-z2,
.vex-theme-green.light .mat-elevation-z2,
.vex-theme-green.light .mat-mdc-elevation-specific.mat-elevation-z2,
.vex-theme-green .light .mat-elevation-z2,
.vex-theme-green .light .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-green .mat-elevation-z3, .vex-theme-green .mat-mdc-elevation-specific.mat-elevation-z3,
.vex-theme-green.light .mat-elevation-z3,
.vex-theme-green.light .mat-mdc-elevation-specific.mat-elevation-z3,
.vex-theme-green .light .mat-elevation-z3,
.vex-theme-green .light .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-green .mat-elevation-z4, .vex-theme-green .mat-mdc-elevation-specific.mat-elevation-z4,
.vex-theme-green.light .mat-elevation-z4,
.vex-theme-green.light .mat-mdc-elevation-specific.mat-elevation-z4,
.vex-theme-green .light .mat-elevation-z4,
.vex-theme-green .light .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-green .mat-elevation-z5, .vex-theme-green .mat-mdc-elevation-specific.mat-elevation-z5,
.vex-theme-green.light .mat-elevation-z5,
.vex-theme-green.light .mat-mdc-elevation-specific.mat-elevation-z5,
.vex-theme-green .light .mat-elevation-z5,
.vex-theme-green .light .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-green .mat-elevation-z6, .vex-theme-green .mat-mdc-elevation-specific.mat-elevation-z6,
.vex-theme-green.light .mat-elevation-z6,
.vex-theme-green.light .mat-mdc-elevation-specific.mat-elevation-z6,
.vex-theme-green .light .mat-elevation-z6,
.vex-theme-green .light .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-green .mat-elevation-z7, .vex-theme-green .mat-mdc-elevation-specific.mat-elevation-z7,
.vex-theme-green.light .mat-elevation-z7,
.vex-theme-green.light .mat-mdc-elevation-specific.mat-elevation-z7,
.vex-theme-green .light .mat-elevation-z7,
.vex-theme-green .light .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.vex-theme-green .mat-elevation-z8, .vex-theme-green .mat-mdc-elevation-specific.mat-elevation-z8,
.vex-theme-green.light .mat-elevation-z8,
.vex-theme-green.light .mat-mdc-elevation-specific.mat-elevation-z8,
.vex-theme-green .light .mat-elevation-z8,
.vex-theme-green .light .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.vex-theme-green .mat-elevation-z9, .vex-theme-green .mat-mdc-elevation-specific.mat-elevation-z9,
.vex-theme-green.light .mat-elevation-z9,
.vex-theme-green.light .mat-mdc-elevation-specific.mat-elevation-z9,
.vex-theme-green .light .mat-elevation-z9,
.vex-theme-green .light .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.vex-theme-green .mat-elevation-z10, .vex-theme-green .mat-mdc-elevation-specific.mat-elevation-z10,
.vex-theme-green.light .mat-elevation-z10,
.vex-theme-green.light .mat-mdc-elevation-specific.mat-elevation-z10,
.vex-theme-green .light .mat-elevation-z10,
.vex-theme-green .light .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.vex-theme-green .mat-elevation-z11, .vex-theme-green .mat-mdc-elevation-specific.mat-elevation-z11,
.vex-theme-green.light .mat-elevation-z11,
.vex-theme-green.light .mat-mdc-elevation-specific.mat-elevation-z11,
.vex-theme-green .light .mat-elevation-z11,
.vex-theme-green .light .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.vex-theme-green .mat-elevation-z12, .vex-theme-green .mat-mdc-elevation-specific.mat-elevation-z12,
.vex-theme-green.light .mat-elevation-z12,
.vex-theme-green.light .mat-mdc-elevation-specific.mat-elevation-z12,
.vex-theme-green .light .mat-elevation-z12,
.vex-theme-green .light .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.vex-theme-green .mat-elevation-z13, .vex-theme-green .mat-mdc-elevation-specific.mat-elevation-z13,
.vex-theme-green.light .mat-elevation-z13,
.vex-theme-green.light .mat-mdc-elevation-specific.mat-elevation-z13,
.vex-theme-green .light .mat-elevation-z13,
.vex-theme-green .light .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.vex-theme-green .mat-elevation-z14, .vex-theme-green .mat-mdc-elevation-specific.mat-elevation-z14,
.vex-theme-green.light .mat-elevation-z14,
.vex-theme-green.light .mat-mdc-elevation-specific.mat-elevation-z14,
.vex-theme-green .light .mat-elevation-z14,
.vex-theme-green .light .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.vex-theme-green .mat-elevation-z15, .vex-theme-green .mat-mdc-elevation-specific.mat-elevation-z15,
.vex-theme-green.light .mat-elevation-z15,
.vex-theme-green.light .mat-mdc-elevation-specific.mat-elevation-z15,
.vex-theme-green .light .mat-elevation-z15,
.vex-theme-green .light .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.vex-theme-green .mat-elevation-z16, .vex-theme-green .mat-mdc-elevation-specific.mat-elevation-z16,
.vex-theme-green.light .mat-elevation-z16,
.vex-theme-green.light .mat-mdc-elevation-specific.mat-elevation-z16,
.vex-theme-green .light .mat-elevation-z16,
.vex-theme-green .light .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.vex-theme-green .mat-elevation-z17, .vex-theme-green .mat-mdc-elevation-specific.mat-elevation-z17,
.vex-theme-green.light .mat-elevation-z17,
.vex-theme-green.light .mat-mdc-elevation-specific.mat-elevation-z17,
.vex-theme-green .light .mat-elevation-z17,
.vex-theme-green .light .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.vex-theme-green .mat-elevation-z18, .vex-theme-green .mat-mdc-elevation-specific.mat-elevation-z18,
.vex-theme-green.light .mat-elevation-z18,
.vex-theme-green.light .mat-mdc-elevation-specific.mat-elevation-z18,
.vex-theme-green .light .mat-elevation-z18,
.vex-theme-green .light .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.vex-theme-green .mat-elevation-z19, .vex-theme-green .mat-mdc-elevation-specific.mat-elevation-z19,
.vex-theme-green.light .mat-elevation-z19,
.vex-theme-green.light .mat-mdc-elevation-specific.mat-elevation-z19,
.vex-theme-green .light .mat-elevation-z19,
.vex-theme-green .light .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.vex-theme-green .mat-elevation-z20, .vex-theme-green .mat-mdc-elevation-specific.mat-elevation-z20,
.vex-theme-green.light .mat-elevation-z20,
.vex-theme-green.light .mat-mdc-elevation-specific.mat-elevation-z20,
.vex-theme-green .light .mat-elevation-z20,
.vex-theme-green .light .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.vex-theme-green .mat-elevation-z21, .vex-theme-green .mat-mdc-elevation-specific.mat-elevation-z21,
.vex-theme-green.light .mat-elevation-z21,
.vex-theme-green.light .mat-mdc-elevation-specific.mat-elevation-z21,
.vex-theme-green .light .mat-elevation-z21,
.vex-theme-green .light .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.vex-theme-green .mat-elevation-z22, .vex-theme-green .mat-mdc-elevation-specific.mat-elevation-z22,
.vex-theme-green.light .mat-elevation-z22,
.vex-theme-green.light .mat-mdc-elevation-specific.mat-elevation-z22,
.vex-theme-green .light .mat-elevation-z22,
.vex-theme-green .light .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.vex-theme-green .mat-elevation-z23, .vex-theme-green .mat-mdc-elevation-specific.mat-elevation-z23,
.vex-theme-green.light .mat-elevation-z23,
.vex-theme-green.light .mat-mdc-elevation-specific.mat-elevation-z23,
.vex-theme-green .light .mat-elevation-z23,
.vex-theme-green .light .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.vex-theme-green .mat-elevation-z24, .vex-theme-green .mat-mdc-elevation-specific.mat-elevation-z24,
.vex-theme-green.light .mat-elevation-z24,
.vex-theme-green.light .mat-mdc-elevation-specific.mat-elevation-z24,
.vex-theme-green .light .mat-elevation-z24,
.vex-theme-green .light .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.vex-theme-green .mat-mdc-card,
.vex-theme-green.light .mat-mdc-card,
.vex-theme-green .light .mat-mdc-card {
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: #4b5563;
}
.vex-theme-green .mat-mdc-progress-bar,
.vex-theme-green.light .mat-mdc-progress-bar,
.vex-theme-green .light .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #16a34a;
  --mdc-linear-progress-track-color: rgba(22, 163, 74, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.vex-theme-green .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots,
.vex-theme-green.light .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots,
.vex-theme-green .light .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-color: rgba(22, 163, 74, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(22, 163, 74, 0.25));
}
@media (forced-colors: active) {
  .vex-theme-green .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots,
  .vex-theme-green.light .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots,
  .vex-theme-green .light .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .vex-theme-green .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots,
  .vex-theme-green.light .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots,
  .vex-theme-green .light .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(22, 163, 74, 0.25)'/%3E%3C/svg%3E");
  }
}
.vex-theme-green .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar,
.vex-theme-green.light .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar,
.vex-theme-green .light .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgba(22, 163, 74, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(22, 163, 74, 0.25));
}
.vex-theme-green .mat-mdc-progress-bar.mat-accent,
.vex-theme-green.light .mat-mdc-progress-bar.mat-accent,
.vex-theme-green .light .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #f59e0b;
  --mdc-linear-progress-track-color: rgba(245, 158, 11, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.vex-theme-green .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots,
.vex-theme-green.light .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots,
.vex-theme-green .light .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-color: rgba(245, 158, 11, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(245, 158, 11, 0.25));
}
@media (forced-colors: active) {
  .vex-theme-green .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots,
  .vex-theme-green.light .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots,
  .vex-theme-green .light .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .vex-theme-green .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots,
  .vex-theme-green.light .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots,
  .vex-theme-green .light .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(245, 158, 11, 0.25)'/%3E%3C/svg%3E");
  }
}
.vex-theme-green .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar,
.vex-theme-green.light .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar,
.vex-theme-green .light .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: rgba(245, 158, 11, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(245, 158, 11, 0.25));
}
.vex-theme-green .mat-mdc-progress-bar.mat-warn,
.vex-theme-green.light .mat-mdc-progress-bar.mat-warn,
.vex-theme-green .light .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #ef4444;
  --mdc-linear-progress-track-color: rgba(239, 68, 68, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.vex-theme-green .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots,
.vex-theme-green.light .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots,
.vex-theme-green .light .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-color: rgba(239, 68, 68, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(239, 68, 68, 0.25));
}
@media (forced-colors: active) {
  .vex-theme-green .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots,
  .vex-theme-green.light .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots,
  .vex-theme-green .light .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .vex-theme-green .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots,
  .vex-theme-green.light .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots,
  .vex-theme-green .light .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(239, 68, 68, 0.25)'/%3E%3C/svg%3E");
  }
}
.vex-theme-green .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar,
.vex-theme-green.light .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar,
.vex-theme-green .light .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: rgba(239, 68, 68, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(239, 68, 68, 0.25));
}
.vex-theme-green .mat-mdc-tooltip,
.vex-theme-green.light .mat-mdc-tooltip,
.vex-theme-green .light .mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #1f2937;
  --mdc-plain-tooltip-supporting-text-color: #fff;
}
.vex-theme-green, .vex-theme-green.light, .vex-theme-green .light {
  --mdc-filled-text-field-caret-color: #16a34a;
  --mdc-filled-text-field-focus-active-indicator-color: #16a34a;
  --mdc-filled-text-field-focus-label-text-color: rgba(22, 163, 74, 0.87);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-error-focus-label-text-color: #ef4444;
  --mdc-filled-text-field-error-label-text-color: #ef4444;
  --mdc-filled-text-field-error-caret-color: #ef4444;
  --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #ef4444;
  --mdc-filled-text-field-error-focus-active-indicator-color: #ef4444;
  --mdc-filled-text-field-error-hover-active-indicator-color: #ef4444;
  --mdc-outlined-text-field-caret-color: #16a34a;
  --mdc-outlined-text-field-focus-outline-color: #16a34a;
  --mdc-outlined-text-field-focus-label-text-color: rgba(22, 163, 74, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-error-caret-color: #ef4444;
  --mdc-outlined-text-field-error-focus-label-text-color: #ef4444;
  --mdc-outlined-text-field-error-label-text-color: #ef4444;
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #ef4444;
  --mdc-outlined-text-field-error-hover-outline-color: #ef4444;
  --mdc-outlined-text-field-error-outline-color: #ef4444;
  --mat-form-field-disabled-input-text-placeholder-color: rgba(0, 0, 0, 0.38);
}

.vex-theme-green .mat-mdc-form-field-error,
.vex-theme-green.light .mat-mdc-form-field-error,
.vex-theme-green .light .mat-mdc-form-field-error {
  color: var(--mdc-theme-error, #ef4444);
}
.vex-theme-green .mat-mdc-form-field-subscript-wrapper,
.vex-theme-green .mat-mdc-form-field-bottom-align::before,
.vex-theme-green.light .mat-mdc-form-field-subscript-wrapper,
.vex-theme-green.light .mat-mdc-form-field-bottom-align::before,
.vex-theme-green .light .mat-mdc-form-field-subscript-wrapper,
.vex-theme-green .light .mat-mdc-form-field-bottom-align::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mat-form-field-subscript-text-font);
  line-height: var(--mat-form-field-subscript-text-line-height);
  font-size: var(--mat-form-field-subscript-text-size);
  letter-spacing: var(--mat-form-field-subscript-text-tracking);
  font-weight: var(--mat-form-field-subscript-text-weight);
}
.vex-theme-green .mat-mdc-form-field-focus-overlay,
.vex-theme-green.light .mat-mdc-form-field-focus-overlay,
.vex-theme-green .light .mat-mdc-form-field-focus-overlay {
  background-color: rgba(0, 0, 0, 0.87);
}
.vex-theme-green .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay,
.vex-theme-green.light .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay,
.vex-theme-green .light .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0.04;
}
.vex-theme-green .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay,
.vex-theme-green.light .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay,
.vex-theme-green .light .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.12;
}
.vex-theme-green .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after,
.vex-theme-green.light .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after,
.vex-theme-green .light .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.vex-theme-green .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after,
.vex-theme-green.light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after,
.vex-theme-green .light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after {
  color: rgba(22, 163, 74, 0.87);
}
.vex-theme-green .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after,
.vex-theme-green.light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after,
.vex-theme-green .light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after {
  color: rgba(245, 158, 11, 0.87);
}
.vex-theme-green .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after,
.vex-theme-green.light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after,
.vex-theme-green .light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after {
  color: rgba(239, 68, 68, 0.87);
}
.vex-theme-green .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after,
.vex-theme-green.light .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after,
.vex-theme-green .light .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.vex-theme-green .mat-mdc-form-field.mat-accent,
.vex-theme-green.light .mat-mdc-form-field.mat-accent,
.vex-theme-green .light .mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #f59e0b;
  --mdc-filled-text-field-focus-active-indicator-color: #f59e0b;
  --mdc-filled-text-field-focus-label-text-color: rgba(245, 158, 11, 0.87);
  --mdc-outlined-text-field-caret-color: #f59e0b;
  --mdc-outlined-text-field-focus-outline-color: #f59e0b;
  --mdc-outlined-text-field-focus-label-text-color: rgba(245, 158, 11, 0.87);
}
.vex-theme-green .mat-mdc-form-field.mat-warn,
.vex-theme-green.light .mat-mdc-form-field.mat-warn,
.vex-theme-green .light .mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #ef4444;
  --mdc-filled-text-field-focus-active-indicator-color: #ef4444;
  --mdc-filled-text-field-focus-label-text-color: rgba(239, 68, 68, 0.87);
  --mdc-outlined-text-field-caret-color: #ef4444;
  --mdc-outlined-text-field-focus-outline-color: #ef4444;
  --mdc-outlined-text-field-focus-label-text-color: rgba(239, 68, 68, 0.87);
}
.vex-theme-green .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch,
.vex-theme-green.light .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch,
.vex-theme-green .light .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: 1px solid transparent;
}
.vex-theme-green [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch,
.vex-theme-green.light [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch,
.vex-theme-green .light [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none;
  border-right: 1px solid transparent;
}
.vex-theme-green, .vex-theme-green.light, .vex-theme-green .light {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(22, 163, 74, 0.87);
  --mat-select-invalid-arrow-color: rgba(239, 68, 68, 0.87);
}
.vex-theme-green .mat-mdc-form-field.mat-accent, .vex-theme-green.light .mat-mdc-form-field.mat-accent, .vex-theme-green .light .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(245, 158, 11, 0.87);
  --mat-select-invalid-arrow-color: rgba(239, 68, 68, 0.87);
}
.vex-theme-green .mat-mdc-form-field.mat-warn, .vex-theme-green.light .mat-mdc-form-field.mat-warn, .vex-theme-green .light .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(239, 68, 68, 0.87);
  --mat-select-invalid-arrow-color: rgba(239, 68, 68, 0.87);
}

.vex-theme-green, .vex-theme-green.light, .vex-theme-green .light {
  --mat-autocomplete-background-color: white;
}

.vex-theme-green .mat-mdc-dialog-container,
.vex-theme-green.light .mat-mdc-dialog-container,
.vex-theme-green .light .mat-mdc-dialog-container {
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}
.vex-theme-green .mat-mdc-standard-chip,
.vex-theme-green.light .mat-mdc-standard-chip,
.vex-theme-green .light .mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
}
.vex-theme-green .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .vex-theme-green .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary,
.vex-theme-green.light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary,
.vex-theme-green.light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary,
.vex-theme-green .light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary,
.vex-theme-green .light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #16a34a;
  --mdc-chip-elevated-disabled-container-color: #16a34a;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.vex-theme-green .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .vex-theme-green .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent,
.vex-theme-green.light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent,
.vex-theme-green.light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent,
.vex-theme-green .light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent,
.vex-theme-green .light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #f59e0b;
  --mdc-chip-elevated-disabled-container-color: #f59e0b;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.vex-theme-green .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .vex-theme-green .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn,
.vex-theme-green.light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn,
.vex-theme-green.light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn,
.vex-theme-green .light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn,
.vex-theme-green .light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #ef4444;
  --mdc-chip-elevated-disabled-container-color: #ef4444;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.vex-theme-green .mat-mdc-slide-toggle,
.vex-theme-green.light .mat-mdc-slide-toggle,
.vex-theme-green .light .mat-mdc-slide-toggle {
  --mdc-switch-selected-focus-state-layer-color: #16a34a;
  --mdc-switch-selected-handle-color: #16a34a;
  --mdc-switch-selected-hover-state-layer-color: #16a34a;
  --mdc-switch-selected-pressed-state-layer-color: #16a34a;
  --mdc-switch-selected-focus-handle-color: #14532d;
  --mdc-switch-selected-hover-handle-color: #14532d;
  --mdc-switch-selected-pressed-handle-color: #14532d;
  --mdc-switch-selected-focus-track-color: #86efac;
  --mdc-switch-selected-hover-track-color: #86efac;
  --mdc-switch-selected-pressed-track-color: #86efac;
  --mdc-switch-selected-track-color: #86efac;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
}
.vex-theme-green .mat-mdc-slide-toggle .mdc-form-field,
.vex-theme-green.light .mat-mdc-slide-toggle .mdc-form-field,
.vex-theme-green .light .mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.vex-theme-green .mat-mdc-slide-toggle .mdc-switch--disabled + label,
.vex-theme-green.light .mat-mdc-slide-toggle .mdc-switch--disabled + label,
.vex-theme-green .light .mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: #6b7280;
}
.vex-theme-green .mat-mdc-slide-toggle.mat-accent,
.vex-theme-green.light .mat-mdc-slide-toggle.mat-accent,
.vex-theme-green .light .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #d97706;
  --mdc-switch-selected-handle-color: #d97706;
  --mdc-switch-selected-hover-state-layer-color: #d97706;
  --mdc-switch-selected-pressed-state-layer-color: #d97706;
  --mdc-switch-selected-focus-handle-color: #78350f;
  --mdc-switch-selected-hover-handle-color: #78350f;
  --mdc-switch-selected-pressed-handle-color: #78350f;
  --mdc-switch-selected-focus-track-color: #fcd34d;
  --mdc-switch-selected-hover-track-color: #fcd34d;
  --mdc-switch-selected-pressed-track-color: #fcd34d;
  --mdc-switch-selected-track-color: #fcd34d;
}
.vex-theme-green .mat-mdc-slide-toggle.mat-warn,
.vex-theme-green.light .mat-mdc-slide-toggle.mat-warn,
.vex-theme-green .light .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #dc2626;
  --mdc-switch-selected-handle-color: #dc2626;
  --mdc-switch-selected-hover-state-layer-color: #dc2626;
  --mdc-switch-selected-pressed-state-layer-color: #dc2626;
  --mdc-switch-selected-focus-handle-color: #7f1d1d;
  --mdc-switch-selected-hover-handle-color: #7f1d1d;
  --mdc-switch-selected-pressed-handle-color: #7f1d1d;
  --mdc-switch-selected-focus-track-color: #fca5a5;
  --mdc-switch-selected-hover-track-color: #fca5a5;
  --mdc-switch-selected-pressed-track-color: #fca5a5;
  --mdc-switch-selected-track-color: #fca5a5;
}
.vex-theme-green .mat-mdc-radio-button .mdc-form-field,
.vex-theme-green.light .mat-mdc-radio-button .mdc-form-field,
.vex-theme-green .light .mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.vex-theme-green .mat-mdc-radio-button.mat-primary,
.vex-theme-green.light .mat-mdc-radio-button.mat-primary,
.vex-theme-green .light .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #16a34a;
  --mdc-radio-selected-hover-icon-color: #16a34a;
  --mdc-radio-selected-icon-color: #16a34a;
  --mdc-radio-selected-pressed-icon-color: #16a34a;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #16a34a;
  --mat-radio-disabled-label-color: #6b7280;
}
.vex-theme-green .mat-mdc-radio-button.mat-accent,
.vex-theme-green.light .mat-mdc-radio-button.mat-accent,
.vex-theme-green .light .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f59e0b;
  --mdc-radio-selected-hover-icon-color: #f59e0b;
  --mdc-radio-selected-icon-color: #f59e0b;
  --mdc-radio-selected-pressed-icon-color: #f59e0b;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #f59e0b;
  --mat-radio-disabled-label-color: #6b7280;
}
.vex-theme-green .mat-mdc-radio-button.mat-warn,
.vex-theme-green.light .mat-mdc-radio-button.mat-warn,
.vex-theme-green .light .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #ef4444;
  --mdc-radio-selected-hover-icon-color: #ef4444;
  --mdc-radio-selected-icon-color: #ef4444;
  --mdc-radio-selected-pressed-icon-color: #ef4444;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #ef4444;
  --mat-radio-disabled-label-color: #6b7280;
}
.vex-theme-green .mat-mdc-slider,
.vex-theme-green.light .mat-mdc-slider,
.vex-theme-green .light .mat-mdc-slider {
  --mdc-slider-label-container-color: black;
  --mdc-slider-label-label-text-color: white;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-mdc-slider-value-indicator-opacity: 0.6;
}
.vex-theme-green .mat-mdc-slider.mat-primary,
.vex-theme-green.light .mat-mdc-slider.mat-primary,
.vex-theme-green .light .mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #16a34a;
  --mdc-slider-focus-handle-color: #16a34a;
  --mdc-slider-hover-handle-color: #16a34a;
  --mdc-slider-active-track-color: #16a34a;
  --mdc-slider-inactive-track-color: #16a34a;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #16a34a;
  --mat-mdc-slider-ripple-color: #16a34a;
  --mat-mdc-slider-hover-ripple-color: rgba(22, 163, 74, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(22, 163, 74, 0.2);
}
.vex-theme-green .mat-mdc-slider.mat-accent,
.vex-theme-green.light .mat-mdc-slider.mat-accent,
.vex-theme-green .light .mat-mdc-slider.mat-accent {
  --mdc-slider-handle-color: #f59e0b;
  --mdc-slider-focus-handle-color: #f59e0b;
  --mdc-slider-hover-handle-color: #f59e0b;
  --mdc-slider-active-track-color: #f59e0b;
  --mdc-slider-inactive-track-color: #f59e0b;
  --mdc-slider-with-tick-marks-active-container-color: #000;
  --mdc-slider-with-tick-marks-inactive-container-color: #f59e0b;
  --mat-mdc-slider-ripple-color: #f59e0b;
  --mat-mdc-slider-hover-ripple-color: rgba(245, 158, 11, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(245, 158, 11, 0.2);
}
.vex-theme-green .mat-mdc-slider.mat-warn,
.vex-theme-green.light .mat-mdc-slider.mat-warn,
.vex-theme-green .light .mat-mdc-slider.mat-warn {
  --mdc-slider-handle-color: #ef4444;
  --mdc-slider-focus-handle-color: #ef4444;
  --mdc-slider-hover-handle-color: #ef4444;
  --mdc-slider-active-track-color: #ef4444;
  --mdc-slider-inactive-track-color: #ef4444;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #ef4444;
  --mat-mdc-slider-ripple-color: #ef4444;
  --mat-mdc-slider-hover-ripple-color: rgba(239, 68, 68, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(239, 68, 68, 0.2);
}
.vex-theme-green, .vex-theme-green.light, .vex-theme-green .light {
  --mat-menu-item-label-text-color: #030712;
  --mat-menu-item-icon-color: #030712;
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
}

.vex-theme-green .mat-mdc-list-base,
.vex-theme-green.light .mat-mdc-list-base,
.vex-theme-green .light .mat-mdc-list-base {
  --mdc-list-list-item-label-text-color: #030712;
  --mdc-list-list-item-supporting-text-color: #4b5563;
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: #6b7280;
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: #030712;
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: #030712;
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
}
.vex-theme-green .mdc-list-item__start,
.vex-theme-green .mdc-list-item__end,
.vex-theme-green.light .mdc-list-item__start,
.vex-theme-green.light .mdc-list-item__end,
.vex-theme-green .light .mdc-list-item__start,
.vex-theme-green .light .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #16a34a;
  --mdc-radio-selected-hover-icon-color: #16a34a;
  --mdc-radio-selected-icon-color: #16a34a;
  --mdc-radio-selected-pressed-icon-color: #16a34a;
}
.vex-theme-green .mat-accent .mdc-list-item__start,
.vex-theme-green .mat-accent .mdc-list-item__end,
.vex-theme-green.light .mat-accent .mdc-list-item__start,
.vex-theme-green.light .mat-accent .mdc-list-item__end,
.vex-theme-green .light .mat-accent .mdc-list-item__start,
.vex-theme-green .light .mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f59e0b;
  --mdc-radio-selected-hover-icon-color: #f59e0b;
  --mdc-radio-selected-icon-color: #f59e0b;
  --mdc-radio-selected-pressed-icon-color: #f59e0b;
}
.vex-theme-green .mat-warn .mdc-list-item__start,
.vex-theme-green .mat-warn .mdc-list-item__end,
.vex-theme-green.light .mat-warn .mdc-list-item__start,
.vex-theme-green.light .mat-warn .mdc-list-item__end,
.vex-theme-green .light .mat-warn .mdc-list-item__start,
.vex-theme-green .light .mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #ef4444;
  --mdc-radio-selected-hover-icon-color: #ef4444;
  --mdc-radio-selected-icon-color: #ef4444;
  --mdc-radio-selected-pressed-icon-color: #ef4444;
}
.vex-theme-green .mat-mdc-list-option,
.vex-theme-green.light .mat-mdc-list-option,
.vex-theme-green .light .mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #16a34a;
  --mdc-checkbox-selected-hover-icon-color: #16a34a;
  --mdc-checkbox-selected-icon-color: #16a34a;
  --mdc-checkbox-selected-pressed-icon-color: #16a34a;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #16a34a;
  --mdc-checkbox-selected-hover-state-layer-color: #16a34a;
  --mdc-checkbox-selected-pressed-state-layer-color: #16a34a;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.vex-theme-green .mat-mdc-list-option.mat-accent,
.vex-theme-green.light .mat-mdc-list-option.mat-accent,
.vex-theme-green .light .mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #f59e0b;
  --mdc-checkbox-selected-hover-icon-color: #f59e0b;
  --mdc-checkbox-selected-icon-color: #f59e0b;
  --mdc-checkbox-selected-pressed-icon-color: #f59e0b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f59e0b;
  --mdc-checkbox-selected-hover-state-layer-color: #f59e0b;
  --mdc-checkbox-selected-pressed-state-layer-color: #f59e0b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.vex-theme-green .mat-mdc-list-option.mat-warn,
.vex-theme-green.light .mat-mdc-list-option.mat-warn,
.vex-theme-green .light .mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #ef4444;
  --mdc-checkbox-selected-hover-icon-color: #ef4444;
  --mdc-checkbox-selected-icon-color: #ef4444;
  --mdc-checkbox-selected-pressed-icon-color: #ef4444;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #ef4444;
  --mdc-checkbox-selected-hover-state-layer-color: #ef4444;
  --mdc-checkbox-selected-pressed-state-layer-color: #ef4444;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.vex-theme-green .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.vex-theme-green .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,
.vex-theme-green.light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.vex-theme-green.light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,
.vex-theme-green .light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.vex-theme-green .light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #16a34a;
}
.vex-theme-green .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.vex-theme-green .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.vex-theme-green.light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.vex-theme-green.light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.vex-theme-green .light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.vex-theme-green .light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #16a34a;
}
.vex-theme-green .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.vex-theme-green .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.vex-theme-green .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end,
.vex-theme-green.light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.vex-theme-green.light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.vex-theme-green.light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end,
.vex-theme-green .light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.vex-theme-green .light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.vex-theme-green .light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.vex-theme-green, .vex-theme-green.light, .vex-theme-green .light {
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
}

.vex-theme-green .mat-mdc-tab-group, .vex-theme-green .mat-mdc-tab-nav-bar,
.vex-theme-green.light .mat-mdc-tab-group,
.vex-theme-green.light .mat-mdc-tab-nav-bar,
.vex-theme-green .light .mat-mdc-tab-group,
.vex-theme-green .light .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #16a34a;
  --mat-tab-header-disabled-ripple-color: #6b7280;
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #16a34a;
  --mat-tab-header-active-ripple-color: #16a34a;
  --mat-tab-header-inactive-ripple-color: #16a34a;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #16a34a;
  --mat-tab-header-active-hover-label-text-color: #16a34a;
  --mat-tab-header-active-focus-indicator-color: #16a34a;
  --mat-tab-header-active-hover-indicator-color: #16a34a;
}
.vex-theme-green .mat-mdc-tab-group.mat-accent, .vex-theme-green .mat-mdc-tab-nav-bar.mat-accent,
.vex-theme-green.light .mat-mdc-tab-group.mat-accent,
.vex-theme-green.light .mat-mdc-tab-nav-bar.mat-accent,
.vex-theme-green .light .mat-mdc-tab-group.mat-accent,
.vex-theme-green .light .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #f59e0b;
  --mat-tab-header-disabled-ripple-color: #6b7280;
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #f59e0b;
  --mat-tab-header-active-ripple-color: #f59e0b;
  --mat-tab-header-inactive-ripple-color: #f59e0b;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #f59e0b;
  --mat-tab-header-active-hover-label-text-color: #f59e0b;
  --mat-tab-header-active-focus-indicator-color: #f59e0b;
  --mat-tab-header-active-hover-indicator-color: #f59e0b;
}
.vex-theme-green .mat-mdc-tab-group.mat-warn, .vex-theme-green .mat-mdc-tab-nav-bar.mat-warn,
.vex-theme-green.light .mat-mdc-tab-group.mat-warn,
.vex-theme-green.light .mat-mdc-tab-nav-bar.mat-warn,
.vex-theme-green .light .mat-mdc-tab-group.mat-warn,
.vex-theme-green .light .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #ef4444;
  --mat-tab-header-disabled-ripple-color: #6b7280;
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #ef4444;
  --mat-tab-header-active-ripple-color: #ef4444;
  --mat-tab-header-inactive-ripple-color: #ef4444;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #ef4444;
  --mat-tab-header-active-hover-label-text-color: #ef4444;
  --mat-tab-header-active-focus-indicator-color: #ef4444;
  --mat-tab-header-active-hover-indicator-color: #ef4444;
}
.vex-theme-green .mat-mdc-tab-group.mat-background-primary, .vex-theme-green .mat-mdc-tab-nav-bar.mat-background-primary,
.vex-theme-green.light .mat-mdc-tab-group.mat-background-primary,
.vex-theme-green.light .mat-mdc-tab-nav-bar.mat-background-primary,
.vex-theme-green .light .mat-mdc-tab-group.mat-background-primary,
.vex-theme-green .light .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #16a34a;
  --mat-tab-header-with-background-foreground-color: white;
}
.vex-theme-green .mat-mdc-tab-group.mat-background-accent, .vex-theme-green .mat-mdc-tab-nav-bar.mat-background-accent,
.vex-theme-green.light .mat-mdc-tab-group.mat-background-accent,
.vex-theme-green.light .mat-mdc-tab-nav-bar.mat-background-accent,
.vex-theme-green .light .mat-mdc-tab-group.mat-background-accent,
.vex-theme-green .light .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #f59e0b;
  --mat-tab-header-with-background-foreground-color: white;
}
.vex-theme-green .mat-mdc-tab-group.mat-background-warn, .vex-theme-green .mat-mdc-tab-nav-bar.mat-background-warn,
.vex-theme-green.light .mat-mdc-tab-group.mat-background-warn,
.vex-theme-green.light .mat-mdc-tab-nav-bar.mat-background-warn,
.vex-theme-green .light .mat-mdc-tab-group.mat-background-warn,
.vex-theme-green .light .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #ef4444;
  --mat-tab-header-with-background-foreground-color: white;
}
.vex-theme-green, .vex-theme-green.light, .vex-theme-green .light {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #f59e0b;
  --mdc-checkbox-selected-hover-icon-color: #f59e0b;
  --mdc-checkbox-selected-icon-color: #f59e0b;
  --mdc-checkbox-selected-pressed-icon-color: #f59e0b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f59e0b;
  --mdc-checkbox-selected-hover-state-layer-color: #f59e0b;
  --mdc-checkbox-selected-pressed-state-layer-color: #f59e0b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.vex-theme-green .mat-mdc-checkbox.mat-primary,
.vex-theme-green.light .mat-mdc-checkbox.mat-primary,
.vex-theme-green .light .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #16a34a;
  --mdc-checkbox-selected-hover-icon-color: #16a34a;
  --mdc-checkbox-selected-icon-color: #16a34a;
  --mdc-checkbox-selected-pressed-icon-color: #16a34a;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #16a34a;
  --mdc-checkbox-selected-hover-state-layer-color: #16a34a;
  --mdc-checkbox-selected-pressed-state-layer-color: #16a34a;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.vex-theme-green .mat-mdc-checkbox.mat-warn,
.vex-theme-green.light .mat-mdc-checkbox.mat-warn,
.vex-theme-green .light .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #ef4444;
  --mdc-checkbox-selected-hover-icon-color: #ef4444;
  --mdc-checkbox-selected-icon-color: #ef4444;
  --mdc-checkbox-selected-pressed-icon-color: #ef4444;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #ef4444;
  --mdc-checkbox-selected-hover-state-layer-color: #ef4444;
  --mdc-checkbox-selected-pressed-state-layer-color: #ef4444;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.vex-theme-green .mat-mdc-checkbox .mdc-form-field,
.vex-theme-green.light .mat-mdc-checkbox .mdc-form-field,
.vex-theme-green .light .mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.vex-theme-green .mat-mdc-checkbox.mat-mdc-checkbox-disabled label,
.vex-theme-green.light .mat-mdc-checkbox.mat-mdc-checkbox-disabled label,
.vex-theme-green .light .mat-mdc-checkbox.mat-mdc-checkbox-disabled label {
  color: #6b7280;
}
.vex-theme-green .mat-mdc-button.mat-unthemed,
.vex-theme-green.light .mat-mdc-button.mat-unthemed,
.vex-theme-green .light .mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #000;
}
.vex-theme-green .mat-mdc-button.mat-primary,
.vex-theme-green.light .mat-mdc-button.mat-primary,
.vex-theme-green .light .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #16a34a;
}
.vex-theme-green .mat-mdc-button.mat-accent,
.vex-theme-green.light .mat-mdc-button.mat-accent,
.vex-theme-green .light .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #f59e0b;
}
.vex-theme-green .mat-mdc-button.mat-warn,
.vex-theme-green.light .mat-mdc-button.mat-warn,
.vex-theme-green .light .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #ef4444;
}
.vex-theme-green .mat-mdc-button[disabled][disabled],
.vex-theme-green.light .mat-mdc-button[disabled][disabled],
.vex-theme-green .light .mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-text-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.vex-theme-green .mat-mdc-unelevated-button.mat-unthemed,
.vex-theme-green.light .mat-mdc-unelevated-button.mat-unthemed,
.vex-theme-green .light .mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #fff;
  --mdc-filled-button-label-text-color: #000;
}
.vex-theme-green .mat-mdc-unelevated-button.mat-primary,
.vex-theme-green.light .mat-mdc-unelevated-button.mat-primary,
.vex-theme-green .light .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #16a34a;
  --mdc-filled-button-label-text-color: #fff;
}
.vex-theme-green .mat-mdc-unelevated-button.mat-accent,
.vex-theme-green.light .mat-mdc-unelevated-button.mat-accent,
.vex-theme-green .light .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #f59e0b;
  --mdc-filled-button-label-text-color: #000;
}
.vex-theme-green .mat-mdc-unelevated-button.mat-warn,
.vex-theme-green.light .mat-mdc-unelevated-button.mat-warn,
.vex-theme-green .light .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #ef4444;
  --mdc-filled-button-label-text-color: #fff;
}
.vex-theme-green .mat-mdc-unelevated-button[disabled][disabled],
.vex-theme-green.light .mat-mdc-unelevated-button[disabled][disabled],
.vex-theme-green .light .mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.vex-theme-green .mat-mdc-raised-button.mat-unthemed,
.vex-theme-green.light .mat-mdc-raised-button.mat-unthemed,
.vex-theme-green .light .mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #fff;
  --mdc-protected-button-label-text-color: #000;
}
.vex-theme-green .mat-mdc-raised-button.mat-primary,
.vex-theme-green.light .mat-mdc-raised-button.mat-primary,
.vex-theme-green .light .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #16a34a;
  --mdc-protected-button-label-text-color: #fff;
}
.vex-theme-green .mat-mdc-raised-button.mat-accent,
.vex-theme-green.light .mat-mdc-raised-button.mat-accent,
.vex-theme-green .light .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #f59e0b;
  --mdc-protected-button-label-text-color: #000;
}
.vex-theme-green .mat-mdc-raised-button.mat-warn,
.vex-theme-green.light .mat-mdc-raised-button.mat-warn,
.vex-theme-green .light .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #ef4444;
  --mdc-protected-button-label-text-color: #fff;
}
.vex-theme-green .mat-mdc-raised-button[disabled][disabled],
.vex-theme-green.light .mat-mdc-raised-button[disabled][disabled],
.vex-theme-green .light .mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation: 0;
}
.vex-theme-green .mat-mdc-outlined-button,
.vex-theme-green.light .mat-mdc-outlined-button,
.vex-theme-green .light .mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.vex-theme-green .mat-mdc-outlined-button.mat-unthemed,
.vex-theme-green.light .mat-mdc-outlined-button.mat-unthemed,
.vex-theme-green .light .mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #000;
}
.vex-theme-green .mat-mdc-outlined-button.mat-primary,
.vex-theme-green.light .mat-mdc-outlined-button.mat-primary,
.vex-theme-green .light .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #16a34a;
}
.vex-theme-green .mat-mdc-outlined-button.mat-accent,
.vex-theme-green.light .mat-mdc-outlined-button.mat-accent,
.vex-theme-green .light .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #f59e0b;
}
.vex-theme-green .mat-mdc-outlined-button.mat-warn,
.vex-theme-green.light .mat-mdc-outlined-button.mat-warn,
.vex-theme-green .light .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #ef4444;
}
.vex-theme-green .mat-mdc-outlined-button[disabled][disabled],
.vex-theme-green.light .mat-mdc-outlined-button[disabled][disabled],
.vex-theme-green .light .mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
}
.vex-theme-green .mat-mdc-button, .vex-theme-green .mat-mdc-outlined-button,
.vex-theme-green.light .mat-mdc-button,
.vex-theme-green.light .mat-mdc-outlined-button,
.vex-theme-green .light .mat-mdc-button,
.vex-theme-green .light .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.vex-theme-green .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .vex-theme-green .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-green.light .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-green.light .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-green .light .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-green .light .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.vex-theme-green .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-green .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-green .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-green .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-green.light .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-green.light .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-green.light .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-green.light .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-green .light .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-green .light .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-green .light .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-green .light .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.vex-theme-green .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .vex-theme-green .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-green.light .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-green.light .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-green .light .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-green .light .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.vex-theme-green .mat-mdc-button.mat-primary, .vex-theme-green .mat-mdc-outlined-button.mat-primary,
.vex-theme-green.light .mat-mdc-button.mat-primary,
.vex-theme-green.light .mat-mdc-outlined-button.mat-primary,
.vex-theme-green .light .mat-mdc-button.mat-primary,
.vex-theme-green .light .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #16a34a;
  --mat-mdc-button-ripple-color: rgba(22, 163, 74, 0.1);
}
.vex-theme-green .mat-mdc-button.mat-accent, .vex-theme-green .mat-mdc-outlined-button.mat-accent,
.vex-theme-green.light .mat-mdc-button.mat-accent,
.vex-theme-green.light .mat-mdc-outlined-button.mat-accent,
.vex-theme-green .light .mat-mdc-button.mat-accent,
.vex-theme-green .light .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #f59e0b;
  --mat-mdc-button-ripple-color: rgba(245, 158, 11, 0.1);
}
.vex-theme-green .mat-mdc-button.mat-warn, .vex-theme-green .mat-mdc-outlined-button.mat-warn,
.vex-theme-green.light .mat-mdc-button.mat-warn,
.vex-theme-green.light .mat-mdc-outlined-button.mat-warn,
.vex-theme-green .light .mat-mdc-button.mat-warn,
.vex-theme-green .light .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #ef4444;
  --mat-mdc-button-ripple-color: rgba(239, 68, 68, 0.1);
}
.vex-theme-green .mat-mdc-raised-button, .vex-theme-green .mat-mdc-unelevated-button,
.vex-theme-green.light .mat-mdc-raised-button,
.vex-theme-green.light .mat-mdc-unelevated-button,
.vex-theme-green .light .mat-mdc-raised-button,
.vex-theme-green .light .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.vex-theme-green .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .vex-theme-green .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-green.light .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-green.light .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-green .light .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-green .light .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.vex-theme-green .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-green .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-green .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-green .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-green.light .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-green.light .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-green.light .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-green.light .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-green .light .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-green .light .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-green .light .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-green .light .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.vex-theme-green .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .vex-theme-green .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-green.light .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-green.light .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-green .light .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-green .light .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.vex-theme-green .mat-mdc-raised-button.mat-primary, .vex-theme-green .mat-mdc-unelevated-button.mat-primary,
.vex-theme-green.light .mat-mdc-raised-button.mat-primary,
.vex-theme-green.light .mat-mdc-unelevated-button.mat-primary,
.vex-theme-green .light .mat-mdc-raised-button.mat-primary,
.vex-theme-green .light .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.vex-theme-green .mat-mdc-raised-button.mat-accent, .vex-theme-green .mat-mdc-unelevated-button.mat-accent,
.vex-theme-green.light .mat-mdc-raised-button.mat-accent,
.vex-theme-green.light .mat-mdc-unelevated-button.mat-accent,
.vex-theme-green .light .mat-mdc-raised-button.mat-accent,
.vex-theme-green .light .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.vex-theme-green .mat-mdc-raised-button.mat-warn, .vex-theme-green .mat-mdc-unelevated-button.mat-warn,
.vex-theme-green.light .mat-mdc-raised-button.mat-warn,
.vex-theme-green.light .mat-mdc-unelevated-button.mat-warn,
.vex-theme-green .light .mat-mdc-raised-button.mat-warn,
.vex-theme-green .light .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.vex-theme-green .mat-mdc-icon-button,
.vex-theme-green.light .mat-mdc-icon-button,
.vex-theme-green .light .mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mdc-icon-button-icon-color: inherit;
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.vex-theme-green .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-green.light .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-green .light .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.vex-theme-green .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-green .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-green.light .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-green.light .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-green .light .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-green .light .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.vex-theme-green .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-green.light .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-green .light .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.vex-theme-green .mat-mdc-icon-button.mat-primary,
.vex-theme-green.light .mat-mdc-icon-button.mat-primary,
.vex-theme-green .light .mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #6200ee;
  --mat-mdc-button-ripple-color: rgba(98, 0, 238, 0.1);
}
.vex-theme-green .mat-mdc-icon-button.mat-accent,
.vex-theme-green.light .mat-mdc-icon-button.mat-accent,
.vex-theme-green .light .mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #018786;
  --mat-mdc-button-ripple-color: rgba(1, 135, 134, 0.1);
}
.vex-theme-green .mat-mdc-icon-button.mat-warn,
.vex-theme-green.light .mat-mdc-icon-button.mat-warn,
.vex-theme-green .light .mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #b00020;
  --mat-mdc-button-ripple-color: rgba(176, 0, 32, 0.1);
}
.vex-theme-green .mat-mdc-icon-button.mat-primary,
.vex-theme-green.light .mat-mdc-icon-button.mat-primary,
.vex-theme-green .light .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #16a34a;
  --mat-mdc-button-persistent-ripple-color: #16a34a;
  --mat-mdc-button-ripple-color: rgba(22, 163, 74, 0.1);
}
.vex-theme-green .mat-mdc-icon-button.mat-accent,
.vex-theme-green.light .mat-mdc-icon-button.mat-accent,
.vex-theme-green .light .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #f59e0b;
  --mat-mdc-button-persistent-ripple-color: #f59e0b;
  --mat-mdc-button-ripple-color: rgba(245, 158, 11, 0.1);
}
.vex-theme-green .mat-mdc-icon-button.mat-warn,
.vex-theme-green.light .mat-mdc-icon-button.mat-warn,
.vex-theme-green .light .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #ef4444;
  --mat-mdc-button-persistent-ripple-color: #ef4444;
  --mat-mdc-button-ripple-color: rgba(239, 68, 68, 0.1);
}
.vex-theme-green .mat-mdc-icon-button[disabled][disabled],
.vex-theme-green.light .mat-mdc-icon-button[disabled][disabled],
.vex-theme-green .light .mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
}
.vex-theme-green .mat-mdc-fab,
.vex-theme-green .mat-mdc-mini-fab,
.vex-theme-green.light .mat-mdc-fab,
.vex-theme-green.light .mat-mdc-mini-fab,
.vex-theme-green .light .mat-mdc-fab,
.vex-theme-green .light .mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.vex-theme-green .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-green .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-green.light .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-green.light .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-green .light .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-green .light .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.vex-theme-green .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-green .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-green .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-green .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-green.light .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-green.light .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-green.light .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-green.light .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-green .light .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-green .light .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-green .light .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-green .light .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.vex-theme-green .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-green .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-green.light .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-green.light .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-green .light .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-green .light .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.vex-theme-green .mat-mdc-fab.mat-primary,
.vex-theme-green .mat-mdc-mini-fab.mat-primary,
.vex-theme-green.light .mat-mdc-fab.mat-primary,
.vex-theme-green.light .mat-mdc-mini-fab.mat-primary,
.vex-theme-green .light .mat-mdc-fab.mat-primary,
.vex-theme-green .light .mat-mdc-mini-fab.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.vex-theme-green .mat-mdc-fab.mat-accent,
.vex-theme-green .mat-mdc-mini-fab.mat-accent,
.vex-theme-green.light .mat-mdc-fab.mat-accent,
.vex-theme-green.light .mat-mdc-mini-fab.mat-accent,
.vex-theme-green .light .mat-mdc-fab.mat-accent,
.vex-theme-green .light .mat-mdc-mini-fab.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.vex-theme-green .mat-mdc-fab.mat-warn,
.vex-theme-green .mat-mdc-mini-fab.mat-warn,
.vex-theme-green.light .mat-mdc-fab.mat-warn,
.vex-theme-green.light .mat-mdc-mini-fab.mat-warn,
.vex-theme-green .light .mat-mdc-fab.mat-warn,
.vex-theme-green .light .mat-mdc-mini-fab.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.vex-theme-green .mat-mdc-fab[disabled][disabled],
.vex-theme-green .mat-mdc-mini-fab[disabled][disabled],
.vex-theme-green.light .mat-mdc-fab[disabled][disabled],
.vex-theme-green.light .mat-mdc-mini-fab[disabled][disabled],
.vex-theme-green .light .mat-mdc-fab[disabled][disabled],
.vex-theme-green .light .mat-mdc-mini-fab[disabled][disabled] {
  --mdc-fab-container-color: rgba(0, 0, 0, 0.12);
  --mdc-fab-icon-color: rgba(0, 0, 0, 0.38);
  --mat-mdc-fab-color: rgba(0, 0, 0, 0.38);
}
.vex-theme-green .mat-mdc-fab.mat-unthemed,
.vex-theme-green .mat-mdc-mini-fab.mat-unthemed,
.vex-theme-green.light .mat-mdc-fab.mat-unthemed,
.vex-theme-green.light .mat-mdc-mini-fab.mat-unthemed,
.vex-theme-green .light .mat-mdc-fab.mat-unthemed,
.vex-theme-green .light .mat-mdc-mini-fab.mat-unthemed {
  --mdc-fab-container-color: white;
  --mdc-fab-icon-color: black;
  --mat-mdc-fab-color: #000;
}
.vex-theme-green .mat-mdc-fab.mat-primary,
.vex-theme-green .mat-mdc-mini-fab.mat-primary,
.vex-theme-green.light .mat-mdc-fab.mat-primary,
.vex-theme-green.light .mat-mdc-mini-fab.mat-primary,
.vex-theme-green .light .mat-mdc-fab.mat-primary,
.vex-theme-green .light .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #16a34a;
  --mdc-fab-icon-color: white;
  --mat-mdc-fab-color: #fff;
}
.vex-theme-green .mat-mdc-fab.mat-accent,
.vex-theme-green .mat-mdc-mini-fab.mat-accent,
.vex-theme-green.light .mat-mdc-fab.mat-accent,
.vex-theme-green.light .mat-mdc-mini-fab.mat-accent,
.vex-theme-green .light .mat-mdc-fab.mat-accent,
.vex-theme-green .light .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: #f59e0b;
  --mdc-fab-icon-color: black;
  --mat-mdc-fab-color: #000;
}
.vex-theme-green .mat-mdc-fab.mat-warn,
.vex-theme-green .mat-mdc-mini-fab.mat-warn,
.vex-theme-green.light .mat-mdc-fab.mat-warn,
.vex-theme-green.light .mat-mdc-mini-fab.mat-warn,
.vex-theme-green .light .mat-mdc-fab.mat-warn,
.vex-theme-green .light .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: #ef4444;
  --mdc-fab-icon-color: white;
  --mat-mdc-fab-color: #fff;
}
.vex-theme-green .mat-mdc-snack-bar-container,
.vex-theme-green.light .mat-mdc-snack-bar-container,
.vex-theme-green .light .mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #f59e0b;
}
.vex-theme-green .mat-mdc-progress-spinner,
.vex-theme-green.light .mat-mdc-progress-spinner,
.vex-theme-green .light .mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #16a34a;
}
.vex-theme-green .mat-mdc-progress-spinner.mat-accent,
.vex-theme-green.light .mat-mdc-progress-spinner.mat-accent,
.vex-theme-green .light .mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: #f59e0b;
}
.vex-theme-green .mat-mdc-progress-spinner.mat-warn,
.vex-theme-green.light .mat-mdc-progress-spinner.mat-warn,
.vex-theme-green .light .mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: #ef4444;
}
.vex-theme-green, .vex-theme-green.light, .vex-theme-green .light {
  --mat-badge-background-color: #16a34a;
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: #9ca3af;
  --mat-badge-disabled-state-text-color: #6b7280;
}

.vex-theme-green .mat-badge-accent,
.vex-theme-green.light .mat-badge-accent,
.vex-theme-green .light .mat-badge-accent {
  --mat-badge-background-color: #f59e0b;
  --mat-badge-text-color: white;
}
.vex-theme-green .mat-badge-warn,
.vex-theme-green.light .mat-badge-warn,
.vex-theme-green .light .mat-badge-warn {
  --mat-badge-background-color: #ef4444;
  --mat-badge-text-color: white;
}
.vex-theme-green, .vex-theme-green.light, .vex-theme-green .light {
  --mat-bottom-sheet-container-text-color: #030712;
  --mat-bottom-sheet-container-background-color: white;
}

.vex-theme-green, .vex-theme-green.light, .vex-theme-green .light {
  --mat-legacy-button-toggle-text-color: #6b7280;
  --mat-legacy-button-toggle-state-layer-color: #e5e7eb;
  --mat-legacy-button-toggle-selected-state-text-color: #4b5563;
  --mat-legacy-button-toggle-selected-state-background-color: #d1d5db;
  --mat-legacy-button-toggle-disabled-state-text-color: #9ca3af;
  --mat-legacy-button-toggle-disabled-state-background-color: #e5e7eb;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #9ca3af;
  --mat-standard-button-toggle-text-color: #030712;
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: #e5e7eb;
  --mat-standard-button-toggle-selected-state-background-color: #d1d5db;
  --mat-standard-button-toggle-selected-state-text-color: #030712;
  --mat-standard-button-toggle-disabled-state-text-color: #9ca3af;
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: #030712;
  --mat-standard-button-toggle-disabled-selected-state-background-color: #9ca3af;
  --mat-standard-button-toggle-divider-color: #f0f0f0;
}

.vex-theme-green, .vex-theme-green.light, .vex-theme-green .light {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #16a34a;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(22, 163, 74, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(22, 163, 74, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(22, 163, 74, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #16a34a;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(22, 163, 74, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: #1f2937;
  --mat-datepicker-calendar-body-label-text-color: #4b5563;
  --mat-datepicker-calendar-period-button-icon-color: #1f2937;
  --mat-datepicker-calendar-navigation-button-icon-color: #1f2937;
  --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.06);
  --mat-datepicker-calendar-header-text-color: #4b5563;
  --mat-datepicker-calendar-date-today-outline-color: #6b7280;
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(107, 114, 128, 0.8);
  --mat-datepicker-calendar-date-text-color: #030712;
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: #6b7280;
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, 0.12);
  --mat-datepicker-range-input-separator-color: #030712;
  --mat-datepicker-range-input-disabled-state-separator-color: #6b7280;
  --mat-datepicker-range-input-disabled-state-text-color: #6b7280;
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: #030712;
}

.vex-theme-green .mat-datepicker-content.mat-accent,
.vex-theme-green.light .mat-datepicker-content.mat-accent,
.vex-theme-green .light .mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #f59e0b;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(245, 158, 11, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(245, 158, 11, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(245, 158, 11, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(245, 158, 11, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.vex-theme-green .mat-datepicker-content.mat-warn,
.vex-theme-green.light .mat-datepicker-content.mat-warn,
.vex-theme-green .light .mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #ef4444;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(239, 68, 68, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(239, 68, 68, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(239, 68, 68, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(239, 68, 68, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.vex-theme-green .mat-datepicker-toggle-active.mat-accent,
.vex-theme-green.light .mat-datepicker-toggle-active.mat-accent,
.vex-theme-green .light .mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #f59e0b;
}
.vex-theme-green .mat-datepicker-toggle-active.mat-warn,
.vex-theme-green.light .mat-datepicker-toggle-active.mat-warn,
.vex-theme-green .light .mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #ef4444;
}
.vex-theme-green, .vex-theme-green.light, .vex-theme-green .light {
  --mat-divider-color: rgba(0, 0, 0, 0.06);
}

.vex-theme-green, .vex-theme-green.light, .vex-theme-green .light {
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: #030712;
  --mat-expansion-actions-divider-color: rgba(0, 0, 0, 0.06);
  --mat-expansion-header-hover-state-layer-color: rgba(17, 24, 39, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(17, 24, 39, 0.04);
  --mat-expansion-header-disabled-state-text-color: #9ca3af;
  --mat-expansion-header-text-color: #030712;
  --mat-expansion-header-description-color: #4b5563;
  --mat-expansion-header-indicator-color: #4b5563;
}

.vex-theme-green, .vex-theme-green.light, .vex-theme-green .light {
  --mat-icon-color: inherit;
}

.vex-theme-green .mat-icon.mat-primary,
.vex-theme-green.light .mat-icon.mat-primary,
.vex-theme-green .light .mat-icon.mat-primary {
  --mat-icon-color: #16a34a;
}
.vex-theme-green .mat-icon.mat-accent,
.vex-theme-green.light .mat-icon.mat-accent,
.vex-theme-green .light .mat-icon.mat-accent {
  --mat-icon-color: #f59e0b;
}
.vex-theme-green .mat-icon.mat-warn,
.vex-theme-green.light .mat-icon.mat-warn,
.vex-theme-green .light .mat-icon.mat-warn {
  --mat-icon-color: #ef4444;
}
.vex-theme-green, .vex-theme-green.light, .vex-theme-green .light {
  --mat-sidenav-container-divider-color: rgba(0, 0, 0, 0.06);
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: #030712;
  --mat-sidenav-content-background-color: #f3f4f6;
  --mat-sidenav-content-text-color: #030712;
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
}

.vex-theme-green, .vex-theme-green.light, .vex-theme-green .light {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #16a34a;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #16a34a;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #16a34a;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: rgba(0, 0, 0, 0.06);
  --mat-stepper-header-hover-state-layer-color: rgba(17, 24, 39, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(17, 24, 39, 0.04);
  --mat-stepper-header-label-text-color: #4b5563;
  --mat-stepper-header-optional-label-text-color: #4b5563;
  --mat-stepper-header-selected-state-label-text-color: #030712;
  --mat-stepper-header-error-state-label-text-color: #ef4444;
  --mat-stepper-header-icon-background-color: #4b5563;
  --mat-stepper-header-error-state-icon-foreground-color: #ef4444;
  --mat-stepper-header-error-state-icon-background-color: transparent;
}
.vex-theme-green .mat-step-header.mat-accent, .vex-theme-green.light .mat-step-header.mat-accent, .vex-theme-green .light .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #f59e0b;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #f59e0b;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #f59e0b;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.vex-theme-green .mat-step-header.mat-warn, .vex-theme-green.light .mat-step-header.mat-warn, .vex-theme-green .light .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #ef4444;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #ef4444;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #ef4444;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}

.vex-theme-green .mat-sort-header-arrow,
.vex-theme-green.light .mat-sort-header-arrow,
.vex-theme-green .light .mat-sort-header-arrow {
  color: #4b5563;
}
.vex-theme-green, .vex-theme-green.light, .vex-theme-green .light {
  --mat-toolbar-container-background-color: #e5e7eb;
  --mat-toolbar-container-text-color: #030712;
}

.vex-theme-green .mat-toolbar.mat-primary,
.vex-theme-green.light .mat-toolbar.mat-primary,
.vex-theme-green .light .mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #16a34a;
  --mat-toolbar-container-text-color: white;
}
.vex-theme-green .mat-toolbar.mat-accent,
.vex-theme-green.light .mat-toolbar.mat-accent,
.vex-theme-green .light .mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #f59e0b;
  --mat-toolbar-container-text-color: white;
}
.vex-theme-green .mat-toolbar.mat-warn,
.vex-theme-green.light .mat-toolbar.mat-warn,
.vex-theme-green .light .mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #ef4444;
  --mat-toolbar-container-text-color: white;
}
.vex-theme-green .mat-tree,
.vex-theme-green.light .mat-tree,
.vex-theme-green .light .mat-tree {
  background: white;
}
.vex-theme-green .mat-tree-node,
.vex-theme-green .mat-nested-tree-node,
.vex-theme-green.light .mat-tree-node,
.vex-theme-green.light .mat-nested-tree-node,
.vex-theme-green .light .mat-tree-node,
.vex-theme-green .light .mat-nested-tree-node {
  color: #030712;
}

/**
 * Override the theme foreground/background colors with the Angular Material colors
 */
.vex-theme-green.dark,
.vex-theme-green .dark {
  --mat-table-background-color: #1f2937;
  --mat-table-header-headline-color: white;
  --mat-table-row-item-label-text-color: white;
  --mat-table-row-item-outline-color: rgba(255, 255, 255, 0.12);
}
.vex-theme-green.dark .mat-ripple-element,
.vex-theme-green .dark .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.vex-theme-green.dark, .vex-theme-green .dark {
  --mat-option-selected-state-label-text-color: #22c55e;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
}

.vex-theme-green.dark .mat-accent,
.vex-theme-green .dark .mat-accent {
  --mat-option-selected-state-label-text-color: #f59e0b;
}
.vex-theme-green.dark .mat-warn,
.vex-theme-green .dark .mat-warn {
  --mat-option-selected-state-label-text-color: #ef4444;
}
.vex-theme-green.dark, .vex-theme-green .dark {
  --mat-optgroup-label-text-color: white;
}

.vex-theme-green.dark .mat-pseudo-checkbox-full,
.vex-theme-green .dark .mat-pseudo-checkbox-full {
  color: #9ca3af;
}
.vex-theme-green.dark .mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled,
.vex-theme-green .dark .mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled {
  color: #686868;
}
.vex-theme-green.dark .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-green.dark .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-green .dark .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-green .dark .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #22c55e;
}
.vex-theme-green.dark .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-green.dark .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-green .dark .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-green .dark .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #22c55e;
}
.vex-theme-green.dark .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-green.dark .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after,
.vex-theme-green .dark .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-green .dark .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #111827;
}
.vex-theme-green.dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-green.dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-green .dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-green .dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #f59e0b;
}
.vex-theme-green.dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-green.dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-green .dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-green .dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #f59e0b;
}
.vex-theme-green.dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-green.dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after,
.vex-theme-green .dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-green .dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #111827;
}
.vex-theme-green.dark .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-green.dark .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-green .dark .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-green .dark .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #f59e0b;
}
.vex-theme-green.dark .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-green.dark .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-green .dark .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-green .dark .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #f59e0b;
}
.vex-theme-green.dark .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-green.dark .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after,
.vex-theme-green .dark .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-green .dark .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #111827;
}
.vex-theme-green.dark .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-green.dark .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-green .dark .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-green .dark .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #ef4444;
}
.vex-theme-green.dark .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-green.dark .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-green .dark .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-green .dark .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #ef4444;
}
.vex-theme-green.dark .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-green.dark .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after,
.vex-theme-green .dark .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-green .dark .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #111827;
}
.vex-theme-green.dark .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-green.dark .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-green .dark .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-green .dark .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #686868;
}
.vex-theme-green.dark .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-green.dark .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-green .dark .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-green .dark .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #686868;
}
.vex-theme-green.dark .mat-app-background, .vex-theme-green.dark.mat-app-background,
.vex-theme-green .dark .mat-app-background,
.vex-theme-green .dark.mat-app-background {
  background-color: #111827;
  color: white;
}
.vex-theme-green.dark .mat-elevation-z0, .vex-theme-green.dark .mat-mdc-elevation-specific.mat-elevation-z0,
.vex-theme-green .dark .mat-elevation-z0,
.vex-theme-green .dark .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-green.dark .mat-elevation-z1, .vex-theme-green.dark .mat-mdc-elevation-specific.mat-elevation-z1,
.vex-theme-green .dark .mat-elevation-z1,
.vex-theme-green .dark .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-green.dark .mat-elevation-z2, .vex-theme-green.dark .mat-mdc-elevation-specific.mat-elevation-z2,
.vex-theme-green .dark .mat-elevation-z2,
.vex-theme-green .dark .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-green.dark .mat-elevation-z3, .vex-theme-green.dark .mat-mdc-elevation-specific.mat-elevation-z3,
.vex-theme-green .dark .mat-elevation-z3,
.vex-theme-green .dark .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-green.dark .mat-elevation-z4, .vex-theme-green.dark .mat-mdc-elevation-specific.mat-elevation-z4,
.vex-theme-green .dark .mat-elevation-z4,
.vex-theme-green .dark .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-green.dark .mat-elevation-z5, .vex-theme-green.dark .mat-mdc-elevation-specific.mat-elevation-z5,
.vex-theme-green .dark .mat-elevation-z5,
.vex-theme-green .dark .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-green.dark .mat-elevation-z6, .vex-theme-green.dark .mat-mdc-elevation-specific.mat-elevation-z6,
.vex-theme-green .dark .mat-elevation-z6,
.vex-theme-green .dark .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-green.dark .mat-elevation-z7, .vex-theme-green.dark .mat-mdc-elevation-specific.mat-elevation-z7,
.vex-theme-green .dark .mat-elevation-z7,
.vex-theme-green .dark .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.vex-theme-green.dark .mat-elevation-z8, .vex-theme-green.dark .mat-mdc-elevation-specific.mat-elevation-z8,
.vex-theme-green .dark .mat-elevation-z8,
.vex-theme-green .dark .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.vex-theme-green.dark .mat-elevation-z9, .vex-theme-green.dark .mat-mdc-elevation-specific.mat-elevation-z9,
.vex-theme-green .dark .mat-elevation-z9,
.vex-theme-green .dark .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.vex-theme-green.dark .mat-elevation-z10, .vex-theme-green.dark .mat-mdc-elevation-specific.mat-elevation-z10,
.vex-theme-green .dark .mat-elevation-z10,
.vex-theme-green .dark .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.vex-theme-green.dark .mat-elevation-z11, .vex-theme-green.dark .mat-mdc-elevation-specific.mat-elevation-z11,
.vex-theme-green .dark .mat-elevation-z11,
.vex-theme-green .dark .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.vex-theme-green.dark .mat-elevation-z12, .vex-theme-green.dark .mat-mdc-elevation-specific.mat-elevation-z12,
.vex-theme-green .dark .mat-elevation-z12,
.vex-theme-green .dark .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.vex-theme-green.dark .mat-elevation-z13, .vex-theme-green.dark .mat-mdc-elevation-specific.mat-elevation-z13,
.vex-theme-green .dark .mat-elevation-z13,
.vex-theme-green .dark .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.vex-theme-green.dark .mat-elevation-z14, .vex-theme-green.dark .mat-mdc-elevation-specific.mat-elevation-z14,
.vex-theme-green .dark .mat-elevation-z14,
.vex-theme-green .dark .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.vex-theme-green.dark .mat-elevation-z15, .vex-theme-green.dark .mat-mdc-elevation-specific.mat-elevation-z15,
.vex-theme-green .dark .mat-elevation-z15,
.vex-theme-green .dark .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.vex-theme-green.dark .mat-elevation-z16, .vex-theme-green.dark .mat-mdc-elevation-specific.mat-elevation-z16,
.vex-theme-green .dark .mat-elevation-z16,
.vex-theme-green .dark .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.vex-theme-green.dark .mat-elevation-z17, .vex-theme-green.dark .mat-mdc-elevation-specific.mat-elevation-z17,
.vex-theme-green .dark .mat-elevation-z17,
.vex-theme-green .dark .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.vex-theme-green.dark .mat-elevation-z18, .vex-theme-green.dark .mat-mdc-elevation-specific.mat-elevation-z18,
.vex-theme-green .dark .mat-elevation-z18,
.vex-theme-green .dark .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.vex-theme-green.dark .mat-elevation-z19, .vex-theme-green.dark .mat-mdc-elevation-specific.mat-elevation-z19,
.vex-theme-green .dark .mat-elevation-z19,
.vex-theme-green .dark .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.vex-theme-green.dark .mat-elevation-z20, .vex-theme-green.dark .mat-mdc-elevation-specific.mat-elevation-z20,
.vex-theme-green .dark .mat-elevation-z20,
.vex-theme-green .dark .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.vex-theme-green.dark .mat-elevation-z21, .vex-theme-green.dark .mat-mdc-elevation-specific.mat-elevation-z21,
.vex-theme-green .dark .mat-elevation-z21,
.vex-theme-green .dark .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.vex-theme-green.dark .mat-elevation-z22, .vex-theme-green.dark .mat-mdc-elevation-specific.mat-elevation-z22,
.vex-theme-green .dark .mat-elevation-z22,
.vex-theme-green .dark .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.vex-theme-green.dark .mat-elevation-z23, .vex-theme-green.dark .mat-mdc-elevation-specific.mat-elevation-z23,
.vex-theme-green .dark .mat-elevation-z23,
.vex-theme-green .dark .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.vex-theme-green.dark .mat-elevation-z24, .vex-theme-green.dark .mat-mdc-elevation-specific.mat-elevation-z24,
.vex-theme-green .dark .mat-elevation-z24,
.vex-theme-green .dark .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.vex-theme-green.dark .mat-mdc-card,
.vex-theme-green .dark .mat-mdc-card {
  --mdc-elevated-card-container-color: #1f2937;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: #1f2937;
  --mdc-outlined-card-outline-color: rgba(255, 255, 255, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: #9ca3af;
}
.vex-theme-green.dark .mat-mdc-progress-bar,
.vex-theme-green .dark .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #22c55e;
  --mdc-linear-progress-track-color: rgba(34, 197, 94, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.vex-theme-green.dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots,
.vex-theme-green .dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-color: rgba(34, 197, 94, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(34, 197, 94, 0.25));
}
@media (forced-colors: active) {
  .vex-theme-green.dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots,
  .vex-theme-green .dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .vex-theme-green.dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots,
  .vex-theme-green .dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(34, 197, 94, 0.25)'/%3E%3C/svg%3E");
  }
}
.vex-theme-green.dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar,
.vex-theme-green .dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgba(34, 197, 94, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(34, 197, 94, 0.25));
}
.vex-theme-green.dark .mat-mdc-progress-bar.mat-accent,
.vex-theme-green .dark .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #f59e0b;
  --mdc-linear-progress-track-color: rgba(245, 158, 11, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.vex-theme-green.dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots,
.vex-theme-green .dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-color: rgba(245, 158, 11, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(245, 158, 11, 0.25));
}
@media (forced-colors: active) {
  .vex-theme-green.dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots,
  .vex-theme-green .dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .vex-theme-green.dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots,
  .vex-theme-green .dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(245, 158, 11, 0.25)'/%3E%3C/svg%3E");
  }
}
.vex-theme-green.dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar,
.vex-theme-green .dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: rgba(245, 158, 11, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(245, 158, 11, 0.25));
}
.vex-theme-green.dark .mat-mdc-progress-bar.mat-warn,
.vex-theme-green .dark .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #ef4444;
  --mdc-linear-progress-track-color: rgba(239, 68, 68, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.vex-theme-green.dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots,
.vex-theme-green .dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-color: rgba(239, 68, 68, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(239, 68, 68, 0.25));
}
@media (forced-colors: active) {
  .vex-theme-green.dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots,
  .vex-theme-green .dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .vex-theme-green.dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots,
  .vex-theme-green .dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(239, 68, 68, 0.25)'/%3E%3C/svg%3E");
  }
}
.vex-theme-green.dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar,
.vex-theme-green .dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: rgba(239, 68, 68, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(239, 68, 68, 0.25));
}
.vex-theme-green.dark .mat-mdc-tooltip,
.vex-theme-green .dark .mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #374151;
  --mdc-plain-tooltip-supporting-text-color: #fff;
}
.vex-theme-green.dark, .vex-theme-green .dark {
  --mdc-filled-text-field-caret-color: #22c55e;
  --mdc-filled-text-field-focus-active-indicator-color: #22c55e;
  --mdc-filled-text-field-focus-label-text-color: rgba(34, 197, 94, 0.87);
  --mdc-filled-text-field-container-color: #28323f;
  --mdc-filled-text-field-disabled-container-color: #232d3b;
  --mdc-filled-text-field-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(255, 255, 255, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(255, 255, 255, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(255, 255, 255, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-error-focus-label-text-color: #ef4444;
  --mdc-filled-text-field-error-label-text-color: #ef4444;
  --mdc-filled-text-field-error-caret-color: #ef4444;
  --mdc-filled-text-field-active-indicator-color: rgba(255, 255, 255, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(255, 255, 255, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(255, 255, 255, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #ef4444;
  --mdc-filled-text-field-error-focus-active-indicator-color: #ef4444;
  --mdc-filled-text-field-error-hover-active-indicator-color: #ef4444;
  --mdc-outlined-text-field-caret-color: #22c55e;
  --mdc-outlined-text-field-focus-outline-color: #22c55e;
  --mdc-outlined-text-field-focus-label-text-color: rgba(34, 197, 94, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(255, 255, 255, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(255, 255, 255, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(255, 255, 255, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-error-caret-color: #ef4444;
  --mdc-outlined-text-field-error-focus-label-text-color: #ef4444;
  --mdc-outlined-text-field-error-label-text-color: #ef4444;
  --mdc-outlined-text-field-outline-color: rgba(255, 255, 255, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(255, 255, 255, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(255, 255, 255, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #ef4444;
  --mdc-outlined-text-field-error-hover-outline-color: #ef4444;
  --mdc-outlined-text-field-error-outline-color: #ef4444;
  --mat-form-field-disabled-input-text-placeholder-color: rgba(255, 255, 255, 0.38);
}

.vex-theme-green.dark .mat-mdc-form-field-error,
.vex-theme-green .dark .mat-mdc-form-field-error {
  color: var(--mdc-theme-error, #ef4444);
}
.vex-theme-green.dark .mat-mdc-form-field-subscript-wrapper,
.vex-theme-green.dark .mat-mdc-form-field-bottom-align::before,
.vex-theme-green .dark .mat-mdc-form-field-subscript-wrapper,
.vex-theme-green .dark .mat-mdc-form-field-bottom-align::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mat-form-field-subscript-text-font);
  line-height: var(--mat-form-field-subscript-text-line-height);
  font-size: var(--mat-form-field-subscript-text-size);
  letter-spacing: var(--mat-form-field-subscript-text-tracking);
  font-weight: var(--mat-form-field-subscript-text-weight);
}
.vex-theme-green.dark .mat-mdc-form-field-focus-overlay,
.vex-theme-green .dark .mat-mdc-form-field-focus-overlay {
  background-color: rgba(255, 255, 255, 0.87);
}
.vex-theme-green.dark .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay,
.vex-theme-green .dark .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0.08;
}
.vex-theme-green.dark .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay,
.vex-theme-green .dark .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.24;
}
.vex-theme-green.dark select.mat-mdc-form-field-input-control:not(.mat-mdc-native-select-inline) option,
.vex-theme-green .dark select.mat-mdc-form-field-input-control:not(.mat-mdc-native-select-inline) option {
  color: rgba(0, 0, 0, 0.87);
}
.vex-theme-green.dark select.mat-mdc-form-field-input-control:not(.mat-mdc-native-select-inline) option:disabled,
.vex-theme-green .dark select.mat-mdc-form-field-input-control:not(.mat-mdc-native-select-inline) option:disabled {
  color: rgba(0, 0, 0, 0.38);
}
.vex-theme-green.dark .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after,
.vex-theme-green .dark .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
  color: rgba(255, 255, 255, 0.54);
}
.vex-theme-green.dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after,
.vex-theme-green .dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after {
  color: rgba(34, 197, 94, 0.87);
}
.vex-theme-green.dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after,
.vex-theme-green .dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after {
  color: rgba(245, 158, 11, 0.87);
}
.vex-theme-green.dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after,
.vex-theme-green .dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after {
  color: rgba(239, 68, 68, 0.87);
}
.vex-theme-green.dark .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after,
.vex-theme-green .dark .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  color: rgba(255, 255, 255, 0.38);
}
.vex-theme-green.dark .mat-mdc-form-field.mat-accent,
.vex-theme-green .dark .mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #f59e0b;
  --mdc-filled-text-field-focus-active-indicator-color: #f59e0b;
  --mdc-filled-text-field-focus-label-text-color: rgba(245, 158, 11, 0.87);
  --mdc-outlined-text-field-caret-color: #f59e0b;
  --mdc-outlined-text-field-focus-outline-color: #f59e0b;
  --mdc-outlined-text-field-focus-label-text-color: rgba(245, 158, 11, 0.87);
}
.vex-theme-green.dark .mat-mdc-form-field.mat-warn,
.vex-theme-green .dark .mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #ef4444;
  --mdc-filled-text-field-focus-active-indicator-color: #ef4444;
  --mdc-filled-text-field-focus-label-text-color: rgba(239, 68, 68, 0.87);
  --mdc-outlined-text-field-caret-color: #ef4444;
  --mdc-outlined-text-field-focus-outline-color: #ef4444;
  --mdc-outlined-text-field-focus-label-text-color: rgba(239, 68, 68, 0.87);
}
.vex-theme-green.dark .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch,
.vex-theme-green .dark .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: 1px solid transparent;
}
.vex-theme-green.dark [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch,
.vex-theme-green .dark [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none;
  border-right: 1px solid transparent;
}
.vex-theme-green.dark, .vex-theme-green .dark {
  --mat-select-panel-background-color: #1f2937;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(255, 255, 255, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(255, 255, 255, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(34, 197, 94, 0.87);
  --mat-select-invalid-arrow-color: rgba(239, 68, 68, 0.87);
}
.vex-theme-green.dark .mat-mdc-form-field.mat-accent, .vex-theme-green .dark .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: #1f2937;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(255, 255, 255, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(255, 255, 255, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(245, 158, 11, 0.87);
  --mat-select-invalid-arrow-color: rgba(239, 68, 68, 0.87);
}
.vex-theme-green.dark .mat-mdc-form-field.mat-warn, .vex-theme-green .dark .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: #1f2937;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(255, 255, 255, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(255, 255, 255, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(239, 68, 68, 0.87);
  --mat-select-invalid-arrow-color: rgba(239, 68, 68, 0.87);
}

.vex-theme-green.dark, .vex-theme-green .dark {
  --mat-autocomplete-background-color: #1f2937;
}

.vex-theme-green.dark .mat-mdc-dialog-container,
.vex-theme-green .dark .mat-mdc-dialog-container {
  --mdc-dialog-container-color: #1f2937;
  --mdc-dialog-subhead-color: rgba(255, 255, 255, 0.87);
  --mdc-dialog-supporting-text-color: rgba(255, 255, 255, 0.6);
}
.vex-theme-green.dark .mat-mdc-standard-chip,
.vex-theme-green .dark .mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #fafafa;
  --mdc-chip-elevated-container-color: #3a434f;
  --mdc-chip-elevated-disabled-container-color: #3a434f;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #fafafa;
  --mdc-chip-with-icon-icon-color: #fafafa;
  --mdc-chip-with-icon-disabled-icon-color: #fafafa;
  --mdc-chip-with-icon-selected-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #fafafa;
}
.vex-theme-green.dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .vex-theme-green.dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary,
.vex-theme-green .dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary,
.vex-theme-green .dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #22c55e;
  --mdc-chip-elevated-disabled-container-color: #22c55e;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.vex-theme-green.dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .vex-theme-green.dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent,
.vex-theme-green .dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent,
.vex-theme-green .dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #f59e0b;
  --mdc-chip-elevated-disabled-container-color: #f59e0b;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.vex-theme-green.dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .vex-theme-green.dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn,
.vex-theme-green .dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn,
.vex-theme-green .dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #ef4444;
  --mdc-chip-elevated-disabled-container-color: #ef4444;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.vex-theme-green.dark .mat-mdc-slide-toggle,
.vex-theme-green .dark .mat-mdc-slide-toggle {
  --mdc-switch-selected-focus-state-layer-color: #86efac;
  --mdc-switch-selected-handle-color: #86efac;
  --mdc-switch-selected-hover-state-layer-color: #86efac;
  --mdc-switch-selected-pressed-state-layer-color: #86efac;
  --mdc-switch-selected-focus-handle-color: #bbf7d0;
  --mdc-switch-selected-hover-handle-color: #bbf7d0;
  --mdc-switch-selected-pressed-handle-color: #bbf7d0;
  --mdc-switch-selected-focus-track-color: #16a34a;
  --mdc-switch-selected-hover-track-color: #16a34a;
  --mdc-switch-selected-pressed-track-color: #16a34a;
  --mdc-switch-selected-track-color: #16a34a;
  --mdc-switch-disabled-selected-handle-color: #000;
  --mdc-switch-disabled-selected-icon-color: #212121;
  --mdc-switch-disabled-selected-track-color: #f5f5f5;
  --mdc-switch-disabled-unselected-handle-color: #000;
  --mdc-switch-disabled-unselected-icon-color: #212121;
  --mdc-switch-disabled-unselected-track-color: #f5f5f5;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #212121;
  --mdc-switch-unselected-focus-handle-color: #fafafa;
  --mdc-switch-unselected-focus-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-focus-track-color: #616161;
  --mdc-switch-unselected-handle-color: #9e9e9e;
  --mdc-switch-unselected-hover-handle-color: #fafafa;
  --mdc-switch-unselected-hover-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-hover-track-color: #616161;
  --mdc-switch-unselected-icon-color: #212121;
  --mdc-switch-unselected-pressed-handle-color: #fafafa;
  --mdc-switch-unselected-pressed-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-pressed-track-color: #616161;
  --mdc-switch-unselected-track-color: #616161;
}
.vex-theme-green.dark .mat-mdc-slide-toggle .mdc-form-field,
.vex-theme-green .dark .mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.vex-theme-green.dark .mat-mdc-slide-toggle .mdc-switch--disabled + label,
.vex-theme-green .dark .mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: #6b7280;
}
.vex-theme-green.dark .mat-mdc-slide-toggle.mat-accent,
.vex-theme-green .dark .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #fcd34d;
  --mdc-switch-selected-handle-color: #fcd34d;
  --mdc-switch-selected-hover-state-layer-color: #fcd34d;
  --mdc-switch-selected-pressed-state-layer-color: #fcd34d;
  --mdc-switch-selected-focus-handle-color: #fde68a;
  --mdc-switch-selected-hover-handle-color: #fde68a;
  --mdc-switch-selected-pressed-handle-color: #fde68a;
  --mdc-switch-selected-focus-track-color: #d97706;
  --mdc-switch-selected-hover-track-color: #d97706;
  --mdc-switch-selected-pressed-track-color: #d97706;
  --mdc-switch-selected-track-color: #d97706;
}
.vex-theme-green.dark .mat-mdc-slide-toggle.mat-warn,
.vex-theme-green .dark .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #fca5a5;
  --mdc-switch-selected-handle-color: #fca5a5;
  --mdc-switch-selected-hover-state-layer-color: #fca5a5;
  --mdc-switch-selected-pressed-state-layer-color: #fca5a5;
  --mdc-switch-selected-focus-handle-color: #fecaca;
  --mdc-switch-selected-hover-handle-color: #fecaca;
  --mdc-switch-selected-pressed-handle-color: #fecaca;
  --mdc-switch-selected-focus-track-color: #dc2626;
  --mdc-switch-selected-hover-track-color: #dc2626;
  --mdc-switch-selected-pressed-track-color: #dc2626;
  --mdc-switch-selected-track-color: #dc2626;
}
.vex-theme-green.dark .mat-mdc-radio-button .mdc-form-field,
.vex-theme-green .dark .mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.vex-theme-green.dark .mat-mdc-radio-button.mat-primary,
.vex-theme-green .dark .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #22c55e;
  --mdc-radio-selected-hover-icon-color: #22c55e;
  --mdc-radio-selected-icon-color: #22c55e;
  --mdc-radio-selected-pressed-icon-color: #22c55e;
  --mat-radio-ripple-color: #fff;
  --mat-radio-checked-ripple-color: #22c55e;
  --mat-radio-disabled-label-color: #6b7280;
}
.vex-theme-green.dark .mat-mdc-radio-button.mat-accent,
.vex-theme-green .dark .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #f59e0b;
  --mdc-radio-selected-hover-icon-color: #f59e0b;
  --mdc-radio-selected-icon-color: #f59e0b;
  --mdc-radio-selected-pressed-icon-color: #f59e0b;
  --mat-radio-ripple-color: #fff;
  --mat-radio-checked-ripple-color: #f59e0b;
  --mat-radio-disabled-label-color: #6b7280;
}
.vex-theme-green.dark .mat-mdc-radio-button.mat-warn,
.vex-theme-green .dark .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #ef4444;
  --mdc-radio-selected-hover-icon-color: #ef4444;
  --mdc-radio-selected-icon-color: #ef4444;
  --mdc-radio-selected-pressed-icon-color: #ef4444;
  --mat-radio-ripple-color: #fff;
  --mat-radio-checked-ripple-color: #ef4444;
  --mat-radio-disabled-label-color: #6b7280;
}
.vex-theme-green.dark .mat-mdc-slider,
.vex-theme-green .dark .mat-mdc-slider {
  --mdc-slider-label-container-color: white;
  --mdc-slider-label-label-text-color: black;
  --mdc-slider-disabled-handle-color: #fff;
  --mdc-slider-disabled-active-track-color: #fff;
  --mdc-slider-disabled-inactive-track-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #fff;
  --mat-mdc-slider-value-indicator-opacity: 0.9;
}
.vex-theme-green.dark .mat-mdc-slider.mat-primary,
.vex-theme-green .dark .mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #22c55e;
  --mdc-slider-focus-handle-color: #22c55e;
  --mdc-slider-hover-handle-color: #22c55e;
  --mdc-slider-active-track-color: #22c55e;
  --mdc-slider-inactive-track-color: #22c55e;
  --mdc-slider-with-tick-marks-active-container-color: #000;
  --mdc-slider-with-tick-marks-inactive-container-color: #22c55e;
  --mat-mdc-slider-ripple-color: #22c55e;
  --mat-mdc-slider-hover-ripple-color: rgba(34, 197, 94, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(34, 197, 94, 0.2);
}
.vex-theme-green.dark .mat-mdc-slider.mat-accent,
.vex-theme-green .dark .mat-mdc-slider.mat-accent {
  --mdc-slider-handle-color: #f59e0b;
  --mdc-slider-focus-handle-color: #f59e0b;
  --mdc-slider-hover-handle-color: #f59e0b;
  --mdc-slider-active-track-color: #f59e0b;
  --mdc-slider-inactive-track-color: #f59e0b;
  --mdc-slider-with-tick-marks-active-container-color: #000;
  --mdc-slider-with-tick-marks-inactive-container-color: #f59e0b;
  --mat-mdc-slider-ripple-color: #f59e0b;
  --mat-mdc-slider-hover-ripple-color: rgba(245, 158, 11, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(245, 158, 11, 0.2);
}
.vex-theme-green.dark .mat-mdc-slider.mat-warn,
.vex-theme-green .dark .mat-mdc-slider.mat-warn {
  --mdc-slider-handle-color: #ef4444;
  --mdc-slider-focus-handle-color: #ef4444;
  --mdc-slider-hover-handle-color: #ef4444;
  --mdc-slider-active-track-color: #ef4444;
  --mdc-slider-inactive-track-color: #ef4444;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #ef4444;
  --mat-mdc-slider-ripple-color: #ef4444;
  --mat-mdc-slider-hover-ripple-color: rgba(239, 68, 68, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(239, 68, 68, 0.2);
}
.vex-theme-green.dark, .vex-theme-green .dark {
  --mat-menu-item-label-text-color: white;
  --mat-menu-item-icon-color: white;
  --mat-menu-item-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-menu-item-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-menu-container-color: #1f2937;
}

.vex-theme-green.dark .mat-mdc-list-base,
.vex-theme-green .dark .mat-mdc-list-base {
  --mdc-list-list-item-label-text-color: white;
  --mdc-list-list-item-supporting-text-color: #9ca3af;
  --mdc-list-list-item-leading-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-trailing-supporting-text-color: #6b7280;
  --mdc-list-list-item-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-disabled-label-text-color: white;
  --mdc-list-list-item-disabled-leading-icon-color: white;
  --mdc-list-list-item-disabled-trailing-icon-color: white;
  --mdc-list-list-item-hover-label-text-color: white;
  --mdc-list-list-item-hover-leading-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-focus-label-text-color: white;
  --mdc-list-list-item-hover-state-layer-color: white;
  --mdc-list-list-item-hover-state-layer-opacity: 0.08;
  --mdc-list-list-item-focus-state-layer-color: white;
  --mdc-list-list-item-focus-state-layer-opacity: 0.24;
}
.vex-theme-green.dark .mdc-list-item__start,
.vex-theme-green.dark .mdc-list-item__end,
.vex-theme-green .dark .mdc-list-item__start,
.vex-theme-green .dark .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #22c55e;
  --mdc-radio-selected-hover-icon-color: #22c55e;
  --mdc-radio-selected-icon-color: #22c55e;
  --mdc-radio-selected-pressed-icon-color: #22c55e;
}
.vex-theme-green.dark .mat-accent .mdc-list-item__start,
.vex-theme-green.dark .mat-accent .mdc-list-item__end,
.vex-theme-green .dark .mat-accent .mdc-list-item__start,
.vex-theme-green .dark .mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #f59e0b;
  --mdc-radio-selected-hover-icon-color: #f59e0b;
  --mdc-radio-selected-icon-color: #f59e0b;
  --mdc-radio-selected-pressed-icon-color: #f59e0b;
}
.vex-theme-green.dark .mat-warn .mdc-list-item__start,
.vex-theme-green.dark .mat-warn .mdc-list-item__end,
.vex-theme-green .dark .mat-warn .mdc-list-item__start,
.vex-theme-green .dark .mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #ef4444;
  --mdc-radio-selected-hover-icon-color: #ef4444;
  --mdc-radio-selected-icon-color: #ef4444;
  --mdc-radio-selected-pressed-icon-color: #ef4444;
}
.vex-theme-green.dark .mat-mdc-list-option,
.vex-theme-green .dark .mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #22c55e;
  --mdc-checkbox-selected-hover-icon-color: #22c55e;
  --mdc-checkbox-selected-icon-color: #22c55e;
  --mdc-checkbox-selected-pressed-icon-color: #22c55e;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #22c55e;
  --mdc-checkbox-selected-hover-state-layer-color: #22c55e;
  --mdc-checkbox-selected-pressed-state-layer-color: #22c55e;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.vex-theme-green.dark .mat-mdc-list-option.mat-accent,
.vex-theme-green .dark .mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #f59e0b;
  --mdc-checkbox-selected-hover-icon-color: #f59e0b;
  --mdc-checkbox-selected-icon-color: #f59e0b;
  --mdc-checkbox-selected-pressed-icon-color: #f59e0b;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f59e0b;
  --mdc-checkbox-selected-hover-state-layer-color: #f59e0b;
  --mdc-checkbox-selected-pressed-state-layer-color: #f59e0b;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.vex-theme-green.dark .mat-mdc-list-option.mat-warn,
.vex-theme-green .dark .mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #ef4444;
  --mdc-checkbox-selected-hover-icon-color: #ef4444;
  --mdc-checkbox-selected-icon-color: #ef4444;
  --mdc-checkbox-selected-pressed-icon-color: #ef4444;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #ef4444;
  --mdc-checkbox-selected-hover-state-layer-color: #ef4444;
  --mdc-checkbox-selected-pressed-state-layer-color: #ef4444;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.vex-theme-green.dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.vex-theme-green.dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,
.vex-theme-green .dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.vex-theme-green .dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #22c55e;
}
.vex-theme-green.dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.vex-theme-green.dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.vex-theme-green .dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.vex-theme-green .dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #22c55e;
}
.vex-theme-green.dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.vex-theme-green.dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.vex-theme-green.dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end,
.vex-theme-green .dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.vex-theme-green .dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.vex-theme-green .dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.vex-theme-green.dark, .vex-theme-green .dark {
  --mat-paginator-container-text-color: rgba(255, 255, 255, 0.87);
  --mat-paginator-container-background-color: #1f2937;
  --mat-paginator-enabled-icon-color: rgba(255, 255, 255, 0.54);
  --mat-paginator-disabled-icon-color: rgba(255, 255, 255, 0.12);
}

.vex-theme-green.dark .mat-mdc-tab-group, .vex-theme-green.dark .mat-mdc-tab-nav-bar,
.vex-theme-green .dark .mat-mdc-tab-group,
.vex-theme-green .dark .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #22c55e;
  --mat-tab-header-disabled-ripple-color: #6b7280;
  --mat-tab-header-pagination-icon-color: #fff;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #22c55e;
  --mat-tab-header-active-ripple-color: #22c55e;
  --mat-tab-header-inactive-ripple-color: #22c55e;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #22c55e;
  --mat-tab-header-active-hover-label-text-color: #22c55e;
  --mat-tab-header-active-focus-indicator-color: #22c55e;
  --mat-tab-header-active-hover-indicator-color: #22c55e;
}
.vex-theme-green.dark .mat-mdc-tab-group.mat-accent, .vex-theme-green.dark .mat-mdc-tab-nav-bar.mat-accent,
.vex-theme-green .dark .mat-mdc-tab-group.mat-accent,
.vex-theme-green .dark .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #f59e0b;
  --mat-tab-header-disabled-ripple-color: #6b7280;
  --mat-tab-header-pagination-icon-color: #fff;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #f59e0b;
  --mat-tab-header-active-ripple-color: #f59e0b;
  --mat-tab-header-inactive-ripple-color: #f59e0b;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #f59e0b;
  --mat-tab-header-active-hover-label-text-color: #f59e0b;
  --mat-tab-header-active-focus-indicator-color: #f59e0b;
  --mat-tab-header-active-hover-indicator-color: #f59e0b;
}
.vex-theme-green.dark .mat-mdc-tab-group.mat-warn, .vex-theme-green.dark .mat-mdc-tab-nav-bar.mat-warn,
.vex-theme-green .dark .mat-mdc-tab-group.mat-warn,
.vex-theme-green .dark .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #ef4444;
  --mat-tab-header-disabled-ripple-color: #6b7280;
  --mat-tab-header-pagination-icon-color: #fff;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #ef4444;
  --mat-tab-header-active-ripple-color: #ef4444;
  --mat-tab-header-inactive-ripple-color: #ef4444;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #ef4444;
  --mat-tab-header-active-hover-label-text-color: #ef4444;
  --mat-tab-header-active-focus-indicator-color: #ef4444;
  --mat-tab-header-active-hover-indicator-color: #ef4444;
}
.vex-theme-green.dark .mat-mdc-tab-group.mat-background-primary, .vex-theme-green.dark .mat-mdc-tab-nav-bar.mat-background-primary,
.vex-theme-green .dark .mat-mdc-tab-group.mat-background-primary,
.vex-theme-green .dark .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #22c55e;
  --mat-tab-header-with-background-foreground-color: white;
}
.vex-theme-green.dark .mat-mdc-tab-group.mat-background-accent, .vex-theme-green.dark .mat-mdc-tab-nav-bar.mat-background-accent,
.vex-theme-green .dark .mat-mdc-tab-group.mat-background-accent,
.vex-theme-green .dark .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #f59e0b;
  --mat-tab-header-with-background-foreground-color: white;
}
.vex-theme-green.dark .mat-mdc-tab-group.mat-background-warn, .vex-theme-green.dark .mat-mdc-tab-nav-bar.mat-background-warn,
.vex-theme-green .dark .mat-mdc-tab-group.mat-background-warn,
.vex-theme-green .dark .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #ef4444;
  --mat-tab-header-with-background-foreground-color: white;
}
.vex-theme-green.dark, .vex-theme-green .dark {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #f59e0b;
  --mdc-checkbox-selected-hover-icon-color: #f59e0b;
  --mdc-checkbox-selected-icon-color: #f59e0b;
  --mdc-checkbox-selected-pressed-icon-color: #f59e0b;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f59e0b;
  --mdc-checkbox-selected-hover-state-layer-color: #f59e0b;
  --mdc-checkbox-selected-pressed-state-layer-color: #f59e0b;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}

.vex-theme-green.dark .mat-mdc-checkbox.mat-primary,
.vex-theme-green .dark .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #22c55e;
  --mdc-checkbox-selected-hover-icon-color: #22c55e;
  --mdc-checkbox-selected-icon-color: #22c55e;
  --mdc-checkbox-selected-pressed-icon-color: #22c55e;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #22c55e;
  --mdc-checkbox-selected-hover-state-layer-color: #22c55e;
  --mdc-checkbox-selected-pressed-state-layer-color: #22c55e;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.vex-theme-green.dark .mat-mdc-checkbox.mat-warn,
.vex-theme-green .dark .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #ef4444;
  --mdc-checkbox-selected-hover-icon-color: #ef4444;
  --mdc-checkbox-selected-icon-color: #ef4444;
  --mdc-checkbox-selected-pressed-icon-color: #ef4444;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #ef4444;
  --mdc-checkbox-selected-hover-state-layer-color: #ef4444;
  --mdc-checkbox-selected-pressed-state-layer-color: #ef4444;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.vex-theme-green.dark .mat-mdc-checkbox .mdc-form-field,
.vex-theme-green .dark .mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.vex-theme-green.dark .mat-mdc-checkbox.mat-mdc-checkbox-disabled label,
.vex-theme-green .dark .mat-mdc-checkbox.mat-mdc-checkbox-disabled label {
  color: #6b7280;
}
.vex-theme-green.dark .mat-mdc-button.mat-unthemed,
.vex-theme-green .dark .mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #fff;
}
.vex-theme-green.dark .mat-mdc-button.mat-primary,
.vex-theme-green .dark .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #22c55e;
}
.vex-theme-green.dark .mat-mdc-button.mat-accent,
.vex-theme-green .dark .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #f59e0b;
}
.vex-theme-green.dark .mat-mdc-button.mat-warn,
.vex-theme-green .dark .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #ef4444;
}
.vex-theme-green.dark .mat-mdc-button[disabled][disabled],
.vex-theme-green .dark .mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-text-button-label-text-color: rgba(255, 255, 255, 0.5);
}
.vex-theme-green.dark .mat-mdc-unelevated-button.mat-unthemed,
.vex-theme-green .dark .mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #1f2937;
  --mdc-filled-button-label-text-color: #fff;
}
.vex-theme-green.dark .mat-mdc-unelevated-button.mat-primary,
.vex-theme-green .dark .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #22c55e;
  --mdc-filled-button-label-text-color: #000;
}
.vex-theme-green.dark .mat-mdc-unelevated-button.mat-accent,
.vex-theme-green .dark .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #f59e0b;
  --mdc-filled-button-label-text-color: #000;
}
.vex-theme-green.dark .mat-mdc-unelevated-button.mat-warn,
.vex-theme-green .dark .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #ef4444;
  --mdc-filled-button-label-text-color: #fff;
}
.vex-theme-green.dark .mat-mdc-unelevated-button[disabled][disabled],
.vex-theme-green .dark .mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(255, 255, 255, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-filled-button-container-color: rgba(255, 255, 255, 0.12);
  --mdc-filled-button-label-text-color: rgba(255, 255, 255, 0.5);
}
.vex-theme-green.dark .mat-mdc-raised-button.mat-unthemed,
.vex-theme-green .dark .mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #1f2937;
  --mdc-protected-button-label-text-color: #fff;
}
.vex-theme-green.dark .mat-mdc-raised-button.mat-primary,
.vex-theme-green .dark .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #22c55e;
  --mdc-protected-button-label-text-color: #000;
}
.vex-theme-green.dark .mat-mdc-raised-button.mat-accent,
.vex-theme-green .dark .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #f59e0b;
  --mdc-protected-button-label-text-color: #000;
}
.vex-theme-green.dark .mat-mdc-raised-button.mat-warn,
.vex-theme-green .dark .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #ef4444;
  --mdc-protected-button-label-text-color: #fff;
}
.vex-theme-green.dark .mat-mdc-raised-button[disabled][disabled],
.vex-theme-green .dark .mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(255, 255, 255, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-protected-button-container-color: rgba(255, 255, 255, 0.12);
  --mdc-protected-button-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-protected-button-container-elevation: 0;
}
.vex-theme-green.dark .mat-mdc-outlined-button,
.vex-theme-green .dark .mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
}
.vex-theme-green.dark .mat-mdc-outlined-button.mat-unthemed,
.vex-theme-green .dark .mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #fff;
}
.vex-theme-green.dark .mat-mdc-outlined-button.mat-primary,
.vex-theme-green .dark .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #22c55e;
}
.vex-theme-green.dark .mat-mdc-outlined-button.mat-accent,
.vex-theme-green .dark .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #f59e0b;
}
.vex-theme-green.dark .mat-mdc-outlined-button.mat-warn,
.vex-theme-green .dark .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #ef4444;
}
.vex-theme-green.dark .mat-mdc-outlined-button[disabled][disabled],
.vex-theme-green .dark .mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-outlined-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(255, 255, 255, 0.12);
}
.vex-theme-green.dark .mat-mdc-button, .vex-theme-green.dark .mat-mdc-outlined-button,
.vex-theme-green .dark .mat-mdc-button,
.vex-theme-green .dark .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.vex-theme-green.dark .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .vex-theme-green.dark .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-green .dark .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-green .dark .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
.vex-theme-green.dark .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-green.dark .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-green.dark .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-green.dark .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-green .dark .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-green .dark .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-green .dark .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-green .dark .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.vex-theme-green.dark .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .vex-theme-green.dark .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-green .dark .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-green .dark .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.vex-theme-green.dark .mat-mdc-button.mat-primary, .vex-theme-green.dark .mat-mdc-outlined-button.mat-primary,
.vex-theme-green .dark .mat-mdc-button.mat-primary,
.vex-theme-green .dark .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #22c55e;
  --mat-mdc-button-ripple-color: rgba(34, 197, 94, 0.1);
}
.vex-theme-green.dark .mat-mdc-button.mat-accent, .vex-theme-green.dark .mat-mdc-outlined-button.mat-accent,
.vex-theme-green .dark .mat-mdc-button.mat-accent,
.vex-theme-green .dark .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #f59e0b;
  --mat-mdc-button-ripple-color: rgba(245, 158, 11, 0.1);
}
.vex-theme-green.dark .mat-mdc-button.mat-warn, .vex-theme-green.dark .mat-mdc-outlined-button.mat-warn,
.vex-theme-green .dark .mat-mdc-button.mat-warn,
.vex-theme-green .dark .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #ef4444;
  --mat-mdc-button-ripple-color: rgba(239, 68, 68, 0.1);
}
.vex-theme-green.dark .mat-mdc-raised-button, .vex-theme-green.dark .mat-mdc-unelevated-button,
.vex-theme-green .dark .mat-mdc-raised-button,
.vex-theme-green .dark .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.vex-theme-green.dark .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .vex-theme-green.dark .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-green .dark .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-green .dark .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
.vex-theme-green.dark .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-green.dark .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-green.dark .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-green.dark .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-green .dark .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-green .dark .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-green .dark .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-green .dark .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.vex-theme-green.dark .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .vex-theme-green.dark .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-green .dark .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-green .dark .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.vex-theme-green.dark .mat-mdc-raised-button.mat-primary, .vex-theme-green.dark .mat-mdc-unelevated-button.mat-primary,
.vex-theme-green .dark .mat-mdc-raised-button.mat-primary,
.vex-theme-green .dark .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.vex-theme-green.dark .mat-mdc-raised-button.mat-accent, .vex-theme-green.dark .mat-mdc-unelevated-button.mat-accent,
.vex-theme-green .dark .mat-mdc-raised-button.mat-accent,
.vex-theme-green .dark .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.vex-theme-green.dark .mat-mdc-raised-button.mat-warn, .vex-theme-green.dark .mat-mdc-unelevated-button.mat-warn,
.vex-theme-green .dark .mat-mdc-raised-button.mat-warn,
.vex-theme-green .dark .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.vex-theme-green.dark .mat-mdc-icon-button,
.vex-theme-green .dark .mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mdc-icon-button-icon-color: inherit;
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.vex-theme-green.dark .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-green .dark .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
.vex-theme-green.dark .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-green.dark .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-green .dark .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-green .dark .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.vex-theme-green.dark .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-green .dark .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.vex-theme-green.dark .mat-mdc-icon-button.mat-primary,
.vex-theme-green .dark .mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #6200ee;
  --mat-mdc-button-ripple-color: rgba(98, 0, 238, 0.1);
}
.vex-theme-green.dark .mat-mdc-icon-button.mat-accent,
.vex-theme-green .dark .mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #018786;
  --mat-mdc-button-ripple-color: rgba(1, 135, 134, 0.1);
}
.vex-theme-green.dark .mat-mdc-icon-button.mat-warn,
.vex-theme-green .dark .mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #b00020;
  --mat-mdc-button-ripple-color: rgba(176, 0, 32, 0.1);
}
.vex-theme-green.dark .mat-mdc-icon-button.mat-primary,
.vex-theme-green .dark .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #22c55e;
  --mat-mdc-button-persistent-ripple-color: #22c55e;
  --mat-mdc-button-ripple-color: rgba(34, 197, 94, 0.1);
}
.vex-theme-green.dark .mat-mdc-icon-button.mat-accent,
.vex-theme-green .dark .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #f59e0b;
  --mat-mdc-button-persistent-ripple-color: #f59e0b;
  --mat-mdc-button-ripple-color: rgba(245, 158, 11, 0.1);
}
.vex-theme-green.dark .mat-mdc-icon-button.mat-warn,
.vex-theme-green .dark .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #ef4444;
  --mat-mdc-button-persistent-ripple-color: #ef4444;
  --mat-mdc-button-ripple-color: rgba(239, 68, 68, 0.1);
}
.vex-theme-green.dark .mat-mdc-icon-button[disabled][disabled],
.vex-theme-green .dark .mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-icon-button-disabled-icon-color: rgba(255, 255, 255, 0.5);
}
.vex-theme-green.dark .mat-mdc-fab,
.vex-theme-green.dark .mat-mdc-mini-fab,
.vex-theme-green .dark .mat-mdc-fab,
.vex-theme-green .dark .mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.vex-theme-green.dark .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-green.dark .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-green .dark .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-green .dark .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
.vex-theme-green.dark .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-green.dark .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-green.dark .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-green.dark .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-green .dark .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-green .dark .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-green .dark .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-green .dark .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.vex-theme-green.dark .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-green.dark .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-green .dark .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-green .dark .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.vex-theme-green.dark .mat-mdc-fab.mat-primary,
.vex-theme-green.dark .mat-mdc-mini-fab.mat-primary,
.vex-theme-green .dark .mat-mdc-fab.mat-primary,
.vex-theme-green .dark .mat-mdc-mini-fab.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.vex-theme-green.dark .mat-mdc-fab.mat-accent,
.vex-theme-green.dark .mat-mdc-mini-fab.mat-accent,
.vex-theme-green .dark .mat-mdc-fab.mat-accent,
.vex-theme-green .dark .mat-mdc-mini-fab.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.vex-theme-green.dark .mat-mdc-fab.mat-warn,
.vex-theme-green.dark .mat-mdc-mini-fab.mat-warn,
.vex-theme-green .dark .mat-mdc-fab.mat-warn,
.vex-theme-green .dark .mat-mdc-mini-fab.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.vex-theme-green.dark .mat-mdc-fab[disabled][disabled],
.vex-theme-green.dark .mat-mdc-mini-fab[disabled][disabled],
.vex-theme-green .dark .mat-mdc-fab[disabled][disabled],
.vex-theme-green .dark .mat-mdc-mini-fab[disabled][disabled] {
  --mdc-fab-container-color: rgba(255, 255, 255, 0.12);
  --mdc-fab-icon-color: rgba(255, 255, 255, 0.5);
  --mat-mdc-fab-color: rgba(255, 255, 255, 0.5);
}
.vex-theme-green.dark .mat-mdc-fab.mat-unthemed,
.vex-theme-green.dark .mat-mdc-mini-fab.mat-unthemed,
.vex-theme-green .dark .mat-mdc-fab.mat-unthemed,
.vex-theme-green .dark .mat-mdc-mini-fab.mat-unthemed {
  --mdc-fab-container-color: #1f2937;
  --mdc-fab-icon-color: white;
  --mat-mdc-fab-color: #fff;
}
.vex-theme-green.dark .mat-mdc-fab.mat-primary,
.vex-theme-green.dark .mat-mdc-mini-fab.mat-primary,
.vex-theme-green .dark .mat-mdc-fab.mat-primary,
.vex-theme-green .dark .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #22c55e;
  --mdc-fab-icon-color: black;
  --mat-mdc-fab-color: #000;
}
.vex-theme-green.dark .mat-mdc-fab.mat-accent,
.vex-theme-green.dark .mat-mdc-mini-fab.mat-accent,
.vex-theme-green .dark .mat-mdc-fab.mat-accent,
.vex-theme-green .dark .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: #f59e0b;
  --mdc-fab-icon-color: black;
  --mat-mdc-fab-color: #000;
}
.vex-theme-green.dark .mat-mdc-fab.mat-warn,
.vex-theme-green.dark .mat-mdc-mini-fab.mat-warn,
.vex-theme-green .dark .mat-mdc-fab.mat-warn,
.vex-theme-green .dark .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: #ef4444;
  --mdc-fab-icon-color: white;
  --mat-mdc-fab-color: #fff;
}
.vex-theme-green.dark .mat-mdc-snack-bar-container,
.vex-theme-green .dark .mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: #d2d4d7;
  --mdc-snackbar-supporting-text-color: rgba(31, 41, 55, 0.87);
  --mat-snack-bar-button-color: rgba(0, 0, 0, 0.87);
}
.vex-theme-green.dark .mat-mdc-progress-spinner,
.vex-theme-green .dark .mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #22c55e;
}
.vex-theme-green.dark .mat-mdc-progress-spinner.mat-accent,
.vex-theme-green .dark .mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: #f59e0b;
}
.vex-theme-green.dark .mat-mdc-progress-spinner.mat-warn,
.vex-theme-green .dark .mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: #ef4444;
}
.vex-theme-green.dark, .vex-theme-green .dark {
  --mat-badge-background-color: #22c55e;
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: #4b5563;
  --mat-badge-disabled-state-text-color: #6b7280;
}

.vex-theme-green.dark .mat-badge-accent,
.vex-theme-green .dark .mat-badge-accent {
  --mat-badge-background-color: #f59e0b;
  --mat-badge-text-color: white;
}
.vex-theme-green.dark .mat-badge-warn,
.vex-theme-green .dark .mat-badge-warn {
  --mat-badge-background-color: #ef4444;
  --mat-badge-text-color: white;
}
.vex-theme-green.dark, .vex-theme-green .dark {
  --mat-bottom-sheet-container-text-color: white;
  --mat-bottom-sheet-container-background-color: #1f2937;
}

.vex-theme-green.dark, .vex-theme-green .dark {
  --mat-legacy-button-toggle-text-color: #6b7280;
  --mat-legacy-button-toggle-state-layer-color: rgba(255, 255, 255, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: #9ca3af;
  --mat-legacy-button-toggle-selected-state-background-color: #111827;
  --mat-legacy-button-toggle-disabled-state-text-color: #4b5563;
  --mat-legacy-button-toggle-disabled-state-background-color: black;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #1f2937;
  --mat-standard-button-toggle-text-color: white;
  --mat-standard-button-toggle-background-color: #1f2937;
  --mat-standard-button-toggle-state-layer-color: white;
  --mat-standard-button-toggle-selected-state-background-color: #111827;
  --mat-standard-button-toggle-selected-state-text-color: white;
  --mat-standard-button-toggle-disabled-state-text-color: #4b5563;
  --mat-standard-button-toggle-disabled-state-background-color: #1f2937;
  --mat-standard-button-toggle-disabled-selected-state-text-color: white;
  --mat-standard-button-toggle-disabled-selected-state-background-color: #1f2937;
  --mat-standard-button-toggle-divider-color: #3a434f;
}

.vex-theme-green.dark, .vex-theme-green .dark {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #22c55e;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(34, 197, 94, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(34, 197, 94, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(34, 197, 94, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #22c55e;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(34, 197, 94, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: white;
  --mat-datepicker-calendar-body-label-text-color: #9ca3af;
  --mat-datepicker-calendar-period-button-icon-color: white;
  --mat-datepicker-calendar-navigation-button-icon-color: white;
  --mat-datepicker-calendar-header-divider-color: rgba(255, 255, 255, 0.12);
  --mat-datepicker-calendar-header-text-color: #9ca3af;
  --mat-datepicker-calendar-date-today-outline-color: #6b7280;
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(107, 114, 128, 0.8);
  --mat-datepicker-calendar-date-text-color: white;
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: #6b7280;
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(255, 255, 255, 0.24);
  --mat-datepicker-range-input-separator-color: white;
  --mat-datepicker-range-input-disabled-state-separator-color: #6b7280;
  --mat-datepicker-range-input-disabled-state-text-color: #6b7280;
  --mat-datepicker-calendar-container-background-color: #1f2937;
  --mat-datepicker-calendar-container-text-color: white;
}

.vex-theme-green.dark .mat-datepicker-content.mat-accent,
.vex-theme-green .dark .mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #f59e0b;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(245, 158, 11, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(245, 158, 11, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(245, 158, 11, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(245, 158, 11, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.vex-theme-green.dark .mat-datepicker-content.mat-warn,
.vex-theme-green .dark .mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #ef4444;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(239, 68, 68, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(239, 68, 68, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(239, 68, 68, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(239, 68, 68, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.vex-theme-green.dark .mat-datepicker-toggle-active.mat-accent,
.vex-theme-green .dark .mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #f59e0b;
}
.vex-theme-green.dark .mat-datepicker-toggle-active.mat-warn,
.vex-theme-green .dark .mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #ef4444;
}
.vex-theme-green.dark, .vex-theme-green .dark {
  --mat-divider-color: rgba(255, 255, 255, 0.12);
}

.vex-theme-green.dark, .vex-theme-green .dark {
  --mat-expansion-container-background-color: #1f2937;
  --mat-expansion-container-text-color: white;
  --mat-expansion-actions-divider-color: rgba(255, 255, 255, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(255, 255, 255, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(255, 255, 255, 0.04);
  --mat-expansion-header-disabled-state-text-color: #4b5563;
  --mat-expansion-header-text-color: white;
  --mat-expansion-header-description-color: #9ca3af;
  --mat-expansion-header-indicator-color: #9ca3af;
}

.vex-theme-green.dark, .vex-theme-green .dark {
  --mat-icon-color: inherit;
}

.vex-theme-green.dark .mat-icon.mat-primary,
.vex-theme-green .dark .mat-icon.mat-primary {
  --mat-icon-color: #22c55e;
}
.vex-theme-green.dark .mat-icon.mat-accent,
.vex-theme-green .dark .mat-icon.mat-accent {
  --mat-icon-color: #f59e0b;
}
.vex-theme-green.dark .mat-icon.mat-warn,
.vex-theme-green .dark .mat-icon.mat-warn {
  --mat-icon-color: #ef4444;
}
.vex-theme-green.dark, .vex-theme-green .dark {
  --mat-sidenav-container-divider-color: rgba(255, 255, 255, 0.12);
  --mat-sidenav-container-background-color: #1f2937;
  --mat-sidenav-container-text-color: white;
  --mat-sidenav-content-background-color: #111827;
  --mat-sidenav-content-text-color: white;
  --mat-sidenav-scrim-color: rgba(224, 214, 200, 0.6);
}

.vex-theme-green.dark, .vex-theme-green .dark {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #22c55e;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #22c55e;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #22c55e;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: #1f2937;
  --mat-stepper-line-color: rgba(255, 255, 255, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(255, 255, 255, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(255, 255, 255, 0.04);
  --mat-stepper-header-label-text-color: #9ca3af;
  --mat-stepper-header-optional-label-text-color: #9ca3af;
  --mat-stepper-header-selected-state-label-text-color: white;
  --mat-stepper-header-error-state-label-text-color: #ef4444;
  --mat-stepper-header-icon-background-color: #9ca3af;
  --mat-stepper-header-error-state-icon-foreground-color: #ef4444;
  --mat-stepper-header-error-state-icon-background-color: transparent;
}
.vex-theme-green.dark .mat-step-header.mat-accent, .vex-theme-green .dark .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #f59e0b;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #f59e0b;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #f59e0b;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.vex-theme-green.dark .mat-step-header.mat-warn, .vex-theme-green .dark .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #ef4444;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #ef4444;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #ef4444;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}

.vex-theme-green.dark .mat-sort-header-arrow,
.vex-theme-green .dark .mat-sort-header-arrow {
  color: #9ca3af;
}
.vex-theme-green.dark, .vex-theme-green .dark {
  --mat-toolbar-container-background-color: #1f2937;
  --mat-toolbar-container-text-color: white;
}

.vex-theme-green.dark .mat-toolbar.mat-primary,
.vex-theme-green .dark .mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #22c55e;
  --mat-toolbar-container-text-color: white;
}
.vex-theme-green.dark .mat-toolbar.mat-accent,
.vex-theme-green .dark .mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #f59e0b;
  --mat-toolbar-container-text-color: white;
}
.vex-theme-green.dark .mat-toolbar.mat-warn,
.vex-theme-green .dark .mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #ef4444;
  --mat-toolbar-container-text-color: white;
}
.vex-theme-green.dark .mat-tree,
.vex-theme-green .dark .mat-tree {
  background: #1f2937;
}
.vex-theme-green.dark .mat-tree-node,
.vex-theme-green.dark .mat-nested-tree-node,
.vex-theme-green .dark .mat-tree-node,
.vex-theme-green .dark .mat-nested-tree-node {
  color: white;
}

/**
 * Override the theme foreground/background colors with the Angular Material colors
 */
.vex-theme-purple,
.vex-theme-purple.light,
.vex-theme-purple .light {
  --mat-table-background-color: white;
  --mat-table-header-headline-color: #030712;
  --mat-table-row-item-label-text-color: #030712;
  --mat-table-row-item-outline-color: rgba(0, 0, 0, 0.06);
}
.vex-theme-purple .mat-ripple-element,
.vex-theme-purple.light .mat-ripple-element,
.vex-theme-purple .light .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.vex-theme-purple, .vex-theme-purple.light, .vex-theme-purple .light {
  --mat-option-selected-state-label-text-color: #9333ea;
  --mat-option-label-text-color: #030712;
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.vex-theme-purple .mat-accent,
.vex-theme-purple.light .mat-accent,
.vex-theme-purple .light .mat-accent {
  --mat-option-selected-state-label-text-color: #f59e0b;
}
.vex-theme-purple .mat-warn,
.vex-theme-purple.light .mat-warn,
.vex-theme-purple .light .mat-warn {
  --mat-option-selected-state-label-text-color: #ef4444;
}
.vex-theme-purple, .vex-theme-purple.light, .vex-theme-purple .light {
  --mat-optgroup-label-text-color: #030712;
}

.vex-theme-purple .mat-pseudo-checkbox-full,
.vex-theme-purple.light .mat-pseudo-checkbox-full,
.vex-theme-purple .light .mat-pseudo-checkbox-full {
  color: #4b5563;
}
.vex-theme-purple .mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled,
.vex-theme-purple.light .mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled,
.vex-theme-purple .light .mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.vex-theme-purple .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-purple .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-purple.light .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-purple.light .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-purple .light .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-purple .light .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #9333ea;
}
.vex-theme-purple .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-purple .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-purple.light .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-purple.light .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-purple .light .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-purple .light .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #9333ea;
}
.vex-theme-purple .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-purple .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after,
.vex-theme-purple.light .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-purple.light .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after,
.vex-theme-purple .light .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-purple .light .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #f3f4f6;
}
.vex-theme-purple .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-purple .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-purple.light .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-purple.light .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-purple .light .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-purple .light .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #f59e0b;
}
.vex-theme-purple .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-purple .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-purple.light .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-purple.light .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-purple .light .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-purple .light .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #f59e0b;
}
.vex-theme-purple .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-purple .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after,
.vex-theme-purple.light .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-purple.light .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after,
.vex-theme-purple .light .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-purple .light .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #f3f4f6;
}
.vex-theme-purple .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-purple .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-purple.light .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-purple.light .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-purple .light .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-purple .light .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #f59e0b;
}
.vex-theme-purple .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-purple .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-purple.light .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-purple.light .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-purple .light .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-purple .light .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #f59e0b;
}
.vex-theme-purple .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-purple .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after,
.vex-theme-purple.light .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-purple.light .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after,
.vex-theme-purple .light .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-purple .light .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #f3f4f6;
}
.vex-theme-purple .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-purple .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-purple.light .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-purple.light .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-purple .light .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-purple .light .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #ef4444;
}
.vex-theme-purple .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-purple .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-purple.light .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-purple.light .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-purple .light .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-purple .light .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #ef4444;
}
.vex-theme-purple .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-purple .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after,
.vex-theme-purple.light .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-purple.light .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after,
.vex-theme-purple .light .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-purple .light .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #f3f4f6;
}
.vex-theme-purple .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-purple .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-purple.light .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-purple.light .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-purple .light .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-purple .light .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #b0b0b0;
}
.vex-theme-purple .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-purple .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-purple.light .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-purple.light .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-purple .light .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-purple .light .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #b0b0b0;
}
.vex-theme-purple .mat-app-background, .vex-theme-purple.mat-app-background,
.vex-theme-purple.light .mat-app-background,
.vex-theme-purple.light.mat-app-background,
.vex-theme-purple .light .mat-app-background,
.vex-theme-purple .light.mat-app-background {
  background-color: #f3f4f6;
  color: #030712;
}
.vex-theme-purple .mat-elevation-z0, .vex-theme-purple .mat-mdc-elevation-specific.mat-elevation-z0,
.vex-theme-purple.light .mat-elevation-z0,
.vex-theme-purple.light .mat-mdc-elevation-specific.mat-elevation-z0,
.vex-theme-purple .light .mat-elevation-z0,
.vex-theme-purple .light .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-purple .mat-elevation-z1, .vex-theme-purple .mat-mdc-elevation-specific.mat-elevation-z1,
.vex-theme-purple.light .mat-elevation-z1,
.vex-theme-purple.light .mat-mdc-elevation-specific.mat-elevation-z1,
.vex-theme-purple .light .mat-elevation-z1,
.vex-theme-purple .light .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-purple .mat-elevation-z2, .vex-theme-purple .mat-mdc-elevation-specific.mat-elevation-z2,
.vex-theme-purple.light .mat-elevation-z2,
.vex-theme-purple.light .mat-mdc-elevation-specific.mat-elevation-z2,
.vex-theme-purple .light .mat-elevation-z2,
.vex-theme-purple .light .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-purple .mat-elevation-z3, .vex-theme-purple .mat-mdc-elevation-specific.mat-elevation-z3,
.vex-theme-purple.light .mat-elevation-z3,
.vex-theme-purple.light .mat-mdc-elevation-specific.mat-elevation-z3,
.vex-theme-purple .light .mat-elevation-z3,
.vex-theme-purple .light .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-purple .mat-elevation-z4, .vex-theme-purple .mat-mdc-elevation-specific.mat-elevation-z4,
.vex-theme-purple.light .mat-elevation-z4,
.vex-theme-purple.light .mat-mdc-elevation-specific.mat-elevation-z4,
.vex-theme-purple .light .mat-elevation-z4,
.vex-theme-purple .light .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-purple .mat-elevation-z5, .vex-theme-purple .mat-mdc-elevation-specific.mat-elevation-z5,
.vex-theme-purple.light .mat-elevation-z5,
.vex-theme-purple.light .mat-mdc-elevation-specific.mat-elevation-z5,
.vex-theme-purple .light .mat-elevation-z5,
.vex-theme-purple .light .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-purple .mat-elevation-z6, .vex-theme-purple .mat-mdc-elevation-specific.mat-elevation-z6,
.vex-theme-purple.light .mat-elevation-z6,
.vex-theme-purple.light .mat-mdc-elevation-specific.mat-elevation-z6,
.vex-theme-purple .light .mat-elevation-z6,
.vex-theme-purple .light .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-purple .mat-elevation-z7, .vex-theme-purple .mat-mdc-elevation-specific.mat-elevation-z7,
.vex-theme-purple.light .mat-elevation-z7,
.vex-theme-purple.light .mat-mdc-elevation-specific.mat-elevation-z7,
.vex-theme-purple .light .mat-elevation-z7,
.vex-theme-purple .light .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.vex-theme-purple .mat-elevation-z8, .vex-theme-purple .mat-mdc-elevation-specific.mat-elevation-z8,
.vex-theme-purple.light .mat-elevation-z8,
.vex-theme-purple.light .mat-mdc-elevation-specific.mat-elevation-z8,
.vex-theme-purple .light .mat-elevation-z8,
.vex-theme-purple .light .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.vex-theme-purple .mat-elevation-z9, .vex-theme-purple .mat-mdc-elevation-specific.mat-elevation-z9,
.vex-theme-purple.light .mat-elevation-z9,
.vex-theme-purple.light .mat-mdc-elevation-specific.mat-elevation-z9,
.vex-theme-purple .light .mat-elevation-z9,
.vex-theme-purple .light .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.vex-theme-purple .mat-elevation-z10, .vex-theme-purple .mat-mdc-elevation-specific.mat-elevation-z10,
.vex-theme-purple.light .mat-elevation-z10,
.vex-theme-purple.light .mat-mdc-elevation-specific.mat-elevation-z10,
.vex-theme-purple .light .mat-elevation-z10,
.vex-theme-purple .light .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.vex-theme-purple .mat-elevation-z11, .vex-theme-purple .mat-mdc-elevation-specific.mat-elevation-z11,
.vex-theme-purple.light .mat-elevation-z11,
.vex-theme-purple.light .mat-mdc-elevation-specific.mat-elevation-z11,
.vex-theme-purple .light .mat-elevation-z11,
.vex-theme-purple .light .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.vex-theme-purple .mat-elevation-z12, .vex-theme-purple .mat-mdc-elevation-specific.mat-elevation-z12,
.vex-theme-purple.light .mat-elevation-z12,
.vex-theme-purple.light .mat-mdc-elevation-specific.mat-elevation-z12,
.vex-theme-purple .light .mat-elevation-z12,
.vex-theme-purple .light .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.vex-theme-purple .mat-elevation-z13, .vex-theme-purple .mat-mdc-elevation-specific.mat-elevation-z13,
.vex-theme-purple.light .mat-elevation-z13,
.vex-theme-purple.light .mat-mdc-elevation-specific.mat-elevation-z13,
.vex-theme-purple .light .mat-elevation-z13,
.vex-theme-purple .light .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.vex-theme-purple .mat-elevation-z14, .vex-theme-purple .mat-mdc-elevation-specific.mat-elevation-z14,
.vex-theme-purple.light .mat-elevation-z14,
.vex-theme-purple.light .mat-mdc-elevation-specific.mat-elevation-z14,
.vex-theme-purple .light .mat-elevation-z14,
.vex-theme-purple .light .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.vex-theme-purple .mat-elevation-z15, .vex-theme-purple .mat-mdc-elevation-specific.mat-elevation-z15,
.vex-theme-purple.light .mat-elevation-z15,
.vex-theme-purple.light .mat-mdc-elevation-specific.mat-elevation-z15,
.vex-theme-purple .light .mat-elevation-z15,
.vex-theme-purple .light .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.vex-theme-purple .mat-elevation-z16, .vex-theme-purple .mat-mdc-elevation-specific.mat-elevation-z16,
.vex-theme-purple.light .mat-elevation-z16,
.vex-theme-purple.light .mat-mdc-elevation-specific.mat-elevation-z16,
.vex-theme-purple .light .mat-elevation-z16,
.vex-theme-purple .light .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.vex-theme-purple .mat-elevation-z17, .vex-theme-purple .mat-mdc-elevation-specific.mat-elevation-z17,
.vex-theme-purple.light .mat-elevation-z17,
.vex-theme-purple.light .mat-mdc-elevation-specific.mat-elevation-z17,
.vex-theme-purple .light .mat-elevation-z17,
.vex-theme-purple .light .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.vex-theme-purple .mat-elevation-z18, .vex-theme-purple .mat-mdc-elevation-specific.mat-elevation-z18,
.vex-theme-purple.light .mat-elevation-z18,
.vex-theme-purple.light .mat-mdc-elevation-specific.mat-elevation-z18,
.vex-theme-purple .light .mat-elevation-z18,
.vex-theme-purple .light .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.vex-theme-purple .mat-elevation-z19, .vex-theme-purple .mat-mdc-elevation-specific.mat-elevation-z19,
.vex-theme-purple.light .mat-elevation-z19,
.vex-theme-purple.light .mat-mdc-elevation-specific.mat-elevation-z19,
.vex-theme-purple .light .mat-elevation-z19,
.vex-theme-purple .light .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.vex-theme-purple .mat-elevation-z20, .vex-theme-purple .mat-mdc-elevation-specific.mat-elevation-z20,
.vex-theme-purple.light .mat-elevation-z20,
.vex-theme-purple.light .mat-mdc-elevation-specific.mat-elevation-z20,
.vex-theme-purple .light .mat-elevation-z20,
.vex-theme-purple .light .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.vex-theme-purple .mat-elevation-z21, .vex-theme-purple .mat-mdc-elevation-specific.mat-elevation-z21,
.vex-theme-purple.light .mat-elevation-z21,
.vex-theme-purple.light .mat-mdc-elevation-specific.mat-elevation-z21,
.vex-theme-purple .light .mat-elevation-z21,
.vex-theme-purple .light .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.vex-theme-purple .mat-elevation-z22, .vex-theme-purple .mat-mdc-elevation-specific.mat-elevation-z22,
.vex-theme-purple.light .mat-elevation-z22,
.vex-theme-purple.light .mat-mdc-elevation-specific.mat-elevation-z22,
.vex-theme-purple .light .mat-elevation-z22,
.vex-theme-purple .light .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.vex-theme-purple .mat-elevation-z23, .vex-theme-purple .mat-mdc-elevation-specific.mat-elevation-z23,
.vex-theme-purple.light .mat-elevation-z23,
.vex-theme-purple.light .mat-mdc-elevation-specific.mat-elevation-z23,
.vex-theme-purple .light .mat-elevation-z23,
.vex-theme-purple .light .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.vex-theme-purple .mat-elevation-z24, .vex-theme-purple .mat-mdc-elevation-specific.mat-elevation-z24,
.vex-theme-purple.light .mat-elevation-z24,
.vex-theme-purple.light .mat-mdc-elevation-specific.mat-elevation-z24,
.vex-theme-purple .light .mat-elevation-z24,
.vex-theme-purple .light .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.vex-theme-purple .mat-mdc-card,
.vex-theme-purple.light .mat-mdc-card,
.vex-theme-purple .light .mat-mdc-card {
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: #4b5563;
}
.vex-theme-purple .mat-mdc-progress-bar,
.vex-theme-purple.light .mat-mdc-progress-bar,
.vex-theme-purple .light .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #9333ea;
  --mdc-linear-progress-track-color: rgba(147, 51, 234, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.vex-theme-purple .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots,
.vex-theme-purple.light .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots,
.vex-theme-purple .light .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-color: rgba(147, 51, 234, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(147, 51, 234, 0.25));
}
@media (forced-colors: active) {
  .vex-theme-purple .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots,
  .vex-theme-purple.light .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots,
  .vex-theme-purple .light .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .vex-theme-purple .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots,
  .vex-theme-purple.light .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots,
  .vex-theme-purple .light .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(147, 51, 234, 0.25)'/%3E%3C/svg%3E");
  }
}
.vex-theme-purple .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar,
.vex-theme-purple.light .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar,
.vex-theme-purple .light .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgba(147, 51, 234, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(147, 51, 234, 0.25));
}
.vex-theme-purple .mat-mdc-progress-bar.mat-accent,
.vex-theme-purple.light .mat-mdc-progress-bar.mat-accent,
.vex-theme-purple .light .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #f59e0b;
  --mdc-linear-progress-track-color: rgba(245, 158, 11, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.vex-theme-purple .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots,
.vex-theme-purple.light .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots,
.vex-theme-purple .light .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-color: rgba(245, 158, 11, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(245, 158, 11, 0.25));
}
@media (forced-colors: active) {
  .vex-theme-purple .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots,
  .vex-theme-purple.light .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots,
  .vex-theme-purple .light .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .vex-theme-purple .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots,
  .vex-theme-purple.light .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots,
  .vex-theme-purple .light .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(245, 158, 11, 0.25)'/%3E%3C/svg%3E");
  }
}
.vex-theme-purple .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar,
.vex-theme-purple.light .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar,
.vex-theme-purple .light .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: rgba(245, 158, 11, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(245, 158, 11, 0.25));
}
.vex-theme-purple .mat-mdc-progress-bar.mat-warn,
.vex-theme-purple.light .mat-mdc-progress-bar.mat-warn,
.vex-theme-purple .light .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #ef4444;
  --mdc-linear-progress-track-color: rgba(239, 68, 68, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.vex-theme-purple .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots,
.vex-theme-purple.light .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots,
.vex-theme-purple .light .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-color: rgba(239, 68, 68, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(239, 68, 68, 0.25));
}
@media (forced-colors: active) {
  .vex-theme-purple .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots,
  .vex-theme-purple.light .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots,
  .vex-theme-purple .light .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .vex-theme-purple .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots,
  .vex-theme-purple.light .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots,
  .vex-theme-purple .light .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(239, 68, 68, 0.25)'/%3E%3C/svg%3E");
  }
}
.vex-theme-purple .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar,
.vex-theme-purple.light .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar,
.vex-theme-purple .light .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: rgba(239, 68, 68, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(239, 68, 68, 0.25));
}
.vex-theme-purple .mat-mdc-tooltip,
.vex-theme-purple.light .mat-mdc-tooltip,
.vex-theme-purple .light .mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #1f2937;
  --mdc-plain-tooltip-supporting-text-color: #fff;
}
.vex-theme-purple, .vex-theme-purple.light, .vex-theme-purple .light {
  --mdc-filled-text-field-caret-color: #9333ea;
  --mdc-filled-text-field-focus-active-indicator-color: #9333ea;
  --mdc-filled-text-field-focus-label-text-color: rgba(147, 51, 234, 0.87);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-error-focus-label-text-color: #ef4444;
  --mdc-filled-text-field-error-label-text-color: #ef4444;
  --mdc-filled-text-field-error-caret-color: #ef4444;
  --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #ef4444;
  --mdc-filled-text-field-error-focus-active-indicator-color: #ef4444;
  --mdc-filled-text-field-error-hover-active-indicator-color: #ef4444;
  --mdc-outlined-text-field-caret-color: #9333ea;
  --mdc-outlined-text-field-focus-outline-color: #9333ea;
  --mdc-outlined-text-field-focus-label-text-color: rgba(147, 51, 234, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-error-caret-color: #ef4444;
  --mdc-outlined-text-field-error-focus-label-text-color: #ef4444;
  --mdc-outlined-text-field-error-label-text-color: #ef4444;
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #ef4444;
  --mdc-outlined-text-field-error-hover-outline-color: #ef4444;
  --mdc-outlined-text-field-error-outline-color: #ef4444;
  --mat-form-field-disabled-input-text-placeholder-color: rgba(0, 0, 0, 0.38);
}

.vex-theme-purple .mat-mdc-form-field-error,
.vex-theme-purple.light .mat-mdc-form-field-error,
.vex-theme-purple .light .mat-mdc-form-field-error {
  color: var(--mdc-theme-error, #ef4444);
}
.vex-theme-purple .mat-mdc-form-field-subscript-wrapper,
.vex-theme-purple .mat-mdc-form-field-bottom-align::before,
.vex-theme-purple.light .mat-mdc-form-field-subscript-wrapper,
.vex-theme-purple.light .mat-mdc-form-field-bottom-align::before,
.vex-theme-purple .light .mat-mdc-form-field-subscript-wrapper,
.vex-theme-purple .light .mat-mdc-form-field-bottom-align::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mat-form-field-subscript-text-font);
  line-height: var(--mat-form-field-subscript-text-line-height);
  font-size: var(--mat-form-field-subscript-text-size);
  letter-spacing: var(--mat-form-field-subscript-text-tracking);
  font-weight: var(--mat-form-field-subscript-text-weight);
}
.vex-theme-purple .mat-mdc-form-field-focus-overlay,
.vex-theme-purple.light .mat-mdc-form-field-focus-overlay,
.vex-theme-purple .light .mat-mdc-form-field-focus-overlay {
  background-color: rgba(0, 0, 0, 0.87);
}
.vex-theme-purple .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay,
.vex-theme-purple.light .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay,
.vex-theme-purple .light .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0.04;
}
.vex-theme-purple .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay,
.vex-theme-purple.light .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay,
.vex-theme-purple .light .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.12;
}
.vex-theme-purple .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after,
.vex-theme-purple.light .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after,
.vex-theme-purple .light .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.vex-theme-purple .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after,
.vex-theme-purple.light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after,
.vex-theme-purple .light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after {
  color: rgba(147, 51, 234, 0.87);
}
.vex-theme-purple .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after,
.vex-theme-purple.light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after,
.vex-theme-purple .light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after {
  color: rgba(245, 158, 11, 0.87);
}
.vex-theme-purple .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after,
.vex-theme-purple.light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after,
.vex-theme-purple .light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after {
  color: rgba(239, 68, 68, 0.87);
}
.vex-theme-purple .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after,
.vex-theme-purple.light .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after,
.vex-theme-purple .light .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.vex-theme-purple .mat-mdc-form-field.mat-accent,
.vex-theme-purple.light .mat-mdc-form-field.mat-accent,
.vex-theme-purple .light .mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #f59e0b;
  --mdc-filled-text-field-focus-active-indicator-color: #f59e0b;
  --mdc-filled-text-field-focus-label-text-color: rgba(245, 158, 11, 0.87);
  --mdc-outlined-text-field-caret-color: #f59e0b;
  --mdc-outlined-text-field-focus-outline-color: #f59e0b;
  --mdc-outlined-text-field-focus-label-text-color: rgba(245, 158, 11, 0.87);
}
.vex-theme-purple .mat-mdc-form-field.mat-warn,
.vex-theme-purple.light .mat-mdc-form-field.mat-warn,
.vex-theme-purple .light .mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #ef4444;
  --mdc-filled-text-field-focus-active-indicator-color: #ef4444;
  --mdc-filled-text-field-focus-label-text-color: rgba(239, 68, 68, 0.87);
  --mdc-outlined-text-field-caret-color: #ef4444;
  --mdc-outlined-text-field-focus-outline-color: #ef4444;
  --mdc-outlined-text-field-focus-label-text-color: rgba(239, 68, 68, 0.87);
}
.vex-theme-purple .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch,
.vex-theme-purple.light .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch,
.vex-theme-purple .light .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: 1px solid transparent;
}
.vex-theme-purple [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch,
.vex-theme-purple.light [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch,
.vex-theme-purple .light [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none;
  border-right: 1px solid transparent;
}
.vex-theme-purple, .vex-theme-purple.light, .vex-theme-purple .light {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(147, 51, 234, 0.87);
  --mat-select-invalid-arrow-color: rgba(239, 68, 68, 0.87);
}
.vex-theme-purple .mat-mdc-form-field.mat-accent, .vex-theme-purple.light .mat-mdc-form-field.mat-accent, .vex-theme-purple .light .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(245, 158, 11, 0.87);
  --mat-select-invalid-arrow-color: rgba(239, 68, 68, 0.87);
}
.vex-theme-purple .mat-mdc-form-field.mat-warn, .vex-theme-purple.light .mat-mdc-form-field.mat-warn, .vex-theme-purple .light .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(239, 68, 68, 0.87);
  --mat-select-invalid-arrow-color: rgba(239, 68, 68, 0.87);
}

.vex-theme-purple, .vex-theme-purple.light, .vex-theme-purple .light {
  --mat-autocomplete-background-color: white;
}

.vex-theme-purple .mat-mdc-dialog-container,
.vex-theme-purple.light .mat-mdc-dialog-container,
.vex-theme-purple .light .mat-mdc-dialog-container {
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}
.vex-theme-purple .mat-mdc-standard-chip,
.vex-theme-purple.light .mat-mdc-standard-chip,
.vex-theme-purple .light .mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
}
.vex-theme-purple .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .vex-theme-purple .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary,
.vex-theme-purple.light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary,
.vex-theme-purple.light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary,
.vex-theme-purple .light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary,
.vex-theme-purple .light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #9333ea;
  --mdc-chip-elevated-disabled-container-color: #9333ea;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.vex-theme-purple .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .vex-theme-purple .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent,
.vex-theme-purple.light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent,
.vex-theme-purple.light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent,
.vex-theme-purple .light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent,
.vex-theme-purple .light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #f59e0b;
  --mdc-chip-elevated-disabled-container-color: #f59e0b;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.vex-theme-purple .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .vex-theme-purple .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn,
.vex-theme-purple.light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn,
.vex-theme-purple.light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn,
.vex-theme-purple .light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn,
.vex-theme-purple .light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #ef4444;
  --mdc-chip-elevated-disabled-container-color: #ef4444;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.vex-theme-purple .mat-mdc-slide-toggle,
.vex-theme-purple.light .mat-mdc-slide-toggle,
.vex-theme-purple .light .mat-mdc-slide-toggle {
  --mdc-switch-selected-focus-state-layer-color: #9333ea;
  --mdc-switch-selected-handle-color: #9333ea;
  --mdc-switch-selected-hover-state-layer-color: #9333ea;
  --mdc-switch-selected-pressed-state-layer-color: #9333ea;
  --mdc-switch-selected-focus-handle-color: #581c87;
  --mdc-switch-selected-hover-handle-color: #581c87;
  --mdc-switch-selected-pressed-handle-color: #581c87;
  --mdc-switch-selected-focus-track-color: #d8b4fe;
  --mdc-switch-selected-hover-track-color: #d8b4fe;
  --mdc-switch-selected-pressed-track-color: #d8b4fe;
  --mdc-switch-selected-track-color: #d8b4fe;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
}
.vex-theme-purple .mat-mdc-slide-toggle .mdc-form-field,
.vex-theme-purple.light .mat-mdc-slide-toggle .mdc-form-field,
.vex-theme-purple .light .mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.vex-theme-purple .mat-mdc-slide-toggle .mdc-switch--disabled + label,
.vex-theme-purple.light .mat-mdc-slide-toggle .mdc-switch--disabled + label,
.vex-theme-purple .light .mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: #6b7280;
}
.vex-theme-purple .mat-mdc-slide-toggle.mat-accent,
.vex-theme-purple.light .mat-mdc-slide-toggle.mat-accent,
.vex-theme-purple .light .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #d97706;
  --mdc-switch-selected-handle-color: #d97706;
  --mdc-switch-selected-hover-state-layer-color: #d97706;
  --mdc-switch-selected-pressed-state-layer-color: #d97706;
  --mdc-switch-selected-focus-handle-color: #78350f;
  --mdc-switch-selected-hover-handle-color: #78350f;
  --mdc-switch-selected-pressed-handle-color: #78350f;
  --mdc-switch-selected-focus-track-color: #fcd34d;
  --mdc-switch-selected-hover-track-color: #fcd34d;
  --mdc-switch-selected-pressed-track-color: #fcd34d;
  --mdc-switch-selected-track-color: #fcd34d;
}
.vex-theme-purple .mat-mdc-slide-toggle.mat-warn,
.vex-theme-purple.light .mat-mdc-slide-toggle.mat-warn,
.vex-theme-purple .light .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #dc2626;
  --mdc-switch-selected-handle-color: #dc2626;
  --mdc-switch-selected-hover-state-layer-color: #dc2626;
  --mdc-switch-selected-pressed-state-layer-color: #dc2626;
  --mdc-switch-selected-focus-handle-color: #7f1d1d;
  --mdc-switch-selected-hover-handle-color: #7f1d1d;
  --mdc-switch-selected-pressed-handle-color: #7f1d1d;
  --mdc-switch-selected-focus-track-color: #fca5a5;
  --mdc-switch-selected-hover-track-color: #fca5a5;
  --mdc-switch-selected-pressed-track-color: #fca5a5;
  --mdc-switch-selected-track-color: #fca5a5;
}
.vex-theme-purple .mat-mdc-radio-button .mdc-form-field,
.vex-theme-purple.light .mat-mdc-radio-button .mdc-form-field,
.vex-theme-purple .light .mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.vex-theme-purple .mat-mdc-radio-button.mat-primary,
.vex-theme-purple.light .mat-mdc-radio-button.mat-primary,
.vex-theme-purple .light .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #9333ea;
  --mdc-radio-selected-hover-icon-color: #9333ea;
  --mdc-radio-selected-icon-color: #9333ea;
  --mdc-radio-selected-pressed-icon-color: #9333ea;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #9333ea;
  --mat-radio-disabled-label-color: #6b7280;
}
.vex-theme-purple .mat-mdc-radio-button.mat-accent,
.vex-theme-purple.light .mat-mdc-radio-button.mat-accent,
.vex-theme-purple .light .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f59e0b;
  --mdc-radio-selected-hover-icon-color: #f59e0b;
  --mdc-radio-selected-icon-color: #f59e0b;
  --mdc-radio-selected-pressed-icon-color: #f59e0b;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #f59e0b;
  --mat-radio-disabled-label-color: #6b7280;
}
.vex-theme-purple .mat-mdc-radio-button.mat-warn,
.vex-theme-purple.light .mat-mdc-radio-button.mat-warn,
.vex-theme-purple .light .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #ef4444;
  --mdc-radio-selected-hover-icon-color: #ef4444;
  --mdc-radio-selected-icon-color: #ef4444;
  --mdc-radio-selected-pressed-icon-color: #ef4444;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #ef4444;
  --mat-radio-disabled-label-color: #6b7280;
}
.vex-theme-purple .mat-mdc-slider,
.vex-theme-purple.light .mat-mdc-slider,
.vex-theme-purple .light .mat-mdc-slider {
  --mdc-slider-label-container-color: black;
  --mdc-slider-label-label-text-color: white;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-mdc-slider-value-indicator-opacity: 0.6;
}
.vex-theme-purple .mat-mdc-slider.mat-primary,
.vex-theme-purple.light .mat-mdc-slider.mat-primary,
.vex-theme-purple .light .mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #9333ea;
  --mdc-slider-focus-handle-color: #9333ea;
  --mdc-slider-hover-handle-color: #9333ea;
  --mdc-slider-active-track-color: #9333ea;
  --mdc-slider-inactive-track-color: #9333ea;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #9333ea;
  --mat-mdc-slider-ripple-color: #9333ea;
  --mat-mdc-slider-hover-ripple-color: rgba(147, 51, 234, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(147, 51, 234, 0.2);
}
.vex-theme-purple .mat-mdc-slider.mat-accent,
.vex-theme-purple.light .mat-mdc-slider.mat-accent,
.vex-theme-purple .light .mat-mdc-slider.mat-accent {
  --mdc-slider-handle-color: #f59e0b;
  --mdc-slider-focus-handle-color: #f59e0b;
  --mdc-slider-hover-handle-color: #f59e0b;
  --mdc-slider-active-track-color: #f59e0b;
  --mdc-slider-inactive-track-color: #f59e0b;
  --mdc-slider-with-tick-marks-active-container-color: #000;
  --mdc-slider-with-tick-marks-inactive-container-color: #f59e0b;
  --mat-mdc-slider-ripple-color: #f59e0b;
  --mat-mdc-slider-hover-ripple-color: rgba(245, 158, 11, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(245, 158, 11, 0.2);
}
.vex-theme-purple .mat-mdc-slider.mat-warn,
.vex-theme-purple.light .mat-mdc-slider.mat-warn,
.vex-theme-purple .light .mat-mdc-slider.mat-warn {
  --mdc-slider-handle-color: #ef4444;
  --mdc-slider-focus-handle-color: #ef4444;
  --mdc-slider-hover-handle-color: #ef4444;
  --mdc-slider-active-track-color: #ef4444;
  --mdc-slider-inactive-track-color: #ef4444;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #ef4444;
  --mat-mdc-slider-ripple-color: #ef4444;
  --mat-mdc-slider-hover-ripple-color: rgba(239, 68, 68, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(239, 68, 68, 0.2);
}
.vex-theme-purple, .vex-theme-purple.light, .vex-theme-purple .light {
  --mat-menu-item-label-text-color: #030712;
  --mat-menu-item-icon-color: #030712;
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
}

.vex-theme-purple .mat-mdc-list-base,
.vex-theme-purple.light .mat-mdc-list-base,
.vex-theme-purple .light .mat-mdc-list-base {
  --mdc-list-list-item-label-text-color: #030712;
  --mdc-list-list-item-supporting-text-color: #4b5563;
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: #6b7280;
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: #030712;
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: #030712;
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
}
.vex-theme-purple .mdc-list-item__start,
.vex-theme-purple .mdc-list-item__end,
.vex-theme-purple.light .mdc-list-item__start,
.vex-theme-purple.light .mdc-list-item__end,
.vex-theme-purple .light .mdc-list-item__start,
.vex-theme-purple .light .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #9333ea;
  --mdc-radio-selected-hover-icon-color: #9333ea;
  --mdc-radio-selected-icon-color: #9333ea;
  --mdc-radio-selected-pressed-icon-color: #9333ea;
}
.vex-theme-purple .mat-accent .mdc-list-item__start,
.vex-theme-purple .mat-accent .mdc-list-item__end,
.vex-theme-purple.light .mat-accent .mdc-list-item__start,
.vex-theme-purple.light .mat-accent .mdc-list-item__end,
.vex-theme-purple .light .mat-accent .mdc-list-item__start,
.vex-theme-purple .light .mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f59e0b;
  --mdc-radio-selected-hover-icon-color: #f59e0b;
  --mdc-radio-selected-icon-color: #f59e0b;
  --mdc-radio-selected-pressed-icon-color: #f59e0b;
}
.vex-theme-purple .mat-warn .mdc-list-item__start,
.vex-theme-purple .mat-warn .mdc-list-item__end,
.vex-theme-purple.light .mat-warn .mdc-list-item__start,
.vex-theme-purple.light .mat-warn .mdc-list-item__end,
.vex-theme-purple .light .mat-warn .mdc-list-item__start,
.vex-theme-purple .light .mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #ef4444;
  --mdc-radio-selected-hover-icon-color: #ef4444;
  --mdc-radio-selected-icon-color: #ef4444;
  --mdc-radio-selected-pressed-icon-color: #ef4444;
}
.vex-theme-purple .mat-mdc-list-option,
.vex-theme-purple.light .mat-mdc-list-option,
.vex-theme-purple .light .mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #9333ea;
  --mdc-checkbox-selected-hover-icon-color: #9333ea;
  --mdc-checkbox-selected-icon-color: #9333ea;
  --mdc-checkbox-selected-pressed-icon-color: #9333ea;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #9333ea;
  --mdc-checkbox-selected-hover-state-layer-color: #9333ea;
  --mdc-checkbox-selected-pressed-state-layer-color: #9333ea;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.vex-theme-purple .mat-mdc-list-option.mat-accent,
.vex-theme-purple.light .mat-mdc-list-option.mat-accent,
.vex-theme-purple .light .mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #f59e0b;
  --mdc-checkbox-selected-hover-icon-color: #f59e0b;
  --mdc-checkbox-selected-icon-color: #f59e0b;
  --mdc-checkbox-selected-pressed-icon-color: #f59e0b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f59e0b;
  --mdc-checkbox-selected-hover-state-layer-color: #f59e0b;
  --mdc-checkbox-selected-pressed-state-layer-color: #f59e0b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.vex-theme-purple .mat-mdc-list-option.mat-warn,
.vex-theme-purple.light .mat-mdc-list-option.mat-warn,
.vex-theme-purple .light .mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #ef4444;
  --mdc-checkbox-selected-hover-icon-color: #ef4444;
  --mdc-checkbox-selected-icon-color: #ef4444;
  --mdc-checkbox-selected-pressed-icon-color: #ef4444;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #ef4444;
  --mdc-checkbox-selected-hover-state-layer-color: #ef4444;
  --mdc-checkbox-selected-pressed-state-layer-color: #ef4444;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.vex-theme-purple .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.vex-theme-purple .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,
.vex-theme-purple.light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.vex-theme-purple.light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,
.vex-theme-purple .light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.vex-theme-purple .light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #9333ea;
}
.vex-theme-purple .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.vex-theme-purple .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.vex-theme-purple.light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.vex-theme-purple.light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.vex-theme-purple .light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.vex-theme-purple .light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #9333ea;
}
.vex-theme-purple .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.vex-theme-purple .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.vex-theme-purple .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end,
.vex-theme-purple.light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.vex-theme-purple.light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.vex-theme-purple.light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end,
.vex-theme-purple .light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.vex-theme-purple .light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.vex-theme-purple .light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.vex-theme-purple, .vex-theme-purple.light, .vex-theme-purple .light {
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
}

.vex-theme-purple .mat-mdc-tab-group, .vex-theme-purple .mat-mdc-tab-nav-bar,
.vex-theme-purple.light .mat-mdc-tab-group,
.vex-theme-purple.light .mat-mdc-tab-nav-bar,
.vex-theme-purple .light .mat-mdc-tab-group,
.vex-theme-purple .light .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #9333ea;
  --mat-tab-header-disabled-ripple-color: #6b7280;
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #9333ea;
  --mat-tab-header-active-ripple-color: #9333ea;
  --mat-tab-header-inactive-ripple-color: #9333ea;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #9333ea;
  --mat-tab-header-active-hover-label-text-color: #9333ea;
  --mat-tab-header-active-focus-indicator-color: #9333ea;
  --mat-tab-header-active-hover-indicator-color: #9333ea;
}
.vex-theme-purple .mat-mdc-tab-group.mat-accent, .vex-theme-purple .mat-mdc-tab-nav-bar.mat-accent,
.vex-theme-purple.light .mat-mdc-tab-group.mat-accent,
.vex-theme-purple.light .mat-mdc-tab-nav-bar.mat-accent,
.vex-theme-purple .light .mat-mdc-tab-group.mat-accent,
.vex-theme-purple .light .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #f59e0b;
  --mat-tab-header-disabled-ripple-color: #6b7280;
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #f59e0b;
  --mat-tab-header-active-ripple-color: #f59e0b;
  --mat-tab-header-inactive-ripple-color: #f59e0b;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #f59e0b;
  --mat-tab-header-active-hover-label-text-color: #f59e0b;
  --mat-tab-header-active-focus-indicator-color: #f59e0b;
  --mat-tab-header-active-hover-indicator-color: #f59e0b;
}
.vex-theme-purple .mat-mdc-tab-group.mat-warn, .vex-theme-purple .mat-mdc-tab-nav-bar.mat-warn,
.vex-theme-purple.light .mat-mdc-tab-group.mat-warn,
.vex-theme-purple.light .mat-mdc-tab-nav-bar.mat-warn,
.vex-theme-purple .light .mat-mdc-tab-group.mat-warn,
.vex-theme-purple .light .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #ef4444;
  --mat-tab-header-disabled-ripple-color: #6b7280;
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #ef4444;
  --mat-tab-header-active-ripple-color: #ef4444;
  --mat-tab-header-inactive-ripple-color: #ef4444;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #ef4444;
  --mat-tab-header-active-hover-label-text-color: #ef4444;
  --mat-tab-header-active-focus-indicator-color: #ef4444;
  --mat-tab-header-active-hover-indicator-color: #ef4444;
}
.vex-theme-purple .mat-mdc-tab-group.mat-background-primary, .vex-theme-purple .mat-mdc-tab-nav-bar.mat-background-primary,
.vex-theme-purple.light .mat-mdc-tab-group.mat-background-primary,
.vex-theme-purple.light .mat-mdc-tab-nav-bar.mat-background-primary,
.vex-theme-purple .light .mat-mdc-tab-group.mat-background-primary,
.vex-theme-purple .light .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #9333ea;
  --mat-tab-header-with-background-foreground-color: white;
}
.vex-theme-purple .mat-mdc-tab-group.mat-background-accent, .vex-theme-purple .mat-mdc-tab-nav-bar.mat-background-accent,
.vex-theme-purple.light .mat-mdc-tab-group.mat-background-accent,
.vex-theme-purple.light .mat-mdc-tab-nav-bar.mat-background-accent,
.vex-theme-purple .light .mat-mdc-tab-group.mat-background-accent,
.vex-theme-purple .light .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #f59e0b;
  --mat-tab-header-with-background-foreground-color: white;
}
.vex-theme-purple .mat-mdc-tab-group.mat-background-warn, .vex-theme-purple .mat-mdc-tab-nav-bar.mat-background-warn,
.vex-theme-purple.light .mat-mdc-tab-group.mat-background-warn,
.vex-theme-purple.light .mat-mdc-tab-nav-bar.mat-background-warn,
.vex-theme-purple .light .mat-mdc-tab-group.mat-background-warn,
.vex-theme-purple .light .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #ef4444;
  --mat-tab-header-with-background-foreground-color: white;
}
.vex-theme-purple, .vex-theme-purple.light, .vex-theme-purple .light {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #f59e0b;
  --mdc-checkbox-selected-hover-icon-color: #f59e0b;
  --mdc-checkbox-selected-icon-color: #f59e0b;
  --mdc-checkbox-selected-pressed-icon-color: #f59e0b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f59e0b;
  --mdc-checkbox-selected-hover-state-layer-color: #f59e0b;
  --mdc-checkbox-selected-pressed-state-layer-color: #f59e0b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.vex-theme-purple .mat-mdc-checkbox.mat-primary,
.vex-theme-purple.light .mat-mdc-checkbox.mat-primary,
.vex-theme-purple .light .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #9333ea;
  --mdc-checkbox-selected-hover-icon-color: #9333ea;
  --mdc-checkbox-selected-icon-color: #9333ea;
  --mdc-checkbox-selected-pressed-icon-color: #9333ea;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #9333ea;
  --mdc-checkbox-selected-hover-state-layer-color: #9333ea;
  --mdc-checkbox-selected-pressed-state-layer-color: #9333ea;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.vex-theme-purple .mat-mdc-checkbox.mat-warn,
.vex-theme-purple.light .mat-mdc-checkbox.mat-warn,
.vex-theme-purple .light .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #ef4444;
  --mdc-checkbox-selected-hover-icon-color: #ef4444;
  --mdc-checkbox-selected-icon-color: #ef4444;
  --mdc-checkbox-selected-pressed-icon-color: #ef4444;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #ef4444;
  --mdc-checkbox-selected-hover-state-layer-color: #ef4444;
  --mdc-checkbox-selected-pressed-state-layer-color: #ef4444;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.vex-theme-purple .mat-mdc-checkbox .mdc-form-field,
.vex-theme-purple.light .mat-mdc-checkbox .mdc-form-field,
.vex-theme-purple .light .mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.vex-theme-purple .mat-mdc-checkbox.mat-mdc-checkbox-disabled label,
.vex-theme-purple.light .mat-mdc-checkbox.mat-mdc-checkbox-disabled label,
.vex-theme-purple .light .mat-mdc-checkbox.mat-mdc-checkbox-disabled label {
  color: #6b7280;
}
.vex-theme-purple .mat-mdc-button.mat-unthemed,
.vex-theme-purple.light .mat-mdc-button.mat-unthemed,
.vex-theme-purple .light .mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #000;
}
.vex-theme-purple .mat-mdc-button.mat-primary,
.vex-theme-purple.light .mat-mdc-button.mat-primary,
.vex-theme-purple .light .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #9333ea;
}
.vex-theme-purple .mat-mdc-button.mat-accent,
.vex-theme-purple.light .mat-mdc-button.mat-accent,
.vex-theme-purple .light .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #f59e0b;
}
.vex-theme-purple .mat-mdc-button.mat-warn,
.vex-theme-purple.light .mat-mdc-button.mat-warn,
.vex-theme-purple .light .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #ef4444;
}
.vex-theme-purple .mat-mdc-button[disabled][disabled],
.vex-theme-purple.light .mat-mdc-button[disabled][disabled],
.vex-theme-purple .light .mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-text-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.vex-theme-purple .mat-mdc-unelevated-button.mat-unthemed,
.vex-theme-purple.light .mat-mdc-unelevated-button.mat-unthemed,
.vex-theme-purple .light .mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #fff;
  --mdc-filled-button-label-text-color: #000;
}
.vex-theme-purple .mat-mdc-unelevated-button.mat-primary,
.vex-theme-purple.light .mat-mdc-unelevated-button.mat-primary,
.vex-theme-purple .light .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #9333ea;
  --mdc-filled-button-label-text-color: #fff;
}
.vex-theme-purple .mat-mdc-unelevated-button.mat-accent,
.vex-theme-purple.light .mat-mdc-unelevated-button.mat-accent,
.vex-theme-purple .light .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #f59e0b;
  --mdc-filled-button-label-text-color: #000;
}
.vex-theme-purple .mat-mdc-unelevated-button.mat-warn,
.vex-theme-purple.light .mat-mdc-unelevated-button.mat-warn,
.vex-theme-purple .light .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #ef4444;
  --mdc-filled-button-label-text-color: #fff;
}
.vex-theme-purple .mat-mdc-unelevated-button[disabled][disabled],
.vex-theme-purple.light .mat-mdc-unelevated-button[disabled][disabled],
.vex-theme-purple .light .mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.vex-theme-purple .mat-mdc-raised-button.mat-unthemed,
.vex-theme-purple.light .mat-mdc-raised-button.mat-unthemed,
.vex-theme-purple .light .mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #fff;
  --mdc-protected-button-label-text-color: #000;
}
.vex-theme-purple .mat-mdc-raised-button.mat-primary,
.vex-theme-purple.light .mat-mdc-raised-button.mat-primary,
.vex-theme-purple .light .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #9333ea;
  --mdc-protected-button-label-text-color: #fff;
}
.vex-theme-purple .mat-mdc-raised-button.mat-accent,
.vex-theme-purple.light .mat-mdc-raised-button.mat-accent,
.vex-theme-purple .light .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #f59e0b;
  --mdc-protected-button-label-text-color: #000;
}
.vex-theme-purple .mat-mdc-raised-button.mat-warn,
.vex-theme-purple.light .mat-mdc-raised-button.mat-warn,
.vex-theme-purple .light .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #ef4444;
  --mdc-protected-button-label-text-color: #fff;
}
.vex-theme-purple .mat-mdc-raised-button[disabled][disabled],
.vex-theme-purple.light .mat-mdc-raised-button[disabled][disabled],
.vex-theme-purple .light .mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation: 0;
}
.vex-theme-purple .mat-mdc-outlined-button,
.vex-theme-purple.light .mat-mdc-outlined-button,
.vex-theme-purple .light .mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.vex-theme-purple .mat-mdc-outlined-button.mat-unthemed,
.vex-theme-purple.light .mat-mdc-outlined-button.mat-unthemed,
.vex-theme-purple .light .mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #000;
}
.vex-theme-purple .mat-mdc-outlined-button.mat-primary,
.vex-theme-purple.light .mat-mdc-outlined-button.mat-primary,
.vex-theme-purple .light .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #9333ea;
}
.vex-theme-purple .mat-mdc-outlined-button.mat-accent,
.vex-theme-purple.light .mat-mdc-outlined-button.mat-accent,
.vex-theme-purple .light .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #f59e0b;
}
.vex-theme-purple .mat-mdc-outlined-button.mat-warn,
.vex-theme-purple.light .mat-mdc-outlined-button.mat-warn,
.vex-theme-purple .light .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #ef4444;
}
.vex-theme-purple .mat-mdc-outlined-button[disabled][disabled],
.vex-theme-purple.light .mat-mdc-outlined-button[disabled][disabled],
.vex-theme-purple .light .mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
}
.vex-theme-purple .mat-mdc-button, .vex-theme-purple .mat-mdc-outlined-button,
.vex-theme-purple.light .mat-mdc-button,
.vex-theme-purple.light .mat-mdc-outlined-button,
.vex-theme-purple .light .mat-mdc-button,
.vex-theme-purple .light .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.vex-theme-purple .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .vex-theme-purple .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple.light .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple.light .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple .light .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple .light .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.vex-theme-purple .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-purple .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-purple .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-purple .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple.light .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple.light .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple.light .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple.light .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple .light .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple .light .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple .light .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple .light .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.vex-theme-purple .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .vex-theme-purple .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple.light .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple.light .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple .light .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple .light .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.vex-theme-purple .mat-mdc-button.mat-primary, .vex-theme-purple .mat-mdc-outlined-button.mat-primary,
.vex-theme-purple.light .mat-mdc-button.mat-primary,
.vex-theme-purple.light .mat-mdc-outlined-button.mat-primary,
.vex-theme-purple .light .mat-mdc-button.mat-primary,
.vex-theme-purple .light .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #9333ea;
  --mat-mdc-button-ripple-color: rgba(147, 51, 234, 0.1);
}
.vex-theme-purple .mat-mdc-button.mat-accent, .vex-theme-purple .mat-mdc-outlined-button.mat-accent,
.vex-theme-purple.light .mat-mdc-button.mat-accent,
.vex-theme-purple.light .mat-mdc-outlined-button.mat-accent,
.vex-theme-purple .light .mat-mdc-button.mat-accent,
.vex-theme-purple .light .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #f59e0b;
  --mat-mdc-button-ripple-color: rgba(245, 158, 11, 0.1);
}
.vex-theme-purple .mat-mdc-button.mat-warn, .vex-theme-purple .mat-mdc-outlined-button.mat-warn,
.vex-theme-purple.light .mat-mdc-button.mat-warn,
.vex-theme-purple.light .mat-mdc-outlined-button.mat-warn,
.vex-theme-purple .light .mat-mdc-button.mat-warn,
.vex-theme-purple .light .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #ef4444;
  --mat-mdc-button-ripple-color: rgba(239, 68, 68, 0.1);
}
.vex-theme-purple .mat-mdc-raised-button, .vex-theme-purple .mat-mdc-unelevated-button,
.vex-theme-purple.light .mat-mdc-raised-button,
.vex-theme-purple.light .mat-mdc-unelevated-button,
.vex-theme-purple .light .mat-mdc-raised-button,
.vex-theme-purple .light .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.vex-theme-purple .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .vex-theme-purple .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple.light .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple.light .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple .light .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple .light .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.vex-theme-purple .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-purple .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-purple .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-purple .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple.light .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple.light .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple.light .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple.light .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple .light .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple .light .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple .light .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple .light .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.vex-theme-purple .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .vex-theme-purple .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple.light .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple.light .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple .light .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple .light .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.vex-theme-purple .mat-mdc-raised-button.mat-primary, .vex-theme-purple .mat-mdc-unelevated-button.mat-primary,
.vex-theme-purple.light .mat-mdc-raised-button.mat-primary,
.vex-theme-purple.light .mat-mdc-unelevated-button.mat-primary,
.vex-theme-purple .light .mat-mdc-raised-button.mat-primary,
.vex-theme-purple .light .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.vex-theme-purple .mat-mdc-raised-button.mat-accent, .vex-theme-purple .mat-mdc-unelevated-button.mat-accent,
.vex-theme-purple.light .mat-mdc-raised-button.mat-accent,
.vex-theme-purple.light .mat-mdc-unelevated-button.mat-accent,
.vex-theme-purple .light .mat-mdc-raised-button.mat-accent,
.vex-theme-purple .light .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.vex-theme-purple .mat-mdc-raised-button.mat-warn, .vex-theme-purple .mat-mdc-unelevated-button.mat-warn,
.vex-theme-purple.light .mat-mdc-raised-button.mat-warn,
.vex-theme-purple.light .mat-mdc-unelevated-button.mat-warn,
.vex-theme-purple .light .mat-mdc-raised-button.mat-warn,
.vex-theme-purple .light .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.vex-theme-purple .mat-mdc-icon-button,
.vex-theme-purple.light .mat-mdc-icon-button,
.vex-theme-purple .light .mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mdc-icon-button-icon-color: inherit;
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.vex-theme-purple .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple.light .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple .light .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.vex-theme-purple .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-purple .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple.light .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple.light .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple .light .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple .light .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.vex-theme-purple .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple.light .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple .light .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.vex-theme-purple .mat-mdc-icon-button.mat-primary,
.vex-theme-purple.light .mat-mdc-icon-button.mat-primary,
.vex-theme-purple .light .mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #6200ee;
  --mat-mdc-button-ripple-color: rgba(98, 0, 238, 0.1);
}
.vex-theme-purple .mat-mdc-icon-button.mat-accent,
.vex-theme-purple.light .mat-mdc-icon-button.mat-accent,
.vex-theme-purple .light .mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #018786;
  --mat-mdc-button-ripple-color: rgba(1, 135, 134, 0.1);
}
.vex-theme-purple .mat-mdc-icon-button.mat-warn,
.vex-theme-purple.light .mat-mdc-icon-button.mat-warn,
.vex-theme-purple .light .mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #b00020;
  --mat-mdc-button-ripple-color: rgba(176, 0, 32, 0.1);
}
.vex-theme-purple .mat-mdc-icon-button.mat-primary,
.vex-theme-purple.light .mat-mdc-icon-button.mat-primary,
.vex-theme-purple .light .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #9333ea;
  --mat-mdc-button-persistent-ripple-color: #9333ea;
  --mat-mdc-button-ripple-color: rgba(147, 51, 234, 0.1);
}
.vex-theme-purple .mat-mdc-icon-button.mat-accent,
.vex-theme-purple.light .mat-mdc-icon-button.mat-accent,
.vex-theme-purple .light .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #f59e0b;
  --mat-mdc-button-persistent-ripple-color: #f59e0b;
  --mat-mdc-button-ripple-color: rgba(245, 158, 11, 0.1);
}
.vex-theme-purple .mat-mdc-icon-button.mat-warn,
.vex-theme-purple.light .mat-mdc-icon-button.mat-warn,
.vex-theme-purple .light .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #ef4444;
  --mat-mdc-button-persistent-ripple-color: #ef4444;
  --mat-mdc-button-ripple-color: rgba(239, 68, 68, 0.1);
}
.vex-theme-purple .mat-mdc-icon-button[disabled][disabled],
.vex-theme-purple.light .mat-mdc-icon-button[disabled][disabled],
.vex-theme-purple .light .mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
}
.vex-theme-purple .mat-mdc-fab,
.vex-theme-purple .mat-mdc-mini-fab,
.vex-theme-purple.light .mat-mdc-fab,
.vex-theme-purple.light .mat-mdc-mini-fab,
.vex-theme-purple .light .mat-mdc-fab,
.vex-theme-purple .light .mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.vex-theme-purple .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple.light .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple.light .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple .light .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple .light .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.vex-theme-purple .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-purple .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple.light .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple.light .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple.light .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple.light .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple .light .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple .light .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple .light .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple .light .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.vex-theme-purple .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple.light .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple.light .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple .light .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple .light .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.vex-theme-purple .mat-mdc-fab.mat-primary,
.vex-theme-purple .mat-mdc-mini-fab.mat-primary,
.vex-theme-purple.light .mat-mdc-fab.mat-primary,
.vex-theme-purple.light .mat-mdc-mini-fab.mat-primary,
.vex-theme-purple .light .mat-mdc-fab.mat-primary,
.vex-theme-purple .light .mat-mdc-mini-fab.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.vex-theme-purple .mat-mdc-fab.mat-accent,
.vex-theme-purple .mat-mdc-mini-fab.mat-accent,
.vex-theme-purple.light .mat-mdc-fab.mat-accent,
.vex-theme-purple.light .mat-mdc-mini-fab.mat-accent,
.vex-theme-purple .light .mat-mdc-fab.mat-accent,
.vex-theme-purple .light .mat-mdc-mini-fab.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.vex-theme-purple .mat-mdc-fab.mat-warn,
.vex-theme-purple .mat-mdc-mini-fab.mat-warn,
.vex-theme-purple.light .mat-mdc-fab.mat-warn,
.vex-theme-purple.light .mat-mdc-mini-fab.mat-warn,
.vex-theme-purple .light .mat-mdc-fab.mat-warn,
.vex-theme-purple .light .mat-mdc-mini-fab.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.vex-theme-purple .mat-mdc-fab[disabled][disabled],
.vex-theme-purple .mat-mdc-mini-fab[disabled][disabled],
.vex-theme-purple.light .mat-mdc-fab[disabled][disabled],
.vex-theme-purple.light .mat-mdc-mini-fab[disabled][disabled],
.vex-theme-purple .light .mat-mdc-fab[disabled][disabled],
.vex-theme-purple .light .mat-mdc-mini-fab[disabled][disabled] {
  --mdc-fab-container-color: rgba(0, 0, 0, 0.12);
  --mdc-fab-icon-color: rgba(0, 0, 0, 0.38);
  --mat-mdc-fab-color: rgba(0, 0, 0, 0.38);
}
.vex-theme-purple .mat-mdc-fab.mat-unthemed,
.vex-theme-purple .mat-mdc-mini-fab.mat-unthemed,
.vex-theme-purple.light .mat-mdc-fab.mat-unthemed,
.vex-theme-purple.light .mat-mdc-mini-fab.mat-unthemed,
.vex-theme-purple .light .mat-mdc-fab.mat-unthemed,
.vex-theme-purple .light .mat-mdc-mini-fab.mat-unthemed {
  --mdc-fab-container-color: white;
  --mdc-fab-icon-color: black;
  --mat-mdc-fab-color: #000;
}
.vex-theme-purple .mat-mdc-fab.mat-primary,
.vex-theme-purple .mat-mdc-mini-fab.mat-primary,
.vex-theme-purple.light .mat-mdc-fab.mat-primary,
.vex-theme-purple.light .mat-mdc-mini-fab.mat-primary,
.vex-theme-purple .light .mat-mdc-fab.mat-primary,
.vex-theme-purple .light .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #9333ea;
  --mdc-fab-icon-color: white;
  --mat-mdc-fab-color: #fff;
}
.vex-theme-purple .mat-mdc-fab.mat-accent,
.vex-theme-purple .mat-mdc-mini-fab.mat-accent,
.vex-theme-purple.light .mat-mdc-fab.mat-accent,
.vex-theme-purple.light .mat-mdc-mini-fab.mat-accent,
.vex-theme-purple .light .mat-mdc-fab.mat-accent,
.vex-theme-purple .light .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: #f59e0b;
  --mdc-fab-icon-color: black;
  --mat-mdc-fab-color: #000;
}
.vex-theme-purple .mat-mdc-fab.mat-warn,
.vex-theme-purple .mat-mdc-mini-fab.mat-warn,
.vex-theme-purple.light .mat-mdc-fab.mat-warn,
.vex-theme-purple.light .mat-mdc-mini-fab.mat-warn,
.vex-theme-purple .light .mat-mdc-fab.mat-warn,
.vex-theme-purple .light .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: #ef4444;
  --mdc-fab-icon-color: white;
  --mat-mdc-fab-color: #fff;
}
.vex-theme-purple .mat-mdc-snack-bar-container,
.vex-theme-purple.light .mat-mdc-snack-bar-container,
.vex-theme-purple .light .mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #f59e0b;
}
.vex-theme-purple .mat-mdc-progress-spinner,
.vex-theme-purple.light .mat-mdc-progress-spinner,
.vex-theme-purple .light .mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #9333ea;
}
.vex-theme-purple .mat-mdc-progress-spinner.mat-accent,
.vex-theme-purple.light .mat-mdc-progress-spinner.mat-accent,
.vex-theme-purple .light .mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: #f59e0b;
}
.vex-theme-purple .mat-mdc-progress-spinner.mat-warn,
.vex-theme-purple.light .mat-mdc-progress-spinner.mat-warn,
.vex-theme-purple .light .mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: #ef4444;
}
.vex-theme-purple, .vex-theme-purple.light, .vex-theme-purple .light {
  --mat-badge-background-color: #9333ea;
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: #9ca3af;
  --mat-badge-disabled-state-text-color: #6b7280;
}

.vex-theme-purple .mat-badge-accent,
.vex-theme-purple.light .mat-badge-accent,
.vex-theme-purple .light .mat-badge-accent {
  --mat-badge-background-color: #f59e0b;
  --mat-badge-text-color: white;
}
.vex-theme-purple .mat-badge-warn,
.vex-theme-purple.light .mat-badge-warn,
.vex-theme-purple .light .mat-badge-warn {
  --mat-badge-background-color: #ef4444;
  --mat-badge-text-color: white;
}
.vex-theme-purple, .vex-theme-purple.light, .vex-theme-purple .light {
  --mat-bottom-sheet-container-text-color: #030712;
  --mat-bottom-sheet-container-background-color: white;
}

.vex-theme-purple, .vex-theme-purple.light, .vex-theme-purple .light {
  --mat-legacy-button-toggle-text-color: #6b7280;
  --mat-legacy-button-toggle-state-layer-color: #e5e7eb;
  --mat-legacy-button-toggle-selected-state-text-color: #4b5563;
  --mat-legacy-button-toggle-selected-state-background-color: #d1d5db;
  --mat-legacy-button-toggle-disabled-state-text-color: #9ca3af;
  --mat-legacy-button-toggle-disabled-state-background-color: #e5e7eb;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #9ca3af;
  --mat-standard-button-toggle-text-color: #030712;
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: #e5e7eb;
  --mat-standard-button-toggle-selected-state-background-color: #d1d5db;
  --mat-standard-button-toggle-selected-state-text-color: #030712;
  --mat-standard-button-toggle-disabled-state-text-color: #9ca3af;
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: #030712;
  --mat-standard-button-toggle-disabled-selected-state-background-color: #9ca3af;
  --mat-standard-button-toggle-divider-color: #f0f0f0;
}

.vex-theme-purple, .vex-theme-purple.light, .vex-theme-purple .light {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #9333ea;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(147, 51, 234, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(147, 51, 234, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(147, 51, 234, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #9333ea;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(147, 51, 234, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: #1f2937;
  --mat-datepicker-calendar-body-label-text-color: #4b5563;
  --mat-datepicker-calendar-period-button-icon-color: #1f2937;
  --mat-datepicker-calendar-navigation-button-icon-color: #1f2937;
  --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.06);
  --mat-datepicker-calendar-header-text-color: #4b5563;
  --mat-datepicker-calendar-date-today-outline-color: #6b7280;
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(107, 114, 128, 0.8);
  --mat-datepicker-calendar-date-text-color: #030712;
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: #6b7280;
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, 0.12);
  --mat-datepicker-range-input-separator-color: #030712;
  --mat-datepicker-range-input-disabled-state-separator-color: #6b7280;
  --mat-datepicker-range-input-disabled-state-text-color: #6b7280;
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: #030712;
}

.vex-theme-purple .mat-datepicker-content.mat-accent,
.vex-theme-purple.light .mat-datepicker-content.mat-accent,
.vex-theme-purple .light .mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #f59e0b;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(245, 158, 11, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(245, 158, 11, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(245, 158, 11, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(245, 158, 11, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.vex-theme-purple .mat-datepicker-content.mat-warn,
.vex-theme-purple.light .mat-datepicker-content.mat-warn,
.vex-theme-purple .light .mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #ef4444;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(239, 68, 68, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(239, 68, 68, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(239, 68, 68, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(239, 68, 68, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.vex-theme-purple .mat-datepicker-toggle-active.mat-accent,
.vex-theme-purple.light .mat-datepicker-toggle-active.mat-accent,
.vex-theme-purple .light .mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #f59e0b;
}
.vex-theme-purple .mat-datepicker-toggle-active.mat-warn,
.vex-theme-purple.light .mat-datepicker-toggle-active.mat-warn,
.vex-theme-purple .light .mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #ef4444;
}
.vex-theme-purple, .vex-theme-purple.light, .vex-theme-purple .light {
  --mat-divider-color: rgba(0, 0, 0, 0.06);
}

.vex-theme-purple, .vex-theme-purple.light, .vex-theme-purple .light {
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: #030712;
  --mat-expansion-actions-divider-color: rgba(0, 0, 0, 0.06);
  --mat-expansion-header-hover-state-layer-color: rgba(17, 24, 39, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(17, 24, 39, 0.04);
  --mat-expansion-header-disabled-state-text-color: #9ca3af;
  --mat-expansion-header-text-color: #030712;
  --mat-expansion-header-description-color: #4b5563;
  --mat-expansion-header-indicator-color: #4b5563;
}

.vex-theme-purple, .vex-theme-purple.light, .vex-theme-purple .light {
  --mat-icon-color: inherit;
}

.vex-theme-purple .mat-icon.mat-primary,
.vex-theme-purple.light .mat-icon.mat-primary,
.vex-theme-purple .light .mat-icon.mat-primary {
  --mat-icon-color: #9333ea;
}
.vex-theme-purple .mat-icon.mat-accent,
.vex-theme-purple.light .mat-icon.mat-accent,
.vex-theme-purple .light .mat-icon.mat-accent {
  --mat-icon-color: #f59e0b;
}
.vex-theme-purple .mat-icon.mat-warn,
.vex-theme-purple.light .mat-icon.mat-warn,
.vex-theme-purple .light .mat-icon.mat-warn {
  --mat-icon-color: #ef4444;
}
.vex-theme-purple, .vex-theme-purple.light, .vex-theme-purple .light {
  --mat-sidenav-container-divider-color: rgba(0, 0, 0, 0.06);
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: #030712;
  --mat-sidenav-content-background-color: #f3f4f6;
  --mat-sidenav-content-text-color: #030712;
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
}

.vex-theme-purple, .vex-theme-purple.light, .vex-theme-purple .light {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #9333ea;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #9333ea;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #9333ea;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: rgba(0, 0, 0, 0.06);
  --mat-stepper-header-hover-state-layer-color: rgba(17, 24, 39, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(17, 24, 39, 0.04);
  --mat-stepper-header-label-text-color: #4b5563;
  --mat-stepper-header-optional-label-text-color: #4b5563;
  --mat-stepper-header-selected-state-label-text-color: #030712;
  --mat-stepper-header-error-state-label-text-color: #ef4444;
  --mat-stepper-header-icon-background-color: #4b5563;
  --mat-stepper-header-error-state-icon-foreground-color: #ef4444;
  --mat-stepper-header-error-state-icon-background-color: transparent;
}
.vex-theme-purple .mat-step-header.mat-accent, .vex-theme-purple.light .mat-step-header.mat-accent, .vex-theme-purple .light .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #f59e0b;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #f59e0b;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #f59e0b;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.vex-theme-purple .mat-step-header.mat-warn, .vex-theme-purple.light .mat-step-header.mat-warn, .vex-theme-purple .light .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #ef4444;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #ef4444;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #ef4444;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}

.vex-theme-purple .mat-sort-header-arrow,
.vex-theme-purple.light .mat-sort-header-arrow,
.vex-theme-purple .light .mat-sort-header-arrow {
  color: #4b5563;
}
.vex-theme-purple, .vex-theme-purple.light, .vex-theme-purple .light {
  --mat-toolbar-container-background-color: #e5e7eb;
  --mat-toolbar-container-text-color: #030712;
}

.vex-theme-purple .mat-toolbar.mat-primary,
.vex-theme-purple.light .mat-toolbar.mat-primary,
.vex-theme-purple .light .mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #9333ea;
  --mat-toolbar-container-text-color: white;
}
.vex-theme-purple .mat-toolbar.mat-accent,
.vex-theme-purple.light .mat-toolbar.mat-accent,
.vex-theme-purple .light .mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #f59e0b;
  --mat-toolbar-container-text-color: white;
}
.vex-theme-purple .mat-toolbar.mat-warn,
.vex-theme-purple.light .mat-toolbar.mat-warn,
.vex-theme-purple .light .mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #ef4444;
  --mat-toolbar-container-text-color: white;
}
.vex-theme-purple .mat-tree,
.vex-theme-purple.light .mat-tree,
.vex-theme-purple .light .mat-tree {
  background: white;
}
.vex-theme-purple .mat-tree-node,
.vex-theme-purple .mat-nested-tree-node,
.vex-theme-purple.light .mat-tree-node,
.vex-theme-purple.light .mat-nested-tree-node,
.vex-theme-purple .light .mat-tree-node,
.vex-theme-purple .light .mat-nested-tree-node {
  color: #030712;
}

/**
 * Override the theme foreground/background colors with the Angular Material colors
 */
.vex-theme-purple.dark,
.vex-theme-purple .dark {
  --mat-table-background-color: #1f2937;
  --mat-table-header-headline-color: white;
  --mat-table-row-item-label-text-color: white;
  --mat-table-row-item-outline-color: rgba(255, 255, 255, 0.12);
}
.vex-theme-purple.dark .mat-ripple-element,
.vex-theme-purple .dark .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.vex-theme-purple.dark, .vex-theme-purple .dark {
  --mat-option-selected-state-label-text-color: #a855f7;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
}

.vex-theme-purple.dark .mat-accent,
.vex-theme-purple .dark .mat-accent {
  --mat-option-selected-state-label-text-color: #f59e0b;
}
.vex-theme-purple.dark .mat-warn,
.vex-theme-purple .dark .mat-warn {
  --mat-option-selected-state-label-text-color: #ef4444;
}
.vex-theme-purple.dark, .vex-theme-purple .dark {
  --mat-optgroup-label-text-color: white;
}

.vex-theme-purple.dark .mat-pseudo-checkbox-full,
.vex-theme-purple .dark .mat-pseudo-checkbox-full {
  color: #9ca3af;
}
.vex-theme-purple.dark .mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled,
.vex-theme-purple .dark .mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled {
  color: #686868;
}
.vex-theme-purple.dark .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-purple.dark .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-purple .dark .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-purple .dark .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #a855f7;
}
.vex-theme-purple.dark .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-purple.dark .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-purple .dark .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-purple .dark .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #a855f7;
}
.vex-theme-purple.dark .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-purple.dark .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after,
.vex-theme-purple .dark .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-purple .dark .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #111827;
}
.vex-theme-purple.dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-purple.dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-purple .dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-purple .dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #f59e0b;
}
.vex-theme-purple.dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-purple.dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-purple .dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-purple .dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #f59e0b;
}
.vex-theme-purple.dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-purple.dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after,
.vex-theme-purple .dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-purple .dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #111827;
}
.vex-theme-purple.dark .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-purple.dark .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-purple .dark .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-purple .dark .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #f59e0b;
}
.vex-theme-purple.dark .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-purple.dark .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-purple .dark .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-purple .dark .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #f59e0b;
}
.vex-theme-purple.dark .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-purple.dark .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after,
.vex-theme-purple .dark .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-purple .dark .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #111827;
}
.vex-theme-purple.dark .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-purple.dark .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-purple .dark .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-purple .dark .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #ef4444;
}
.vex-theme-purple.dark .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-purple.dark .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-purple .dark .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-purple .dark .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #ef4444;
}
.vex-theme-purple.dark .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-purple.dark .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after,
.vex-theme-purple .dark .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-purple .dark .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #111827;
}
.vex-theme-purple.dark .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-purple.dark .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-purple .dark .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-purple .dark .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #686868;
}
.vex-theme-purple.dark .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-purple.dark .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-purple .dark .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-purple .dark .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #686868;
}
.vex-theme-purple.dark .mat-app-background, .vex-theme-purple.dark.mat-app-background,
.vex-theme-purple .dark .mat-app-background,
.vex-theme-purple .dark.mat-app-background {
  background-color: #111827;
  color: white;
}
.vex-theme-purple.dark .mat-elevation-z0, .vex-theme-purple.dark .mat-mdc-elevation-specific.mat-elevation-z0,
.vex-theme-purple .dark .mat-elevation-z0,
.vex-theme-purple .dark .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-purple.dark .mat-elevation-z1, .vex-theme-purple.dark .mat-mdc-elevation-specific.mat-elevation-z1,
.vex-theme-purple .dark .mat-elevation-z1,
.vex-theme-purple .dark .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-purple.dark .mat-elevation-z2, .vex-theme-purple.dark .mat-mdc-elevation-specific.mat-elevation-z2,
.vex-theme-purple .dark .mat-elevation-z2,
.vex-theme-purple .dark .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-purple.dark .mat-elevation-z3, .vex-theme-purple.dark .mat-mdc-elevation-specific.mat-elevation-z3,
.vex-theme-purple .dark .mat-elevation-z3,
.vex-theme-purple .dark .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-purple.dark .mat-elevation-z4, .vex-theme-purple.dark .mat-mdc-elevation-specific.mat-elevation-z4,
.vex-theme-purple .dark .mat-elevation-z4,
.vex-theme-purple .dark .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-purple.dark .mat-elevation-z5, .vex-theme-purple.dark .mat-mdc-elevation-specific.mat-elevation-z5,
.vex-theme-purple .dark .mat-elevation-z5,
.vex-theme-purple .dark .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-purple.dark .mat-elevation-z6, .vex-theme-purple.dark .mat-mdc-elevation-specific.mat-elevation-z6,
.vex-theme-purple .dark .mat-elevation-z6,
.vex-theme-purple .dark .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-purple.dark .mat-elevation-z7, .vex-theme-purple.dark .mat-mdc-elevation-specific.mat-elevation-z7,
.vex-theme-purple .dark .mat-elevation-z7,
.vex-theme-purple .dark .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.vex-theme-purple.dark .mat-elevation-z8, .vex-theme-purple.dark .mat-mdc-elevation-specific.mat-elevation-z8,
.vex-theme-purple .dark .mat-elevation-z8,
.vex-theme-purple .dark .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.vex-theme-purple.dark .mat-elevation-z9, .vex-theme-purple.dark .mat-mdc-elevation-specific.mat-elevation-z9,
.vex-theme-purple .dark .mat-elevation-z9,
.vex-theme-purple .dark .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.vex-theme-purple.dark .mat-elevation-z10, .vex-theme-purple.dark .mat-mdc-elevation-specific.mat-elevation-z10,
.vex-theme-purple .dark .mat-elevation-z10,
.vex-theme-purple .dark .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.vex-theme-purple.dark .mat-elevation-z11, .vex-theme-purple.dark .mat-mdc-elevation-specific.mat-elevation-z11,
.vex-theme-purple .dark .mat-elevation-z11,
.vex-theme-purple .dark .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.vex-theme-purple.dark .mat-elevation-z12, .vex-theme-purple.dark .mat-mdc-elevation-specific.mat-elevation-z12,
.vex-theme-purple .dark .mat-elevation-z12,
.vex-theme-purple .dark .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.vex-theme-purple.dark .mat-elevation-z13, .vex-theme-purple.dark .mat-mdc-elevation-specific.mat-elevation-z13,
.vex-theme-purple .dark .mat-elevation-z13,
.vex-theme-purple .dark .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.vex-theme-purple.dark .mat-elevation-z14, .vex-theme-purple.dark .mat-mdc-elevation-specific.mat-elevation-z14,
.vex-theme-purple .dark .mat-elevation-z14,
.vex-theme-purple .dark .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.vex-theme-purple.dark .mat-elevation-z15, .vex-theme-purple.dark .mat-mdc-elevation-specific.mat-elevation-z15,
.vex-theme-purple .dark .mat-elevation-z15,
.vex-theme-purple .dark .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.vex-theme-purple.dark .mat-elevation-z16, .vex-theme-purple.dark .mat-mdc-elevation-specific.mat-elevation-z16,
.vex-theme-purple .dark .mat-elevation-z16,
.vex-theme-purple .dark .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.vex-theme-purple.dark .mat-elevation-z17, .vex-theme-purple.dark .mat-mdc-elevation-specific.mat-elevation-z17,
.vex-theme-purple .dark .mat-elevation-z17,
.vex-theme-purple .dark .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.vex-theme-purple.dark .mat-elevation-z18, .vex-theme-purple.dark .mat-mdc-elevation-specific.mat-elevation-z18,
.vex-theme-purple .dark .mat-elevation-z18,
.vex-theme-purple .dark .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.vex-theme-purple.dark .mat-elevation-z19, .vex-theme-purple.dark .mat-mdc-elevation-specific.mat-elevation-z19,
.vex-theme-purple .dark .mat-elevation-z19,
.vex-theme-purple .dark .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.vex-theme-purple.dark .mat-elevation-z20, .vex-theme-purple.dark .mat-mdc-elevation-specific.mat-elevation-z20,
.vex-theme-purple .dark .mat-elevation-z20,
.vex-theme-purple .dark .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.vex-theme-purple.dark .mat-elevation-z21, .vex-theme-purple.dark .mat-mdc-elevation-specific.mat-elevation-z21,
.vex-theme-purple .dark .mat-elevation-z21,
.vex-theme-purple .dark .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.vex-theme-purple.dark .mat-elevation-z22, .vex-theme-purple.dark .mat-mdc-elevation-specific.mat-elevation-z22,
.vex-theme-purple .dark .mat-elevation-z22,
.vex-theme-purple .dark .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.vex-theme-purple.dark .mat-elevation-z23, .vex-theme-purple.dark .mat-mdc-elevation-specific.mat-elevation-z23,
.vex-theme-purple .dark .mat-elevation-z23,
.vex-theme-purple .dark .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.vex-theme-purple.dark .mat-elevation-z24, .vex-theme-purple.dark .mat-mdc-elevation-specific.mat-elevation-z24,
.vex-theme-purple .dark .mat-elevation-z24,
.vex-theme-purple .dark .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.vex-theme-purple.dark .mat-mdc-card,
.vex-theme-purple .dark .mat-mdc-card {
  --mdc-elevated-card-container-color: #1f2937;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: #1f2937;
  --mdc-outlined-card-outline-color: rgba(255, 255, 255, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: #9ca3af;
}
.vex-theme-purple.dark .mat-mdc-progress-bar,
.vex-theme-purple .dark .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #a855f7;
  --mdc-linear-progress-track-color: rgba(168, 85, 247, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.vex-theme-purple.dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots,
.vex-theme-purple .dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-color: rgba(168, 85, 247, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(168, 85, 247, 0.25));
}
@media (forced-colors: active) {
  .vex-theme-purple.dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots,
  .vex-theme-purple .dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .vex-theme-purple.dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots,
  .vex-theme-purple .dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(168, 85, 247, 0.25)'/%3E%3C/svg%3E");
  }
}
.vex-theme-purple.dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar,
.vex-theme-purple .dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgba(168, 85, 247, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(168, 85, 247, 0.25));
}
.vex-theme-purple.dark .mat-mdc-progress-bar.mat-accent,
.vex-theme-purple .dark .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #f59e0b;
  --mdc-linear-progress-track-color: rgba(245, 158, 11, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.vex-theme-purple.dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots,
.vex-theme-purple .dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-color: rgba(245, 158, 11, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(245, 158, 11, 0.25));
}
@media (forced-colors: active) {
  .vex-theme-purple.dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots,
  .vex-theme-purple .dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .vex-theme-purple.dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots,
  .vex-theme-purple .dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(245, 158, 11, 0.25)'/%3E%3C/svg%3E");
  }
}
.vex-theme-purple.dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar,
.vex-theme-purple .dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: rgba(245, 158, 11, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(245, 158, 11, 0.25));
}
.vex-theme-purple.dark .mat-mdc-progress-bar.mat-warn,
.vex-theme-purple .dark .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #ef4444;
  --mdc-linear-progress-track-color: rgba(239, 68, 68, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.vex-theme-purple.dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots,
.vex-theme-purple .dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-color: rgba(239, 68, 68, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(239, 68, 68, 0.25));
}
@media (forced-colors: active) {
  .vex-theme-purple.dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots,
  .vex-theme-purple .dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .vex-theme-purple.dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots,
  .vex-theme-purple .dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(239, 68, 68, 0.25)'/%3E%3C/svg%3E");
  }
}
.vex-theme-purple.dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar,
.vex-theme-purple .dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: rgba(239, 68, 68, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(239, 68, 68, 0.25));
}
.vex-theme-purple.dark .mat-mdc-tooltip,
.vex-theme-purple .dark .mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #374151;
  --mdc-plain-tooltip-supporting-text-color: #fff;
}
.vex-theme-purple.dark, .vex-theme-purple .dark {
  --mdc-filled-text-field-caret-color: #a855f7;
  --mdc-filled-text-field-focus-active-indicator-color: #a855f7;
  --mdc-filled-text-field-focus-label-text-color: rgba(168, 85, 247, 0.87);
  --mdc-filled-text-field-container-color: #28323f;
  --mdc-filled-text-field-disabled-container-color: #232d3b;
  --mdc-filled-text-field-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(255, 255, 255, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(255, 255, 255, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(255, 255, 255, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-error-focus-label-text-color: #ef4444;
  --mdc-filled-text-field-error-label-text-color: #ef4444;
  --mdc-filled-text-field-error-caret-color: #ef4444;
  --mdc-filled-text-field-active-indicator-color: rgba(255, 255, 255, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(255, 255, 255, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(255, 255, 255, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #ef4444;
  --mdc-filled-text-field-error-focus-active-indicator-color: #ef4444;
  --mdc-filled-text-field-error-hover-active-indicator-color: #ef4444;
  --mdc-outlined-text-field-caret-color: #a855f7;
  --mdc-outlined-text-field-focus-outline-color: #a855f7;
  --mdc-outlined-text-field-focus-label-text-color: rgba(168, 85, 247, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(255, 255, 255, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(255, 255, 255, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(255, 255, 255, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-error-caret-color: #ef4444;
  --mdc-outlined-text-field-error-focus-label-text-color: #ef4444;
  --mdc-outlined-text-field-error-label-text-color: #ef4444;
  --mdc-outlined-text-field-outline-color: rgba(255, 255, 255, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(255, 255, 255, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(255, 255, 255, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #ef4444;
  --mdc-outlined-text-field-error-hover-outline-color: #ef4444;
  --mdc-outlined-text-field-error-outline-color: #ef4444;
  --mat-form-field-disabled-input-text-placeholder-color: rgba(255, 255, 255, 0.38);
}

.vex-theme-purple.dark .mat-mdc-form-field-error,
.vex-theme-purple .dark .mat-mdc-form-field-error {
  color: var(--mdc-theme-error, #ef4444);
}
.vex-theme-purple.dark .mat-mdc-form-field-subscript-wrapper,
.vex-theme-purple.dark .mat-mdc-form-field-bottom-align::before,
.vex-theme-purple .dark .mat-mdc-form-field-subscript-wrapper,
.vex-theme-purple .dark .mat-mdc-form-field-bottom-align::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mat-form-field-subscript-text-font);
  line-height: var(--mat-form-field-subscript-text-line-height);
  font-size: var(--mat-form-field-subscript-text-size);
  letter-spacing: var(--mat-form-field-subscript-text-tracking);
  font-weight: var(--mat-form-field-subscript-text-weight);
}
.vex-theme-purple.dark .mat-mdc-form-field-focus-overlay,
.vex-theme-purple .dark .mat-mdc-form-field-focus-overlay {
  background-color: rgba(255, 255, 255, 0.87);
}
.vex-theme-purple.dark .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay,
.vex-theme-purple .dark .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0.08;
}
.vex-theme-purple.dark .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay,
.vex-theme-purple .dark .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.24;
}
.vex-theme-purple.dark select.mat-mdc-form-field-input-control:not(.mat-mdc-native-select-inline) option,
.vex-theme-purple .dark select.mat-mdc-form-field-input-control:not(.mat-mdc-native-select-inline) option {
  color: rgba(0, 0, 0, 0.87);
}
.vex-theme-purple.dark select.mat-mdc-form-field-input-control:not(.mat-mdc-native-select-inline) option:disabled,
.vex-theme-purple .dark select.mat-mdc-form-field-input-control:not(.mat-mdc-native-select-inline) option:disabled {
  color: rgba(0, 0, 0, 0.38);
}
.vex-theme-purple.dark .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after,
.vex-theme-purple .dark .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
  color: rgba(255, 255, 255, 0.54);
}
.vex-theme-purple.dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after,
.vex-theme-purple .dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after {
  color: rgba(168, 85, 247, 0.87);
}
.vex-theme-purple.dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after,
.vex-theme-purple .dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after {
  color: rgba(245, 158, 11, 0.87);
}
.vex-theme-purple.dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after,
.vex-theme-purple .dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after {
  color: rgba(239, 68, 68, 0.87);
}
.vex-theme-purple.dark .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after,
.vex-theme-purple .dark .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  color: rgba(255, 255, 255, 0.38);
}
.vex-theme-purple.dark .mat-mdc-form-field.mat-accent,
.vex-theme-purple .dark .mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #f59e0b;
  --mdc-filled-text-field-focus-active-indicator-color: #f59e0b;
  --mdc-filled-text-field-focus-label-text-color: rgba(245, 158, 11, 0.87);
  --mdc-outlined-text-field-caret-color: #f59e0b;
  --mdc-outlined-text-field-focus-outline-color: #f59e0b;
  --mdc-outlined-text-field-focus-label-text-color: rgba(245, 158, 11, 0.87);
}
.vex-theme-purple.dark .mat-mdc-form-field.mat-warn,
.vex-theme-purple .dark .mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #ef4444;
  --mdc-filled-text-field-focus-active-indicator-color: #ef4444;
  --mdc-filled-text-field-focus-label-text-color: rgba(239, 68, 68, 0.87);
  --mdc-outlined-text-field-caret-color: #ef4444;
  --mdc-outlined-text-field-focus-outline-color: #ef4444;
  --mdc-outlined-text-field-focus-label-text-color: rgba(239, 68, 68, 0.87);
}
.vex-theme-purple.dark .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch,
.vex-theme-purple .dark .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: 1px solid transparent;
}
.vex-theme-purple.dark [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch,
.vex-theme-purple .dark [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none;
  border-right: 1px solid transparent;
}
.vex-theme-purple.dark, .vex-theme-purple .dark {
  --mat-select-panel-background-color: #1f2937;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(255, 255, 255, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(255, 255, 255, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(168, 85, 247, 0.87);
  --mat-select-invalid-arrow-color: rgba(239, 68, 68, 0.87);
}
.vex-theme-purple.dark .mat-mdc-form-field.mat-accent, .vex-theme-purple .dark .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: #1f2937;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(255, 255, 255, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(255, 255, 255, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(245, 158, 11, 0.87);
  --mat-select-invalid-arrow-color: rgba(239, 68, 68, 0.87);
}
.vex-theme-purple.dark .mat-mdc-form-field.mat-warn, .vex-theme-purple .dark .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: #1f2937;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(255, 255, 255, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(255, 255, 255, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(239, 68, 68, 0.87);
  --mat-select-invalid-arrow-color: rgba(239, 68, 68, 0.87);
}

.vex-theme-purple.dark, .vex-theme-purple .dark {
  --mat-autocomplete-background-color: #1f2937;
}

.vex-theme-purple.dark .mat-mdc-dialog-container,
.vex-theme-purple .dark .mat-mdc-dialog-container {
  --mdc-dialog-container-color: #1f2937;
  --mdc-dialog-subhead-color: rgba(255, 255, 255, 0.87);
  --mdc-dialog-supporting-text-color: rgba(255, 255, 255, 0.6);
}
.vex-theme-purple.dark .mat-mdc-standard-chip,
.vex-theme-purple .dark .mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #fafafa;
  --mdc-chip-elevated-container-color: #3a434f;
  --mdc-chip-elevated-disabled-container-color: #3a434f;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #fafafa;
  --mdc-chip-with-icon-icon-color: #fafafa;
  --mdc-chip-with-icon-disabled-icon-color: #fafafa;
  --mdc-chip-with-icon-selected-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #fafafa;
}
.vex-theme-purple.dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .vex-theme-purple.dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary,
.vex-theme-purple .dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary,
.vex-theme-purple .dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #a855f7;
  --mdc-chip-elevated-disabled-container-color: #a855f7;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.vex-theme-purple.dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .vex-theme-purple.dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent,
.vex-theme-purple .dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent,
.vex-theme-purple .dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #f59e0b;
  --mdc-chip-elevated-disabled-container-color: #f59e0b;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.vex-theme-purple.dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .vex-theme-purple.dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn,
.vex-theme-purple .dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn,
.vex-theme-purple .dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #ef4444;
  --mdc-chip-elevated-disabled-container-color: #ef4444;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.vex-theme-purple.dark .mat-mdc-slide-toggle,
.vex-theme-purple .dark .mat-mdc-slide-toggle {
  --mdc-switch-selected-focus-state-layer-color: #d8b4fe;
  --mdc-switch-selected-handle-color: #d8b4fe;
  --mdc-switch-selected-hover-state-layer-color: #d8b4fe;
  --mdc-switch-selected-pressed-state-layer-color: #d8b4fe;
  --mdc-switch-selected-focus-handle-color: #e9d5ff;
  --mdc-switch-selected-hover-handle-color: #e9d5ff;
  --mdc-switch-selected-pressed-handle-color: #e9d5ff;
  --mdc-switch-selected-focus-track-color: #9333ea;
  --mdc-switch-selected-hover-track-color: #9333ea;
  --mdc-switch-selected-pressed-track-color: #9333ea;
  --mdc-switch-selected-track-color: #9333ea;
  --mdc-switch-disabled-selected-handle-color: #000;
  --mdc-switch-disabled-selected-icon-color: #212121;
  --mdc-switch-disabled-selected-track-color: #f5f5f5;
  --mdc-switch-disabled-unselected-handle-color: #000;
  --mdc-switch-disabled-unselected-icon-color: #212121;
  --mdc-switch-disabled-unselected-track-color: #f5f5f5;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #212121;
  --mdc-switch-unselected-focus-handle-color: #fafafa;
  --mdc-switch-unselected-focus-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-focus-track-color: #616161;
  --mdc-switch-unselected-handle-color: #9e9e9e;
  --mdc-switch-unselected-hover-handle-color: #fafafa;
  --mdc-switch-unselected-hover-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-hover-track-color: #616161;
  --mdc-switch-unselected-icon-color: #212121;
  --mdc-switch-unselected-pressed-handle-color: #fafafa;
  --mdc-switch-unselected-pressed-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-pressed-track-color: #616161;
  --mdc-switch-unselected-track-color: #616161;
}
.vex-theme-purple.dark .mat-mdc-slide-toggle .mdc-form-field,
.vex-theme-purple .dark .mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.vex-theme-purple.dark .mat-mdc-slide-toggle .mdc-switch--disabled + label,
.vex-theme-purple .dark .mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: #6b7280;
}
.vex-theme-purple.dark .mat-mdc-slide-toggle.mat-accent,
.vex-theme-purple .dark .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #fcd34d;
  --mdc-switch-selected-handle-color: #fcd34d;
  --mdc-switch-selected-hover-state-layer-color: #fcd34d;
  --mdc-switch-selected-pressed-state-layer-color: #fcd34d;
  --mdc-switch-selected-focus-handle-color: #fde68a;
  --mdc-switch-selected-hover-handle-color: #fde68a;
  --mdc-switch-selected-pressed-handle-color: #fde68a;
  --mdc-switch-selected-focus-track-color: #d97706;
  --mdc-switch-selected-hover-track-color: #d97706;
  --mdc-switch-selected-pressed-track-color: #d97706;
  --mdc-switch-selected-track-color: #d97706;
}
.vex-theme-purple.dark .mat-mdc-slide-toggle.mat-warn,
.vex-theme-purple .dark .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #fca5a5;
  --mdc-switch-selected-handle-color: #fca5a5;
  --mdc-switch-selected-hover-state-layer-color: #fca5a5;
  --mdc-switch-selected-pressed-state-layer-color: #fca5a5;
  --mdc-switch-selected-focus-handle-color: #fecaca;
  --mdc-switch-selected-hover-handle-color: #fecaca;
  --mdc-switch-selected-pressed-handle-color: #fecaca;
  --mdc-switch-selected-focus-track-color: #dc2626;
  --mdc-switch-selected-hover-track-color: #dc2626;
  --mdc-switch-selected-pressed-track-color: #dc2626;
  --mdc-switch-selected-track-color: #dc2626;
}
.vex-theme-purple.dark .mat-mdc-radio-button .mdc-form-field,
.vex-theme-purple .dark .mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.vex-theme-purple.dark .mat-mdc-radio-button.mat-primary,
.vex-theme-purple .dark .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #a855f7;
  --mdc-radio-selected-hover-icon-color: #a855f7;
  --mdc-radio-selected-icon-color: #a855f7;
  --mdc-radio-selected-pressed-icon-color: #a855f7;
  --mat-radio-ripple-color: #fff;
  --mat-radio-checked-ripple-color: #a855f7;
  --mat-radio-disabled-label-color: #6b7280;
}
.vex-theme-purple.dark .mat-mdc-radio-button.mat-accent,
.vex-theme-purple .dark .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #f59e0b;
  --mdc-radio-selected-hover-icon-color: #f59e0b;
  --mdc-radio-selected-icon-color: #f59e0b;
  --mdc-radio-selected-pressed-icon-color: #f59e0b;
  --mat-radio-ripple-color: #fff;
  --mat-radio-checked-ripple-color: #f59e0b;
  --mat-radio-disabled-label-color: #6b7280;
}
.vex-theme-purple.dark .mat-mdc-radio-button.mat-warn,
.vex-theme-purple .dark .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #ef4444;
  --mdc-radio-selected-hover-icon-color: #ef4444;
  --mdc-radio-selected-icon-color: #ef4444;
  --mdc-radio-selected-pressed-icon-color: #ef4444;
  --mat-radio-ripple-color: #fff;
  --mat-radio-checked-ripple-color: #ef4444;
  --mat-radio-disabled-label-color: #6b7280;
}
.vex-theme-purple.dark .mat-mdc-slider,
.vex-theme-purple .dark .mat-mdc-slider {
  --mdc-slider-label-container-color: white;
  --mdc-slider-label-label-text-color: black;
  --mdc-slider-disabled-handle-color: #fff;
  --mdc-slider-disabled-active-track-color: #fff;
  --mdc-slider-disabled-inactive-track-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #fff;
  --mat-mdc-slider-value-indicator-opacity: 0.9;
}
.vex-theme-purple.dark .mat-mdc-slider.mat-primary,
.vex-theme-purple .dark .mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #a855f7;
  --mdc-slider-focus-handle-color: #a855f7;
  --mdc-slider-hover-handle-color: #a855f7;
  --mdc-slider-active-track-color: #a855f7;
  --mdc-slider-inactive-track-color: #a855f7;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #a855f7;
  --mat-mdc-slider-ripple-color: #a855f7;
  --mat-mdc-slider-hover-ripple-color: rgba(168, 85, 247, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(168, 85, 247, 0.2);
}
.vex-theme-purple.dark .mat-mdc-slider.mat-accent,
.vex-theme-purple .dark .mat-mdc-slider.mat-accent {
  --mdc-slider-handle-color: #f59e0b;
  --mdc-slider-focus-handle-color: #f59e0b;
  --mdc-slider-hover-handle-color: #f59e0b;
  --mdc-slider-active-track-color: #f59e0b;
  --mdc-slider-inactive-track-color: #f59e0b;
  --mdc-slider-with-tick-marks-active-container-color: #000;
  --mdc-slider-with-tick-marks-inactive-container-color: #f59e0b;
  --mat-mdc-slider-ripple-color: #f59e0b;
  --mat-mdc-slider-hover-ripple-color: rgba(245, 158, 11, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(245, 158, 11, 0.2);
}
.vex-theme-purple.dark .mat-mdc-slider.mat-warn,
.vex-theme-purple .dark .mat-mdc-slider.mat-warn {
  --mdc-slider-handle-color: #ef4444;
  --mdc-slider-focus-handle-color: #ef4444;
  --mdc-slider-hover-handle-color: #ef4444;
  --mdc-slider-active-track-color: #ef4444;
  --mdc-slider-inactive-track-color: #ef4444;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #ef4444;
  --mat-mdc-slider-ripple-color: #ef4444;
  --mat-mdc-slider-hover-ripple-color: rgba(239, 68, 68, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(239, 68, 68, 0.2);
}
.vex-theme-purple.dark, .vex-theme-purple .dark {
  --mat-menu-item-label-text-color: white;
  --mat-menu-item-icon-color: white;
  --mat-menu-item-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-menu-item-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-menu-container-color: #1f2937;
}

.vex-theme-purple.dark .mat-mdc-list-base,
.vex-theme-purple .dark .mat-mdc-list-base {
  --mdc-list-list-item-label-text-color: white;
  --mdc-list-list-item-supporting-text-color: #9ca3af;
  --mdc-list-list-item-leading-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-trailing-supporting-text-color: #6b7280;
  --mdc-list-list-item-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-disabled-label-text-color: white;
  --mdc-list-list-item-disabled-leading-icon-color: white;
  --mdc-list-list-item-disabled-trailing-icon-color: white;
  --mdc-list-list-item-hover-label-text-color: white;
  --mdc-list-list-item-hover-leading-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-focus-label-text-color: white;
  --mdc-list-list-item-hover-state-layer-color: white;
  --mdc-list-list-item-hover-state-layer-opacity: 0.08;
  --mdc-list-list-item-focus-state-layer-color: white;
  --mdc-list-list-item-focus-state-layer-opacity: 0.24;
}
.vex-theme-purple.dark .mdc-list-item__start,
.vex-theme-purple.dark .mdc-list-item__end,
.vex-theme-purple .dark .mdc-list-item__start,
.vex-theme-purple .dark .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #a855f7;
  --mdc-radio-selected-hover-icon-color: #a855f7;
  --mdc-radio-selected-icon-color: #a855f7;
  --mdc-radio-selected-pressed-icon-color: #a855f7;
}
.vex-theme-purple.dark .mat-accent .mdc-list-item__start,
.vex-theme-purple.dark .mat-accent .mdc-list-item__end,
.vex-theme-purple .dark .mat-accent .mdc-list-item__start,
.vex-theme-purple .dark .mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #f59e0b;
  --mdc-radio-selected-hover-icon-color: #f59e0b;
  --mdc-radio-selected-icon-color: #f59e0b;
  --mdc-radio-selected-pressed-icon-color: #f59e0b;
}
.vex-theme-purple.dark .mat-warn .mdc-list-item__start,
.vex-theme-purple.dark .mat-warn .mdc-list-item__end,
.vex-theme-purple .dark .mat-warn .mdc-list-item__start,
.vex-theme-purple .dark .mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #ef4444;
  --mdc-radio-selected-hover-icon-color: #ef4444;
  --mdc-radio-selected-icon-color: #ef4444;
  --mdc-radio-selected-pressed-icon-color: #ef4444;
}
.vex-theme-purple.dark .mat-mdc-list-option,
.vex-theme-purple .dark .mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #a855f7;
  --mdc-checkbox-selected-hover-icon-color: #a855f7;
  --mdc-checkbox-selected-icon-color: #a855f7;
  --mdc-checkbox-selected-pressed-icon-color: #a855f7;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #a855f7;
  --mdc-checkbox-selected-hover-state-layer-color: #a855f7;
  --mdc-checkbox-selected-pressed-state-layer-color: #a855f7;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.vex-theme-purple.dark .mat-mdc-list-option.mat-accent,
.vex-theme-purple .dark .mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #f59e0b;
  --mdc-checkbox-selected-hover-icon-color: #f59e0b;
  --mdc-checkbox-selected-icon-color: #f59e0b;
  --mdc-checkbox-selected-pressed-icon-color: #f59e0b;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f59e0b;
  --mdc-checkbox-selected-hover-state-layer-color: #f59e0b;
  --mdc-checkbox-selected-pressed-state-layer-color: #f59e0b;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.vex-theme-purple.dark .mat-mdc-list-option.mat-warn,
.vex-theme-purple .dark .mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #ef4444;
  --mdc-checkbox-selected-hover-icon-color: #ef4444;
  --mdc-checkbox-selected-icon-color: #ef4444;
  --mdc-checkbox-selected-pressed-icon-color: #ef4444;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #ef4444;
  --mdc-checkbox-selected-hover-state-layer-color: #ef4444;
  --mdc-checkbox-selected-pressed-state-layer-color: #ef4444;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.vex-theme-purple.dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.vex-theme-purple.dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,
.vex-theme-purple .dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.vex-theme-purple .dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #a855f7;
}
.vex-theme-purple.dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.vex-theme-purple.dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.vex-theme-purple .dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.vex-theme-purple .dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #a855f7;
}
.vex-theme-purple.dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.vex-theme-purple.dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.vex-theme-purple.dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end,
.vex-theme-purple .dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.vex-theme-purple .dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.vex-theme-purple .dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.vex-theme-purple.dark, .vex-theme-purple .dark {
  --mat-paginator-container-text-color: rgba(255, 255, 255, 0.87);
  --mat-paginator-container-background-color: #1f2937;
  --mat-paginator-enabled-icon-color: rgba(255, 255, 255, 0.54);
  --mat-paginator-disabled-icon-color: rgba(255, 255, 255, 0.12);
}

.vex-theme-purple.dark .mat-mdc-tab-group, .vex-theme-purple.dark .mat-mdc-tab-nav-bar,
.vex-theme-purple .dark .mat-mdc-tab-group,
.vex-theme-purple .dark .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #a855f7;
  --mat-tab-header-disabled-ripple-color: #6b7280;
  --mat-tab-header-pagination-icon-color: #fff;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #a855f7;
  --mat-tab-header-active-ripple-color: #a855f7;
  --mat-tab-header-inactive-ripple-color: #a855f7;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #a855f7;
  --mat-tab-header-active-hover-label-text-color: #a855f7;
  --mat-tab-header-active-focus-indicator-color: #a855f7;
  --mat-tab-header-active-hover-indicator-color: #a855f7;
}
.vex-theme-purple.dark .mat-mdc-tab-group.mat-accent, .vex-theme-purple.dark .mat-mdc-tab-nav-bar.mat-accent,
.vex-theme-purple .dark .mat-mdc-tab-group.mat-accent,
.vex-theme-purple .dark .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #f59e0b;
  --mat-tab-header-disabled-ripple-color: #6b7280;
  --mat-tab-header-pagination-icon-color: #fff;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #f59e0b;
  --mat-tab-header-active-ripple-color: #f59e0b;
  --mat-tab-header-inactive-ripple-color: #f59e0b;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #f59e0b;
  --mat-tab-header-active-hover-label-text-color: #f59e0b;
  --mat-tab-header-active-focus-indicator-color: #f59e0b;
  --mat-tab-header-active-hover-indicator-color: #f59e0b;
}
.vex-theme-purple.dark .mat-mdc-tab-group.mat-warn, .vex-theme-purple.dark .mat-mdc-tab-nav-bar.mat-warn,
.vex-theme-purple .dark .mat-mdc-tab-group.mat-warn,
.vex-theme-purple .dark .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #ef4444;
  --mat-tab-header-disabled-ripple-color: #6b7280;
  --mat-tab-header-pagination-icon-color: #fff;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #ef4444;
  --mat-tab-header-active-ripple-color: #ef4444;
  --mat-tab-header-inactive-ripple-color: #ef4444;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #ef4444;
  --mat-tab-header-active-hover-label-text-color: #ef4444;
  --mat-tab-header-active-focus-indicator-color: #ef4444;
  --mat-tab-header-active-hover-indicator-color: #ef4444;
}
.vex-theme-purple.dark .mat-mdc-tab-group.mat-background-primary, .vex-theme-purple.dark .mat-mdc-tab-nav-bar.mat-background-primary,
.vex-theme-purple .dark .mat-mdc-tab-group.mat-background-primary,
.vex-theme-purple .dark .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #a855f7;
  --mat-tab-header-with-background-foreground-color: white;
}
.vex-theme-purple.dark .mat-mdc-tab-group.mat-background-accent, .vex-theme-purple.dark .mat-mdc-tab-nav-bar.mat-background-accent,
.vex-theme-purple .dark .mat-mdc-tab-group.mat-background-accent,
.vex-theme-purple .dark .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #f59e0b;
  --mat-tab-header-with-background-foreground-color: white;
}
.vex-theme-purple.dark .mat-mdc-tab-group.mat-background-warn, .vex-theme-purple.dark .mat-mdc-tab-nav-bar.mat-background-warn,
.vex-theme-purple .dark .mat-mdc-tab-group.mat-background-warn,
.vex-theme-purple .dark .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #ef4444;
  --mat-tab-header-with-background-foreground-color: white;
}
.vex-theme-purple.dark, .vex-theme-purple .dark {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #f59e0b;
  --mdc-checkbox-selected-hover-icon-color: #f59e0b;
  --mdc-checkbox-selected-icon-color: #f59e0b;
  --mdc-checkbox-selected-pressed-icon-color: #f59e0b;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f59e0b;
  --mdc-checkbox-selected-hover-state-layer-color: #f59e0b;
  --mdc-checkbox-selected-pressed-state-layer-color: #f59e0b;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}

.vex-theme-purple.dark .mat-mdc-checkbox.mat-primary,
.vex-theme-purple .dark .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #a855f7;
  --mdc-checkbox-selected-hover-icon-color: #a855f7;
  --mdc-checkbox-selected-icon-color: #a855f7;
  --mdc-checkbox-selected-pressed-icon-color: #a855f7;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #a855f7;
  --mdc-checkbox-selected-hover-state-layer-color: #a855f7;
  --mdc-checkbox-selected-pressed-state-layer-color: #a855f7;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.vex-theme-purple.dark .mat-mdc-checkbox.mat-warn,
.vex-theme-purple .dark .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #ef4444;
  --mdc-checkbox-selected-hover-icon-color: #ef4444;
  --mdc-checkbox-selected-icon-color: #ef4444;
  --mdc-checkbox-selected-pressed-icon-color: #ef4444;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #ef4444;
  --mdc-checkbox-selected-hover-state-layer-color: #ef4444;
  --mdc-checkbox-selected-pressed-state-layer-color: #ef4444;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.vex-theme-purple.dark .mat-mdc-checkbox .mdc-form-field,
.vex-theme-purple .dark .mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.vex-theme-purple.dark .mat-mdc-checkbox.mat-mdc-checkbox-disabled label,
.vex-theme-purple .dark .mat-mdc-checkbox.mat-mdc-checkbox-disabled label {
  color: #6b7280;
}
.vex-theme-purple.dark .mat-mdc-button.mat-unthemed,
.vex-theme-purple .dark .mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #fff;
}
.vex-theme-purple.dark .mat-mdc-button.mat-primary,
.vex-theme-purple .dark .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #a855f7;
}
.vex-theme-purple.dark .mat-mdc-button.mat-accent,
.vex-theme-purple .dark .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #f59e0b;
}
.vex-theme-purple.dark .mat-mdc-button.mat-warn,
.vex-theme-purple .dark .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #ef4444;
}
.vex-theme-purple.dark .mat-mdc-button[disabled][disabled],
.vex-theme-purple .dark .mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-text-button-label-text-color: rgba(255, 255, 255, 0.5);
}
.vex-theme-purple.dark .mat-mdc-unelevated-button.mat-unthemed,
.vex-theme-purple .dark .mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #1f2937;
  --mdc-filled-button-label-text-color: #fff;
}
.vex-theme-purple.dark .mat-mdc-unelevated-button.mat-primary,
.vex-theme-purple .dark .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #a855f7;
  --mdc-filled-button-label-text-color: #fff;
}
.vex-theme-purple.dark .mat-mdc-unelevated-button.mat-accent,
.vex-theme-purple .dark .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #f59e0b;
  --mdc-filled-button-label-text-color: #000;
}
.vex-theme-purple.dark .mat-mdc-unelevated-button.mat-warn,
.vex-theme-purple .dark .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #ef4444;
  --mdc-filled-button-label-text-color: #fff;
}
.vex-theme-purple.dark .mat-mdc-unelevated-button[disabled][disabled],
.vex-theme-purple .dark .mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(255, 255, 255, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-filled-button-container-color: rgba(255, 255, 255, 0.12);
  --mdc-filled-button-label-text-color: rgba(255, 255, 255, 0.5);
}
.vex-theme-purple.dark .mat-mdc-raised-button.mat-unthemed,
.vex-theme-purple .dark .mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #1f2937;
  --mdc-protected-button-label-text-color: #fff;
}
.vex-theme-purple.dark .mat-mdc-raised-button.mat-primary,
.vex-theme-purple .dark .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #a855f7;
  --mdc-protected-button-label-text-color: #fff;
}
.vex-theme-purple.dark .mat-mdc-raised-button.mat-accent,
.vex-theme-purple .dark .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #f59e0b;
  --mdc-protected-button-label-text-color: #000;
}
.vex-theme-purple.dark .mat-mdc-raised-button.mat-warn,
.vex-theme-purple .dark .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #ef4444;
  --mdc-protected-button-label-text-color: #fff;
}
.vex-theme-purple.dark .mat-mdc-raised-button[disabled][disabled],
.vex-theme-purple .dark .mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(255, 255, 255, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-protected-button-container-color: rgba(255, 255, 255, 0.12);
  --mdc-protected-button-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-protected-button-container-elevation: 0;
}
.vex-theme-purple.dark .mat-mdc-outlined-button,
.vex-theme-purple .dark .mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
}
.vex-theme-purple.dark .mat-mdc-outlined-button.mat-unthemed,
.vex-theme-purple .dark .mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #fff;
}
.vex-theme-purple.dark .mat-mdc-outlined-button.mat-primary,
.vex-theme-purple .dark .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #a855f7;
}
.vex-theme-purple.dark .mat-mdc-outlined-button.mat-accent,
.vex-theme-purple .dark .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #f59e0b;
}
.vex-theme-purple.dark .mat-mdc-outlined-button.mat-warn,
.vex-theme-purple .dark .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #ef4444;
}
.vex-theme-purple.dark .mat-mdc-outlined-button[disabled][disabled],
.vex-theme-purple .dark .mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-outlined-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(255, 255, 255, 0.12);
}
.vex-theme-purple.dark .mat-mdc-button, .vex-theme-purple.dark .mat-mdc-outlined-button,
.vex-theme-purple .dark .mat-mdc-button,
.vex-theme-purple .dark .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.vex-theme-purple.dark .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .vex-theme-purple.dark .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple .dark .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple .dark .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
.vex-theme-purple.dark .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-purple.dark .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-purple.dark .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-purple.dark .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple .dark .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple .dark .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple .dark .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple .dark .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.vex-theme-purple.dark .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .vex-theme-purple.dark .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple .dark .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple .dark .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.vex-theme-purple.dark .mat-mdc-button.mat-primary, .vex-theme-purple.dark .mat-mdc-outlined-button.mat-primary,
.vex-theme-purple .dark .mat-mdc-button.mat-primary,
.vex-theme-purple .dark .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #a855f7;
  --mat-mdc-button-ripple-color: rgba(168, 85, 247, 0.1);
}
.vex-theme-purple.dark .mat-mdc-button.mat-accent, .vex-theme-purple.dark .mat-mdc-outlined-button.mat-accent,
.vex-theme-purple .dark .mat-mdc-button.mat-accent,
.vex-theme-purple .dark .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #f59e0b;
  --mat-mdc-button-ripple-color: rgba(245, 158, 11, 0.1);
}
.vex-theme-purple.dark .mat-mdc-button.mat-warn, .vex-theme-purple.dark .mat-mdc-outlined-button.mat-warn,
.vex-theme-purple .dark .mat-mdc-button.mat-warn,
.vex-theme-purple .dark .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #ef4444;
  --mat-mdc-button-ripple-color: rgba(239, 68, 68, 0.1);
}
.vex-theme-purple.dark .mat-mdc-raised-button, .vex-theme-purple.dark .mat-mdc-unelevated-button,
.vex-theme-purple .dark .mat-mdc-raised-button,
.vex-theme-purple .dark .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.vex-theme-purple.dark .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .vex-theme-purple.dark .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple .dark .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple .dark .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
.vex-theme-purple.dark .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-purple.dark .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-purple.dark .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-purple.dark .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple .dark .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple .dark .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple .dark .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple .dark .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.vex-theme-purple.dark .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .vex-theme-purple.dark .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple .dark .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple .dark .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.vex-theme-purple.dark .mat-mdc-raised-button.mat-primary, .vex-theme-purple.dark .mat-mdc-unelevated-button.mat-primary,
.vex-theme-purple .dark .mat-mdc-raised-button.mat-primary,
.vex-theme-purple .dark .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.vex-theme-purple.dark .mat-mdc-raised-button.mat-accent, .vex-theme-purple.dark .mat-mdc-unelevated-button.mat-accent,
.vex-theme-purple .dark .mat-mdc-raised-button.mat-accent,
.vex-theme-purple .dark .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.vex-theme-purple.dark .mat-mdc-raised-button.mat-warn, .vex-theme-purple.dark .mat-mdc-unelevated-button.mat-warn,
.vex-theme-purple .dark .mat-mdc-raised-button.mat-warn,
.vex-theme-purple .dark .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.vex-theme-purple.dark .mat-mdc-icon-button,
.vex-theme-purple .dark .mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mdc-icon-button-icon-color: inherit;
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.vex-theme-purple.dark .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple .dark .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
.vex-theme-purple.dark .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-purple.dark .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple .dark .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple .dark .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.vex-theme-purple.dark .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple .dark .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.vex-theme-purple.dark .mat-mdc-icon-button.mat-primary,
.vex-theme-purple .dark .mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #6200ee;
  --mat-mdc-button-ripple-color: rgba(98, 0, 238, 0.1);
}
.vex-theme-purple.dark .mat-mdc-icon-button.mat-accent,
.vex-theme-purple .dark .mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #018786;
  --mat-mdc-button-ripple-color: rgba(1, 135, 134, 0.1);
}
.vex-theme-purple.dark .mat-mdc-icon-button.mat-warn,
.vex-theme-purple .dark .mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #b00020;
  --mat-mdc-button-ripple-color: rgba(176, 0, 32, 0.1);
}
.vex-theme-purple.dark .mat-mdc-icon-button.mat-primary,
.vex-theme-purple .dark .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #a855f7;
  --mat-mdc-button-persistent-ripple-color: #a855f7;
  --mat-mdc-button-ripple-color: rgba(168, 85, 247, 0.1);
}
.vex-theme-purple.dark .mat-mdc-icon-button.mat-accent,
.vex-theme-purple .dark .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #f59e0b;
  --mat-mdc-button-persistent-ripple-color: #f59e0b;
  --mat-mdc-button-ripple-color: rgba(245, 158, 11, 0.1);
}
.vex-theme-purple.dark .mat-mdc-icon-button.mat-warn,
.vex-theme-purple .dark .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #ef4444;
  --mat-mdc-button-persistent-ripple-color: #ef4444;
  --mat-mdc-button-ripple-color: rgba(239, 68, 68, 0.1);
}
.vex-theme-purple.dark .mat-mdc-icon-button[disabled][disabled],
.vex-theme-purple .dark .mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-icon-button-disabled-icon-color: rgba(255, 255, 255, 0.5);
}
.vex-theme-purple.dark .mat-mdc-fab,
.vex-theme-purple.dark .mat-mdc-mini-fab,
.vex-theme-purple .dark .mat-mdc-fab,
.vex-theme-purple .dark .mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.vex-theme-purple.dark .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple.dark .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple .dark .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple .dark .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
.vex-theme-purple.dark .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-purple.dark .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple.dark .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple.dark .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple .dark .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple .dark .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple .dark .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple .dark .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.vex-theme-purple.dark .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple.dark .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple .dark .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-purple .dark .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.vex-theme-purple.dark .mat-mdc-fab.mat-primary,
.vex-theme-purple.dark .mat-mdc-mini-fab.mat-primary,
.vex-theme-purple .dark .mat-mdc-fab.mat-primary,
.vex-theme-purple .dark .mat-mdc-mini-fab.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.vex-theme-purple.dark .mat-mdc-fab.mat-accent,
.vex-theme-purple.dark .mat-mdc-mini-fab.mat-accent,
.vex-theme-purple .dark .mat-mdc-fab.mat-accent,
.vex-theme-purple .dark .mat-mdc-mini-fab.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.vex-theme-purple.dark .mat-mdc-fab.mat-warn,
.vex-theme-purple.dark .mat-mdc-mini-fab.mat-warn,
.vex-theme-purple .dark .mat-mdc-fab.mat-warn,
.vex-theme-purple .dark .mat-mdc-mini-fab.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.vex-theme-purple.dark .mat-mdc-fab[disabled][disabled],
.vex-theme-purple.dark .mat-mdc-mini-fab[disabled][disabled],
.vex-theme-purple .dark .mat-mdc-fab[disabled][disabled],
.vex-theme-purple .dark .mat-mdc-mini-fab[disabled][disabled] {
  --mdc-fab-container-color: rgba(255, 255, 255, 0.12);
  --mdc-fab-icon-color: rgba(255, 255, 255, 0.5);
  --mat-mdc-fab-color: rgba(255, 255, 255, 0.5);
}
.vex-theme-purple.dark .mat-mdc-fab.mat-unthemed,
.vex-theme-purple.dark .mat-mdc-mini-fab.mat-unthemed,
.vex-theme-purple .dark .mat-mdc-fab.mat-unthemed,
.vex-theme-purple .dark .mat-mdc-mini-fab.mat-unthemed {
  --mdc-fab-container-color: #1f2937;
  --mdc-fab-icon-color: white;
  --mat-mdc-fab-color: #fff;
}
.vex-theme-purple.dark .mat-mdc-fab.mat-primary,
.vex-theme-purple.dark .mat-mdc-mini-fab.mat-primary,
.vex-theme-purple .dark .mat-mdc-fab.mat-primary,
.vex-theme-purple .dark .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #a855f7;
  --mdc-fab-icon-color: white;
  --mat-mdc-fab-color: #fff;
}
.vex-theme-purple.dark .mat-mdc-fab.mat-accent,
.vex-theme-purple.dark .mat-mdc-mini-fab.mat-accent,
.vex-theme-purple .dark .mat-mdc-fab.mat-accent,
.vex-theme-purple .dark .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: #f59e0b;
  --mdc-fab-icon-color: black;
  --mat-mdc-fab-color: #000;
}
.vex-theme-purple.dark .mat-mdc-fab.mat-warn,
.vex-theme-purple.dark .mat-mdc-mini-fab.mat-warn,
.vex-theme-purple .dark .mat-mdc-fab.mat-warn,
.vex-theme-purple .dark .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: #ef4444;
  --mdc-fab-icon-color: white;
  --mat-mdc-fab-color: #fff;
}
.vex-theme-purple.dark .mat-mdc-snack-bar-container,
.vex-theme-purple .dark .mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: #d2d4d7;
  --mdc-snackbar-supporting-text-color: rgba(31, 41, 55, 0.87);
  --mat-snack-bar-button-color: rgba(0, 0, 0, 0.87);
}
.vex-theme-purple.dark .mat-mdc-progress-spinner,
.vex-theme-purple .dark .mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #a855f7;
}
.vex-theme-purple.dark .mat-mdc-progress-spinner.mat-accent,
.vex-theme-purple .dark .mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: #f59e0b;
}
.vex-theme-purple.dark .mat-mdc-progress-spinner.mat-warn,
.vex-theme-purple .dark .mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: #ef4444;
}
.vex-theme-purple.dark, .vex-theme-purple .dark {
  --mat-badge-background-color: #a855f7;
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: #4b5563;
  --mat-badge-disabled-state-text-color: #6b7280;
}

.vex-theme-purple.dark .mat-badge-accent,
.vex-theme-purple .dark .mat-badge-accent {
  --mat-badge-background-color: #f59e0b;
  --mat-badge-text-color: white;
}
.vex-theme-purple.dark .mat-badge-warn,
.vex-theme-purple .dark .mat-badge-warn {
  --mat-badge-background-color: #ef4444;
  --mat-badge-text-color: white;
}
.vex-theme-purple.dark, .vex-theme-purple .dark {
  --mat-bottom-sheet-container-text-color: white;
  --mat-bottom-sheet-container-background-color: #1f2937;
}

.vex-theme-purple.dark, .vex-theme-purple .dark {
  --mat-legacy-button-toggle-text-color: #6b7280;
  --mat-legacy-button-toggle-state-layer-color: rgba(255, 255, 255, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: #9ca3af;
  --mat-legacy-button-toggle-selected-state-background-color: #111827;
  --mat-legacy-button-toggle-disabled-state-text-color: #4b5563;
  --mat-legacy-button-toggle-disabled-state-background-color: black;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #1f2937;
  --mat-standard-button-toggle-text-color: white;
  --mat-standard-button-toggle-background-color: #1f2937;
  --mat-standard-button-toggle-state-layer-color: white;
  --mat-standard-button-toggle-selected-state-background-color: #111827;
  --mat-standard-button-toggle-selected-state-text-color: white;
  --mat-standard-button-toggle-disabled-state-text-color: #4b5563;
  --mat-standard-button-toggle-disabled-state-background-color: #1f2937;
  --mat-standard-button-toggle-disabled-selected-state-text-color: white;
  --mat-standard-button-toggle-disabled-selected-state-background-color: #1f2937;
  --mat-standard-button-toggle-divider-color: #3a434f;
}

.vex-theme-purple.dark, .vex-theme-purple .dark {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #a855f7;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(168, 85, 247, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(168, 85, 247, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(168, 85, 247, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #a855f7;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(168, 85, 247, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: white;
  --mat-datepicker-calendar-body-label-text-color: #9ca3af;
  --mat-datepicker-calendar-period-button-icon-color: white;
  --mat-datepicker-calendar-navigation-button-icon-color: white;
  --mat-datepicker-calendar-header-divider-color: rgba(255, 255, 255, 0.12);
  --mat-datepicker-calendar-header-text-color: #9ca3af;
  --mat-datepicker-calendar-date-today-outline-color: #6b7280;
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(107, 114, 128, 0.8);
  --mat-datepicker-calendar-date-text-color: white;
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: #6b7280;
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(255, 255, 255, 0.24);
  --mat-datepicker-range-input-separator-color: white;
  --mat-datepicker-range-input-disabled-state-separator-color: #6b7280;
  --mat-datepicker-range-input-disabled-state-text-color: #6b7280;
  --mat-datepicker-calendar-container-background-color: #1f2937;
  --mat-datepicker-calendar-container-text-color: white;
}

.vex-theme-purple.dark .mat-datepicker-content.mat-accent,
.vex-theme-purple .dark .mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #f59e0b;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(245, 158, 11, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(245, 158, 11, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(245, 158, 11, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(245, 158, 11, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.vex-theme-purple.dark .mat-datepicker-content.mat-warn,
.vex-theme-purple .dark .mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #ef4444;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(239, 68, 68, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(239, 68, 68, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(239, 68, 68, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(239, 68, 68, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.vex-theme-purple.dark .mat-datepicker-toggle-active.mat-accent,
.vex-theme-purple .dark .mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #f59e0b;
}
.vex-theme-purple.dark .mat-datepicker-toggle-active.mat-warn,
.vex-theme-purple .dark .mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #ef4444;
}
.vex-theme-purple.dark, .vex-theme-purple .dark {
  --mat-divider-color: rgba(255, 255, 255, 0.12);
}

.vex-theme-purple.dark, .vex-theme-purple .dark {
  --mat-expansion-container-background-color: #1f2937;
  --mat-expansion-container-text-color: white;
  --mat-expansion-actions-divider-color: rgba(255, 255, 255, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(255, 255, 255, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(255, 255, 255, 0.04);
  --mat-expansion-header-disabled-state-text-color: #4b5563;
  --mat-expansion-header-text-color: white;
  --mat-expansion-header-description-color: #9ca3af;
  --mat-expansion-header-indicator-color: #9ca3af;
}

.vex-theme-purple.dark, .vex-theme-purple .dark {
  --mat-icon-color: inherit;
}

.vex-theme-purple.dark .mat-icon.mat-primary,
.vex-theme-purple .dark .mat-icon.mat-primary {
  --mat-icon-color: #a855f7;
}
.vex-theme-purple.dark .mat-icon.mat-accent,
.vex-theme-purple .dark .mat-icon.mat-accent {
  --mat-icon-color: #f59e0b;
}
.vex-theme-purple.dark .mat-icon.mat-warn,
.vex-theme-purple .dark .mat-icon.mat-warn {
  --mat-icon-color: #ef4444;
}
.vex-theme-purple.dark, .vex-theme-purple .dark {
  --mat-sidenav-container-divider-color: rgba(255, 255, 255, 0.12);
  --mat-sidenav-container-background-color: #1f2937;
  --mat-sidenav-container-text-color: white;
  --mat-sidenav-content-background-color: #111827;
  --mat-sidenav-content-text-color: white;
  --mat-sidenav-scrim-color: rgba(224, 214, 200, 0.6);
}

.vex-theme-purple.dark, .vex-theme-purple .dark {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #a855f7;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #a855f7;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #a855f7;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: #1f2937;
  --mat-stepper-line-color: rgba(255, 255, 255, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(255, 255, 255, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(255, 255, 255, 0.04);
  --mat-stepper-header-label-text-color: #9ca3af;
  --mat-stepper-header-optional-label-text-color: #9ca3af;
  --mat-stepper-header-selected-state-label-text-color: white;
  --mat-stepper-header-error-state-label-text-color: #ef4444;
  --mat-stepper-header-icon-background-color: #9ca3af;
  --mat-stepper-header-error-state-icon-foreground-color: #ef4444;
  --mat-stepper-header-error-state-icon-background-color: transparent;
}
.vex-theme-purple.dark .mat-step-header.mat-accent, .vex-theme-purple .dark .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #f59e0b;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #f59e0b;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #f59e0b;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.vex-theme-purple.dark .mat-step-header.mat-warn, .vex-theme-purple .dark .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #ef4444;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #ef4444;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #ef4444;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}

.vex-theme-purple.dark .mat-sort-header-arrow,
.vex-theme-purple .dark .mat-sort-header-arrow {
  color: #9ca3af;
}
.vex-theme-purple.dark, .vex-theme-purple .dark {
  --mat-toolbar-container-background-color: #1f2937;
  --mat-toolbar-container-text-color: white;
}

.vex-theme-purple.dark .mat-toolbar.mat-primary,
.vex-theme-purple .dark .mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #a855f7;
  --mat-toolbar-container-text-color: white;
}
.vex-theme-purple.dark .mat-toolbar.mat-accent,
.vex-theme-purple .dark .mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #f59e0b;
  --mat-toolbar-container-text-color: white;
}
.vex-theme-purple.dark .mat-toolbar.mat-warn,
.vex-theme-purple .dark .mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #ef4444;
  --mat-toolbar-container-text-color: white;
}
.vex-theme-purple.dark .mat-tree,
.vex-theme-purple .dark .mat-tree {
  background: #1f2937;
}
.vex-theme-purple.dark .mat-tree-node,
.vex-theme-purple.dark .mat-nested-tree-node,
.vex-theme-purple .dark .mat-tree-node,
.vex-theme-purple .dark .mat-nested-tree-node {
  color: white;
}

/**
 * Override the theme foreground/background colors with the Angular Material colors
 */
.vex-theme-red,
.vex-theme-red.light,
.vex-theme-red .light {
  --mat-table-background-color: white;
  --mat-table-header-headline-color: #030712;
  --mat-table-row-item-label-text-color: #030712;
  --mat-table-row-item-outline-color: rgba(0, 0, 0, 0.06);
}
.vex-theme-red .mat-ripple-element,
.vex-theme-red.light .mat-ripple-element,
.vex-theme-red .light .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.vex-theme-red, .vex-theme-red.light, .vex-theme-red .light {
  --mat-option-selected-state-label-text-color: #dc2626;
  --mat-option-label-text-color: #030712;
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.vex-theme-red .mat-accent,
.vex-theme-red.light .mat-accent,
.vex-theme-red .light .mat-accent {
  --mat-option-selected-state-label-text-color: #f59e0b;
}
.vex-theme-red .mat-warn,
.vex-theme-red.light .mat-warn,
.vex-theme-red .light .mat-warn {
  --mat-option-selected-state-label-text-color: #ef4444;
}
.vex-theme-red, .vex-theme-red.light, .vex-theme-red .light {
  --mat-optgroup-label-text-color: #030712;
}

.vex-theme-red .mat-pseudo-checkbox-full,
.vex-theme-red.light .mat-pseudo-checkbox-full,
.vex-theme-red .light .mat-pseudo-checkbox-full {
  color: #4b5563;
}
.vex-theme-red .mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled,
.vex-theme-red.light .mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled,
.vex-theme-red .light .mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.vex-theme-red .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-red .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-red.light .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-red.light .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-red .light .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-red .light .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #dc2626;
}
.vex-theme-red .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-red .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-red.light .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-red.light .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-red .light .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-red .light .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #dc2626;
}
.vex-theme-red .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-red .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after,
.vex-theme-red.light .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-red.light .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after,
.vex-theme-red .light .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-red .light .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #f3f4f6;
}
.vex-theme-red .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-red .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-red.light .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-red.light .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-red .light .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-red .light .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #f59e0b;
}
.vex-theme-red .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-red .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-red.light .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-red.light .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-red .light .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-red .light .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #f59e0b;
}
.vex-theme-red .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-red .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after,
.vex-theme-red.light .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-red.light .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after,
.vex-theme-red .light .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-red .light .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #f3f4f6;
}
.vex-theme-red .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-red .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-red.light .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-red.light .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-red .light .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-red .light .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #f59e0b;
}
.vex-theme-red .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-red .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-red.light .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-red.light .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-red .light .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-red .light .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #f59e0b;
}
.vex-theme-red .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-red .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after,
.vex-theme-red.light .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-red.light .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after,
.vex-theme-red .light .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-red .light .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #f3f4f6;
}
.vex-theme-red .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-red .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-red.light .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-red.light .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-red .light .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-red .light .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #ef4444;
}
.vex-theme-red .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-red .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-red.light .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-red.light .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-red .light .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-red .light .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #ef4444;
}
.vex-theme-red .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-red .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after,
.vex-theme-red.light .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-red.light .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after,
.vex-theme-red .light .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-red .light .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #f3f4f6;
}
.vex-theme-red .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-red .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-red.light .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-red.light .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-red .light .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-red .light .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #b0b0b0;
}
.vex-theme-red .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-red .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-red.light .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-red.light .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-red .light .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-red .light .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #b0b0b0;
}
.vex-theme-red .mat-app-background, .vex-theme-red.mat-app-background,
.vex-theme-red.light .mat-app-background,
.vex-theme-red.light.mat-app-background,
.vex-theme-red .light .mat-app-background,
.vex-theme-red .light.mat-app-background {
  background-color: #f3f4f6;
  color: #030712;
}
.vex-theme-red .mat-elevation-z0, .vex-theme-red .mat-mdc-elevation-specific.mat-elevation-z0,
.vex-theme-red.light .mat-elevation-z0,
.vex-theme-red.light .mat-mdc-elevation-specific.mat-elevation-z0,
.vex-theme-red .light .mat-elevation-z0,
.vex-theme-red .light .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-red .mat-elevation-z1, .vex-theme-red .mat-mdc-elevation-specific.mat-elevation-z1,
.vex-theme-red.light .mat-elevation-z1,
.vex-theme-red.light .mat-mdc-elevation-specific.mat-elevation-z1,
.vex-theme-red .light .mat-elevation-z1,
.vex-theme-red .light .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-red .mat-elevation-z2, .vex-theme-red .mat-mdc-elevation-specific.mat-elevation-z2,
.vex-theme-red.light .mat-elevation-z2,
.vex-theme-red.light .mat-mdc-elevation-specific.mat-elevation-z2,
.vex-theme-red .light .mat-elevation-z2,
.vex-theme-red .light .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-red .mat-elevation-z3, .vex-theme-red .mat-mdc-elevation-specific.mat-elevation-z3,
.vex-theme-red.light .mat-elevation-z3,
.vex-theme-red.light .mat-mdc-elevation-specific.mat-elevation-z3,
.vex-theme-red .light .mat-elevation-z3,
.vex-theme-red .light .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-red .mat-elevation-z4, .vex-theme-red .mat-mdc-elevation-specific.mat-elevation-z4,
.vex-theme-red.light .mat-elevation-z4,
.vex-theme-red.light .mat-mdc-elevation-specific.mat-elevation-z4,
.vex-theme-red .light .mat-elevation-z4,
.vex-theme-red .light .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-red .mat-elevation-z5, .vex-theme-red .mat-mdc-elevation-specific.mat-elevation-z5,
.vex-theme-red.light .mat-elevation-z5,
.vex-theme-red.light .mat-mdc-elevation-specific.mat-elevation-z5,
.vex-theme-red .light .mat-elevation-z5,
.vex-theme-red .light .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-red .mat-elevation-z6, .vex-theme-red .mat-mdc-elevation-specific.mat-elevation-z6,
.vex-theme-red.light .mat-elevation-z6,
.vex-theme-red.light .mat-mdc-elevation-specific.mat-elevation-z6,
.vex-theme-red .light .mat-elevation-z6,
.vex-theme-red .light .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-red .mat-elevation-z7, .vex-theme-red .mat-mdc-elevation-specific.mat-elevation-z7,
.vex-theme-red.light .mat-elevation-z7,
.vex-theme-red.light .mat-mdc-elevation-specific.mat-elevation-z7,
.vex-theme-red .light .mat-elevation-z7,
.vex-theme-red .light .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.vex-theme-red .mat-elevation-z8, .vex-theme-red .mat-mdc-elevation-specific.mat-elevation-z8,
.vex-theme-red.light .mat-elevation-z8,
.vex-theme-red.light .mat-mdc-elevation-specific.mat-elevation-z8,
.vex-theme-red .light .mat-elevation-z8,
.vex-theme-red .light .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.vex-theme-red .mat-elevation-z9, .vex-theme-red .mat-mdc-elevation-specific.mat-elevation-z9,
.vex-theme-red.light .mat-elevation-z9,
.vex-theme-red.light .mat-mdc-elevation-specific.mat-elevation-z9,
.vex-theme-red .light .mat-elevation-z9,
.vex-theme-red .light .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.vex-theme-red .mat-elevation-z10, .vex-theme-red .mat-mdc-elevation-specific.mat-elevation-z10,
.vex-theme-red.light .mat-elevation-z10,
.vex-theme-red.light .mat-mdc-elevation-specific.mat-elevation-z10,
.vex-theme-red .light .mat-elevation-z10,
.vex-theme-red .light .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.vex-theme-red .mat-elevation-z11, .vex-theme-red .mat-mdc-elevation-specific.mat-elevation-z11,
.vex-theme-red.light .mat-elevation-z11,
.vex-theme-red.light .mat-mdc-elevation-specific.mat-elevation-z11,
.vex-theme-red .light .mat-elevation-z11,
.vex-theme-red .light .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.vex-theme-red .mat-elevation-z12, .vex-theme-red .mat-mdc-elevation-specific.mat-elevation-z12,
.vex-theme-red.light .mat-elevation-z12,
.vex-theme-red.light .mat-mdc-elevation-specific.mat-elevation-z12,
.vex-theme-red .light .mat-elevation-z12,
.vex-theme-red .light .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.vex-theme-red .mat-elevation-z13, .vex-theme-red .mat-mdc-elevation-specific.mat-elevation-z13,
.vex-theme-red.light .mat-elevation-z13,
.vex-theme-red.light .mat-mdc-elevation-specific.mat-elevation-z13,
.vex-theme-red .light .mat-elevation-z13,
.vex-theme-red .light .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.vex-theme-red .mat-elevation-z14, .vex-theme-red .mat-mdc-elevation-specific.mat-elevation-z14,
.vex-theme-red.light .mat-elevation-z14,
.vex-theme-red.light .mat-mdc-elevation-specific.mat-elevation-z14,
.vex-theme-red .light .mat-elevation-z14,
.vex-theme-red .light .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.vex-theme-red .mat-elevation-z15, .vex-theme-red .mat-mdc-elevation-specific.mat-elevation-z15,
.vex-theme-red.light .mat-elevation-z15,
.vex-theme-red.light .mat-mdc-elevation-specific.mat-elevation-z15,
.vex-theme-red .light .mat-elevation-z15,
.vex-theme-red .light .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.vex-theme-red .mat-elevation-z16, .vex-theme-red .mat-mdc-elevation-specific.mat-elevation-z16,
.vex-theme-red.light .mat-elevation-z16,
.vex-theme-red.light .mat-mdc-elevation-specific.mat-elevation-z16,
.vex-theme-red .light .mat-elevation-z16,
.vex-theme-red .light .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.vex-theme-red .mat-elevation-z17, .vex-theme-red .mat-mdc-elevation-specific.mat-elevation-z17,
.vex-theme-red.light .mat-elevation-z17,
.vex-theme-red.light .mat-mdc-elevation-specific.mat-elevation-z17,
.vex-theme-red .light .mat-elevation-z17,
.vex-theme-red .light .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.vex-theme-red .mat-elevation-z18, .vex-theme-red .mat-mdc-elevation-specific.mat-elevation-z18,
.vex-theme-red.light .mat-elevation-z18,
.vex-theme-red.light .mat-mdc-elevation-specific.mat-elevation-z18,
.vex-theme-red .light .mat-elevation-z18,
.vex-theme-red .light .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.vex-theme-red .mat-elevation-z19, .vex-theme-red .mat-mdc-elevation-specific.mat-elevation-z19,
.vex-theme-red.light .mat-elevation-z19,
.vex-theme-red.light .mat-mdc-elevation-specific.mat-elevation-z19,
.vex-theme-red .light .mat-elevation-z19,
.vex-theme-red .light .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.vex-theme-red .mat-elevation-z20, .vex-theme-red .mat-mdc-elevation-specific.mat-elevation-z20,
.vex-theme-red.light .mat-elevation-z20,
.vex-theme-red.light .mat-mdc-elevation-specific.mat-elevation-z20,
.vex-theme-red .light .mat-elevation-z20,
.vex-theme-red .light .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.vex-theme-red .mat-elevation-z21, .vex-theme-red .mat-mdc-elevation-specific.mat-elevation-z21,
.vex-theme-red.light .mat-elevation-z21,
.vex-theme-red.light .mat-mdc-elevation-specific.mat-elevation-z21,
.vex-theme-red .light .mat-elevation-z21,
.vex-theme-red .light .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.vex-theme-red .mat-elevation-z22, .vex-theme-red .mat-mdc-elevation-specific.mat-elevation-z22,
.vex-theme-red.light .mat-elevation-z22,
.vex-theme-red.light .mat-mdc-elevation-specific.mat-elevation-z22,
.vex-theme-red .light .mat-elevation-z22,
.vex-theme-red .light .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.vex-theme-red .mat-elevation-z23, .vex-theme-red .mat-mdc-elevation-specific.mat-elevation-z23,
.vex-theme-red.light .mat-elevation-z23,
.vex-theme-red.light .mat-mdc-elevation-specific.mat-elevation-z23,
.vex-theme-red .light .mat-elevation-z23,
.vex-theme-red .light .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.vex-theme-red .mat-elevation-z24, .vex-theme-red .mat-mdc-elevation-specific.mat-elevation-z24,
.vex-theme-red.light .mat-elevation-z24,
.vex-theme-red.light .mat-mdc-elevation-specific.mat-elevation-z24,
.vex-theme-red .light .mat-elevation-z24,
.vex-theme-red .light .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.vex-theme-red .mat-mdc-card,
.vex-theme-red.light .mat-mdc-card,
.vex-theme-red .light .mat-mdc-card {
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: #4b5563;
}
.vex-theme-red .mat-mdc-progress-bar,
.vex-theme-red.light .mat-mdc-progress-bar,
.vex-theme-red .light .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #dc2626;
  --mdc-linear-progress-track-color: rgba(220, 38, 38, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.vex-theme-red .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots,
.vex-theme-red.light .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots,
.vex-theme-red .light .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-color: rgba(220, 38, 38, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(220, 38, 38, 0.25));
}
@media (forced-colors: active) {
  .vex-theme-red .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots,
  .vex-theme-red.light .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots,
  .vex-theme-red .light .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .vex-theme-red .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots,
  .vex-theme-red.light .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots,
  .vex-theme-red .light .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(220, 38, 38, 0.25)'/%3E%3C/svg%3E");
  }
}
.vex-theme-red .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar,
.vex-theme-red.light .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar,
.vex-theme-red .light .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgba(220, 38, 38, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(220, 38, 38, 0.25));
}
.vex-theme-red .mat-mdc-progress-bar.mat-accent,
.vex-theme-red.light .mat-mdc-progress-bar.mat-accent,
.vex-theme-red .light .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #f59e0b;
  --mdc-linear-progress-track-color: rgba(245, 158, 11, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.vex-theme-red .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots,
.vex-theme-red.light .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots,
.vex-theme-red .light .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-color: rgba(245, 158, 11, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(245, 158, 11, 0.25));
}
@media (forced-colors: active) {
  .vex-theme-red .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots,
  .vex-theme-red.light .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots,
  .vex-theme-red .light .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .vex-theme-red .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots,
  .vex-theme-red.light .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots,
  .vex-theme-red .light .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(245, 158, 11, 0.25)'/%3E%3C/svg%3E");
  }
}
.vex-theme-red .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar,
.vex-theme-red.light .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar,
.vex-theme-red .light .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: rgba(245, 158, 11, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(245, 158, 11, 0.25));
}
.vex-theme-red .mat-mdc-progress-bar.mat-warn,
.vex-theme-red.light .mat-mdc-progress-bar.mat-warn,
.vex-theme-red .light .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #ef4444;
  --mdc-linear-progress-track-color: rgba(239, 68, 68, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.vex-theme-red .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots,
.vex-theme-red.light .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots,
.vex-theme-red .light .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-color: rgba(239, 68, 68, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(239, 68, 68, 0.25));
}
@media (forced-colors: active) {
  .vex-theme-red .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots,
  .vex-theme-red.light .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots,
  .vex-theme-red .light .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .vex-theme-red .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots,
  .vex-theme-red.light .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots,
  .vex-theme-red .light .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(239, 68, 68, 0.25)'/%3E%3C/svg%3E");
  }
}
.vex-theme-red .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar,
.vex-theme-red.light .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar,
.vex-theme-red .light .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: rgba(239, 68, 68, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(239, 68, 68, 0.25));
}
.vex-theme-red .mat-mdc-tooltip,
.vex-theme-red.light .mat-mdc-tooltip,
.vex-theme-red .light .mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #1f2937;
  --mdc-plain-tooltip-supporting-text-color: #fff;
}
.vex-theme-red, .vex-theme-red.light, .vex-theme-red .light {
  --mdc-filled-text-field-caret-color: #dc2626;
  --mdc-filled-text-field-focus-active-indicator-color: #dc2626;
  --mdc-filled-text-field-focus-label-text-color: rgba(220, 38, 38, 0.87);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-error-focus-label-text-color: #ef4444;
  --mdc-filled-text-field-error-label-text-color: #ef4444;
  --mdc-filled-text-field-error-caret-color: #ef4444;
  --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #ef4444;
  --mdc-filled-text-field-error-focus-active-indicator-color: #ef4444;
  --mdc-filled-text-field-error-hover-active-indicator-color: #ef4444;
  --mdc-outlined-text-field-caret-color: #dc2626;
  --mdc-outlined-text-field-focus-outline-color: #dc2626;
  --mdc-outlined-text-field-focus-label-text-color: rgba(220, 38, 38, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-error-caret-color: #ef4444;
  --mdc-outlined-text-field-error-focus-label-text-color: #ef4444;
  --mdc-outlined-text-field-error-label-text-color: #ef4444;
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #ef4444;
  --mdc-outlined-text-field-error-hover-outline-color: #ef4444;
  --mdc-outlined-text-field-error-outline-color: #ef4444;
  --mat-form-field-disabled-input-text-placeholder-color: rgba(0, 0, 0, 0.38);
}

.vex-theme-red .mat-mdc-form-field-error,
.vex-theme-red.light .mat-mdc-form-field-error,
.vex-theme-red .light .mat-mdc-form-field-error {
  color: var(--mdc-theme-error, #ef4444);
}
.vex-theme-red .mat-mdc-form-field-subscript-wrapper,
.vex-theme-red .mat-mdc-form-field-bottom-align::before,
.vex-theme-red.light .mat-mdc-form-field-subscript-wrapper,
.vex-theme-red.light .mat-mdc-form-field-bottom-align::before,
.vex-theme-red .light .mat-mdc-form-field-subscript-wrapper,
.vex-theme-red .light .mat-mdc-form-field-bottom-align::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mat-form-field-subscript-text-font);
  line-height: var(--mat-form-field-subscript-text-line-height);
  font-size: var(--mat-form-field-subscript-text-size);
  letter-spacing: var(--mat-form-field-subscript-text-tracking);
  font-weight: var(--mat-form-field-subscript-text-weight);
}
.vex-theme-red .mat-mdc-form-field-focus-overlay,
.vex-theme-red.light .mat-mdc-form-field-focus-overlay,
.vex-theme-red .light .mat-mdc-form-field-focus-overlay {
  background-color: rgba(0, 0, 0, 0.87);
}
.vex-theme-red .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay,
.vex-theme-red.light .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay,
.vex-theme-red .light .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0.04;
}
.vex-theme-red .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay,
.vex-theme-red.light .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay,
.vex-theme-red .light .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.12;
}
.vex-theme-red .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after,
.vex-theme-red.light .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after,
.vex-theme-red .light .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.vex-theme-red .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after,
.vex-theme-red.light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after,
.vex-theme-red .light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after {
  color: rgba(220, 38, 38, 0.87);
}
.vex-theme-red .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after,
.vex-theme-red.light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after,
.vex-theme-red .light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after {
  color: rgba(245, 158, 11, 0.87);
}
.vex-theme-red .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after,
.vex-theme-red.light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after,
.vex-theme-red .light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after {
  color: rgba(239, 68, 68, 0.87);
}
.vex-theme-red .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after,
.vex-theme-red.light .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after,
.vex-theme-red .light .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.vex-theme-red .mat-mdc-form-field.mat-accent,
.vex-theme-red.light .mat-mdc-form-field.mat-accent,
.vex-theme-red .light .mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #f59e0b;
  --mdc-filled-text-field-focus-active-indicator-color: #f59e0b;
  --mdc-filled-text-field-focus-label-text-color: rgba(245, 158, 11, 0.87);
  --mdc-outlined-text-field-caret-color: #f59e0b;
  --mdc-outlined-text-field-focus-outline-color: #f59e0b;
  --mdc-outlined-text-field-focus-label-text-color: rgba(245, 158, 11, 0.87);
}
.vex-theme-red .mat-mdc-form-field.mat-warn,
.vex-theme-red.light .mat-mdc-form-field.mat-warn,
.vex-theme-red .light .mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #ef4444;
  --mdc-filled-text-field-focus-active-indicator-color: #ef4444;
  --mdc-filled-text-field-focus-label-text-color: rgba(239, 68, 68, 0.87);
  --mdc-outlined-text-field-caret-color: #ef4444;
  --mdc-outlined-text-field-focus-outline-color: #ef4444;
  --mdc-outlined-text-field-focus-label-text-color: rgba(239, 68, 68, 0.87);
}
.vex-theme-red .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch,
.vex-theme-red.light .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch,
.vex-theme-red .light .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: 1px solid transparent;
}
.vex-theme-red [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch,
.vex-theme-red.light [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch,
.vex-theme-red .light [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none;
  border-right: 1px solid transparent;
}
.vex-theme-red, .vex-theme-red.light, .vex-theme-red .light {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(220, 38, 38, 0.87);
  --mat-select-invalid-arrow-color: rgba(239, 68, 68, 0.87);
}
.vex-theme-red .mat-mdc-form-field.mat-accent, .vex-theme-red.light .mat-mdc-form-field.mat-accent, .vex-theme-red .light .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(245, 158, 11, 0.87);
  --mat-select-invalid-arrow-color: rgba(239, 68, 68, 0.87);
}
.vex-theme-red .mat-mdc-form-field.mat-warn, .vex-theme-red.light .mat-mdc-form-field.mat-warn, .vex-theme-red .light .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(239, 68, 68, 0.87);
  --mat-select-invalid-arrow-color: rgba(239, 68, 68, 0.87);
}

.vex-theme-red, .vex-theme-red.light, .vex-theme-red .light {
  --mat-autocomplete-background-color: white;
}

.vex-theme-red .mat-mdc-dialog-container,
.vex-theme-red.light .mat-mdc-dialog-container,
.vex-theme-red .light .mat-mdc-dialog-container {
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}
.vex-theme-red .mat-mdc-standard-chip,
.vex-theme-red.light .mat-mdc-standard-chip,
.vex-theme-red .light .mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
}
.vex-theme-red .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .vex-theme-red .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary,
.vex-theme-red.light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary,
.vex-theme-red.light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary,
.vex-theme-red .light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary,
.vex-theme-red .light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #dc2626;
  --mdc-chip-elevated-disabled-container-color: #dc2626;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.vex-theme-red .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .vex-theme-red .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent,
.vex-theme-red.light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent,
.vex-theme-red.light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent,
.vex-theme-red .light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent,
.vex-theme-red .light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #f59e0b;
  --mdc-chip-elevated-disabled-container-color: #f59e0b;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.vex-theme-red .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .vex-theme-red .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn,
.vex-theme-red.light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn,
.vex-theme-red.light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn,
.vex-theme-red .light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn,
.vex-theme-red .light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #ef4444;
  --mdc-chip-elevated-disabled-container-color: #ef4444;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.vex-theme-red .mat-mdc-slide-toggle,
.vex-theme-red.light .mat-mdc-slide-toggle,
.vex-theme-red .light .mat-mdc-slide-toggle {
  --mdc-switch-selected-focus-state-layer-color: #dc2626;
  --mdc-switch-selected-handle-color: #dc2626;
  --mdc-switch-selected-hover-state-layer-color: #dc2626;
  --mdc-switch-selected-pressed-state-layer-color: #dc2626;
  --mdc-switch-selected-focus-handle-color: #7f1d1d;
  --mdc-switch-selected-hover-handle-color: #7f1d1d;
  --mdc-switch-selected-pressed-handle-color: #7f1d1d;
  --mdc-switch-selected-focus-track-color: #fca5a5;
  --mdc-switch-selected-hover-track-color: #fca5a5;
  --mdc-switch-selected-pressed-track-color: #fca5a5;
  --mdc-switch-selected-track-color: #fca5a5;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
}
.vex-theme-red .mat-mdc-slide-toggle .mdc-form-field,
.vex-theme-red.light .mat-mdc-slide-toggle .mdc-form-field,
.vex-theme-red .light .mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.vex-theme-red .mat-mdc-slide-toggle .mdc-switch--disabled + label,
.vex-theme-red.light .mat-mdc-slide-toggle .mdc-switch--disabled + label,
.vex-theme-red .light .mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: #6b7280;
}
.vex-theme-red .mat-mdc-slide-toggle.mat-accent,
.vex-theme-red.light .mat-mdc-slide-toggle.mat-accent,
.vex-theme-red .light .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #d97706;
  --mdc-switch-selected-handle-color: #d97706;
  --mdc-switch-selected-hover-state-layer-color: #d97706;
  --mdc-switch-selected-pressed-state-layer-color: #d97706;
  --mdc-switch-selected-focus-handle-color: #78350f;
  --mdc-switch-selected-hover-handle-color: #78350f;
  --mdc-switch-selected-pressed-handle-color: #78350f;
  --mdc-switch-selected-focus-track-color: #fcd34d;
  --mdc-switch-selected-hover-track-color: #fcd34d;
  --mdc-switch-selected-pressed-track-color: #fcd34d;
  --mdc-switch-selected-track-color: #fcd34d;
}
.vex-theme-red .mat-mdc-slide-toggle.mat-warn,
.vex-theme-red.light .mat-mdc-slide-toggle.mat-warn,
.vex-theme-red .light .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #dc2626;
  --mdc-switch-selected-handle-color: #dc2626;
  --mdc-switch-selected-hover-state-layer-color: #dc2626;
  --mdc-switch-selected-pressed-state-layer-color: #dc2626;
  --mdc-switch-selected-focus-handle-color: #7f1d1d;
  --mdc-switch-selected-hover-handle-color: #7f1d1d;
  --mdc-switch-selected-pressed-handle-color: #7f1d1d;
  --mdc-switch-selected-focus-track-color: #fca5a5;
  --mdc-switch-selected-hover-track-color: #fca5a5;
  --mdc-switch-selected-pressed-track-color: #fca5a5;
  --mdc-switch-selected-track-color: #fca5a5;
}
.vex-theme-red .mat-mdc-radio-button .mdc-form-field,
.vex-theme-red.light .mat-mdc-radio-button .mdc-form-field,
.vex-theme-red .light .mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.vex-theme-red .mat-mdc-radio-button.mat-primary,
.vex-theme-red.light .mat-mdc-radio-button.mat-primary,
.vex-theme-red .light .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #dc2626;
  --mdc-radio-selected-hover-icon-color: #dc2626;
  --mdc-radio-selected-icon-color: #dc2626;
  --mdc-radio-selected-pressed-icon-color: #dc2626;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #dc2626;
  --mat-radio-disabled-label-color: #6b7280;
}
.vex-theme-red .mat-mdc-radio-button.mat-accent,
.vex-theme-red.light .mat-mdc-radio-button.mat-accent,
.vex-theme-red .light .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f59e0b;
  --mdc-radio-selected-hover-icon-color: #f59e0b;
  --mdc-radio-selected-icon-color: #f59e0b;
  --mdc-radio-selected-pressed-icon-color: #f59e0b;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #f59e0b;
  --mat-radio-disabled-label-color: #6b7280;
}
.vex-theme-red .mat-mdc-radio-button.mat-warn,
.vex-theme-red.light .mat-mdc-radio-button.mat-warn,
.vex-theme-red .light .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #ef4444;
  --mdc-radio-selected-hover-icon-color: #ef4444;
  --mdc-radio-selected-icon-color: #ef4444;
  --mdc-radio-selected-pressed-icon-color: #ef4444;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #ef4444;
  --mat-radio-disabled-label-color: #6b7280;
}
.vex-theme-red .mat-mdc-slider,
.vex-theme-red.light .mat-mdc-slider,
.vex-theme-red .light .mat-mdc-slider {
  --mdc-slider-label-container-color: black;
  --mdc-slider-label-label-text-color: white;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-mdc-slider-value-indicator-opacity: 0.6;
}
.vex-theme-red .mat-mdc-slider.mat-primary,
.vex-theme-red.light .mat-mdc-slider.mat-primary,
.vex-theme-red .light .mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #dc2626;
  --mdc-slider-focus-handle-color: #dc2626;
  --mdc-slider-hover-handle-color: #dc2626;
  --mdc-slider-active-track-color: #dc2626;
  --mdc-slider-inactive-track-color: #dc2626;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #dc2626;
  --mat-mdc-slider-ripple-color: #dc2626;
  --mat-mdc-slider-hover-ripple-color: rgba(220, 38, 38, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(220, 38, 38, 0.2);
}
.vex-theme-red .mat-mdc-slider.mat-accent,
.vex-theme-red.light .mat-mdc-slider.mat-accent,
.vex-theme-red .light .mat-mdc-slider.mat-accent {
  --mdc-slider-handle-color: #f59e0b;
  --mdc-slider-focus-handle-color: #f59e0b;
  --mdc-slider-hover-handle-color: #f59e0b;
  --mdc-slider-active-track-color: #f59e0b;
  --mdc-slider-inactive-track-color: #f59e0b;
  --mdc-slider-with-tick-marks-active-container-color: #000;
  --mdc-slider-with-tick-marks-inactive-container-color: #f59e0b;
  --mat-mdc-slider-ripple-color: #f59e0b;
  --mat-mdc-slider-hover-ripple-color: rgba(245, 158, 11, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(245, 158, 11, 0.2);
}
.vex-theme-red .mat-mdc-slider.mat-warn,
.vex-theme-red.light .mat-mdc-slider.mat-warn,
.vex-theme-red .light .mat-mdc-slider.mat-warn {
  --mdc-slider-handle-color: #ef4444;
  --mdc-slider-focus-handle-color: #ef4444;
  --mdc-slider-hover-handle-color: #ef4444;
  --mdc-slider-active-track-color: #ef4444;
  --mdc-slider-inactive-track-color: #ef4444;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #ef4444;
  --mat-mdc-slider-ripple-color: #ef4444;
  --mat-mdc-slider-hover-ripple-color: rgba(239, 68, 68, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(239, 68, 68, 0.2);
}
.vex-theme-red, .vex-theme-red.light, .vex-theme-red .light {
  --mat-menu-item-label-text-color: #030712;
  --mat-menu-item-icon-color: #030712;
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
}

.vex-theme-red .mat-mdc-list-base,
.vex-theme-red.light .mat-mdc-list-base,
.vex-theme-red .light .mat-mdc-list-base {
  --mdc-list-list-item-label-text-color: #030712;
  --mdc-list-list-item-supporting-text-color: #4b5563;
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: #6b7280;
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: #030712;
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: #030712;
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
}
.vex-theme-red .mdc-list-item__start,
.vex-theme-red .mdc-list-item__end,
.vex-theme-red.light .mdc-list-item__start,
.vex-theme-red.light .mdc-list-item__end,
.vex-theme-red .light .mdc-list-item__start,
.vex-theme-red .light .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #dc2626;
  --mdc-radio-selected-hover-icon-color: #dc2626;
  --mdc-radio-selected-icon-color: #dc2626;
  --mdc-radio-selected-pressed-icon-color: #dc2626;
}
.vex-theme-red .mat-accent .mdc-list-item__start,
.vex-theme-red .mat-accent .mdc-list-item__end,
.vex-theme-red.light .mat-accent .mdc-list-item__start,
.vex-theme-red.light .mat-accent .mdc-list-item__end,
.vex-theme-red .light .mat-accent .mdc-list-item__start,
.vex-theme-red .light .mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f59e0b;
  --mdc-radio-selected-hover-icon-color: #f59e0b;
  --mdc-radio-selected-icon-color: #f59e0b;
  --mdc-radio-selected-pressed-icon-color: #f59e0b;
}
.vex-theme-red .mat-warn .mdc-list-item__start,
.vex-theme-red .mat-warn .mdc-list-item__end,
.vex-theme-red.light .mat-warn .mdc-list-item__start,
.vex-theme-red.light .mat-warn .mdc-list-item__end,
.vex-theme-red .light .mat-warn .mdc-list-item__start,
.vex-theme-red .light .mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #ef4444;
  --mdc-radio-selected-hover-icon-color: #ef4444;
  --mdc-radio-selected-icon-color: #ef4444;
  --mdc-radio-selected-pressed-icon-color: #ef4444;
}
.vex-theme-red .mat-mdc-list-option,
.vex-theme-red.light .mat-mdc-list-option,
.vex-theme-red .light .mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #dc2626;
  --mdc-checkbox-selected-hover-icon-color: #dc2626;
  --mdc-checkbox-selected-icon-color: #dc2626;
  --mdc-checkbox-selected-pressed-icon-color: #dc2626;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #dc2626;
  --mdc-checkbox-selected-hover-state-layer-color: #dc2626;
  --mdc-checkbox-selected-pressed-state-layer-color: #dc2626;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.vex-theme-red .mat-mdc-list-option.mat-accent,
.vex-theme-red.light .mat-mdc-list-option.mat-accent,
.vex-theme-red .light .mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #f59e0b;
  --mdc-checkbox-selected-hover-icon-color: #f59e0b;
  --mdc-checkbox-selected-icon-color: #f59e0b;
  --mdc-checkbox-selected-pressed-icon-color: #f59e0b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f59e0b;
  --mdc-checkbox-selected-hover-state-layer-color: #f59e0b;
  --mdc-checkbox-selected-pressed-state-layer-color: #f59e0b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.vex-theme-red .mat-mdc-list-option.mat-warn,
.vex-theme-red.light .mat-mdc-list-option.mat-warn,
.vex-theme-red .light .mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #ef4444;
  --mdc-checkbox-selected-hover-icon-color: #ef4444;
  --mdc-checkbox-selected-icon-color: #ef4444;
  --mdc-checkbox-selected-pressed-icon-color: #ef4444;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #ef4444;
  --mdc-checkbox-selected-hover-state-layer-color: #ef4444;
  --mdc-checkbox-selected-pressed-state-layer-color: #ef4444;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.vex-theme-red .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.vex-theme-red .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,
.vex-theme-red.light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.vex-theme-red.light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,
.vex-theme-red .light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.vex-theme-red .light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #dc2626;
}
.vex-theme-red .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.vex-theme-red .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.vex-theme-red.light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.vex-theme-red.light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.vex-theme-red .light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.vex-theme-red .light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #dc2626;
}
.vex-theme-red .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.vex-theme-red .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.vex-theme-red .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end,
.vex-theme-red.light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.vex-theme-red.light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.vex-theme-red.light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end,
.vex-theme-red .light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.vex-theme-red .light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.vex-theme-red .light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.vex-theme-red, .vex-theme-red.light, .vex-theme-red .light {
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
}

.vex-theme-red .mat-mdc-tab-group, .vex-theme-red .mat-mdc-tab-nav-bar,
.vex-theme-red.light .mat-mdc-tab-group,
.vex-theme-red.light .mat-mdc-tab-nav-bar,
.vex-theme-red .light .mat-mdc-tab-group,
.vex-theme-red .light .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #dc2626;
  --mat-tab-header-disabled-ripple-color: #6b7280;
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #dc2626;
  --mat-tab-header-active-ripple-color: #dc2626;
  --mat-tab-header-inactive-ripple-color: #dc2626;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #dc2626;
  --mat-tab-header-active-hover-label-text-color: #dc2626;
  --mat-tab-header-active-focus-indicator-color: #dc2626;
  --mat-tab-header-active-hover-indicator-color: #dc2626;
}
.vex-theme-red .mat-mdc-tab-group.mat-accent, .vex-theme-red .mat-mdc-tab-nav-bar.mat-accent,
.vex-theme-red.light .mat-mdc-tab-group.mat-accent,
.vex-theme-red.light .mat-mdc-tab-nav-bar.mat-accent,
.vex-theme-red .light .mat-mdc-tab-group.mat-accent,
.vex-theme-red .light .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #f59e0b;
  --mat-tab-header-disabled-ripple-color: #6b7280;
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #f59e0b;
  --mat-tab-header-active-ripple-color: #f59e0b;
  --mat-tab-header-inactive-ripple-color: #f59e0b;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #f59e0b;
  --mat-tab-header-active-hover-label-text-color: #f59e0b;
  --mat-tab-header-active-focus-indicator-color: #f59e0b;
  --mat-tab-header-active-hover-indicator-color: #f59e0b;
}
.vex-theme-red .mat-mdc-tab-group.mat-warn, .vex-theme-red .mat-mdc-tab-nav-bar.mat-warn,
.vex-theme-red.light .mat-mdc-tab-group.mat-warn,
.vex-theme-red.light .mat-mdc-tab-nav-bar.mat-warn,
.vex-theme-red .light .mat-mdc-tab-group.mat-warn,
.vex-theme-red .light .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #ef4444;
  --mat-tab-header-disabled-ripple-color: #6b7280;
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #ef4444;
  --mat-tab-header-active-ripple-color: #ef4444;
  --mat-tab-header-inactive-ripple-color: #ef4444;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #ef4444;
  --mat-tab-header-active-hover-label-text-color: #ef4444;
  --mat-tab-header-active-focus-indicator-color: #ef4444;
  --mat-tab-header-active-hover-indicator-color: #ef4444;
}
.vex-theme-red .mat-mdc-tab-group.mat-background-primary, .vex-theme-red .mat-mdc-tab-nav-bar.mat-background-primary,
.vex-theme-red.light .mat-mdc-tab-group.mat-background-primary,
.vex-theme-red.light .mat-mdc-tab-nav-bar.mat-background-primary,
.vex-theme-red .light .mat-mdc-tab-group.mat-background-primary,
.vex-theme-red .light .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #dc2626;
  --mat-tab-header-with-background-foreground-color: white;
}
.vex-theme-red .mat-mdc-tab-group.mat-background-accent, .vex-theme-red .mat-mdc-tab-nav-bar.mat-background-accent,
.vex-theme-red.light .mat-mdc-tab-group.mat-background-accent,
.vex-theme-red.light .mat-mdc-tab-nav-bar.mat-background-accent,
.vex-theme-red .light .mat-mdc-tab-group.mat-background-accent,
.vex-theme-red .light .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #f59e0b;
  --mat-tab-header-with-background-foreground-color: white;
}
.vex-theme-red .mat-mdc-tab-group.mat-background-warn, .vex-theme-red .mat-mdc-tab-nav-bar.mat-background-warn,
.vex-theme-red.light .mat-mdc-tab-group.mat-background-warn,
.vex-theme-red.light .mat-mdc-tab-nav-bar.mat-background-warn,
.vex-theme-red .light .mat-mdc-tab-group.mat-background-warn,
.vex-theme-red .light .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #ef4444;
  --mat-tab-header-with-background-foreground-color: white;
}
.vex-theme-red, .vex-theme-red.light, .vex-theme-red .light {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #f59e0b;
  --mdc-checkbox-selected-hover-icon-color: #f59e0b;
  --mdc-checkbox-selected-icon-color: #f59e0b;
  --mdc-checkbox-selected-pressed-icon-color: #f59e0b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f59e0b;
  --mdc-checkbox-selected-hover-state-layer-color: #f59e0b;
  --mdc-checkbox-selected-pressed-state-layer-color: #f59e0b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.vex-theme-red .mat-mdc-checkbox.mat-primary,
.vex-theme-red.light .mat-mdc-checkbox.mat-primary,
.vex-theme-red .light .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #dc2626;
  --mdc-checkbox-selected-hover-icon-color: #dc2626;
  --mdc-checkbox-selected-icon-color: #dc2626;
  --mdc-checkbox-selected-pressed-icon-color: #dc2626;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #dc2626;
  --mdc-checkbox-selected-hover-state-layer-color: #dc2626;
  --mdc-checkbox-selected-pressed-state-layer-color: #dc2626;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.vex-theme-red .mat-mdc-checkbox.mat-warn,
.vex-theme-red.light .mat-mdc-checkbox.mat-warn,
.vex-theme-red .light .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #ef4444;
  --mdc-checkbox-selected-hover-icon-color: #ef4444;
  --mdc-checkbox-selected-icon-color: #ef4444;
  --mdc-checkbox-selected-pressed-icon-color: #ef4444;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #ef4444;
  --mdc-checkbox-selected-hover-state-layer-color: #ef4444;
  --mdc-checkbox-selected-pressed-state-layer-color: #ef4444;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.vex-theme-red .mat-mdc-checkbox .mdc-form-field,
.vex-theme-red.light .mat-mdc-checkbox .mdc-form-field,
.vex-theme-red .light .mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.vex-theme-red .mat-mdc-checkbox.mat-mdc-checkbox-disabled label,
.vex-theme-red.light .mat-mdc-checkbox.mat-mdc-checkbox-disabled label,
.vex-theme-red .light .mat-mdc-checkbox.mat-mdc-checkbox-disabled label {
  color: #6b7280;
}
.vex-theme-red .mat-mdc-button.mat-unthemed,
.vex-theme-red.light .mat-mdc-button.mat-unthemed,
.vex-theme-red .light .mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #000;
}
.vex-theme-red .mat-mdc-button.mat-primary,
.vex-theme-red.light .mat-mdc-button.mat-primary,
.vex-theme-red .light .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #dc2626;
}
.vex-theme-red .mat-mdc-button.mat-accent,
.vex-theme-red.light .mat-mdc-button.mat-accent,
.vex-theme-red .light .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #f59e0b;
}
.vex-theme-red .mat-mdc-button.mat-warn,
.vex-theme-red.light .mat-mdc-button.mat-warn,
.vex-theme-red .light .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #ef4444;
}
.vex-theme-red .mat-mdc-button[disabled][disabled],
.vex-theme-red.light .mat-mdc-button[disabled][disabled],
.vex-theme-red .light .mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-text-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.vex-theme-red .mat-mdc-unelevated-button.mat-unthemed,
.vex-theme-red.light .mat-mdc-unelevated-button.mat-unthemed,
.vex-theme-red .light .mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #fff;
  --mdc-filled-button-label-text-color: #000;
}
.vex-theme-red .mat-mdc-unelevated-button.mat-primary,
.vex-theme-red.light .mat-mdc-unelevated-button.mat-primary,
.vex-theme-red .light .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #dc2626;
  --mdc-filled-button-label-text-color: #fff;
}
.vex-theme-red .mat-mdc-unelevated-button.mat-accent,
.vex-theme-red.light .mat-mdc-unelevated-button.mat-accent,
.vex-theme-red .light .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #f59e0b;
  --mdc-filled-button-label-text-color: #000;
}
.vex-theme-red .mat-mdc-unelevated-button.mat-warn,
.vex-theme-red.light .mat-mdc-unelevated-button.mat-warn,
.vex-theme-red .light .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #ef4444;
  --mdc-filled-button-label-text-color: #fff;
}
.vex-theme-red .mat-mdc-unelevated-button[disabled][disabled],
.vex-theme-red.light .mat-mdc-unelevated-button[disabled][disabled],
.vex-theme-red .light .mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.vex-theme-red .mat-mdc-raised-button.mat-unthemed,
.vex-theme-red.light .mat-mdc-raised-button.mat-unthemed,
.vex-theme-red .light .mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #fff;
  --mdc-protected-button-label-text-color: #000;
}
.vex-theme-red .mat-mdc-raised-button.mat-primary,
.vex-theme-red.light .mat-mdc-raised-button.mat-primary,
.vex-theme-red .light .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #dc2626;
  --mdc-protected-button-label-text-color: #fff;
}
.vex-theme-red .mat-mdc-raised-button.mat-accent,
.vex-theme-red.light .mat-mdc-raised-button.mat-accent,
.vex-theme-red .light .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #f59e0b;
  --mdc-protected-button-label-text-color: #000;
}
.vex-theme-red .mat-mdc-raised-button.mat-warn,
.vex-theme-red.light .mat-mdc-raised-button.mat-warn,
.vex-theme-red .light .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #ef4444;
  --mdc-protected-button-label-text-color: #fff;
}
.vex-theme-red .mat-mdc-raised-button[disabled][disabled],
.vex-theme-red.light .mat-mdc-raised-button[disabled][disabled],
.vex-theme-red .light .mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation: 0;
}
.vex-theme-red .mat-mdc-outlined-button,
.vex-theme-red.light .mat-mdc-outlined-button,
.vex-theme-red .light .mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.vex-theme-red .mat-mdc-outlined-button.mat-unthemed,
.vex-theme-red.light .mat-mdc-outlined-button.mat-unthemed,
.vex-theme-red .light .mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #000;
}
.vex-theme-red .mat-mdc-outlined-button.mat-primary,
.vex-theme-red.light .mat-mdc-outlined-button.mat-primary,
.vex-theme-red .light .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #dc2626;
}
.vex-theme-red .mat-mdc-outlined-button.mat-accent,
.vex-theme-red.light .mat-mdc-outlined-button.mat-accent,
.vex-theme-red .light .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #f59e0b;
}
.vex-theme-red .mat-mdc-outlined-button.mat-warn,
.vex-theme-red.light .mat-mdc-outlined-button.mat-warn,
.vex-theme-red .light .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #ef4444;
}
.vex-theme-red .mat-mdc-outlined-button[disabled][disabled],
.vex-theme-red.light .mat-mdc-outlined-button[disabled][disabled],
.vex-theme-red .light .mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
}
.vex-theme-red .mat-mdc-button, .vex-theme-red .mat-mdc-outlined-button,
.vex-theme-red.light .mat-mdc-button,
.vex-theme-red.light .mat-mdc-outlined-button,
.vex-theme-red .light .mat-mdc-button,
.vex-theme-red .light .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.vex-theme-red .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .vex-theme-red .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-red.light .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-red.light .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-red .light .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-red .light .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.vex-theme-red .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-red .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-red .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-red .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-red.light .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-red.light .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-red.light .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-red.light .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-red .light .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-red .light .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-red .light .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-red .light .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.vex-theme-red .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .vex-theme-red .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-red.light .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-red.light .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-red .light .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-red .light .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.vex-theme-red .mat-mdc-button.mat-primary, .vex-theme-red .mat-mdc-outlined-button.mat-primary,
.vex-theme-red.light .mat-mdc-button.mat-primary,
.vex-theme-red.light .mat-mdc-outlined-button.mat-primary,
.vex-theme-red .light .mat-mdc-button.mat-primary,
.vex-theme-red .light .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #dc2626;
  --mat-mdc-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.vex-theme-red .mat-mdc-button.mat-accent, .vex-theme-red .mat-mdc-outlined-button.mat-accent,
.vex-theme-red.light .mat-mdc-button.mat-accent,
.vex-theme-red.light .mat-mdc-outlined-button.mat-accent,
.vex-theme-red .light .mat-mdc-button.mat-accent,
.vex-theme-red .light .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #f59e0b;
  --mat-mdc-button-ripple-color: rgba(245, 158, 11, 0.1);
}
.vex-theme-red .mat-mdc-button.mat-warn, .vex-theme-red .mat-mdc-outlined-button.mat-warn,
.vex-theme-red.light .mat-mdc-button.mat-warn,
.vex-theme-red.light .mat-mdc-outlined-button.mat-warn,
.vex-theme-red .light .mat-mdc-button.mat-warn,
.vex-theme-red .light .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #ef4444;
  --mat-mdc-button-ripple-color: rgba(239, 68, 68, 0.1);
}
.vex-theme-red .mat-mdc-raised-button, .vex-theme-red .mat-mdc-unelevated-button,
.vex-theme-red.light .mat-mdc-raised-button,
.vex-theme-red.light .mat-mdc-unelevated-button,
.vex-theme-red .light .mat-mdc-raised-button,
.vex-theme-red .light .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.vex-theme-red .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .vex-theme-red .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-red.light .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-red.light .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-red .light .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-red .light .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.vex-theme-red .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-red .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-red .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-red .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-red.light .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-red.light .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-red.light .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-red.light .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-red .light .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-red .light .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-red .light .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-red .light .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.vex-theme-red .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .vex-theme-red .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-red.light .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-red.light .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-red .light .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-red .light .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.vex-theme-red .mat-mdc-raised-button.mat-primary, .vex-theme-red .mat-mdc-unelevated-button.mat-primary,
.vex-theme-red.light .mat-mdc-raised-button.mat-primary,
.vex-theme-red.light .mat-mdc-unelevated-button.mat-primary,
.vex-theme-red .light .mat-mdc-raised-button.mat-primary,
.vex-theme-red .light .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.vex-theme-red .mat-mdc-raised-button.mat-accent, .vex-theme-red .mat-mdc-unelevated-button.mat-accent,
.vex-theme-red.light .mat-mdc-raised-button.mat-accent,
.vex-theme-red.light .mat-mdc-unelevated-button.mat-accent,
.vex-theme-red .light .mat-mdc-raised-button.mat-accent,
.vex-theme-red .light .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.vex-theme-red .mat-mdc-raised-button.mat-warn, .vex-theme-red .mat-mdc-unelevated-button.mat-warn,
.vex-theme-red.light .mat-mdc-raised-button.mat-warn,
.vex-theme-red.light .mat-mdc-unelevated-button.mat-warn,
.vex-theme-red .light .mat-mdc-raised-button.mat-warn,
.vex-theme-red .light .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.vex-theme-red .mat-mdc-icon-button,
.vex-theme-red.light .mat-mdc-icon-button,
.vex-theme-red .light .mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mdc-icon-button-icon-color: inherit;
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.vex-theme-red .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-red.light .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-red .light .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.vex-theme-red .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-red .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-red.light .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-red.light .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-red .light .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-red .light .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.vex-theme-red .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-red.light .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-red .light .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.vex-theme-red .mat-mdc-icon-button.mat-primary,
.vex-theme-red.light .mat-mdc-icon-button.mat-primary,
.vex-theme-red .light .mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #6200ee;
  --mat-mdc-button-ripple-color: rgba(98, 0, 238, 0.1);
}
.vex-theme-red .mat-mdc-icon-button.mat-accent,
.vex-theme-red.light .mat-mdc-icon-button.mat-accent,
.vex-theme-red .light .mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #018786;
  --mat-mdc-button-ripple-color: rgba(1, 135, 134, 0.1);
}
.vex-theme-red .mat-mdc-icon-button.mat-warn,
.vex-theme-red.light .mat-mdc-icon-button.mat-warn,
.vex-theme-red .light .mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #b00020;
  --mat-mdc-button-ripple-color: rgba(176, 0, 32, 0.1);
}
.vex-theme-red .mat-mdc-icon-button.mat-primary,
.vex-theme-red.light .mat-mdc-icon-button.mat-primary,
.vex-theme-red .light .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #dc2626;
  --mat-mdc-button-persistent-ripple-color: #dc2626;
  --mat-mdc-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.vex-theme-red .mat-mdc-icon-button.mat-accent,
.vex-theme-red.light .mat-mdc-icon-button.mat-accent,
.vex-theme-red .light .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #f59e0b;
  --mat-mdc-button-persistent-ripple-color: #f59e0b;
  --mat-mdc-button-ripple-color: rgba(245, 158, 11, 0.1);
}
.vex-theme-red .mat-mdc-icon-button.mat-warn,
.vex-theme-red.light .mat-mdc-icon-button.mat-warn,
.vex-theme-red .light .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #ef4444;
  --mat-mdc-button-persistent-ripple-color: #ef4444;
  --mat-mdc-button-ripple-color: rgba(239, 68, 68, 0.1);
}
.vex-theme-red .mat-mdc-icon-button[disabled][disabled],
.vex-theme-red.light .mat-mdc-icon-button[disabled][disabled],
.vex-theme-red .light .mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
}
.vex-theme-red .mat-mdc-fab,
.vex-theme-red .mat-mdc-mini-fab,
.vex-theme-red.light .mat-mdc-fab,
.vex-theme-red.light .mat-mdc-mini-fab,
.vex-theme-red .light .mat-mdc-fab,
.vex-theme-red .light .mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.vex-theme-red .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-red .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-red.light .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-red.light .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-red .light .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-red .light .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.vex-theme-red .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-red .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-red .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-red .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-red.light .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-red.light .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-red.light .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-red.light .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-red .light .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-red .light .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-red .light .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-red .light .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.vex-theme-red .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-red .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-red.light .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-red.light .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-red .light .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-red .light .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.vex-theme-red .mat-mdc-fab.mat-primary,
.vex-theme-red .mat-mdc-mini-fab.mat-primary,
.vex-theme-red.light .mat-mdc-fab.mat-primary,
.vex-theme-red.light .mat-mdc-mini-fab.mat-primary,
.vex-theme-red .light .mat-mdc-fab.mat-primary,
.vex-theme-red .light .mat-mdc-mini-fab.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.vex-theme-red .mat-mdc-fab.mat-accent,
.vex-theme-red .mat-mdc-mini-fab.mat-accent,
.vex-theme-red.light .mat-mdc-fab.mat-accent,
.vex-theme-red.light .mat-mdc-mini-fab.mat-accent,
.vex-theme-red .light .mat-mdc-fab.mat-accent,
.vex-theme-red .light .mat-mdc-mini-fab.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.vex-theme-red .mat-mdc-fab.mat-warn,
.vex-theme-red .mat-mdc-mini-fab.mat-warn,
.vex-theme-red.light .mat-mdc-fab.mat-warn,
.vex-theme-red.light .mat-mdc-mini-fab.mat-warn,
.vex-theme-red .light .mat-mdc-fab.mat-warn,
.vex-theme-red .light .mat-mdc-mini-fab.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.vex-theme-red .mat-mdc-fab[disabled][disabled],
.vex-theme-red .mat-mdc-mini-fab[disabled][disabled],
.vex-theme-red.light .mat-mdc-fab[disabled][disabled],
.vex-theme-red.light .mat-mdc-mini-fab[disabled][disabled],
.vex-theme-red .light .mat-mdc-fab[disabled][disabled],
.vex-theme-red .light .mat-mdc-mini-fab[disabled][disabled] {
  --mdc-fab-container-color: rgba(0, 0, 0, 0.12);
  --mdc-fab-icon-color: rgba(0, 0, 0, 0.38);
  --mat-mdc-fab-color: rgba(0, 0, 0, 0.38);
}
.vex-theme-red .mat-mdc-fab.mat-unthemed,
.vex-theme-red .mat-mdc-mini-fab.mat-unthemed,
.vex-theme-red.light .mat-mdc-fab.mat-unthemed,
.vex-theme-red.light .mat-mdc-mini-fab.mat-unthemed,
.vex-theme-red .light .mat-mdc-fab.mat-unthemed,
.vex-theme-red .light .mat-mdc-mini-fab.mat-unthemed {
  --mdc-fab-container-color: white;
  --mdc-fab-icon-color: black;
  --mat-mdc-fab-color: #000;
}
.vex-theme-red .mat-mdc-fab.mat-primary,
.vex-theme-red .mat-mdc-mini-fab.mat-primary,
.vex-theme-red.light .mat-mdc-fab.mat-primary,
.vex-theme-red.light .mat-mdc-mini-fab.mat-primary,
.vex-theme-red .light .mat-mdc-fab.mat-primary,
.vex-theme-red .light .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #dc2626;
  --mdc-fab-icon-color: white;
  --mat-mdc-fab-color: #fff;
}
.vex-theme-red .mat-mdc-fab.mat-accent,
.vex-theme-red .mat-mdc-mini-fab.mat-accent,
.vex-theme-red.light .mat-mdc-fab.mat-accent,
.vex-theme-red.light .mat-mdc-mini-fab.mat-accent,
.vex-theme-red .light .mat-mdc-fab.mat-accent,
.vex-theme-red .light .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: #f59e0b;
  --mdc-fab-icon-color: black;
  --mat-mdc-fab-color: #000;
}
.vex-theme-red .mat-mdc-fab.mat-warn,
.vex-theme-red .mat-mdc-mini-fab.mat-warn,
.vex-theme-red.light .mat-mdc-fab.mat-warn,
.vex-theme-red.light .mat-mdc-mini-fab.mat-warn,
.vex-theme-red .light .mat-mdc-fab.mat-warn,
.vex-theme-red .light .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: #ef4444;
  --mdc-fab-icon-color: white;
  --mat-mdc-fab-color: #fff;
}
.vex-theme-red .mat-mdc-snack-bar-container,
.vex-theme-red.light .mat-mdc-snack-bar-container,
.vex-theme-red .light .mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #f59e0b;
}
.vex-theme-red .mat-mdc-progress-spinner,
.vex-theme-red.light .mat-mdc-progress-spinner,
.vex-theme-red .light .mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #dc2626;
}
.vex-theme-red .mat-mdc-progress-spinner.mat-accent,
.vex-theme-red.light .mat-mdc-progress-spinner.mat-accent,
.vex-theme-red .light .mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: #f59e0b;
}
.vex-theme-red .mat-mdc-progress-spinner.mat-warn,
.vex-theme-red.light .mat-mdc-progress-spinner.mat-warn,
.vex-theme-red .light .mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: #ef4444;
}
.vex-theme-red, .vex-theme-red.light, .vex-theme-red .light {
  --mat-badge-background-color: #dc2626;
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: #9ca3af;
  --mat-badge-disabled-state-text-color: #6b7280;
}

.vex-theme-red .mat-badge-accent,
.vex-theme-red.light .mat-badge-accent,
.vex-theme-red .light .mat-badge-accent {
  --mat-badge-background-color: #f59e0b;
  --mat-badge-text-color: white;
}
.vex-theme-red .mat-badge-warn,
.vex-theme-red.light .mat-badge-warn,
.vex-theme-red .light .mat-badge-warn {
  --mat-badge-background-color: #ef4444;
  --mat-badge-text-color: white;
}
.vex-theme-red, .vex-theme-red.light, .vex-theme-red .light {
  --mat-bottom-sheet-container-text-color: #030712;
  --mat-bottom-sheet-container-background-color: white;
}

.vex-theme-red, .vex-theme-red.light, .vex-theme-red .light {
  --mat-legacy-button-toggle-text-color: #6b7280;
  --mat-legacy-button-toggle-state-layer-color: #e5e7eb;
  --mat-legacy-button-toggle-selected-state-text-color: #4b5563;
  --mat-legacy-button-toggle-selected-state-background-color: #d1d5db;
  --mat-legacy-button-toggle-disabled-state-text-color: #9ca3af;
  --mat-legacy-button-toggle-disabled-state-background-color: #e5e7eb;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #9ca3af;
  --mat-standard-button-toggle-text-color: #030712;
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: #e5e7eb;
  --mat-standard-button-toggle-selected-state-background-color: #d1d5db;
  --mat-standard-button-toggle-selected-state-text-color: #030712;
  --mat-standard-button-toggle-disabled-state-text-color: #9ca3af;
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: #030712;
  --mat-standard-button-toggle-disabled-selected-state-background-color: #9ca3af;
  --mat-standard-button-toggle-divider-color: #f0f0f0;
}

.vex-theme-red, .vex-theme-red.light, .vex-theme-red .light {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #dc2626;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(220, 38, 38, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(220, 38, 38, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(220, 38, 38, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #dc2626;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(220, 38, 38, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: #1f2937;
  --mat-datepicker-calendar-body-label-text-color: #4b5563;
  --mat-datepicker-calendar-period-button-icon-color: #1f2937;
  --mat-datepicker-calendar-navigation-button-icon-color: #1f2937;
  --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.06);
  --mat-datepicker-calendar-header-text-color: #4b5563;
  --mat-datepicker-calendar-date-today-outline-color: #6b7280;
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(107, 114, 128, 0.8);
  --mat-datepicker-calendar-date-text-color: #030712;
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: #6b7280;
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, 0.12);
  --mat-datepicker-range-input-separator-color: #030712;
  --mat-datepicker-range-input-disabled-state-separator-color: #6b7280;
  --mat-datepicker-range-input-disabled-state-text-color: #6b7280;
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: #030712;
}

.vex-theme-red .mat-datepicker-content.mat-accent,
.vex-theme-red.light .mat-datepicker-content.mat-accent,
.vex-theme-red .light .mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #f59e0b;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(245, 158, 11, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(245, 158, 11, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(245, 158, 11, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(245, 158, 11, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.vex-theme-red .mat-datepicker-content.mat-warn,
.vex-theme-red.light .mat-datepicker-content.mat-warn,
.vex-theme-red .light .mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #ef4444;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(239, 68, 68, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(239, 68, 68, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(239, 68, 68, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(239, 68, 68, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.vex-theme-red .mat-datepicker-toggle-active.mat-accent,
.vex-theme-red.light .mat-datepicker-toggle-active.mat-accent,
.vex-theme-red .light .mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #f59e0b;
}
.vex-theme-red .mat-datepicker-toggle-active.mat-warn,
.vex-theme-red.light .mat-datepicker-toggle-active.mat-warn,
.vex-theme-red .light .mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #ef4444;
}
.vex-theme-red, .vex-theme-red.light, .vex-theme-red .light {
  --mat-divider-color: rgba(0, 0, 0, 0.06);
}

.vex-theme-red, .vex-theme-red.light, .vex-theme-red .light {
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: #030712;
  --mat-expansion-actions-divider-color: rgba(0, 0, 0, 0.06);
  --mat-expansion-header-hover-state-layer-color: rgba(17, 24, 39, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(17, 24, 39, 0.04);
  --mat-expansion-header-disabled-state-text-color: #9ca3af;
  --mat-expansion-header-text-color: #030712;
  --mat-expansion-header-description-color: #4b5563;
  --mat-expansion-header-indicator-color: #4b5563;
}

.vex-theme-red, .vex-theme-red.light, .vex-theme-red .light {
  --mat-icon-color: inherit;
}

.vex-theme-red .mat-icon.mat-primary,
.vex-theme-red.light .mat-icon.mat-primary,
.vex-theme-red .light .mat-icon.mat-primary {
  --mat-icon-color: #dc2626;
}
.vex-theme-red .mat-icon.mat-accent,
.vex-theme-red.light .mat-icon.mat-accent,
.vex-theme-red .light .mat-icon.mat-accent {
  --mat-icon-color: #f59e0b;
}
.vex-theme-red .mat-icon.mat-warn,
.vex-theme-red.light .mat-icon.mat-warn,
.vex-theme-red .light .mat-icon.mat-warn {
  --mat-icon-color: #ef4444;
}
.vex-theme-red, .vex-theme-red.light, .vex-theme-red .light {
  --mat-sidenav-container-divider-color: rgba(0, 0, 0, 0.06);
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: #030712;
  --mat-sidenav-content-background-color: #f3f4f6;
  --mat-sidenav-content-text-color: #030712;
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
}

.vex-theme-red, .vex-theme-red.light, .vex-theme-red .light {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #dc2626;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #dc2626;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #dc2626;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: rgba(0, 0, 0, 0.06);
  --mat-stepper-header-hover-state-layer-color: rgba(17, 24, 39, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(17, 24, 39, 0.04);
  --mat-stepper-header-label-text-color: #4b5563;
  --mat-stepper-header-optional-label-text-color: #4b5563;
  --mat-stepper-header-selected-state-label-text-color: #030712;
  --mat-stepper-header-error-state-label-text-color: #ef4444;
  --mat-stepper-header-icon-background-color: #4b5563;
  --mat-stepper-header-error-state-icon-foreground-color: #ef4444;
  --mat-stepper-header-error-state-icon-background-color: transparent;
}
.vex-theme-red .mat-step-header.mat-accent, .vex-theme-red.light .mat-step-header.mat-accent, .vex-theme-red .light .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #f59e0b;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #f59e0b;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #f59e0b;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.vex-theme-red .mat-step-header.mat-warn, .vex-theme-red.light .mat-step-header.mat-warn, .vex-theme-red .light .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #ef4444;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #ef4444;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #ef4444;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}

.vex-theme-red .mat-sort-header-arrow,
.vex-theme-red.light .mat-sort-header-arrow,
.vex-theme-red .light .mat-sort-header-arrow {
  color: #4b5563;
}
.vex-theme-red, .vex-theme-red.light, .vex-theme-red .light {
  --mat-toolbar-container-background-color: #e5e7eb;
  --mat-toolbar-container-text-color: #030712;
}

.vex-theme-red .mat-toolbar.mat-primary,
.vex-theme-red.light .mat-toolbar.mat-primary,
.vex-theme-red .light .mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #dc2626;
  --mat-toolbar-container-text-color: white;
}
.vex-theme-red .mat-toolbar.mat-accent,
.vex-theme-red.light .mat-toolbar.mat-accent,
.vex-theme-red .light .mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #f59e0b;
  --mat-toolbar-container-text-color: white;
}
.vex-theme-red .mat-toolbar.mat-warn,
.vex-theme-red.light .mat-toolbar.mat-warn,
.vex-theme-red .light .mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #ef4444;
  --mat-toolbar-container-text-color: white;
}
.vex-theme-red .mat-tree,
.vex-theme-red.light .mat-tree,
.vex-theme-red .light .mat-tree {
  background: white;
}
.vex-theme-red .mat-tree-node,
.vex-theme-red .mat-nested-tree-node,
.vex-theme-red.light .mat-tree-node,
.vex-theme-red.light .mat-nested-tree-node,
.vex-theme-red .light .mat-tree-node,
.vex-theme-red .light .mat-nested-tree-node {
  color: #030712;
}

/**
 * Override the theme foreground/background colors with the Angular Material colors
 */
.vex-theme-red.dark,
.vex-theme-red .dark {
  --mat-table-background-color: #1f2937;
  --mat-table-header-headline-color: white;
  --mat-table-row-item-label-text-color: white;
  --mat-table-row-item-outline-color: rgba(255, 255, 255, 0.12);
}
.vex-theme-red.dark .mat-ripple-element,
.vex-theme-red .dark .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.vex-theme-red.dark, .vex-theme-red .dark {
  --mat-option-selected-state-label-text-color: #ef4444;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
}

.vex-theme-red.dark .mat-accent,
.vex-theme-red .dark .mat-accent {
  --mat-option-selected-state-label-text-color: #f59e0b;
}
.vex-theme-red.dark .mat-warn,
.vex-theme-red .dark .mat-warn {
  --mat-option-selected-state-label-text-color: #ef4444;
}
.vex-theme-red.dark, .vex-theme-red .dark {
  --mat-optgroup-label-text-color: white;
}

.vex-theme-red.dark .mat-pseudo-checkbox-full,
.vex-theme-red .dark .mat-pseudo-checkbox-full {
  color: #9ca3af;
}
.vex-theme-red.dark .mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled,
.vex-theme-red .dark .mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled {
  color: #686868;
}
.vex-theme-red.dark .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-red.dark .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-red .dark .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-red .dark .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #ef4444;
}
.vex-theme-red.dark .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-red.dark .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-red .dark .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-red .dark .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #ef4444;
}
.vex-theme-red.dark .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-red.dark .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after,
.vex-theme-red .dark .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-red .dark .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #111827;
}
.vex-theme-red.dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-red.dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-red .dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-red .dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #f59e0b;
}
.vex-theme-red.dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-red.dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-red .dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-red .dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #f59e0b;
}
.vex-theme-red.dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-red.dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after,
.vex-theme-red .dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-red .dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #111827;
}
.vex-theme-red.dark .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-red.dark .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-red .dark .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-red .dark .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #f59e0b;
}
.vex-theme-red.dark .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-red.dark .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-red .dark .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-red .dark .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #f59e0b;
}
.vex-theme-red.dark .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-red.dark .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after,
.vex-theme-red .dark .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-red .dark .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #111827;
}
.vex-theme-red.dark .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-red.dark .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-red .dark .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-red .dark .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #ef4444;
}
.vex-theme-red.dark .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-red.dark .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-red .dark .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-red .dark .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #ef4444;
}
.vex-theme-red.dark .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-red.dark .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after,
.vex-theme-red .dark .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-red .dark .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #111827;
}
.vex-theme-red.dark .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-red.dark .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-red .dark .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-red .dark .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #686868;
}
.vex-theme-red.dark .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-red.dark .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-red .dark .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-red .dark .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #686868;
}
.vex-theme-red.dark .mat-app-background, .vex-theme-red.dark.mat-app-background,
.vex-theme-red .dark .mat-app-background,
.vex-theme-red .dark.mat-app-background {
  background-color: #111827;
  color: white;
}
.vex-theme-red.dark .mat-elevation-z0, .vex-theme-red.dark .mat-mdc-elevation-specific.mat-elevation-z0,
.vex-theme-red .dark .mat-elevation-z0,
.vex-theme-red .dark .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-red.dark .mat-elevation-z1, .vex-theme-red.dark .mat-mdc-elevation-specific.mat-elevation-z1,
.vex-theme-red .dark .mat-elevation-z1,
.vex-theme-red .dark .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-red.dark .mat-elevation-z2, .vex-theme-red.dark .mat-mdc-elevation-specific.mat-elevation-z2,
.vex-theme-red .dark .mat-elevation-z2,
.vex-theme-red .dark .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-red.dark .mat-elevation-z3, .vex-theme-red.dark .mat-mdc-elevation-specific.mat-elevation-z3,
.vex-theme-red .dark .mat-elevation-z3,
.vex-theme-red .dark .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-red.dark .mat-elevation-z4, .vex-theme-red.dark .mat-mdc-elevation-specific.mat-elevation-z4,
.vex-theme-red .dark .mat-elevation-z4,
.vex-theme-red .dark .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-red.dark .mat-elevation-z5, .vex-theme-red.dark .mat-mdc-elevation-specific.mat-elevation-z5,
.vex-theme-red .dark .mat-elevation-z5,
.vex-theme-red .dark .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-red.dark .mat-elevation-z6, .vex-theme-red.dark .mat-mdc-elevation-specific.mat-elevation-z6,
.vex-theme-red .dark .mat-elevation-z6,
.vex-theme-red .dark .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-red.dark .mat-elevation-z7, .vex-theme-red.dark .mat-mdc-elevation-specific.mat-elevation-z7,
.vex-theme-red .dark .mat-elevation-z7,
.vex-theme-red .dark .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.vex-theme-red.dark .mat-elevation-z8, .vex-theme-red.dark .mat-mdc-elevation-specific.mat-elevation-z8,
.vex-theme-red .dark .mat-elevation-z8,
.vex-theme-red .dark .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.vex-theme-red.dark .mat-elevation-z9, .vex-theme-red.dark .mat-mdc-elevation-specific.mat-elevation-z9,
.vex-theme-red .dark .mat-elevation-z9,
.vex-theme-red .dark .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.vex-theme-red.dark .mat-elevation-z10, .vex-theme-red.dark .mat-mdc-elevation-specific.mat-elevation-z10,
.vex-theme-red .dark .mat-elevation-z10,
.vex-theme-red .dark .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.vex-theme-red.dark .mat-elevation-z11, .vex-theme-red.dark .mat-mdc-elevation-specific.mat-elevation-z11,
.vex-theme-red .dark .mat-elevation-z11,
.vex-theme-red .dark .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.vex-theme-red.dark .mat-elevation-z12, .vex-theme-red.dark .mat-mdc-elevation-specific.mat-elevation-z12,
.vex-theme-red .dark .mat-elevation-z12,
.vex-theme-red .dark .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.vex-theme-red.dark .mat-elevation-z13, .vex-theme-red.dark .mat-mdc-elevation-specific.mat-elevation-z13,
.vex-theme-red .dark .mat-elevation-z13,
.vex-theme-red .dark .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.vex-theme-red.dark .mat-elevation-z14, .vex-theme-red.dark .mat-mdc-elevation-specific.mat-elevation-z14,
.vex-theme-red .dark .mat-elevation-z14,
.vex-theme-red .dark .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.vex-theme-red.dark .mat-elevation-z15, .vex-theme-red.dark .mat-mdc-elevation-specific.mat-elevation-z15,
.vex-theme-red .dark .mat-elevation-z15,
.vex-theme-red .dark .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.vex-theme-red.dark .mat-elevation-z16, .vex-theme-red.dark .mat-mdc-elevation-specific.mat-elevation-z16,
.vex-theme-red .dark .mat-elevation-z16,
.vex-theme-red .dark .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.vex-theme-red.dark .mat-elevation-z17, .vex-theme-red.dark .mat-mdc-elevation-specific.mat-elevation-z17,
.vex-theme-red .dark .mat-elevation-z17,
.vex-theme-red .dark .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.vex-theme-red.dark .mat-elevation-z18, .vex-theme-red.dark .mat-mdc-elevation-specific.mat-elevation-z18,
.vex-theme-red .dark .mat-elevation-z18,
.vex-theme-red .dark .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.vex-theme-red.dark .mat-elevation-z19, .vex-theme-red.dark .mat-mdc-elevation-specific.mat-elevation-z19,
.vex-theme-red .dark .mat-elevation-z19,
.vex-theme-red .dark .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.vex-theme-red.dark .mat-elevation-z20, .vex-theme-red.dark .mat-mdc-elevation-specific.mat-elevation-z20,
.vex-theme-red .dark .mat-elevation-z20,
.vex-theme-red .dark .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.vex-theme-red.dark .mat-elevation-z21, .vex-theme-red.dark .mat-mdc-elevation-specific.mat-elevation-z21,
.vex-theme-red .dark .mat-elevation-z21,
.vex-theme-red .dark .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.vex-theme-red.dark .mat-elevation-z22, .vex-theme-red.dark .mat-mdc-elevation-specific.mat-elevation-z22,
.vex-theme-red .dark .mat-elevation-z22,
.vex-theme-red .dark .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.vex-theme-red.dark .mat-elevation-z23, .vex-theme-red.dark .mat-mdc-elevation-specific.mat-elevation-z23,
.vex-theme-red .dark .mat-elevation-z23,
.vex-theme-red .dark .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.vex-theme-red.dark .mat-elevation-z24, .vex-theme-red.dark .mat-mdc-elevation-specific.mat-elevation-z24,
.vex-theme-red .dark .mat-elevation-z24,
.vex-theme-red .dark .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.vex-theme-red.dark .mat-mdc-card,
.vex-theme-red .dark .mat-mdc-card {
  --mdc-elevated-card-container-color: #1f2937;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: #1f2937;
  --mdc-outlined-card-outline-color: rgba(255, 255, 255, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: #9ca3af;
}
.vex-theme-red.dark .mat-mdc-progress-bar,
.vex-theme-red .dark .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #ef4444;
  --mdc-linear-progress-track-color: rgba(239, 68, 68, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.vex-theme-red.dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots,
.vex-theme-red .dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-color: rgba(239, 68, 68, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(239, 68, 68, 0.25));
}
@media (forced-colors: active) {
  .vex-theme-red.dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots,
  .vex-theme-red .dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .vex-theme-red.dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots,
  .vex-theme-red .dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(239, 68, 68, 0.25)'/%3E%3C/svg%3E");
  }
}
.vex-theme-red.dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar,
.vex-theme-red .dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgba(239, 68, 68, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(239, 68, 68, 0.25));
}
.vex-theme-red.dark .mat-mdc-progress-bar.mat-accent,
.vex-theme-red .dark .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #f59e0b;
  --mdc-linear-progress-track-color: rgba(245, 158, 11, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.vex-theme-red.dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots,
.vex-theme-red .dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-color: rgba(245, 158, 11, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(245, 158, 11, 0.25));
}
@media (forced-colors: active) {
  .vex-theme-red.dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots,
  .vex-theme-red .dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .vex-theme-red.dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots,
  .vex-theme-red .dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(245, 158, 11, 0.25)'/%3E%3C/svg%3E");
  }
}
.vex-theme-red.dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar,
.vex-theme-red .dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: rgba(245, 158, 11, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(245, 158, 11, 0.25));
}
.vex-theme-red.dark .mat-mdc-progress-bar.mat-warn,
.vex-theme-red .dark .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #ef4444;
  --mdc-linear-progress-track-color: rgba(239, 68, 68, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.vex-theme-red.dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots,
.vex-theme-red .dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-color: rgba(239, 68, 68, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(239, 68, 68, 0.25));
}
@media (forced-colors: active) {
  .vex-theme-red.dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots,
  .vex-theme-red .dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .vex-theme-red.dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots,
  .vex-theme-red .dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(239, 68, 68, 0.25)'/%3E%3C/svg%3E");
  }
}
.vex-theme-red.dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar,
.vex-theme-red .dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: rgba(239, 68, 68, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(239, 68, 68, 0.25));
}
.vex-theme-red.dark .mat-mdc-tooltip,
.vex-theme-red .dark .mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #374151;
  --mdc-plain-tooltip-supporting-text-color: #fff;
}
.vex-theme-red.dark, .vex-theme-red .dark {
  --mdc-filled-text-field-caret-color: #ef4444;
  --mdc-filled-text-field-focus-active-indicator-color: #ef4444;
  --mdc-filled-text-field-focus-label-text-color: rgba(239, 68, 68, 0.87);
  --mdc-filled-text-field-container-color: #28323f;
  --mdc-filled-text-field-disabled-container-color: #232d3b;
  --mdc-filled-text-field-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(255, 255, 255, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(255, 255, 255, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(255, 255, 255, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-error-focus-label-text-color: #ef4444;
  --mdc-filled-text-field-error-label-text-color: #ef4444;
  --mdc-filled-text-field-error-caret-color: #ef4444;
  --mdc-filled-text-field-active-indicator-color: rgba(255, 255, 255, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(255, 255, 255, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(255, 255, 255, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #ef4444;
  --mdc-filled-text-field-error-focus-active-indicator-color: #ef4444;
  --mdc-filled-text-field-error-hover-active-indicator-color: #ef4444;
  --mdc-outlined-text-field-caret-color: #ef4444;
  --mdc-outlined-text-field-focus-outline-color: #ef4444;
  --mdc-outlined-text-field-focus-label-text-color: rgba(239, 68, 68, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(255, 255, 255, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(255, 255, 255, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(255, 255, 255, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-error-caret-color: #ef4444;
  --mdc-outlined-text-field-error-focus-label-text-color: #ef4444;
  --mdc-outlined-text-field-error-label-text-color: #ef4444;
  --mdc-outlined-text-field-outline-color: rgba(255, 255, 255, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(255, 255, 255, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(255, 255, 255, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #ef4444;
  --mdc-outlined-text-field-error-hover-outline-color: #ef4444;
  --mdc-outlined-text-field-error-outline-color: #ef4444;
  --mat-form-field-disabled-input-text-placeholder-color: rgba(255, 255, 255, 0.38);
}

.vex-theme-red.dark .mat-mdc-form-field-error,
.vex-theme-red .dark .mat-mdc-form-field-error {
  color: var(--mdc-theme-error, #ef4444);
}
.vex-theme-red.dark .mat-mdc-form-field-subscript-wrapper,
.vex-theme-red.dark .mat-mdc-form-field-bottom-align::before,
.vex-theme-red .dark .mat-mdc-form-field-subscript-wrapper,
.vex-theme-red .dark .mat-mdc-form-field-bottom-align::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mat-form-field-subscript-text-font);
  line-height: var(--mat-form-field-subscript-text-line-height);
  font-size: var(--mat-form-field-subscript-text-size);
  letter-spacing: var(--mat-form-field-subscript-text-tracking);
  font-weight: var(--mat-form-field-subscript-text-weight);
}
.vex-theme-red.dark .mat-mdc-form-field-focus-overlay,
.vex-theme-red .dark .mat-mdc-form-field-focus-overlay {
  background-color: rgba(255, 255, 255, 0.87);
}
.vex-theme-red.dark .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay,
.vex-theme-red .dark .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0.08;
}
.vex-theme-red.dark .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay,
.vex-theme-red .dark .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.24;
}
.vex-theme-red.dark select.mat-mdc-form-field-input-control:not(.mat-mdc-native-select-inline) option,
.vex-theme-red .dark select.mat-mdc-form-field-input-control:not(.mat-mdc-native-select-inline) option {
  color: rgba(0, 0, 0, 0.87);
}
.vex-theme-red.dark select.mat-mdc-form-field-input-control:not(.mat-mdc-native-select-inline) option:disabled,
.vex-theme-red .dark select.mat-mdc-form-field-input-control:not(.mat-mdc-native-select-inline) option:disabled {
  color: rgba(0, 0, 0, 0.38);
}
.vex-theme-red.dark .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after,
.vex-theme-red .dark .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
  color: rgba(255, 255, 255, 0.54);
}
.vex-theme-red.dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after,
.vex-theme-red .dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after {
  color: rgba(239, 68, 68, 0.87);
}
.vex-theme-red.dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after,
.vex-theme-red .dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after {
  color: rgba(245, 158, 11, 0.87);
}
.vex-theme-red.dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after,
.vex-theme-red .dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after {
  color: rgba(239, 68, 68, 0.87);
}
.vex-theme-red.dark .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after,
.vex-theme-red .dark .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  color: rgba(255, 255, 255, 0.38);
}
.vex-theme-red.dark .mat-mdc-form-field.mat-accent,
.vex-theme-red .dark .mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #f59e0b;
  --mdc-filled-text-field-focus-active-indicator-color: #f59e0b;
  --mdc-filled-text-field-focus-label-text-color: rgba(245, 158, 11, 0.87);
  --mdc-outlined-text-field-caret-color: #f59e0b;
  --mdc-outlined-text-field-focus-outline-color: #f59e0b;
  --mdc-outlined-text-field-focus-label-text-color: rgba(245, 158, 11, 0.87);
}
.vex-theme-red.dark .mat-mdc-form-field.mat-warn,
.vex-theme-red .dark .mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #ef4444;
  --mdc-filled-text-field-focus-active-indicator-color: #ef4444;
  --mdc-filled-text-field-focus-label-text-color: rgba(239, 68, 68, 0.87);
  --mdc-outlined-text-field-caret-color: #ef4444;
  --mdc-outlined-text-field-focus-outline-color: #ef4444;
  --mdc-outlined-text-field-focus-label-text-color: rgba(239, 68, 68, 0.87);
}
.vex-theme-red.dark .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch,
.vex-theme-red .dark .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: 1px solid transparent;
}
.vex-theme-red.dark [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch,
.vex-theme-red .dark [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none;
  border-right: 1px solid transparent;
}
.vex-theme-red.dark, .vex-theme-red .dark {
  --mat-select-panel-background-color: #1f2937;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(255, 255, 255, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(255, 255, 255, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(239, 68, 68, 0.87);
  --mat-select-invalid-arrow-color: rgba(239, 68, 68, 0.87);
}
.vex-theme-red.dark .mat-mdc-form-field.mat-accent, .vex-theme-red .dark .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: #1f2937;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(255, 255, 255, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(255, 255, 255, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(245, 158, 11, 0.87);
  --mat-select-invalid-arrow-color: rgba(239, 68, 68, 0.87);
}
.vex-theme-red.dark .mat-mdc-form-field.mat-warn, .vex-theme-red .dark .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: #1f2937;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(255, 255, 255, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(255, 255, 255, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(239, 68, 68, 0.87);
  --mat-select-invalid-arrow-color: rgba(239, 68, 68, 0.87);
}

.vex-theme-red.dark, .vex-theme-red .dark {
  --mat-autocomplete-background-color: #1f2937;
}

.vex-theme-red.dark .mat-mdc-dialog-container,
.vex-theme-red .dark .mat-mdc-dialog-container {
  --mdc-dialog-container-color: #1f2937;
  --mdc-dialog-subhead-color: rgba(255, 255, 255, 0.87);
  --mdc-dialog-supporting-text-color: rgba(255, 255, 255, 0.6);
}
.vex-theme-red.dark .mat-mdc-standard-chip,
.vex-theme-red .dark .mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #fafafa;
  --mdc-chip-elevated-container-color: #3a434f;
  --mdc-chip-elevated-disabled-container-color: #3a434f;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #fafafa;
  --mdc-chip-with-icon-icon-color: #fafafa;
  --mdc-chip-with-icon-disabled-icon-color: #fafafa;
  --mdc-chip-with-icon-selected-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #fafafa;
}
.vex-theme-red.dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .vex-theme-red.dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary,
.vex-theme-red .dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary,
.vex-theme-red .dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #ef4444;
  --mdc-chip-elevated-disabled-container-color: #ef4444;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.vex-theme-red.dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .vex-theme-red.dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent,
.vex-theme-red .dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent,
.vex-theme-red .dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #f59e0b;
  --mdc-chip-elevated-disabled-container-color: #f59e0b;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.vex-theme-red.dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .vex-theme-red.dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn,
.vex-theme-red .dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn,
.vex-theme-red .dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #ef4444;
  --mdc-chip-elevated-disabled-container-color: #ef4444;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.vex-theme-red.dark .mat-mdc-slide-toggle,
.vex-theme-red .dark .mat-mdc-slide-toggle {
  --mdc-switch-selected-focus-state-layer-color: #fca5a5;
  --mdc-switch-selected-handle-color: #fca5a5;
  --mdc-switch-selected-hover-state-layer-color: #fca5a5;
  --mdc-switch-selected-pressed-state-layer-color: #fca5a5;
  --mdc-switch-selected-focus-handle-color: #fecaca;
  --mdc-switch-selected-hover-handle-color: #fecaca;
  --mdc-switch-selected-pressed-handle-color: #fecaca;
  --mdc-switch-selected-focus-track-color: #dc2626;
  --mdc-switch-selected-hover-track-color: #dc2626;
  --mdc-switch-selected-pressed-track-color: #dc2626;
  --mdc-switch-selected-track-color: #dc2626;
  --mdc-switch-disabled-selected-handle-color: #000;
  --mdc-switch-disabled-selected-icon-color: #212121;
  --mdc-switch-disabled-selected-track-color: #f5f5f5;
  --mdc-switch-disabled-unselected-handle-color: #000;
  --mdc-switch-disabled-unselected-icon-color: #212121;
  --mdc-switch-disabled-unselected-track-color: #f5f5f5;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #212121;
  --mdc-switch-unselected-focus-handle-color: #fafafa;
  --mdc-switch-unselected-focus-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-focus-track-color: #616161;
  --mdc-switch-unselected-handle-color: #9e9e9e;
  --mdc-switch-unselected-hover-handle-color: #fafafa;
  --mdc-switch-unselected-hover-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-hover-track-color: #616161;
  --mdc-switch-unselected-icon-color: #212121;
  --mdc-switch-unselected-pressed-handle-color: #fafafa;
  --mdc-switch-unselected-pressed-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-pressed-track-color: #616161;
  --mdc-switch-unselected-track-color: #616161;
}
.vex-theme-red.dark .mat-mdc-slide-toggle .mdc-form-field,
.vex-theme-red .dark .mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.vex-theme-red.dark .mat-mdc-slide-toggle .mdc-switch--disabled + label,
.vex-theme-red .dark .mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: #6b7280;
}
.vex-theme-red.dark .mat-mdc-slide-toggle.mat-accent,
.vex-theme-red .dark .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #fcd34d;
  --mdc-switch-selected-handle-color: #fcd34d;
  --mdc-switch-selected-hover-state-layer-color: #fcd34d;
  --mdc-switch-selected-pressed-state-layer-color: #fcd34d;
  --mdc-switch-selected-focus-handle-color: #fde68a;
  --mdc-switch-selected-hover-handle-color: #fde68a;
  --mdc-switch-selected-pressed-handle-color: #fde68a;
  --mdc-switch-selected-focus-track-color: #d97706;
  --mdc-switch-selected-hover-track-color: #d97706;
  --mdc-switch-selected-pressed-track-color: #d97706;
  --mdc-switch-selected-track-color: #d97706;
}
.vex-theme-red.dark .mat-mdc-slide-toggle.mat-warn,
.vex-theme-red .dark .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #fca5a5;
  --mdc-switch-selected-handle-color: #fca5a5;
  --mdc-switch-selected-hover-state-layer-color: #fca5a5;
  --mdc-switch-selected-pressed-state-layer-color: #fca5a5;
  --mdc-switch-selected-focus-handle-color: #fecaca;
  --mdc-switch-selected-hover-handle-color: #fecaca;
  --mdc-switch-selected-pressed-handle-color: #fecaca;
  --mdc-switch-selected-focus-track-color: #dc2626;
  --mdc-switch-selected-hover-track-color: #dc2626;
  --mdc-switch-selected-pressed-track-color: #dc2626;
  --mdc-switch-selected-track-color: #dc2626;
}
.vex-theme-red.dark .mat-mdc-radio-button .mdc-form-field,
.vex-theme-red .dark .mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.vex-theme-red.dark .mat-mdc-radio-button.mat-primary,
.vex-theme-red .dark .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #ef4444;
  --mdc-radio-selected-hover-icon-color: #ef4444;
  --mdc-radio-selected-icon-color: #ef4444;
  --mdc-radio-selected-pressed-icon-color: #ef4444;
  --mat-radio-ripple-color: #fff;
  --mat-radio-checked-ripple-color: #ef4444;
  --mat-radio-disabled-label-color: #6b7280;
}
.vex-theme-red.dark .mat-mdc-radio-button.mat-accent,
.vex-theme-red .dark .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #f59e0b;
  --mdc-radio-selected-hover-icon-color: #f59e0b;
  --mdc-radio-selected-icon-color: #f59e0b;
  --mdc-radio-selected-pressed-icon-color: #f59e0b;
  --mat-radio-ripple-color: #fff;
  --mat-radio-checked-ripple-color: #f59e0b;
  --mat-radio-disabled-label-color: #6b7280;
}
.vex-theme-red.dark .mat-mdc-radio-button.mat-warn,
.vex-theme-red .dark .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #ef4444;
  --mdc-radio-selected-hover-icon-color: #ef4444;
  --mdc-radio-selected-icon-color: #ef4444;
  --mdc-radio-selected-pressed-icon-color: #ef4444;
  --mat-radio-ripple-color: #fff;
  --mat-radio-checked-ripple-color: #ef4444;
  --mat-radio-disabled-label-color: #6b7280;
}
.vex-theme-red.dark .mat-mdc-slider,
.vex-theme-red .dark .mat-mdc-slider {
  --mdc-slider-label-container-color: white;
  --mdc-slider-label-label-text-color: black;
  --mdc-slider-disabled-handle-color: #fff;
  --mdc-slider-disabled-active-track-color: #fff;
  --mdc-slider-disabled-inactive-track-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #fff;
  --mat-mdc-slider-value-indicator-opacity: 0.9;
}
.vex-theme-red.dark .mat-mdc-slider.mat-primary,
.vex-theme-red .dark .mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #ef4444;
  --mdc-slider-focus-handle-color: #ef4444;
  --mdc-slider-hover-handle-color: #ef4444;
  --mdc-slider-active-track-color: #ef4444;
  --mdc-slider-inactive-track-color: #ef4444;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #ef4444;
  --mat-mdc-slider-ripple-color: #ef4444;
  --mat-mdc-slider-hover-ripple-color: rgba(239, 68, 68, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(239, 68, 68, 0.2);
}
.vex-theme-red.dark .mat-mdc-slider.mat-accent,
.vex-theme-red .dark .mat-mdc-slider.mat-accent {
  --mdc-slider-handle-color: #f59e0b;
  --mdc-slider-focus-handle-color: #f59e0b;
  --mdc-slider-hover-handle-color: #f59e0b;
  --mdc-slider-active-track-color: #f59e0b;
  --mdc-slider-inactive-track-color: #f59e0b;
  --mdc-slider-with-tick-marks-active-container-color: #000;
  --mdc-slider-with-tick-marks-inactive-container-color: #f59e0b;
  --mat-mdc-slider-ripple-color: #f59e0b;
  --mat-mdc-slider-hover-ripple-color: rgba(245, 158, 11, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(245, 158, 11, 0.2);
}
.vex-theme-red.dark .mat-mdc-slider.mat-warn,
.vex-theme-red .dark .mat-mdc-slider.mat-warn {
  --mdc-slider-handle-color: #ef4444;
  --mdc-slider-focus-handle-color: #ef4444;
  --mdc-slider-hover-handle-color: #ef4444;
  --mdc-slider-active-track-color: #ef4444;
  --mdc-slider-inactive-track-color: #ef4444;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #ef4444;
  --mat-mdc-slider-ripple-color: #ef4444;
  --mat-mdc-slider-hover-ripple-color: rgba(239, 68, 68, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(239, 68, 68, 0.2);
}
.vex-theme-red.dark, .vex-theme-red .dark {
  --mat-menu-item-label-text-color: white;
  --mat-menu-item-icon-color: white;
  --mat-menu-item-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-menu-item-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-menu-container-color: #1f2937;
}

.vex-theme-red.dark .mat-mdc-list-base,
.vex-theme-red .dark .mat-mdc-list-base {
  --mdc-list-list-item-label-text-color: white;
  --mdc-list-list-item-supporting-text-color: #9ca3af;
  --mdc-list-list-item-leading-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-trailing-supporting-text-color: #6b7280;
  --mdc-list-list-item-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-disabled-label-text-color: white;
  --mdc-list-list-item-disabled-leading-icon-color: white;
  --mdc-list-list-item-disabled-trailing-icon-color: white;
  --mdc-list-list-item-hover-label-text-color: white;
  --mdc-list-list-item-hover-leading-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-focus-label-text-color: white;
  --mdc-list-list-item-hover-state-layer-color: white;
  --mdc-list-list-item-hover-state-layer-opacity: 0.08;
  --mdc-list-list-item-focus-state-layer-color: white;
  --mdc-list-list-item-focus-state-layer-opacity: 0.24;
}
.vex-theme-red.dark .mdc-list-item__start,
.vex-theme-red.dark .mdc-list-item__end,
.vex-theme-red .dark .mdc-list-item__start,
.vex-theme-red .dark .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #ef4444;
  --mdc-radio-selected-hover-icon-color: #ef4444;
  --mdc-radio-selected-icon-color: #ef4444;
  --mdc-radio-selected-pressed-icon-color: #ef4444;
}
.vex-theme-red.dark .mat-accent .mdc-list-item__start,
.vex-theme-red.dark .mat-accent .mdc-list-item__end,
.vex-theme-red .dark .mat-accent .mdc-list-item__start,
.vex-theme-red .dark .mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #f59e0b;
  --mdc-radio-selected-hover-icon-color: #f59e0b;
  --mdc-radio-selected-icon-color: #f59e0b;
  --mdc-radio-selected-pressed-icon-color: #f59e0b;
}
.vex-theme-red.dark .mat-warn .mdc-list-item__start,
.vex-theme-red.dark .mat-warn .mdc-list-item__end,
.vex-theme-red .dark .mat-warn .mdc-list-item__start,
.vex-theme-red .dark .mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #ef4444;
  --mdc-radio-selected-hover-icon-color: #ef4444;
  --mdc-radio-selected-icon-color: #ef4444;
  --mdc-radio-selected-pressed-icon-color: #ef4444;
}
.vex-theme-red.dark .mat-mdc-list-option,
.vex-theme-red .dark .mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #ef4444;
  --mdc-checkbox-selected-hover-icon-color: #ef4444;
  --mdc-checkbox-selected-icon-color: #ef4444;
  --mdc-checkbox-selected-pressed-icon-color: #ef4444;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #ef4444;
  --mdc-checkbox-selected-hover-state-layer-color: #ef4444;
  --mdc-checkbox-selected-pressed-state-layer-color: #ef4444;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.vex-theme-red.dark .mat-mdc-list-option.mat-accent,
.vex-theme-red .dark .mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #f59e0b;
  --mdc-checkbox-selected-hover-icon-color: #f59e0b;
  --mdc-checkbox-selected-icon-color: #f59e0b;
  --mdc-checkbox-selected-pressed-icon-color: #f59e0b;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f59e0b;
  --mdc-checkbox-selected-hover-state-layer-color: #f59e0b;
  --mdc-checkbox-selected-pressed-state-layer-color: #f59e0b;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.vex-theme-red.dark .mat-mdc-list-option.mat-warn,
.vex-theme-red .dark .mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #ef4444;
  --mdc-checkbox-selected-hover-icon-color: #ef4444;
  --mdc-checkbox-selected-icon-color: #ef4444;
  --mdc-checkbox-selected-pressed-icon-color: #ef4444;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #ef4444;
  --mdc-checkbox-selected-hover-state-layer-color: #ef4444;
  --mdc-checkbox-selected-pressed-state-layer-color: #ef4444;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.vex-theme-red.dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.vex-theme-red.dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,
.vex-theme-red .dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.vex-theme-red .dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #ef4444;
}
.vex-theme-red.dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.vex-theme-red.dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.vex-theme-red .dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.vex-theme-red .dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #ef4444;
}
.vex-theme-red.dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.vex-theme-red.dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.vex-theme-red.dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end,
.vex-theme-red .dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.vex-theme-red .dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.vex-theme-red .dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.vex-theme-red.dark, .vex-theme-red .dark {
  --mat-paginator-container-text-color: rgba(255, 255, 255, 0.87);
  --mat-paginator-container-background-color: #1f2937;
  --mat-paginator-enabled-icon-color: rgba(255, 255, 255, 0.54);
  --mat-paginator-disabled-icon-color: rgba(255, 255, 255, 0.12);
}

.vex-theme-red.dark .mat-mdc-tab-group, .vex-theme-red.dark .mat-mdc-tab-nav-bar,
.vex-theme-red .dark .mat-mdc-tab-group,
.vex-theme-red .dark .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #ef4444;
  --mat-tab-header-disabled-ripple-color: #6b7280;
  --mat-tab-header-pagination-icon-color: #fff;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #ef4444;
  --mat-tab-header-active-ripple-color: #ef4444;
  --mat-tab-header-inactive-ripple-color: #ef4444;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #ef4444;
  --mat-tab-header-active-hover-label-text-color: #ef4444;
  --mat-tab-header-active-focus-indicator-color: #ef4444;
  --mat-tab-header-active-hover-indicator-color: #ef4444;
}
.vex-theme-red.dark .mat-mdc-tab-group.mat-accent, .vex-theme-red.dark .mat-mdc-tab-nav-bar.mat-accent,
.vex-theme-red .dark .mat-mdc-tab-group.mat-accent,
.vex-theme-red .dark .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #f59e0b;
  --mat-tab-header-disabled-ripple-color: #6b7280;
  --mat-tab-header-pagination-icon-color: #fff;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #f59e0b;
  --mat-tab-header-active-ripple-color: #f59e0b;
  --mat-tab-header-inactive-ripple-color: #f59e0b;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #f59e0b;
  --mat-tab-header-active-hover-label-text-color: #f59e0b;
  --mat-tab-header-active-focus-indicator-color: #f59e0b;
  --mat-tab-header-active-hover-indicator-color: #f59e0b;
}
.vex-theme-red.dark .mat-mdc-tab-group.mat-warn, .vex-theme-red.dark .mat-mdc-tab-nav-bar.mat-warn,
.vex-theme-red .dark .mat-mdc-tab-group.mat-warn,
.vex-theme-red .dark .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #ef4444;
  --mat-tab-header-disabled-ripple-color: #6b7280;
  --mat-tab-header-pagination-icon-color: #fff;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #ef4444;
  --mat-tab-header-active-ripple-color: #ef4444;
  --mat-tab-header-inactive-ripple-color: #ef4444;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #ef4444;
  --mat-tab-header-active-hover-label-text-color: #ef4444;
  --mat-tab-header-active-focus-indicator-color: #ef4444;
  --mat-tab-header-active-hover-indicator-color: #ef4444;
}
.vex-theme-red.dark .mat-mdc-tab-group.mat-background-primary, .vex-theme-red.dark .mat-mdc-tab-nav-bar.mat-background-primary,
.vex-theme-red .dark .mat-mdc-tab-group.mat-background-primary,
.vex-theme-red .dark .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #ef4444;
  --mat-tab-header-with-background-foreground-color: white;
}
.vex-theme-red.dark .mat-mdc-tab-group.mat-background-accent, .vex-theme-red.dark .mat-mdc-tab-nav-bar.mat-background-accent,
.vex-theme-red .dark .mat-mdc-tab-group.mat-background-accent,
.vex-theme-red .dark .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #f59e0b;
  --mat-tab-header-with-background-foreground-color: white;
}
.vex-theme-red.dark .mat-mdc-tab-group.mat-background-warn, .vex-theme-red.dark .mat-mdc-tab-nav-bar.mat-background-warn,
.vex-theme-red .dark .mat-mdc-tab-group.mat-background-warn,
.vex-theme-red .dark .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #ef4444;
  --mat-tab-header-with-background-foreground-color: white;
}
.vex-theme-red.dark, .vex-theme-red .dark {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #f59e0b;
  --mdc-checkbox-selected-hover-icon-color: #f59e0b;
  --mdc-checkbox-selected-icon-color: #f59e0b;
  --mdc-checkbox-selected-pressed-icon-color: #f59e0b;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f59e0b;
  --mdc-checkbox-selected-hover-state-layer-color: #f59e0b;
  --mdc-checkbox-selected-pressed-state-layer-color: #f59e0b;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}

.vex-theme-red.dark .mat-mdc-checkbox.mat-primary,
.vex-theme-red .dark .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #ef4444;
  --mdc-checkbox-selected-hover-icon-color: #ef4444;
  --mdc-checkbox-selected-icon-color: #ef4444;
  --mdc-checkbox-selected-pressed-icon-color: #ef4444;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #ef4444;
  --mdc-checkbox-selected-hover-state-layer-color: #ef4444;
  --mdc-checkbox-selected-pressed-state-layer-color: #ef4444;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.vex-theme-red.dark .mat-mdc-checkbox.mat-warn,
.vex-theme-red .dark .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #ef4444;
  --mdc-checkbox-selected-hover-icon-color: #ef4444;
  --mdc-checkbox-selected-icon-color: #ef4444;
  --mdc-checkbox-selected-pressed-icon-color: #ef4444;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #ef4444;
  --mdc-checkbox-selected-hover-state-layer-color: #ef4444;
  --mdc-checkbox-selected-pressed-state-layer-color: #ef4444;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.vex-theme-red.dark .mat-mdc-checkbox .mdc-form-field,
.vex-theme-red .dark .mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.vex-theme-red.dark .mat-mdc-checkbox.mat-mdc-checkbox-disabled label,
.vex-theme-red .dark .mat-mdc-checkbox.mat-mdc-checkbox-disabled label {
  color: #6b7280;
}
.vex-theme-red.dark .mat-mdc-button.mat-unthemed,
.vex-theme-red .dark .mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #fff;
}
.vex-theme-red.dark .mat-mdc-button.mat-primary,
.vex-theme-red .dark .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #ef4444;
}
.vex-theme-red.dark .mat-mdc-button.mat-accent,
.vex-theme-red .dark .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #f59e0b;
}
.vex-theme-red.dark .mat-mdc-button.mat-warn,
.vex-theme-red .dark .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #ef4444;
}
.vex-theme-red.dark .mat-mdc-button[disabled][disabled],
.vex-theme-red .dark .mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-text-button-label-text-color: rgba(255, 255, 255, 0.5);
}
.vex-theme-red.dark .mat-mdc-unelevated-button.mat-unthemed,
.vex-theme-red .dark .mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #1f2937;
  --mdc-filled-button-label-text-color: #fff;
}
.vex-theme-red.dark .mat-mdc-unelevated-button.mat-primary,
.vex-theme-red .dark .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #ef4444;
  --mdc-filled-button-label-text-color: #fff;
}
.vex-theme-red.dark .mat-mdc-unelevated-button.mat-accent,
.vex-theme-red .dark .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #f59e0b;
  --mdc-filled-button-label-text-color: #000;
}
.vex-theme-red.dark .mat-mdc-unelevated-button.mat-warn,
.vex-theme-red .dark .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #ef4444;
  --mdc-filled-button-label-text-color: #fff;
}
.vex-theme-red.dark .mat-mdc-unelevated-button[disabled][disabled],
.vex-theme-red .dark .mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(255, 255, 255, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-filled-button-container-color: rgba(255, 255, 255, 0.12);
  --mdc-filled-button-label-text-color: rgba(255, 255, 255, 0.5);
}
.vex-theme-red.dark .mat-mdc-raised-button.mat-unthemed,
.vex-theme-red .dark .mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #1f2937;
  --mdc-protected-button-label-text-color: #fff;
}
.vex-theme-red.dark .mat-mdc-raised-button.mat-primary,
.vex-theme-red .dark .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #ef4444;
  --mdc-protected-button-label-text-color: #fff;
}
.vex-theme-red.dark .mat-mdc-raised-button.mat-accent,
.vex-theme-red .dark .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #f59e0b;
  --mdc-protected-button-label-text-color: #000;
}
.vex-theme-red.dark .mat-mdc-raised-button.mat-warn,
.vex-theme-red .dark .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #ef4444;
  --mdc-protected-button-label-text-color: #fff;
}
.vex-theme-red.dark .mat-mdc-raised-button[disabled][disabled],
.vex-theme-red .dark .mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(255, 255, 255, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-protected-button-container-color: rgba(255, 255, 255, 0.12);
  --mdc-protected-button-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-protected-button-container-elevation: 0;
}
.vex-theme-red.dark .mat-mdc-outlined-button,
.vex-theme-red .dark .mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
}
.vex-theme-red.dark .mat-mdc-outlined-button.mat-unthemed,
.vex-theme-red .dark .mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #fff;
}
.vex-theme-red.dark .mat-mdc-outlined-button.mat-primary,
.vex-theme-red .dark .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #ef4444;
}
.vex-theme-red.dark .mat-mdc-outlined-button.mat-accent,
.vex-theme-red .dark .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #f59e0b;
}
.vex-theme-red.dark .mat-mdc-outlined-button.mat-warn,
.vex-theme-red .dark .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #ef4444;
}
.vex-theme-red.dark .mat-mdc-outlined-button[disabled][disabled],
.vex-theme-red .dark .mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-outlined-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(255, 255, 255, 0.12);
}
.vex-theme-red.dark .mat-mdc-button, .vex-theme-red.dark .mat-mdc-outlined-button,
.vex-theme-red .dark .mat-mdc-button,
.vex-theme-red .dark .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.vex-theme-red.dark .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .vex-theme-red.dark .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-red .dark .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-red .dark .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
.vex-theme-red.dark .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-red.dark .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-red.dark .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-red.dark .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-red .dark .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-red .dark .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-red .dark .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-red .dark .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.vex-theme-red.dark .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .vex-theme-red.dark .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-red .dark .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-red .dark .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.vex-theme-red.dark .mat-mdc-button.mat-primary, .vex-theme-red.dark .mat-mdc-outlined-button.mat-primary,
.vex-theme-red .dark .mat-mdc-button.mat-primary,
.vex-theme-red .dark .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #ef4444;
  --mat-mdc-button-ripple-color: rgba(239, 68, 68, 0.1);
}
.vex-theme-red.dark .mat-mdc-button.mat-accent, .vex-theme-red.dark .mat-mdc-outlined-button.mat-accent,
.vex-theme-red .dark .mat-mdc-button.mat-accent,
.vex-theme-red .dark .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #f59e0b;
  --mat-mdc-button-ripple-color: rgba(245, 158, 11, 0.1);
}
.vex-theme-red.dark .mat-mdc-button.mat-warn, .vex-theme-red.dark .mat-mdc-outlined-button.mat-warn,
.vex-theme-red .dark .mat-mdc-button.mat-warn,
.vex-theme-red .dark .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #ef4444;
  --mat-mdc-button-ripple-color: rgba(239, 68, 68, 0.1);
}
.vex-theme-red.dark .mat-mdc-raised-button, .vex-theme-red.dark .mat-mdc-unelevated-button,
.vex-theme-red .dark .mat-mdc-raised-button,
.vex-theme-red .dark .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.vex-theme-red.dark .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .vex-theme-red.dark .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-red .dark .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-red .dark .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
.vex-theme-red.dark .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-red.dark .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-red.dark .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-red.dark .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-red .dark .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-red .dark .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-red .dark .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-red .dark .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.vex-theme-red.dark .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .vex-theme-red.dark .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-red .dark .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-red .dark .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.vex-theme-red.dark .mat-mdc-raised-button.mat-primary, .vex-theme-red.dark .mat-mdc-unelevated-button.mat-primary,
.vex-theme-red .dark .mat-mdc-raised-button.mat-primary,
.vex-theme-red .dark .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.vex-theme-red.dark .mat-mdc-raised-button.mat-accent, .vex-theme-red.dark .mat-mdc-unelevated-button.mat-accent,
.vex-theme-red .dark .mat-mdc-raised-button.mat-accent,
.vex-theme-red .dark .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.vex-theme-red.dark .mat-mdc-raised-button.mat-warn, .vex-theme-red.dark .mat-mdc-unelevated-button.mat-warn,
.vex-theme-red .dark .mat-mdc-raised-button.mat-warn,
.vex-theme-red .dark .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.vex-theme-red.dark .mat-mdc-icon-button,
.vex-theme-red .dark .mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mdc-icon-button-icon-color: inherit;
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.vex-theme-red.dark .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-red .dark .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
.vex-theme-red.dark .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-red.dark .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-red .dark .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-red .dark .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.vex-theme-red.dark .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-red .dark .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.vex-theme-red.dark .mat-mdc-icon-button.mat-primary,
.vex-theme-red .dark .mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #6200ee;
  --mat-mdc-button-ripple-color: rgba(98, 0, 238, 0.1);
}
.vex-theme-red.dark .mat-mdc-icon-button.mat-accent,
.vex-theme-red .dark .mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #018786;
  --mat-mdc-button-ripple-color: rgba(1, 135, 134, 0.1);
}
.vex-theme-red.dark .mat-mdc-icon-button.mat-warn,
.vex-theme-red .dark .mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #b00020;
  --mat-mdc-button-ripple-color: rgba(176, 0, 32, 0.1);
}
.vex-theme-red.dark .mat-mdc-icon-button.mat-primary,
.vex-theme-red .dark .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #ef4444;
  --mat-mdc-button-persistent-ripple-color: #ef4444;
  --mat-mdc-button-ripple-color: rgba(239, 68, 68, 0.1);
}
.vex-theme-red.dark .mat-mdc-icon-button.mat-accent,
.vex-theme-red .dark .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #f59e0b;
  --mat-mdc-button-persistent-ripple-color: #f59e0b;
  --mat-mdc-button-ripple-color: rgba(245, 158, 11, 0.1);
}
.vex-theme-red.dark .mat-mdc-icon-button.mat-warn,
.vex-theme-red .dark .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #ef4444;
  --mat-mdc-button-persistent-ripple-color: #ef4444;
  --mat-mdc-button-ripple-color: rgba(239, 68, 68, 0.1);
}
.vex-theme-red.dark .mat-mdc-icon-button[disabled][disabled],
.vex-theme-red .dark .mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-icon-button-disabled-icon-color: rgba(255, 255, 255, 0.5);
}
.vex-theme-red.dark .mat-mdc-fab,
.vex-theme-red.dark .mat-mdc-mini-fab,
.vex-theme-red .dark .mat-mdc-fab,
.vex-theme-red .dark .mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.vex-theme-red.dark .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-red.dark .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-red .dark .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-red .dark .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
.vex-theme-red.dark .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-red.dark .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-red.dark .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-red.dark .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-red .dark .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-red .dark .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-red .dark .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-red .dark .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.vex-theme-red.dark .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-red.dark .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-red .dark .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-red .dark .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.vex-theme-red.dark .mat-mdc-fab.mat-primary,
.vex-theme-red.dark .mat-mdc-mini-fab.mat-primary,
.vex-theme-red .dark .mat-mdc-fab.mat-primary,
.vex-theme-red .dark .mat-mdc-mini-fab.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.vex-theme-red.dark .mat-mdc-fab.mat-accent,
.vex-theme-red.dark .mat-mdc-mini-fab.mat-accent,
.vex-theme-red .dark .mat-mdc-fab.mat-accent,
.vex-theme-red .dark .mat-mdc-mini-fab.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.vex-theme-red.dark .mat-mdc-fab.mat-warn,
.vex-theme-red.dark .mat-mdc-mini-fab.mat-warn,
.vex-theme-red .dark .mat-mdc-fab.mat-warn,
.vex-theme-red .dark .mat-mdc-mini-fab.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.vex-theme-red.dark .mat-mdc-fab[disabled][disabled],
.vex-theme-red.dark .mat-mdc-mini-fab[disabled][disabled],
.vex-theme-red .dark .mat-mdc-fab[disabled][disabled],
.vex-theme-red .dark .mat-mdc-mini-fab[disabled][disabled] {
  --mdc-fab-container-color: rgba(255, 255, 255, 0.12);
  --mdc-fab-icon-color: rgba(255, 255, 255, 0.5);
  --mat-mdc-fab-color: rgba(255, 255, 255, 0.5);
}
.vex-theme-red.dark .mat-mdc-fab.mat-unthemed,
.vex-theme-red.dark .mat-mdc-mini-fab.mat-unthemed,
.vex-theme-red .dark .mat-mdc-fab.mat-unthemed,
.vex-theme-red .dark .mat-mdc-mini-fab.mat-unthemed {
  --mdc-fab-container-color: #1f2937;
  --mdc-fab-icon-color: white;
  --mat-mdc-fab-color: #fff;
}
.vex-theme-red.dark .mat-mdc-fab.mat-primary,
.vex-theme-red.dark .mat-mdc-mini-fab.mat-primary,
.vex-theme-red .dark .mat-mdc-fab.mat-primary,
.vex-theme-red .dark .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #ef4444;
  --mdc-fab-icon-color: white;
  --mat-mdc-fab-color: #fff;
}
.vex-theme-red.dark .mat-mdc-fab.mat-accent,
.vex-theme-red.dark .mat-mdc-mini-fab.mat-accent,
.vex-theme-red .dark .mat-mdc-fab.mat-accent,
.vex-theme-red .dark .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: #f59e0b;
  --mdc-fab-icon-color: black;
  --mat-mdc-fab-color: #000;
}
.vex-theme-red.dark .mat-mdc-fab.mat-warn,
.vex-theme-red.dark .mat-mdc-mini-fab.mat-warn,
.vex-theme-red .dark .mat-mdc-fab.mat-warn,
.vex-theme-red .dark .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: #ef4444;
  --mdc-fab-icon-color: white;
  --mat-mdc-fab-color: #fff;
}
.vex-theme-red.dark .mat-mdc-snack-bar-container,
.vex-theme-red .dark .mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: #d2d4d7;
  --mdc-snackbar-supporting-text-color: rgba(31, 41, 55, 0.87);
  --mat-snack-bar-button-color: rgba(0, 0, 0, 0.87);
}
.vex-theme-red.dark .mat-mdc-progress-spinner,
.vex-theme-red .dark .mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #ef4444;
}
.vex-theme-red.dark .mat-mdc-progress-spinner.mat-accent,
.vex-theme-red .dark .mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: #f59e0b;
}
.vex-theme-red.dark .mat-mdc-progress-spinner.mat-warn,
.vex-theme-red .dark .mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: #ef4444;
}
.vex-theme-red.dark, .vex-theme-red .dark {
  --mat-badge-background-color: #ef4444;
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: #4b5563;
  --mat-badge-disabled-state-text-color: #6b7280;
}

.vex-theme-red.dark .mat-badge-accent,
.vex-theme-red .dark .mat-badge-accent {
  --mat-badge-background-color: #f59e0b;
  --mat-badge-text-color: white;
}
.vex-theme-red.dark .mat-badge-warn,
.vex-theme-red .dark .mat-badge-warn {
  --mat-badge-background-color: #ef4444;
  --mat-badge-text-color: white;
}
.vex-theme-red.dark, .vex-theme-red .dark {
  --mat-bottom-sheet-container-text-color: white;
  --mat-bottom-sheet-container-background-color: #1f2937;
}

.vex-theme-red.dark, .vex-theme-red .dark {
  --mat-legacy-button-toggle-text-color: #6b7280;
  --mat-legacy-button-toggle-state-layer-color: rgba(255, 255, 255, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: #9ca3af;
  --mat-legacy-button-toggle-selected-state-background-color: #111827;
  --mat-legacy-button-toggle-disabled-state-text-color: #4b5563;
  --mat-legacy-button-toggle-disabled-state-background-color: black;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #1f2937;
  --mat-standard-button-toggle-text-color: white;
  --mat-standard-button-toggle-background-color: #1f2937;
  --mat-standard-button-toggle-state-layer-color: white;
  --mat-standard-button-toggle-selected-state-background-color: #111827;
  --mat-standard-button-toggle-selected-state-text-color: white;
  --mat-standard-button-toggle-disabled-state-text-color: #4b5563;
  --mat-standard-button-toggle-disabled-state-background-color: #1f2937;
  --mat-standard-button-toggle-disabled-selected-state-text-color: white;
  --mat-standard-button-toggle-disabled-selected-state-background-color: #1f2937;
  --mat-standard-button-toggle-divider-color: #3a434f;
}

.vex-theme-red.dark, .vex-theme-red .dark {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #ef4444;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(239, 68, 68, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(239, 68, 68, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(239, 68, 68, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #ef4444;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(239, 68, 68, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: white;
  --mat-datepicker-calendar-body-label-text-color: #9ca3af;
  --mat-datepicker-calendar-period-button-icon-color: white;
  --mat-datepicker-calendar-navigation-button-icon-color: white;
  --mat-datepicker-calendar-header-divider-color: rgba(255, 255, 255, 0.12);
  --mat-datepicker-calendar-header-text-color: #9ca3af;
  --mat-datepicker-calendar-date-today-outline-color: #6b7280;
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(107, 114, 128, 0.8);
  --mat-datepicker-calendar-date-text-color: white;
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: #6b7280;
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(255, 255, 255, 0.24);
  --mat-datepicker-range-input-separator-color: white;
  --mat-datepicker-range-input-disabled-state-separator-color: #6b7280;
  --mat-datepicker-range-input-disabled-state-text-color: #6b7280;
  --mat-datepicker-calendar-container-background-color: #1f2937;
  --mat-datepicker-calendar-container-text-color: white;
}

.vex-theme-red.dark .mat-datepicker-content.mat-accent,
.vex-theme-red .dark .mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #f59e0b;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(245, 158, 11, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(245, 158, 11, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(245, 158, 11, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(245, 158, 11, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.vex-theme-red.dark .mat-datepicker-content.mat-warn,
.vex-theme-red .dark .mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #ef4444;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(239, 68, 68, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(239, 68, 68, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(239, 68, 68, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(239, 68, 68, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.vex-theme-red.dark .mat-datepicker-toggle-active.mat-accent,
.vex-theme-red .dark .mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #f59e0b;
}
.vex-theme-red.dark .mat-datepicker-toggle-active.mat-warn,
.vex-theme-red .dark .mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #ef4444;
}
.vex-theme-red.dark, .vex-theme-red .dark {
  --mat-divider-color: rgba(255, 255, 255, 0.12);
}

.vex-theme-red.dark, .vex-theme-red .dark {
  --mat-expansion-container-background-color: #1f2937;
  --mat-expansion-container-text-color: white;
  --mat-expansion-actions-divider-color: rgba(255, 255, 255, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(255, 255, 255, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(255, 255, 255, 0.04);
  --mat-expansion-header-disabled-state-text-color: #4b5563;
  --mat-expansion-header-text-color: white;
  --mat-expansion-header-description-color: #9ca3af;
  --mat-expansion-header-indicator-color: #9ca3af;
}

.vex-theme-red.dark, .vex-theme-red .dark {
  --mat-icon-color: inherit;
}

.vex-theme-red.dark .mat-icon.mat-primary,
.vex-theme-red .dark .mat-icon.mat-primary {
  --mat-icon-color: #ef4444;
}
.vex-theme-red.dark .mat-icon.mat-accent,
.vex-theme-red .dark .mat-icon.mat-accent {
  --mat-icon-color: #f59e0b;
}
.vex-theme-red.dark .mat-icon.mat-warn,
.vex-theme-red .dark .mat-icon.mat-warn {
  --mat-icon-color: #ef4444;
}
.vex-theme-red.dark, .vex-theme-red .dark {
  --mat-sidenav-container-divider-color: rgba(255, 255, 255, 0.12);
  --mat-sidenav-container-background-color: #1f2937;
  --mat-sidenav-container-text-color: white;
  --mat-sidenav-content-background-color: #111827;
  --mat-sidenav-content-text-color: white;
  --mat-sidenav-scrim-color: rgba(224, 214, 200, 0.6);
}

.vex-theme-red.dark, .vex-theme-red .dark {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #ef4444;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #ef4444;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #ef4444;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: #1f2937;
  --mat-stepper-line-color: rgba(255, 255, 255, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(255, 255, 255, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(255, 255, 255, 0.04);
  --mat-stepper-header-label-text-color: #9ca3af;
  --mat-stepper-header-optional-label-text-color: #9ca3af;
  --mat-stepper-header-selected-state-label-text-color: white;
  --mat-stepper-header-error-state-label-text-color: #ef4444;
  --mat-stepper-header-icon-background-color: #9ca3af;
  --mat-stepper-header-error-state-icon-foreground-color: #ef4444;
  --mat-stepper-header-error-state-icon-background-color: transparent;
}
.vex-theme-red.dark .mat-step-header.mat-accent, .vex-theme-red .dark .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #f59e0b;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #f59e0b;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #f59e0b;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.vex-theme-red.dark .mat-step-header.mat-warn, .vex-theme-red .dark .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #ef4444;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #ef4444;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #ef4444;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}

.vex-theme-red.dark .mat-sort-header-arrow,
.vex-theme-red .dark .mat-sort-header-arrow {
  color: #9ca3af;
}
.vex-theme-red.dark, .vex-theme-red .dark {
  --mat-toolbar-container-background-color: #1f2937;
  --mat-toolbar-container-text-color: white;
}

.vex-theme-red.dark .mat-toolbar.mat-primary,
.vex-theme-red .dark .mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #ef4444;
  --mat-toolbar-container-text-color: white;
}
.vex-theme-red.dark .mat-toolbar.mat-accent,
.vex-theme-red .dark .mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #f59e0b;
  --mat-toolbar-container-text-color: white;
}
.vex-theme-red.dark .mat-toolbar.mat-warn,
.vex-theme-red .dark .mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #ef4444;
  --mat-toolbar-container-text-color: white;
}
.vex-theme-red.dark .mat-tree,
.vex-theme-red .dark .mat-tree {
  background: #1f2937;
}
.vex-theme-red.dark .mat-tree-node,
.vex-theme-red.dark .mat-nested-tree-node,
.vex-theme-red .dark .mat-tree-node,
.vex-theme-red .dark .mat-nested-tree-node {
  color: white;
}

/**
 * Override the theme foreground/background colors with the Angular Material colors
 */
.vex-theme-orange,
.vex-theme-orange.light,
.vex-theme-orange .light {
  --mat-table-background-color: white;
  --mat-table-header-headline-color: #030712;
  --mat-table-row-item-label-text-color: #030712;
  --mat-table-row-item-outline-color: rgba(0, 0, 0, 0.06);
}
.vex-theme-orange .mat-ripple-element,
.vex-theme-orange.light .mat-ripple-element,
.vex-theme-orange .light .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.vex-theme-orange, .vex-theme-orange.light, .vex-theme-orange .light {
  --mat-option-selected-state-label-text-color: #ea580c;
  --mat-option-label-text-color: #030712;
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.vex-theme-orange .mat-accent,
.vex-theme-orange.light .mat-accent,
.vex-theme-orange .light .mat-accent {
  --mat-option-selected-state-label-text-color: #f59e0b;
}
.vex-theme-orange .mat-warn,
.vex-theme-orange.light .mat-warn,
.vex-theme-orange .light .mat-warn {
  --mat-option-selected-state-label-text-color: #ef4444;
}
.vex-theme-orange, .vex-theme-orange.light, .vex-theme-orange .light {
  --mat-optgroup-label-text-color: #030712;
}

.vex-theme-orange .mat-pseudo-checkbox-full,
.vex-theme-orange.light .mat-pseudo-checkbox-full,
.vex-theme-orange .light .mat-pseudo-checkbox-full {
  color: #4b5563;
}
.vex-theme-orange .mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled,
.vex-theme-orange.light .mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled,
.vex-theme-orange .light .mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.vex-theme-orange .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-orange .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-orange.light .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-orange.light .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-orange .light .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-orange .light .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #ea580c;
}
.vex-theme-orange .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-orange .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-orange.light .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-orange.light .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-orange .light .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-orange .light .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #ea580c;
}
.vex-theme-orange .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-orange .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after,
.vex-theme-orange.light .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-orange.light .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after,
.vex-theme-orange .light .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-orange .light .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #f3f4f6;
}
.vex-theme-orange .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-orange .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-orange.light .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-orange.light .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-orange .light .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-orange .light .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #f59e0b;
}
.vex-theme-orange .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-orange .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-orange.light .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-orange.light .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-orange .light .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-orange .light .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #f59e0b;
}
.vex-theme-orange .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-orange .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after,
.vex-theme-orange.light .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-orange.light .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after,
.vex-theme-orange .light .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-orange .light .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #f3f4f6;
}
.vex-theme-orange .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-orange .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-orange.light .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-orange.light .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-orange .light .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-orange .light .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #f59e0b;
}
.vex-theme-orange .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-orange .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-orange.light .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-orange.light .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-orange .light .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-orange .light .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #f59e0b;
}
.vex-theme-orange .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-orange .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after,
.vex-theme-orange.light .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-orange.light .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after,
.vex-theme-orange .light .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-orange .light .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #f3f4f6;
}
.vex-theme-orange .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-orange .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-orange.light .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-orange.light .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-orange .light .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-orange .light .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #ef4444;
}
.vex-theme-orange .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-orange .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-orange.light .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-orange.light .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-orange .light .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-orange .light .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #ef4444;
}
.vex-theme-orange .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-orange .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after,
.vex-theme-orange.light .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-orange.light .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after,
.vex-theme-orange .light .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-orange .light .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #f3f4f6;
}
.vex-theme-orange .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-orange .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-orange.light .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-orange.light .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-orange .light .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-orange .light .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #b0b0b0;
}
.vex-theme-orange .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-orange .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-orange.light .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-orange.light .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-orange .light .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-orange .light .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #b0b0b0;
}
.vex-theme-orange .mat-app-background, .vex-theme-orange.mat-app-background,
.vex-theme-orange.light .mat-app-background,
.vex-theme-orange.light.mat-app-background,
.vex-theme-orange .light .mat-app-background,
.vex-theme-orange .light.mat-app-background {
  background-color: #f3f4f6;
  color: #030712;
}
.vex-theme-orange .mat-elevation-z0, .vex-theme-orange .mat-mdc-elevation-specific.mat-elevation-z0,
.vex-theme-orange.light .mat-elevation-z0,
.vex-theme-orange.light .mat-mdc-elevation-specific.mat-elevation-z0,
.vex-theme-orange .light .mat-elevation-z0,
.vex-theme-orange .light .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-orange .mat-elevation-z1, .vex-theme-orange .mat-mdc-elevation-specific.mat-elevation-z1,
.vex-theme-orange.light .mat-elevation-z1,
.vex-theme-orange.light .mat-mdc-elevation-specific.mat-elevation-z1,
.vex-theme-orange .light .mat-elevation-z1,
.vex-theme-orange .light .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-orange .mat-elevation-z2, .vex-theme-orange .mat-mdc-elevation-specific.mat-elevation-z2,
.vex-theme-orange.light .mat-elevation-z2,
.vex-theme-orange.light .mat-mdc-elevation-specific.mat-elevation-z2,
.vex-theme-orange .light .mat-elevation-z2,
.vex-theme-orange .light .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-orange .mat-elevation-z3, .vex-theme-orange .mat-mdc-elevation-specific.mat-elevation-z3,
.vex-theme-orange.light .mat-elevation-z3,
.vex-theme-orange.light .mat-mdc-elevation-specific.mat-elevation-z3,
.vex-theme-orange .light .mat-elevation-z3,
.vex-theme-orange .light .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-orange .mat-elevation-z4, .vex-theme-orange .mat-mdc-elevation-specific.mat-elevation-z4,
.vex-theme-orange.light .mat-elevation-z4,
.vex-theme-orange.light .mat-mdc-elevation-specific.mat-elevation-z4,
.vex-theme-orange .light .mat-elevation-z4,
.vex-theme-orange .light .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-orange .mat-elevation-z5, .vex-theme-orange .mat-mdc-elevation-specific.mat-elevation-z5,
.vex-theme-orange.light .mat-elevation-z5,
.vex-theme-orange.light .mat-mdc-elevation-specific.mat-elevation-z5,
.vex-theme-orange .light .mat-elevation-z5,
.vex-theme-orange .light .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-orange .mat-elevation-z6, .vex-theme-orange .mat-mdc-elevation-specific.mat-elevation-z6,
.vex-theme-orange.light .mat-elevation-z6,
.vex-theme-orange.light .mat-mdc-elevation-specific.mat-elevation-z6,
.vex-theme-orange .light .mat-elevation-z6,
.vex-theme-orange .light .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-orange .mat-elevation-z7, .vex-theme-orange .mat-mdc-elevation-specific.mat-elevation-z7,
.vex-theme-orange.light .mat-elevation-z7,
.vex-theme-orange.light .mat-mdc-elevation-specific.mat-elevation-z7,
.vex-theme-orange .light .mat-elevation-z7,
.vex-theme-orange .light .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.vex-theme-orange .mat-elevation-z8, .vex-theme-orange .mat-mdc-elevation-specific.mat-elevation-z8,
.vex-theme-orange.light .mat-elevation-z8,
.vex-theme-orange.light .mat-mdc-elevation-specific.mat-elevation-z8,
.vex-theme-orange .light .mat-elevation-z8,
.vex-theme-orange .light .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.vex-theme-orange .mat-elevation-z9, .vex-theme-orange .mat-mdc-elevation-specific.mat-elevation-z9,
.vex-theme-orange.light .mat-elevation-z9,
.vex-theme-orange.light .mat-mdc-elevation-specific.mat-elevation-z9,
.vex-theme-orange .light .mat-elevation-z9,
.vex-theme-orange .light .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.vex-theme-orange .mat-elevation-z10, .vex-theme-orange .mat-mdc-elevation-specific.mat-elevation-z10,
.vex-theme-orange.light .mat-elevation-z10,
.vex-theme-orange.light .mat-mdc-elevation-specific.mat-elevation-z10,
.vex-theme-orange .light .mat-elevation-z10,
.vex-theme-orange .light .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.vex-theme-orange .mat-elevation-z11, .vex-theme-orange .mat-mdc-elevation-specific.mat-elevation-z11,
.vex-theme-orange.light .mat-elevation-z11,
.vex-theme-orange.light .mat-mdc-elevation-specific.mat-elevation-z11,
.vex-theme-orange .light .mat-elevation-z11,
.vex-theme-orange .light .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.vex-theme-orange .mat-elevation-z12, .vex-theme-orange .mat-mdc-elevation-specific.mat-elevation-z12,
.vex-theme-orange.light .mat-elevation-z12,
.vex-theme-orange.light .mat-mdc-elevation-specific.mat-elevation-z12,
.vex-theme-orange .light .mat-elevation-z12,
.vex-theme-orange .light .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.vex-theme-orange .mat-elevation-z13, .vex-theme-orange .mat-mdc-elevation-specific.mat-elevation-z13,
.vex-theme-orange.light .mat-elevation-z13,
.vex-theme-orange.light .mat-mdc-elevation-specific.mat-elevation-z13,
.vex-theme-orange .light .mat-elevation-z13,
.vex-theme-orange .light .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.vex-theme-orange .mat-elevation-z14, .vex-theme-orange .mat-mdc-elevation-specific.mat-elevation-z14,
.vex-theme-orange.light .mat-elevation-z14,
.vex-theme-orange.light .mat-mdc-elevation-specific.mat-elevation-z14,
.vex-theme-orange .light .mat-elevation-z14,
.vex-theme-orange .light .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.vex-theme-orange .mat-elevation-z15, .vex-theme-orange .mat-mdc-elevation-specific.mat-elevation-z15,
.vex-theme-orange.light .mat-elevation-z15,
.vex-theme-orange.light .mat-mdc-elevation-specific.mat-elevation-z15,
.vex-theme-orange .light .mat-elevation-z15,
.vex-theme-orange .light .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.vex-theme-orange .mat-elevation-z16, .vex-theme-orange .mat-mdc-elevation-specific.mat-elevation-z16,
.vex-theme-orange.light .mat-elevation-z16,
.vex-theme-orange.light .mat-mdc-elevation-specific.mat-elevation-z16,
.vex-theme-orange .light .mat-elevation-z16,
.vex-theme-orange .light .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.vex-theme-orange .mat-elevation-z17, .vex-theme-orange .mat-mdc-elevation-specific.mat-elevation-z17,
.vex-theme-orange.light .mat-elevation-z17,
.vex-theme-orange.light .mat-mdc-elevation-specific.mat-elevation-z17,
.vex-theme-orange .light .mat-elevation-z17,
.vex-theme-orange .light .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.vex-theme-orange .mat-elevation-z18, .vex-theme-orange .mat-mdc-elevation-specific.mat-elevation-z18,
.vex-theme-orange.light .mat-elevation-z18,
.vex-theme-orange.light .mat-mdc-elevation-specific.mat-elevation-z18,
.vex-theme-orange .light .mat-elevation-z18,
.vex-theme-orange .light .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.vex-theme-orange .mat-elevation-z19, .vex-theme-orange .mat-mdc-elevation-specific.mat-elevation-z19,
.vex-theme-orange.light .mat-elevation-z19,
.vex-theme-orange.light .mat-mdc-elevation-specific.mat-elevation-z19,
.vex-theme-orange .light .mat-elevation-z19,
.vex-theme-orange .light .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.vex-theme-orange .mat-elevation-z20, .vex-theme-orange .mat-mdc-elevation-specific.mat-elevation-z20,
.vex-theme-orange.light .mat-elevation-z20,
.vex-theme-orange.light .mat-mdc-elevation-specific.mat-elevation-z20,
.vex-theme-orange .light .mat-elevation-z20,
.vex-theme-orange .light .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.vex-theme-orange .mat-elevation-z21, .vex-theme-orange .mat-mdc-elevation-specific.mat-elevation-z21,
.vex-theme-orange.light .mat-elevation-z21,
.vex-theme-orange.light .mat-mdc-elevation-specific.mat-elevation-z21,
.vex-theme-orange .light .mat-elevation-z21,
.vex-theme-orange .light .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.vex-theme-orange .mat-elevation-z22, .vex-theme-orange .mat-mdc-elevation-specific.mat-elevation-z22,
.vex-theme-orange.light .mat-elevation-z22,
.vex-theme-orange.light .mat-mdc-elevation-specific.mat-elevation-z22,
.vex-theme-orange .light .mat-elevation-z22,
.vex-theme-orange .light .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.vex-theme-orange .mat-elevation-z23, .vex-theme-orange .mat-mdc-elevation-specific.mat-elevation-z23,
.vex-theme-orange.light .mat-elevation-z23,
.vex-theme-orange.light .mat-mdc-elevation-specific.mat-elevation-z23,
.vex-theme-orange .light .mat-elevation-z23,
.vex-theme-orange .light .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.vex-theme-orange .mat-elevation-z24, .vex-theme-orange .mat-mdc-elevation-specific.mat-elevation-z24,
.vex-theme-orange.light .mat-elevation-z24,
.vex-theme-orange.light .mat-mdc-elevation-specific.mat-elevation-z24,
.vex-theme-orange .light .mat-elevation-z24,
.vex-theme-orange .light .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.vex-theme-orange .mat-mdc-card,
.vex-theme-orange.light .mat-mdc-card,
.vex-theme-orange .light .mat-mdc-card {
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: #4b5563;
}
.vex-theme-orange .mat-mdc-progress-bar,
.vex-theme-orange.light .mat-mdc-progress-bar,
.vex-theme-orange .light .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #ea580c;
  --mdc-linear-progress-track-color: rgba(234, 88, 12, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.vex-theme-orange .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots,
.vex-theme-orange.light .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots,
.vex-theme-orange .light .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-color: rgba(234, 88, 12, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(234, 88, 12, 0.25));
}
@media (forced-colors: active) {
  .vex-theme-orange .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots,
  .vex-theme-orange.light .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots,
  .vex-theme-orange .light .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .vex-theme-orange .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots,
  .vex-theme-orange.light .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots,
  .vex-theme-orange .light .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(234, 88, 12, 0.25)'/%3E%3C/svg%3E");
  }
}
.vex-theme-orange .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar,
.vex-theme-orange.light .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar,
.vex-theme-orange .light .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgba(234, 88, 12, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(234, 88, 12, 0.25));
}
.vex-theme-orange .mat-mdc-progress-bar.mat-accent,
.vex-theme-orange.light .mat-mdc-progress-bar.mat-accent,
.vex-theme-orange .light .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #f59e0b;
  --mdc-linear-progress-track-color: rgba(245, 158, 11, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.vex-theme-orange .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots,
.vex-theme-orange.light .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots,
.vex-theme-orange .light .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-color: rgba(245, 158, 11, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(245, 158, 11, 0.25));
}
@media (forced-colors: active) {
  .vex-theme-orange .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots,
  .vex-theme-orange.light .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots,
  .vex-theme-orange .light .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .vex-theme-orange .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots,
  .vex-theme-orange.light .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots,
  .vex-theme-orange .light .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(245, 158, 11, 0.25)'/%3E%3C/svg%3E");
  }
}
.vex-theme-orange .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar,
.vex-theme-orange.light .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar,
.vex-theme-orange .light .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: rgba(245, 158, 11, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(245, 158, 11, 0.25));
}
.vex-theme-orange .mat-mdc-progress-bar.mat-warn,
.vex-theme-orange.light .mat-mdc-progress-bar.mat-warn,
.vex-theme-orange .light .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #ef4444;
  --mdc-linear-progress-track-color: rgba(239, 68, 68, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.vex-theme-orange .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots,
.vex-theme-orange.light .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots,
.vex-theme-orange .light .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-color: rgba(239, 68, 68, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(239, 68, 68, 0.25));
}
@media (forced-colors: active) {
  .vex-theme-orange .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots,
  .vex-theme-orange.light .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots,
  .vex-theme-orange .light .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .vex-theme-orange .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots,
  .vex-theme-orange.light .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots,
  .vex-theme-orange .light .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(239, 68, 68, 0.25)'/%3E%3C/svg%3E");
  }
}
.vex-theme-orange .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar,
.vex-theme-orange.light .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar,
.vex-theme-orange .light .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: rgba(239, 68, 68, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(239, 68, 68, 0.25));
}
.vex-theme-orange .mat-mdc-tooltip,
.vex-theme-orange.light .mat-mdc-tooltip,
.vex-theme-orange .light .mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #1f2937;
  --mdc-plain-tooltip-supporting-text-color: #fff;
}
.vex-theme-orange, .vex-theme-orange.light, .vex-theme-orange .light {
  --mdc-filled-text-field-caret-color: #ea580c;
  --mdc-filled-text-field-focus-active-indicator-color: #ea580c;
  --mdc-filled-text-field-focus-label-text-color: rgba(234, 88, 12, 0.87);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-error-focus-label-text-color: #ef4444;
  --mdc-filled-text-field-error-label-text-color: #ef4444;
  --mdc-filled-text-field-error-caret-color: #ef4444;
  --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #ef4444;
  --mdc-filled-text-field-error-focus-active-indicator-color: #ef4444;
  --mdc-filled-text-field-error-hover-active-indicator-color: #ef4444;
  --mdc-outlined-text-field-caret-color: #ea580c;
  --mdc-outlined-text-field-focus-outline-color: #ea580c;
  --mdc-outlined-text-field-focus-label-text-color: rgba(234, 88, 12, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-error-caret-color: #ef4444;
  --mdc-outlined-text-field-error-focus-label-text-color: #ef4444;
  --mdc-outlined-text-field-error-label-text-color: #ef4444;
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #ef4444;
  --mdc-outlined-text-field-error-hover-outline-color: #ef4444;
  --mdc-outlined-text-field-error-outline-color: #ef4444;
  --mat-form-field-disabled-input-text-placeholder-color: rgba(0, 0, 0, 0.38);
}

.vex-theme-orange .mat-mdc-form-field-error,
.vex-theme-orange.light .mat-mdc-form-field-error,
.vex-theme-orange .light .mat-mdc-form-field-error {
  color: var(--mdc-theme-error, #ef4444);
}
.vex-theme-orange .mat-mdc-form-field-subscript-wrapper,
.vex-theme-orange .mat-mdc-form-field-bottom-align::before,
.vex-theme-orange.light .mat-mdc-form-field-subscript-wrapper,
.vex-theme-orange.light .mat-mdc-form-field-bottom-align::before,
.vex-theme-orange .light .mat-mdc-form-field-subscript-wrapper,
.vex-theme-orange .light .mat-mdc-form-field-bottom-align::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mat-form-field-subscript-text-font);
  line-height: var(--mat-form-field-subscript-text-line-height);
  font-size: var(--mat-form-field-subscript-text-size);
  letter-spacing: var(--mat-form-field-subscript-text-tracking);
  font-weight: var(--mat-form-field-subscript-text-weight);
}
.vex-theme-orange .mat-mdc-form-field-focus-overlay,
.vex-theme-orange.light .mat-mdc-form-field-focus-overlay,
.vex-theme-orange .light .mat-mdc-form-field-focus-overlay {
  background-color: rgba(0, 0, 0, 0.87);
}
.vex-theme-orange .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay,
.vex-theme-orange.light .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay,
.vex-theme-orange .light .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0.04;
}
.vex-theme-orange .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay,
.vex-theme-orange.light .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay,
.vex-theme-orange .light .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.12;
}
.vex-theme-orange .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after,
.vex-theme-orange.light .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after,
.vex-theme-orange .light .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.vex-theme-orange .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after,
.vex-theme-orange.light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after,
.vex-theme-orange .light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after {
  color: rgba(234, 88, 12, 0.87);
}
.vex-theme-orange .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after,
.vex-theme-orange.light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after,
.vex-theme-orange .light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after {
  color: rgba(245, 158, 11, 0.87);
}
.vex-theme-orange .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after,
.vex-theme-orange.light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after,
.vex-theme-orange .light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after {
  color: rgba(239, 68, 68, 0.87);
}
.vex-theme-orange .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after,
.vex-theme-orange.light .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after,
.vex-theme-orange .light .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.vex-theme-orange .mat-mdc-form-field.mat-accent,
.vex-theme-orange.light .mat-mdc-form-field.mat-accent,
.vex-theme-orange .light .mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #f59e0b;
  --mdc-filled-text-field-focus-active-indicator-color: #f59e0b;
  --mdc-filled-text-field-focus-label-text-color: rgba(245, 158, 11, 0.87);
  --mdc-outlined-text-field-caret-color: #f59e0b;
  --mdc-outlined-text-field-focus-outline-color: #f59e0b;
  --mdc-outlined-text-field-focus-label-text-color: rgba(245, 158, 11, 0.87);
}
.vex-theme-orange .mat-mdc-form-field.mat-warn,
.vex-theme-orange.light .mat-mdc-form-field.mat-warn,
.vex-theme-orange .light .mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #ef4444;
  --mdc-filled-text-field-focus-active-indicator-color: #ef4444;
  --mdc-filled-text-field-focus-label-text-color: rgba(239, 68, 68, 0.87);
  --mdc-outlined-text-field-caret-color: #ef4444;
  --mdc-outlined-text-field-focus-outline-color: #ef4444;
  --mdc-outlined-text-field-focus-label-text-color: rgba(239, 68, 68, 0.87);
}
.vex-theme-orange .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch,
.vex-theme-orange.light .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch,
.vex-theme-orange .light .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: 1px solid transparent;
}
.vex-theme-orange [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch,
.vex-theme-orange.light [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch,
.vex-theme-orange .light [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none;
  border-right: 1px solid transparent;
}
.vex-theme-orange, .vex-theme-orange.light, .vex-theme-orange .light {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(234, 88, 12, 0.87);
  --mat-select-invalid-arrow-color: rgba(239, 68, 68, 0.87);
}
.vex-theme-orange .mat-mdc-form-field.mat-accent, .vex-theme-orange.light .mat-mdc-form-field.mat-accent, .vex-theme-orange .light .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(245, 158, 11, 0.87);
  --mat-select-invalid-arrow-color: rgba(239, 68, 68, 0.87);
}
.vex-theme-orange .mat-mdc-form-field.mat-warn, .vex-theme-orange.light .mat-mdc-form-field.mat-warn, .vex-theme-orange .light .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(239, 68, 68, 0.87);
  --mat-select-invalid-arrow-color: rgba(239, 68, 68, 0.87);
}

.vex-theme-orange, .vex-theme-orange.light, .vex-theme-orange .light {
  --mat-autocomplete-background-color: white;
}

.vex-theme-orange .mat-mdc-dialog-container,
.vex-theme-orange.light .mat-mdc-dialog-container,
.vex-theme-orange .light .mat-mdc-dialog-container {
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}
.vex-theme-orange .mat-mdc-standard-chip,
.vex-theme-orange.light .mat-mdc-standard-chip,
.vex-theme-orange .light .mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
}
.vex-theme-orange .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .vex-theme-orange .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary,
.vex-theme-orange.light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary,
.vex-theme-orange.light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary,
.vex-theme-orange .light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary,
.vex-theme-orange .light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #ea580c;
  --mdc-chip-elevated-disabled-container-color: #ea580c;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.vex-theme-orange .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .vex-theme-orange .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent,
.vex-theme-orange.light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent,
.vex-theme-orange.light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent,
.vex-theme-orange .light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent,
.vex-theme-orange .light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #f59e0b;
  --mdc-chip-elevated-disabled-container-color: #f59e0b;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.vex-theme-orange .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .vex-theme-orange .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn,
.vex-theme-orange.light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn,
.vex-theme-orange.light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn,
.vex-theme-orange .light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn,
.vex-theme-orange .light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #ef4444;
  --mdc-chip-elevated-disabled-container-color: #ef4444;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.vex-theme-orange .mat-mdc-slide-toggle,
.vex-theme-orange.light .mat-mdc-slide-toggle,
.vex-theme-orange .light .mat-mdc-slide-toggle {
  --mdc-switch-selected-focus-state-layer-color: #ea580c;
  --mdc-switch-selected-handle-color: #ea580c;
  --mdc-switch-selected-hover-state-layer-color: #ea580c;
  --mdc-switch-selected-pressed-state-layer-color: #ea580c;
  --mdc-switch-selected-focus-handle-color: #7c2d12;
  --mdc-switch-selected-hover-handle-color: #7c2d12;
  --mdc-switch-selected-pressed-handle-color: #7c2d12;
  --mdc-switch-selected-focus-track-color: #fdba74;
  --mdc-switch-selected-hover-track-color: #fdba74;
  --mdc-switch-selected-pressed-track-color: #fdba74;
  --mdc-switch-selected-track-color: #fdba74;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
}
.vex-theme-orange .mat-mdc-slide-toggle .mdc-form-field,
.vex-theme-orange.light .mat-mdc-slide-toggle .mdc-form-field,
.vex-theme-orange .light .mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.vex-theme-orange .mat-mdc-slide-toggle .mdc-switch--disabled + label,
.vex-theme-orange.light .mat-mdc-slide-toggle .mdc-switch--disabled + label,
.vex-theme-orange .light .mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: #6b7280;
}
.vex-theme-orange .mat-mdc-slide-toggle.mat-accent,
.vex-theme-orange.light .mat-mdc-slide-toggle.mat-accent,
.vex-theme-orange .light .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #d97706;
  --mdc-switch-selected-handle-color: #d97706;
  --mdc-switch-selected-hover-state-layer-color: #d97706;
  --mdc-switch-selected-pressed-state-layer-color: #d97706;
  --mdc-switch-selected-focus-handle-color: #78350f;
  --mdc-switch-selected-hover-handle-color: #78350f;
  --mdc-switch-selected-pressed-handle-color: #78350f;
  --mdc-switch-selected-focus-track-color: #fcd34d;
  --mdc-switch-selected-hover-track-color: #fcd34d;
  --mdc-switch-selected-pressed-track-color: #fcd34d;
  --mdc-switch-selected-track-color: #fcd34d;
}
.vex-theme-orange .mat-mdc-slide-toggle.mat-warn,
.vex-theme-orange.light .mat-mdc-slide-toggle.mat-warn,
.vex-theme-orange .light .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #dc2626;
  --mdc-switch-selected-handle-color: #dc2626;
  --mdc-switch-selected-hover-state-layer-color: #dc2626;
  --mdc-switch-selected-pressed-state-layer-color: #dc2626;
  --mdc-switch-selected-focus-handle-color: #7f1d1d;
  --mdc-switch-selected-hover-handle-color: #7f1d1d;
  --mdc-switch-selected-pressed-handle-color: #7f1d1d;
  --mdc-switch-selected-focus-track-color: #fca5a5;
  --mdc-switch-selected-hover-track-color: #fca5a5;
  --mdc-switch-selected-pressed-track-color: #fca5a5;
  --mdc-switch-selected-track-color: #fca5a5;
}
.vex-theme-orange .mat-mdc-radio-button .mdc-form-field,
.vex-theme-orange.light .mat-mdc-radio-button .mdc-form-field,
.vex-theme-orange .light .mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.vex-theme-orange .mat-mdc-radio-button.mat-primary,
.vex-theme-orange.light .mat-mdc-radio-button.mat-primary,
.vex-theme-orange .light .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #ea580c;
  --mdc-radio-selected-hover-icon-color: #ea580c;
  --mdc-radio-selected-icon-color: #ea580c;
  --mdc-radio-selected-pressed-icon-color: #ea580c;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #ea580c;
  --mat-radio-disabled-label-color: #6b7280;
}
.vex-theme-orange .mat-mdc-radio-button.mat-accent,
.vex-theme-orange.light .mat-mdc-radio-button.mat-accent,
.vex-theme-orange .light .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f59e0b;
  --mdc-radio-selected-hover-icon-color: #f59e0b;
  --mdc-radio-selected-icon-color: #f59e0b;
  --mdc-radio-selected-pressed-icon-color: #f59e0b;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #f59e0b;
  --mat-radio-disabled-label-color: #6b7280;
}
.vex-theme-orange .mat-mdc-radio-button.mat-warn,
.vex-theme-orange.light .mat-mdc-radio-button.mat-warn,
.vex-theme-orange .light .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #ef4444;
  --mdc-radio-selected-hover-icon-color: #ef4444;
  --mdc-radio-selected-icon-color: #ef4444;
  --mdc-radio-selected-pressed-icon-color: #ef4444;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #ef4444;
  --mat-radio-disabled-label-color: #6b7280;
}
.vex-theme-orange .mat-mdc-slider,
.vex-theme-orange.light .mat-mdc-slider,
.vex-theme-orange .light .mat-mdc-slider {
  --mdc-slider-label-container-color: black;
  --mdc-slider-label-label-text-color: white;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-mdc-slider-value-indicator-opacity: 0.6;
}
.vex-theme-orange .mat-mdc-slider.mat-primary,
.vex-theme-orange.light .mat-mdc-slider.mat-primary,
.vex-theme-orange .light .mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #ea580c;
  --mdc-slider-focus-handle-color: #ea580c;
  --mdc-slider-hover-handle-color: #ea580c;
  --mdc-slider-active-track-color: #ea580c;
  --mdc-slider-inactive-track-color: #ea580c;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #ea580c;
  --mat-mdc-slider-ripple-color: #ea580c;
  --mat-mdc-slider-hover-ripple-color: rgba(234, 88, 12, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(234, 88, 12, 0.2);
}
.vex-theme-orange .mat-mdc-slider.mat-accent,
.vex-theme-orange.light .mat-mdc-slider.mat-accent,
.vex-theme-orange .light .mat-mdc-slider.mat-accent {
  --mdc-slider-handle-color: #f59e0b;
  --mdc-slider-focus-handle-color: #f59e0b;
  --mdc-slider-hover-handle-color: #f59e0b;
  --mdc-slider-active-track-color: #f59e0b;
  --mdc-slider-inactive-track-color: #f59e0b;
  --mdc-slider-with-tick-marks-active-container-color: #000;
  --mdc-slider-with-tick-marks-inactive-container-color: #f59e0b;
  --mat-mdc-slider-ripple-color: #f59e0b;
  --mat-mdc-slider-hover-ripple-color: rgba(245, 158, 11, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(245, 158, 11, 0.2);
}
.vex-theme-orange .mat-mdc-slider.mat-warn,
.vex-theme-orange.light .mat-mdc-slider.mat-warn,
.vex-theme-orange .light .mat-mdc-slider.mat-warn {
  --mdc-slider-handle-color: #ef4444;
  --mdc-slider-focus-handle-color: #ef4444;
  --mdc-slider-hover-handle-color: #ef4444;
  --mdc-slider-active-track-color: #ef4444;
  --mdc-slider-inactive-track-color: #ef4444;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #ef4444;
  --mat-mdc-slider-ripple-color: #ef4444;
  --mat-mdc-slider-hover-ripple-color: rgba(239, 68, 68, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(239, 68, 68, 0.2);
}
.vex-theme-orange, .vex-theme-orange.light, .vex-theme-orange .light {
  --mat-menu-item-label-text-color: #030712;
  --mat-menu-item-icon-color: #030712;
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
}

.vex-theme-orange .mat-mdc-list-base,
.vex-theme-orange.light .mat-mdc-list-base,
.vex-theme-orange .light .mat-mdc-list-base {
  --mdc-list-list-item-label-text-color: #030712;
  --mdc-list-list-item-supporting-text-color: #4b5563;
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: #6b7280;
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: #030712;
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: #030712;
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
}
.vex-theme-orange .mdc-list-item__start,
.vex-theme-orange .mdc-list-item__end,
.vex-theme-orange.light .mdc-list-item__start,
.vex-theme-orange.light .mdc-list-item__end,
.vex-theme-orange .light .mdc-list-item__start,
.vex-theme-orange .light .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #ea580c;
  --mdc-radio-selected-hover-icon-color: #ea580c;
  --mdc-radio-selected-icon-color: #ea580c;
  --mdc-radio-selected-pressed-icon-color: #ea580c;
}
.vex-theme-orange .mat-accent .mdc-list-item__start,
.vex-theme-orange .mat-accent .mdc-list-item__end,
.vex-theme-orange.light .mat-accent .mdc-list-item__start,
.vex-theme-orange.light .mat-accent .mdc-list-item__end,
.vex-theme-orange .light .mat-accent .mdc-list-item__start,
.vex-theme-orange .light .mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f59e0b;
  --mdc-radio-selected-hover-icon-color: #f59e0b;
  --mdc-radio-selected-icon-color: #f59e0b;
  --mdc-radio-selected-pressed-icon-color: #f59e0b;
}
.vex-theme-orange .mat-warn .mdc-list-item__start,
.vex-theme-orange .mat-warn .mdc-list-item__end,
.vex-theme-orange.light .mat-warn .mdc-list-item__start,
.vex-theme-orange.light .mat-warn .mdc-list-item__end,
.vex-theme-orange .light .mat-warn .mdc-list-item__start,
.vex-theme-orange .light .mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #ef4444;
  --mdc-radio-selected-hover-icon-color: #ef4444;
  --mdc-radio-selected-icon-color: #ef4444;
  --mdc-radio-selected-pressed-icon-color: #ef4444;
}
.vex-theme-orange .mat-mdc-list-option,
.vex-theme-orange.light .mat-mdc-list-option,
.vex-theme-orange .light .mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #ea580c;
  --mdc-checkbox-selected-hover-icon-color: #ea580c;
  --mdc-checkbox-selected-icon-color: #ea580c;
  --mdc-checkbox-selected-pressed-icon-color: #ea580c;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #ea580c;
  --mdc-checkbox-selected-hover-state-layer-color: #ea580c;
  --mdc-checkbox-selected-pressed-state-layer-color: #ea580c;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.vex-theme-orange .mat-mdc-list-option.mat-accent,
.vex-theme-orange.light .mat-mdc-list-option.mat-accent,
.vex-theme-orange .light .mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #f59e0b;
  --mdc-checkbox-selected-hover-icon-color: #f59e0b;
  --mdc-checkbox-selected-icon-color: #f59e0b;
  --mdc-checkbox-selected-pressed-icon-color: #f59e0b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f59e0b;
  --mdc-checkbox-selected-hover-state-layer-color: #f59e0b;
  --mdc-checkbox-selected-pressed-state-layer-color: #f59e0b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.vex-theme-orange .mat-mdc-list-option.mat-warn,
.vex-theme-orange.light .mat-mdc-list-option.mat-warn,
.vex-theme-orange .light .mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #ef4444;
  --mdc-checkbox-selected-hover-icon-color: #ef4444;
  --mdc-checkbox-selected-icon-color: #ef4444;
  --mdc-checkbox-selected-pressed-icon-color: #ef4444;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #ef4444;
  --mdc-checkbox-selected-hover-state-layer-color: #ef4444;
  --mdc-checkbox-selected-pressed-state-layer-color: #ef4444;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.vex-theme-orange .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.vex-theme-orange .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,
.vex-theme-orange.light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.vex-theme-orange.light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,
.vex-theme-orange .light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.vex-theme-orange .light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #ea580c;
}
.vex-theme-orange .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.vex-theme-orange .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.vex-theme-orange.light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.vex-theme-orange.light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.vex-theme-orange .light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.vex-theme-orange .light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #ea580c;
}
.vex-theme-orange .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.vex-theme-orange .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.vex-theme-orange .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end,
.vex-theme-orange.light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.vex-theme-orange.light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.vex-theme-orange.light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end,
.vex-theme-orange .light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.vex-theme-orange .light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.vex-theme-orange .light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.vex-theme-orange, .vex-theme-orange.light, .vex-theme-orange .light {
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
}

.vex-theme-orange .mat-mdc-tab-group, .vex-theme-orange .mat-mdc-tab-nav-bar,
.vex-theme-orange.light .mat-mdc-tab-group,
.vex-theme-orange.light .mat-mdc-tab-nav-bar,
.vex-theme-orange .light .mat-mdc-tab-group,
.vex-theme-orange .light .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #ea580c;
  --mat-tab-header-disabled-ripple-color: #6b7280;
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #ea580c;
  --mat-tab-header-active-ripple-color: #ea580c;
  --mat-tab-header-inactive-ripple-color: #ea580c;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #ea580c;
  --mat-tab-header-active-hover-label-text-color: #ea580c;
  --mat-tab-header-active-focus-indicator-color: #ea580c;
  --mat-tab-header-active-hover-indicator-color: #ea580c;
}
.vex-theme-orange .mat-mdc-tab-group.mat-accent, .vex-theme-orange .mat-mdc-tab-nav-bar.mat-accent,
.vex-theme-orange.light .mat-mdc-tab-group.mat-accent,
.vex-theme-orange.light .mat-mdc-tab-nav-bar.mat-accent,
.vex-theme-orange .light .mat-mdc-tab-group.mat-accent,
.vex-theme-orange .light .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #f59e0b;
  --mat-tab-header-disabled-ripple-color: #6b7280;
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #f59e0b;
  --mat-tab-header-active-ripple-color: #f59e0b;
  --mat-tab-header-inactive-ripple-color: #f59e0b;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #f59e0b;
  --mat-tab-header-active-hover-label-text-color: #f59e0b;
  --mat-tab-header-active-focus-indicator-color: #f59e0b;
  --mat-tab-header-active-hover-indicator-color: #f59e0b;
}
.vex-theme-orange .mat-mdc-tab-group.mat-warn, .vex-theme-orange .mat-mdc-tab-nav-bar.mat-warn,
.vex-theme-orange.light .mat-mdc-tab-group.mat-warn,
.vex-theme-orange.light .mat-mdc-tab-nav-bar.mat-warn,
.vex-theme-orange .light .mat-mdc-tab-group.mat-warn,
.vex-theme-orange .light .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #ef4444;
  --mat-tab-header-disabled-ripple-color: #6b7280;
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #ef4444;
  --mat-tab-header-active-ripple-color: #ef4444;
  --mat-tab-header-inactive-ripple-color: #ef4444;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #ef4444;
  --mat-tab-header-active-hover-label-text-color: #ef4444;
  --mat-tab-header-active-focus-indicator-color: #ef4444;
  --mat-tab-header-active-hover-indicator-color: #ef4444;
}
.vex-theme-orange .mat-mdc-tab-group.mat-background-primary, .vex-theme-orange .mat-mdc-tab-nav-bar.mat-background-primary,
.vex-theme-orange.light .mat-mdc-tab-group.mat-background-primary,
.vex-theme-orange.light .mat-mdc-tab-nav-bar.mat-background-primary,
.vex-theme-orange .light .mat-mdc-tab-group.mat-background-primary,
.vex-theme-orange .light .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #ea580c;
  --mat-tab-header-with-background-foreground-color: white;
}
.vex-theme-orange .mat-mdc-tab-group.mat-background-accent, .vex-theme-orange .mat-mdc-tab-nav-bar.mat-background-accent,
.vex-theme-orange.light .mat-mdc-tab-group.mat-background-accent,
.vex-theme-orange.light .mat-mdc-tab-nav-bar.mat-background-accent,
.vex-theme-orange .light .mat-mdc-tab-group.mat-background-accent,
.vex-theme-orange .light .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #f59e0b;
  --mat-tab-header-with-background-foreground-color: white;
}
.vex-theme-orange .mat-mdc-tab-group.mat-background-warn, .vex-theme-orange .mat-mdc-tab-nav-bar.mat-background-warn,
.vex-theme-orange.light .mat-mdc-tab-group.mat-background-warn,
.vex-theme-orange.light .mat-mdc-tab-nav-bar.mat-background-warn,
.vex-theme-orange .light .mat-mdc-tab-group.mat-background-warn,
.vex-theme-orange .light .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #ef4444;
  --mat-tab-header-with-background-foreground-color: white;
}
.vex-theme-orange, .vex-theme-orange.light, .vex-theme-orange .light {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #f59e0b;
  --mdc-checkbox-selected-hover-icon-color: #f59e0b;
  --mdc-checkbox-selected-icon-color: #f59e0b;
  --mdc-checkbox-selected-pressed-icon-color: #f59e0b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f59e0b;
  --mdc-checkbox-selected-hover-state-layer-color: #f59e0b;
  --mdc-checkbox-selected-pressed-state-layer-color: #f59e0b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.vex-theme-orange .mat-mdc-checkbox.mat-primary,
.vex-theme-orange.light .mat-mdc-checkbox.mat-primary,
.vex-theme-orange .light .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #ea580c;
  --mdc-checkbox-selected-hover-icon-color: #ea580c;
  --mdc-checkbox-selected-icon-color: #ea580c;
  --mdc-checkbox-selected-pressed-icon-color: #ea580c;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #ea580c;
  --mdc-checkbox-selected-hover-state-layer-color: #ea580c;
  --mdc-checkbox-selected-pressed-state-layer-color: #ea580c;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.vex-theme-orange .mat-mdc-checkbox.mat-warn,
.vex-theme-orange.light .mat-mdc-checkbox.mat-warn,
.vex-theme-orange .light .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #ef4444;
  --mdc-checkbox-selected-hover-icon-color: #ef4444;
  --mdc-checkbox-selected-icon-color: #ef4444;
  --mdc-checkbox-selected-pressed-icon-color: #ef4444;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #ef4444;
  --mdc-checkbox-selected-hover-state-layer-color: #ef4444;
  --mdc-checkbox-selected-pressed-state-layer-color: #ef4444;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.vex-theme-orange .mat-mdc-checkbox .mdc-form-field,
.vex-theme-orange.light .mat-mdc-checkbox .mdc-form-field,
.vex-theme-orange .light .mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.vex-theme-orange .mat-mdc-checkbox.mat-mdc-checkbox-disabled label,
.vex-theme-orange.light .mat-mdc-checkbox.mat-mdc-checkbox-disabled label,
.vex-theme-orange .light .mat-mdc-checkbox.mat-mdc-checkbox-disabled label {
  color: #6b7280;
}
.vex-theme-orange .mat-mdc-button.mat-unthemed,
.vex-theme-orange.light .mat-mdc-button.mat-unthemed,
.vex-theme-orange .light .mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #000;
}
.vex-theme-orange .mat-mdc-button.mat-primary,
.vex-theme-orange.light .mat-mdc-button.mat-primary,
.vex-theme-orange .light .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #ea580c;
}
.vex-theme-orange .mat-mdc-button.mat-accent,
.vex-theme-orange.light .mat-mdc-button.mat-accent,
.vex-theme-orange .light .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #f59e0b;
}
.vex-theme-orange .mat-mdc-button.mat-warn,
.vex-theme-orange.light .mat-mdc-button.mat-warn,
.vex-theme-orange .light .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #ef4444;
}
.vex-theme-orange .mat-mdc-button[disabled][disabled],
.vex-theme-orange.light .mat-mdc-button[disabled][disabled],
.vex-theme-orange .light .mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-text-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.vex-theme-orange .mat-mdc-unelevated-button.mat-unthemed,
.vex-theme-orange.light .mat-mdc-unelevated-button.mat-unthemed,
.vex-theme-orange .light .mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #fff;
  --mdc-filled-button-label-text-color: #000;
}
.vex-theme-orange .mat-mdc-unelevated-button.mat-primary,
.vex-theme-orange.light .mat-mdc-unelevated-button.mat-primary,
.vex-theme-orange .light .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #ea580c;
  --mdc-filled-button-label-text-color: #fff;
}
.vex-theme-orange .mat-mdc-unelevated-button.mat-accent,
.vex-theme-orange.light .mat-mdc-unelevated-button.mat-accent,
.vex-theme-orange .light .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #f59e0b;
  --mdc-filled-button-label-text-color: #000;
}
.vex-theme-orange .mat-mdc-unelevated-button.mat-warn,
.vex-theme-orange.light .mat-mdc-unelevated-button.mat-warn,
.vex-theme-orange .light .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #ef4444;
  --mdc-filled-button-label-text-color: #fff;
}
.vex-theme-orange .mat-mdc-unelevated-button[disabled][disabled],
.vex-theme-orange.light .mat-mdc-unelevated-button[disabled][disabled],
.vex-theme-orange .light .mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.vex-theme-orange .mat-mdc-raised-button.mat-unthemed,
.vex-theme-orange.light .mat-mdc-raised-button.mat-unthemed,
.vex-theme-orange .light .mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #fff;
  --mdc-protected-button-label-text-color: #000;
}
.vex-theme-orange .mat-mdc-raised-button.mat-primary,
.vex-theme-orange.light .mat-mdc-raised-button.mat-primary,
.vex-theme-orange .light .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #ea580c;
  --mdc-protected-button-label-text-color: #fff;
}
.vex-theme-orange .mat-mdc-raised-button.mat-accent,
.vex-theme-orange.light .mat-mdc-raised-button.mat-accent,
.vex-theme-orange .light .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #f59e0b;
  --mdc-protected-button-label-text-color: #000;
}
.vex-theme-orange .mat-mdc-raised-button.mat-warn,
.vex-theme-orange.light .mat-mdc-raised-button.mat-warn,
.vex-theme-orange .light .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #ef4444;
  --mdc-protected-button-label-text-color: #fff;
}
.vex-theme-orange .mat-mdc-raised-button[disabled][disabled],
.vex-theme-orange.light .mat-mdc-raised-button[disabled][disabled],
.vex-theme-orange .light .mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation: 0;
}
.vex-theme-orange .mat-mdc-outlined-button,
.vex-theme-orange.light .mat-mdc-outlined-button,
.vex-theme-orange .light .mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.vex-theme-orange .mat-mdc-outlined-button.mat-unthemed,
.vex-theme-orange.light .mat-mdc-outlined-button.mat-unthemed,
.vex-theme-orange .light .mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #000;
}
.vex-theme-orange .mat-mdc-outlined-button.mat-primary,
.vex-theme-orange.light .mat-mdc-outlined-button.mat-primary,
.vex-theme-orange .light .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #ea580c;
}
.vex-theme-orange .mat-mdc-outlined-button.mat-accent,
.vex-theme-orange.light .mat-mdc-outlined-button.mat-accent,
.vex-theme-orange .light .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #f59e0b;
}
.vex-theme-orange .mat-mdc-outlined-button.mat-warn,
.vex-theme-orange.light .mat-mdc-outlined-button.mat-warn,
.vex-theme-orange .light .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #ef4444;
}
.vex-theme-orange .mat-mdc-outlined-button[disabled][disabled],
.vex-theme-orange.light .mat-mdc-outlined-button[disabled][disabled],
.vex-theme-orange .light .mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
}
.vex-theme-orange .mat-mdc-button, .vex-theme-orange .mat-mdc-outlined-button,
.vex-theme-orange.light .mat-mdc-button,
.vex-theme-orange.light .mat-mdc-outlined-button,
.vex-theme-orange .light .mat-mdc-button,
.vex-theme-orange .light .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.vex-theme-orange .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .vex-theme-orange .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange.light .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange.light .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange .light .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange .light .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.vex-theme-orange .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-orange .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-orange .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-orange .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange.light .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange.light .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange.light .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange.light .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange .light .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange .light .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange .light .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange .light .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.vex-theme-orange .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .vex-theme-orange .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange.light .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange.light .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange .light .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange .light .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.vex-theme-orange .mat-mdc-button.mat-primary, .vex-theme-orange .mat-mdc-outlined-button.mat-primary,
.vex-theme-orange.light .mat-mdc-button.mat-primary,
.vex-theme-orange.light .mat-mdc-outlined-button.mat-primary,
.vex-theme-orange .light .mat-mdc-button.mat-primary,
.vex-theme-orange .light .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #ea580c;
  --mat-mdc-button-ripple-color: rgba(234, 88, 12, 0.1);
}
.vex-theme-orange .mat-mdc-button.mat-accent, .vex-theme-orange .mat-mdc-outlined-button.mat-accent,
.vex-theme-orange.light .mat-mdc-button.mat-accent,
.vex-theme-orange.light .mat-mdc-outlined-button.mat-accent,
.vex-theme-orange .light .mat-mdc-button.mat-accent,
.vex-theme-orange .light .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #f59e0b;
  --mat-mdc-button-ripple-color: rgba(245, 158, 11, 0.1);
}
.vex-theme-orange .mat-mdc-button.mat-warn, .vex-theme-orange .mat-mdc-outlined-button.mat-warn,
.vex-theme-orange.light .mat-mdc-button.mat-warn,
.vex-theme-orange.light .mat-mdc-outlined-button.mat-warn,
.vex-theme-orange .light .mat-mdc-button.mat-warn,
.vex-theme-orange .light .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #ef4444;
  --mat-mdc-button-ripple-color: rgba(239, 68, 68, 0.1);
}
.vex-theme-orange .mat-mdc-raised-button, .vex-theme-orange .mat-mdc-unelevated-button,
.vex-theme-orange.light .mat-mdc-raised-button,
.vex-theme-orange.light .mat-mdc-unelevated-button,
.vex-theme-orange .light .mat-mdc-raised-button,
.vex-theme-orange .light .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.vex-theme-orange .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .vex-theme-orange .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange.light .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange.light .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange .light .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange .light .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.vex-theme-orange .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-orange .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-orange .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-orange .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange.light .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange.light .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange.light .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange.light .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange .light .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange .light .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange .light .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange .light .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.vex-theme-orange .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .vex-theme-orange .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange.light .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange.light .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange .light .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange .light .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.vex-theme-orange .mat-mdc-raised-button.mat-primary, .vex-theme-orange .mat-mdc-unelevated-button.mat-primary,
.vex-theme-orange.light .mat-mdc-raised-button.mat-primary,
.vex-theme-orange.light .mat-mdc-unelevated-button.mat-primary,
.vex-theme-orange .light .mat-mdc-raised-button.mat-primary,
.vex-theme-orange .light .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.vex-theme-orange .mat-mdc-raised-button.mat-accent, .vex-theme-orange .mat-mdc-unelevated-button.mat-accent,
.vex-theme-orange.light .mat-mdc-raised-button.mat-accent,
.vex-theme-orange.light .mat-mdc-unelevated-button.mat-accent,
.vex-theme-orange .light .mat-mdc-raised-button.mat-accent,
.vex-theme-orange .light .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.vex-theme-orange .mat-mdc-raised-button.mat-warn, .vex-theme-orange .mat-mdc-unelevated-button.mat-warn,
.vex-theme-orange.light .mat-mdc-raised-button.mat-warn,
.vex-theme-orange.light .mat-mdc-unelevated-button.mat-warn,
.vex-theme-orange .light .mat-mdc-raised-button.mat-warn,
.vex-theme-orange .light .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.vex-theme-orange .mat-mdc-icon-button,
.vex-theme-orange.light .mat-mdc-icon-button,
.vex-theme-orange .light .mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mdc-icon-button-icon-color: inherit;
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.vex-theme-orange .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange.light .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange .light .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.vex-theme-orange .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-orange .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange.light .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange.light .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange .light .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange .light .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.vex-theme-orange .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange.light .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange .light .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.vex-theme-orange .mat-mdc-icon-button.mat-primary,
.vex-theme-orange.light .mat-mdc-icon-button.mat-primary,
.vex-theme-orange .light .mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #6200ee;
  --mat-mdc-button-ripple-color: rgba(98, 0, 238, 0.1);
}
.vex-theme-orange .mat-mdc-icon-button.mat-accent,
.vex-theme-orange.light .mat-mdc-icon-button.mat-accent,
.vex-theme-orange .light .mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #018786;
  --mat-mdc-button-ripple-color: rgba(1, 135, 134, 0.1);
}
.vex-theme-orange .mat-mdc-icon-button.mat-warn,
.vex-theme-orange.light .mat-mdc-icon-button.mat-warn,
.vex-theme-orange .light .mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #b00020;
  --mat-mdc-button-ripple-color: rgba(176, 0, 32, 0.1);
}
.vex-theme-orange .mat-mdc-icon-button.mat-primary,
.vex-theme-orange.light .mat-mdc-icon-button.mat-primary,
.vex-theme-orange .light .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #ea580c;
  --mat-mdc-button-persistent-ripple-color: #ea580c;
  --mat-mdc-button-ripple-color: rgba(234, 88, 12, 0.1);
}
.vex-theme-orange .mat-mdc-icon-button.mat-accent,
.vex-theme-orange.light .mat-mdc-icon-button.mat-accent,
.vex-theme-orange .light .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #f59e0b;
  --mat-mdc-button-persistent-ripple-color: #f59e0b;
  --mat-mdc-button-ripple-color: rgba(245, 158, 11, 0.1);
}
.vex-theme-orange .mat-mdc-icon-button.mat-warn,
.vex-theme-orange.light .mat-mdc-icon-button.mat-warn,
.vex-theme-orange .light .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #ef4444;
  --mat-mdc-button-persistent-ripple-color: #ef4444;
  --mat-mdc-button-ripple-color: rgba(239, 68, 68, 0.1);
}
.vex-theme-orange .mat-mdc-icon-button[disabled][disabled],
.vex-theme-orange.light .mat-mdc-icon-button[disabled][disabled],
.vex-theme-orange .light .mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
}
.vex-theme-orange .mat-mdc-fab,
.vex-theme-orange .mat-mdc-mini-fab,
.vex-theme-orange.light .mat-mdc-fab,
.vex-theme-orange.light .mat-mdc-mini-fab,
.vex-theme-orange .light .mat-mdc-fab,
.vex-theme-orange .light .mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.vex-theme-orange .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange.light .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange.light .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange .light .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange .light .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.vex-theme-orange .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-orange .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange.light .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange.light .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange.light .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange.light .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange .light .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange .light .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange .light .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange .light .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.vex-theme-orange .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange.light .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange.light .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange .light .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange .light .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.vex-theme-orange .mat-mdc-fab.mat-primary,
.vex-theme-orange .mat-mdc-mini-fab.mat-primary,
.vex-theme-orange.light .mat-mdc-fab.mat-primary,
.vex-theme-orange.light .mat-mdc-mini-fab.mat-primary,
.vex-theme-orange .light .mat-mdc-fab.mat-primary,
.vex-theme-orange .light .mat-mdc-mini-fab.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.vex-theme-orange .mat-mdc-fab.mat-accent,
.vex-theme-orange .mat-mdc-mini-fab.mat-accent,
.vex-theme-orange.light .mat-mdc-fab.mat-accent,
.vex-theme-orange.light .mat-mdc-mini-fab.mat-accent,
.vex-theme-orange .light .mat-mdc-fab.mat-accent,
.vex-theme-orange .light .mat-mdc-mini-fab.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.vex-theme-orange .mat-mdc-fab.mat-warn,
.vex-theme-orange .mat-mdc-mini-fab.mat-warn,
.vex-theme-orange.light .mat-mdc-fab.mat-warn,
.vex-theme-orange.light .mat-mdc-mini-fab.mat-warn,
.vex-theme-orange .light .mat-mdc-fab.mat-warn,
.vex-theme-orange .light .mat-mdc-mini-fab.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.vex-theme-orange .mat-mdc-fab[disabled][disabled],
.vex-theme-orange .mat-mdc-mini-fab[disabled][disabled],
.vex-theme-orange.light .mat-mdc-fab[disabled][disabled],
.vex-theme-orange.light .mat-mdc-mini-fab[disabled][disabled],
.vex-theme-orange .light .mat-mdc-fab[disabled][disabled],
.vex-theme-orange .light .mat-mdc-mini-fab[disabled][disabled] {
  --mdc-fab-container-color: rgba(0, 0, 0, 0.12);
  --mdc-fab-icon-color: rgba(0, 0, 0, 0.38);
  --mat-mdc-fab-color: rgba(0, 0, 0, 0.38);
}
.vex-theme-orange .mat-mdc-fab.mat-unthemed,
.vex-theme-orange .mat-mdc-mini-fab.mat-unthemed,
.vex-theme-orange.light .mat-mdc-fab.mat-unthemed,
.vex-theme-orange.light .mat-mdc-mini-fab.mat-unthemed,
.vex-theme-orange .light .mat-mdc-fab.mat-unthemed,
.vex-theme-orange .light .mat-mdc-mini-fab.mat-unthemed {
  --mdc-fab-container-color: white;
  --mdc-fab-icon-color: black;
  --mat-mdc-fab-color: #000;
}
.vex-theme-orange .mat-mdc-fab.mat-primary,
.vex-theme-orange .mat-mdc-mini-fab.mat-primary,
.vex-theme-orange.light .mat-mdc-fab.mat-primary,
.vex-theme-orange.light .mat-mdc-mini-fab.mat-primary,
.vex-theme-orange .light .mat-mdc-fab.mat-primary,
.vex-theme-orange .light .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #ea580c;
  --mdc-fab-icon-color: white;
  --mat-mdc-fab-color: #fff;
}
.vex-theme-orange .mat-mdc-fab.mat-accent,
.vex-theme-orange .mat-mdc-mini-fab.mat-accent,
.vex-theme-orange.light .mat-mdc-fab.mat-accent,
.vex-theme-orange.light .mat-mdc-mini-fab.mat-accent,
.vex-theme-orange .light .mat-mdc-fab.mat-accent,
.vex-theme-orange .light .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: #f59e0b;
  --mdc-fab-icon-color: black;
  --mat-mdc-fab-color: #000;
}
.vex-theme-orange .mat-mdc-fab.mat-warn,
.vex-theme-orange .mat-mdc-mini-fab.mat-warn,
.vex-theme-orange.light .mat-mdc-fab.mat-warn,
.vex-theme-orange.light .mat-mdc-mini-fab.mat-warn,
.vex-theme-orange .light .mat-mdc-fab.mat-warn,
.vex-theme-orange .light .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: #ef4444;
  --mdc-fab-icon-color: white;
  --mat-mdc-fab-color: #fff;
}
.vex-theme-orange .mat-mdc-snack-bar-container,
.vex-theme-orange.light .mat-mdc-snack-bar-container,
.vex-theme-orange .light .mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #f59e0b;
}
.vex-theme-orange .mat-mdc-progress-spinner,
.vex-theme-orange.light .mat-mdc-progress-spinner,
.vex-theme-orange .light .mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #ea580c;
}
.vex-theme-orange .mat-mdc-progress-spinner.mat-accent,
.vex-theme-orange.light .mat-mdc-progress-spinner.mat-accent,
.vex-theme-orange .light .mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: #f59e0b;
}
.vex-theme-orange .mat-mdc-progress-spinner.mat-warn,
.vex-theme-orange.light .mat-mdc-progress-spinner.mat-warn,
.vex-theme-orange .light .mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: #ef4444;
}
.vex-theme-orange, .vex-theme-orange.light, .vex-theme-orange .light {
  --mat-badge-background-color: #ea580c;
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: #9ca3af;
  --mat-badge-disabled-state-text-color: #6b7280;
}

.vex-theme-orange .mat-badge-accent,
.vex-theme-orange.light .mat-badge-accent,
.vex-theme-orange .light .mat-badge-accent {
  --mat-badge-background-color: #f59e0b;
  --mat-badge-text-color: white;
}
.vex-theme-orange .mat-badge-warn,
.vex-theme-orange.light .mat-badge-warn,
.vex-theme-orange .light .mat-badge-warn {
  --mat-badge-background-color: #ef4444;
  --mat-badge-text-color: white;
}
.vex-theme-orange, .vex-theme-orange.light, .vex-theme-orange .light {
  --mat-bottom-sheet-container-text-color: #030712;
  --mat-bottom-sheet-container-background-color: white;
}

.vex-theme-orange, .vex-theme-orange.light, .vex-theme-orange .light {
  --mat-legacy-button-toggle-text-color: #6b7280;
  --mat-legacy-button-toggle-state-layer-color: #e5e7eb;
  --mat-legacy-button-toggle-selected-state-text-color: #4b5563;
  --mat-legacy-button-toggle-selected-state-background-color: #d1d5db;
  --mat-legacy-button-toggle-disabled-state-text-color: #9ca3af;
  --mat-legacy-button-toggle-disabled-state-background-color: #e5e7eb;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #9ca3af;
  --mat-standard-button-toggle-text-color: #030712;
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: #e5e7eb;
  --mat-standard-button-toggle-selected-state-background-color: #d1d5db;
  --mat-standard-button-toggle-selected-state-text-color: #030712;
  --mat-standard-button-toggle-disabled-state-text-color: #9ca3af;
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: #030712;
  --mat-standard-button-toggle-disabled-selected-state-background-color: #9ca3af;
  --mat-standard-button-toggle-divider-color: #f0f0f0;
}

.vex-theme-orange, .vex-theme-orange.light, .vex-theme-orange .light {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #ea580c;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(234, 88, 12, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(234, 88, 12, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(234, 88, 12, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #ea580c;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(234, 88, 12, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: #1f2937;
  --mat-datepicker-calendar-body-label-text-color: #4b5563;
  --mat-datepicker-calendar-period-button-icon-color: #1f2937;
  --mat-datepicker-calendar-navigation-button-icon-color: #1f2937;
  --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.06);
  --mat-datepicker-calendar-header-text-color: #4b5563;
  --mat-datepicker-calendar-date-today-outline-color: #6b7280;
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(107, 114, 128, 0.8);
  --mat-datepicker-calendar-date-text-color: #030712;
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: #6b7280;
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, 0.12);
  --mat-datepicker-range-input-separator-color: #030712;
  --mat-datepicker-range-input-disabled-state-separator-color: #6b7280;
  --mat-datepicker-range-input-disabled-state-text-color: #6b7280;
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: #030712;
}

.vex-theme-orange .mat-datepicker-content.mat-accent,
.vex-theme-orange.light .mat-datepicker-content.mat-accent,
.vex-theme-orange .light .mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #f59e0b;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(245, 158, 11, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(245, 158, 11, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(245, 158, 11, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(245, 158, 11, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.vex-theme-orange .mat-datepicker-content.mat-warn,
.vex-theme-orange.light .mat-datepicker-content.mat-warn,
.vex-theme-orange .light .mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #ef4444;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(239, 68, 68, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(239, 68, 68, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(239, 68, 68, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(239, 68, 68, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.vex-theme-orange .mat-datepicker-toggle-active.mat-accent,
.vex-theme-orange.light .mat-datepicker-toggle-active.mat-accent,
.vex-theme-orange .light .mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #f59e0b;
}
.vex-theme-orange .mat-datepicker-toggle-active.mat-warn,
.vex-theme-orange.light .mat-datepicker-toggle-active.mat-warn,
.vex-theme-orange .light .mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #ef4444;
}
.vex-theme-orange, .vex-theme-orange.light, .vex-theme-orange .light {
  --mat-divider-color: rgba(0, 0, 0, 0.06);
}

.vex-theme-orange, .vex-theme-orange.light, .vex-theme-orange .light {
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: #030712;
  --mat-expansion-actions-divider-color: rgba(0, 0, 0, 0.06);
  --mat-expansion-header-hover-state-layer-color: rgba(17, 24, 39, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(17, 24, 39, 0.04);
  --mat-expansion-header-disabled-state-text-color: #9ca3af;
  --mat-expansion-header-text-color: #030712;
  --mat-expansion-header-description-color: #4b5563;
  --mat-expansion-header-indicator-color: #4b5563;
}

.vex-theme-orange, .vex-theme-orange.light, .vex-theme-orange .light {
  --mat-icon-color: inherit;
}

.vex-theme-orange .mat-icon.mat-primary,
.vex-theme-orange.light .mat-icon.mat-primary,
.vex-theme-orange .light .mat-icon.mat-primary {
  --mat-icon-color: #ea580c;
}
.vex-theme-orange .mat-icon.mat-accent,
.vex-theme-orange.light .mat-icon.mat-accent,
.vex-theme-orange .light .mat-icon.mat-accent {
  --mat-icon-color: #f59e0b;
}
.vex-theme-orange .mat-icon.mat-warn,
.vex-theme-orange.light .mat-icon.mat-warn,
.vex-theme-orange .light .mat-icon.mat-warn {
  --mat-icon-color: #ef4444;
}
.vex-theme-orange, .vex-theme-orange.light, .vex-theme-orange .light {
  --mat-sidenav-container-divider-color: rgba(0, 0, 0, 0.06);
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: #030712;
  --mat-sidenav-content-background-color: #f3f4f6;
  --mat-sidenav-content-text-color: #030712;
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
}

.vex-theme-orange, .vex-theme-orange.light, .vex-theme-orange .light {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #ea580c;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #ea580c;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #ea580c;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: rgba(0, 0, 0, 0.06);
  --mat-stepper-header-hover-state-layer-color: rgba(17, 24, 39, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(17, 24, 39, 0.04);
  --mat-stepper-header-label-text-color: #4b5563;
  --mat-stepper-header-optional-label-text-color: #4b5563;
  --mat-stepper-header-selected-state-label-text-color: #030712;
  --mat-stepper-header-error-state-label-text-color: #ef4444;
  --mat-stepper-header-icon-background-color: #4b5563;
  --mat-stepper-header-error-state-icon-foreground-color: #ef4444;
  --mat-stepper-header-error-state-icon-background-color: transparent;
}
.vex-theme-orange .mat-step-header.mat-accent, .vex-theme-orange.light .mat-step-header.mat-accent, .vex-theme-orange .light .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #f59e0b;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #f59e0b;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #f59e0b;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.vex-theme-orange .mat-step-header.mat-warn, .vex-theme-orange.light .mat-step-header.mat-warn, .vex-theme-orange .light .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #ef4444;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #ef4444;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #ef4444;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}

.vex-theme-orange .mat-sort-header-arrow,
.vex-theme-orange.light .mat-sort-header-arrow,
.vex-theme-orange .light .mat-sort-header-arrow {
  color: #4b5563;
}
.vex-theme-orange, .vex-theme-orange.light, .vex-theme-orange .light {
  --mat-toolbar-container-background-color: #e5e7eb;
  --mat-toolbar-container-text-color: #030712;
}

.vex-theme-orange .mat-toolbar.mat-primary,
.vex-theme-orange.light .mat-toolbar.mat-primary,
.vex-theme-orange .light .mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #ea580c;
  --mat-toolbar-container-text-color: white;
}
.vex-theme-orange .mat-toolbar.mat-accent,
.vex-theme-orange.light .mat-toolbar.mat-accent,
.vex-theme-orange .light .mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #f59e0b;
  --mat-toolbar-container-text-color: white;
}
.vex-theme-orange .mat-toolbar.mat-warn,
.vex-theme-orange.light .mat-toolbar.mat-warn,
.vex-theme-orange .light .mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #ef4444;
  --mat-toolbar-container-text-color: white;
}
.vex-theme-orange .mat-tree,
.vex-theme-orange.light .mat-tree,
.vex-theme-orange .light .mat-tree {
  background: white;
}
.vex-theme-orange .mat-tree-node,
.vex-theme-orange .mat-nested-tree-node,
.vex-theme-orange.light .mat-tree-node,
.vex-theme-orange.light .mat-nested-tree-node,
.vex-theme-orange .light .mat-tree-node,
.vex-theme-orange .light .mat-nested-tree-node {
  color: #030712;
}

/**
 * Override the theme foreground/background colors with the Angular Material colors
 */
.vex-theme-orange.dark,
.vex-theme-orange .dark {
  --mat-table-background-color: #1f2937;
  --mat-table-header-headline-color: white;
  --mat-table-row-item-label-text-color: white;
  --mat-table-row-item-outline-color: rgba(255, 255, 255, 0.12);
}
.vex-theme-orange.dark .mat-ripple-element,
.vex-theme-orange .dark .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.vex-theme-orange.dark, .vex-theme-orange .dark {
  --mat-option-selected-state-label-text-color: #f97316;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
}

.vex-theme-orange.dark .mat-accent,
.vex-theme-orange .dark .mat-accent {
  --mat-option-selected-state-label-text-color: #f59e0b;
}
.vex-theme-orange.dark .mat-warn,
.vex-theme-orange .dark .mat-warn {
  --mat-option-selected-state-label-text-color: #ef4444;
}
.vex-theme-orange.dark, .vex-theme-orange .dark {
  --mat-optgroup-label-text-color: white;
}

.vex-theme-orange.dark .mat-pseudo-checkbox-full,
.vex-theme-orange .dark .mat-pseudo-checkbox-full {
  color: #9ca3af;
}
.vex-theme-orange.dark .mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled,
.vex-theme-orange .dark .mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled {
  color: #686868;
}
.vex-theme-orange.dark .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-orange.dark .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-orange .dark .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-orange .dark .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #f97316;
}
.vex-theme-orange.dark .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-orange.dark .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-orange .dark .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-orange .dark .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #f97316;
}
.vex-theme-orange.dark .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-orange.dark .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after,
.vex-theme-orange .dark .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-orange .dark .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #111827;
}
.vex-theme-orange.dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-orange.dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-orange .dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-orange .dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #f59e0b;
}
.vex-theme-orange.dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-orange.dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-orange .dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-orange .dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #f59e0b;
}
.vex-theme-orange.dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-orange.dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after,
.vex-theme-orange .dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-orange .dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #111827;
}
.vex-theme-orange.dark .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-orange.dark .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-orange .dark .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-orange .dark .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #f59e0b;
}
.vex-theme-orange.dark .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-orange.dark .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-orange .dark .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-orange .dark .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #f59e0b;
}
.vex-theme-orange.dark .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-orange.dark .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after,
.vex-theme-orange .dark .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-orange .dark .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #111827;
}
.vex-theme-orange.dark .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-orange.dark .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-orange .dark .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-orange .dark .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #ef4444;
}
.vex-theme-orange.dark .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-orange.dark .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-orange .dark .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-orange .dark .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #ef4444;
}
.vex-theme-orange.dark .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-orange.dark .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after,
.vex-theme-orange .dark .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-theme-orange .dark .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #111827;
}
.vex-theme-orange.dark .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-orange.dark .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.vex-theme-orange .dark .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-theme-orange .dark .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #686868;
}
.vex-theme-orange.dark .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-orange.dark .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.vex-theme-orange .dark .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-theme-orange .dark .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #686868;
}
.vex-theme-orange.dark .mat-app-background, .vex-theme-orange.dark.mat-app-background,
.vex-theme-orange .dark .mat-app-background,
.vex-theme-orange .dark.mat-app-background {
  background-color: #111827;
  color: white;
}
.vex-theme-orange.dark .mat-elevation-z0, .vex-theme-orange.dark .mat-mdc-elevation-specific.mat-elevation-z0,
.vex-theme-orange .dark .mat-elevation-z0,
.vex-theme-orange .dark .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-orange.dark .mat-elevation-z1, .vex-theme-orange.dark .mat-mdc-elevation-specific.mat-elevation-z1,
.vex-theme-orange .dark .mat-elevation-z1,
.vex-theme-orange .dark .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-orange.dark .mat-elevation-z2, .vex-theme-orange.dark .mat-mdc-elevation-specific.mat-elevation-z2,
.vex-theme-orange .dark .mat-elevation-z2,
.vex-theme-orange .dark .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-orange.dark .mat-elevation-z3, .vex-theme-orange.dark .mat-mdc-elevation-specific.mat-elevation-z3,
.vex-theme-orange .dark .mat-elevation-z3,
.vex-theme-orange .dark .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-orange.dark .mat-elevation-z4, .vex-theme-orange.dark .mat-mdc-elevation-specific.mat-elevation-z4,
.vex-theme-orange .dark .mat-elevation-z4,
.vex-theme-orange .dark .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-orange.dark .mat-elevation-z5, .vex-theme-orange.dark .mat-mdc-elevation-specific.mat-elevation-z5,
.vex-theme-orange .dark .mat-elevation-z5,
.vex-theme-orange .dark .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-orange.dark .mat-elevation-z6, .vex-theme-orange.dark .mat-mdc-elevation-specific.mat-elevation-z6,
.vex-theme-orange .dark .mat-elevation-z6,
.vex-theme-orange .dark .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.vex-theme-orange.dark .mat-elevation-z7, .vex-theme-orange.dark .mat-mdc-elevation-specific.mat-elevation-z7,
.vex-theme-orange .dark .mat-elevation-z7,
.vex-theme-orange .dark .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.vex-theme-orange.dark .mat-elevation-z8, .vex-theme-orange.dark .mat-mdc-elevation-specific.mat-elevation-z8,
.vex-theme-orange .dark .mat-elevation-z8,
.vex-theme-orange .dark .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.vex-theme-orange.dark .mat-elevation-z9, .vex-theme-orange.dark .mat-mdc-elevation-specific.mat-elevation-z9,
.vex-theme-orange .dark .mat-elevation-z9,
.vex-theme-orange .dark .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.vex-theme-orange.dark .mat-elevation-z10, .vex-theme-orange.dark .mat-mdc-elevation-specific.mat-elevation-z10,
.vex-theme-orange .dark .mat-elevation-z10,
.vex-theme-orange .dark .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.vex-theme-orange.dark .mat-elevation-z11, .vex-theme-orange.dark .mat-mdc-elevation-specific.mat-elevation-z11,
.vex-theme-orange .dark .mat-elevation-z11,
.vex-theme-orange .dark .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.vex-theme-orange.dark .mat-elevation-z12, .vex-theme-orange.dark .mat-mdc-elevation-specific.mat-elevation-z12,
.vex-theme-orange .dark .mat-elevation-z12,
.vex-theme-orange .dark .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.vex-theme-orange.dark .mat-elevation-z13, .vex-theme-orange.dark .mat-mdc-elevation-specific.mat-elevation-z13,
.vex-theme-orange .dark .mat-elevation-z13,
.vex-theme-orange .dark .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.vex-theme-orange.dark .mat-elevation-z14, .vex-theme-orange.dark .mat-mdc-elevation-specific.mat-elevation-z14,
.vex-theme-orange .dark .mat-elevation-z14,
.vex-theme-orange .dark .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.vex-theme-orange.dark .mat-elevation-z15, .vex-theme-orange.dark .mat-mdc-elevation-specific.mat-elevation-z15,
.vex-theme-orange .dark .mat-elevation-z15,
.vex-theme-orange .dark .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.vex-theme-orange.dark .mat-elevation-z16, .vex-theme-orange.dark .mat-mdc-elevation-specific.mat-elevation-z16,
.vex-theme-orange .dark .mat-elevation-z16,
.vex-theme-orange .dark .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.vex-theme-orange.dark .mat-elevation-z17, .vex-theme-orange.dark .mat-mdc-elevation-specific.mat-elevation-z17,
.vex-theme-orange .dark .mat-elevation-z17,
.vex-theme-orange .dark .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.vex-theme-orange.dark .mat-elevation-z18, .vex-theme-orange.dark .mat-mdc-elevation-specific.mat-elevation-z18,
.vex-theme-orange .dark .mat-elevation-z18,
.vex-theme-orange .dark .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.vex-theme-orange.dark .mat-elevation-z19, .vex-theme-orange.dark .mat-mdc-elevation-specific.mat-elevation-z19,
.vex-theme-orange .dark .mat-elevation-z19,
.vex-theme-orange .dark .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.vex-theme-orange.dark .mat-elevation-z20, .vex-theme-orange.dark .mat-mdc-elevation-specific.mat-elevation-z20,
.vex-theme-orange .dark .mat-elevation-z20,
.vex-theme-orange .dark .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.vex-theme-orange.dark .mat-elevation-z21, .vex-theme-orange.dark .mat-mdc-elevation-specific.mat-elevation-z21,
.vex-theme-orange .dark .mat-elevation-z21,
.vex-theme-orange .dark .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.vex-theme-orange.dark .mat-elevation-z22, .vex-theme-orange.dark .mat-mdc-elevation-specific.mat-elevation-z22,
.vex-theme-orange .dark .mat-elevation-z22,
.vex-theme-orange .dark .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.vex-theme-orange.dark .mat-elevation-z23, .vex-theme-orange.dark .mat-mdc-elevation-specific.mat-elevation-z23,
.vex-theme-orange .dark .mat-elevation-z23,
.vex-theme-orange .dark .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.vex-theme-orange.dark .mat-elevation-z24, .vex-theme-orange.dark .mat-mdc-elevation-specific.mat-elevation-z24,
.vex-theme-orange .dark .mat-elevation-z24,
.vex-theme-orange .dark .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.vex-theme-orange.dark .mat-mdc-card,
.vex-theme-orange .dark .mat-mdc-card {
  --mdc-elevated-card-container-color: #1f2937;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: #1f2937;
  --mdc-outlined-card-outline-color: rgba(255, 255, 255, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: #9ca3af;
}
.vex-theme-orange.dark .mat-mdc-progress-bar,
.vex-theme-orange .dark .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #f97316;
  --mdc-linear-progress-track-color: rgba(249, 115, 22, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.vex-theme-orange.dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots,
.vex-theme-orange .dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-color: rgba(249, 115, 22, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(249, 115, 22, 0.25));
}
@media (forced-colors: active) {
  .vex-theme-orange.dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots,
  .vex-theme-orange .dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .vex-theme-orange.dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots,
  .vex-theme-orange .dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(249, 115, 22, 0.25)'/%3E%3C/svg%3E");
  }
}
.vex-theme-orange.dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar,
.vex-theme-orange .dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgba(249, 115, 22, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(249, 115, 22, 0.25));
}
.vex-theme-orange.dark .mat-mdc-progress-bar.mat-accent,
.vex-theme-orange .dark .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #f59e0b;
  --mdc-linear-progress-track-color: rgba(245, 158, 11, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.vex-theme-orange.dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots,
.vex-theme-orange .dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-color: rgba(245, 158, 11, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(245, 158, 11, 0.25));
}
@media (forced-colors: active) {
  .vex-theme-orange.dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots,
  .vex-theme-orange .dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .vex-theme-orange.dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots,
  .vex-theme-orange .dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(245, 158, 11, 0.25)'/%3E%3C/svg%3E");
  }
}
.vex-theme-orange.dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar,
.vex-theme-orange .dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: rgba(245, 158, 11, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(245, 158, 11, 0.25));
}
.vex-theme-orange.dark .mat-mdc-progress-bar.mat-warn,
.vex-theme-orange .dark .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #ef4444;
  --mdc-linear-progress-track-color: rgba(239, 68, 68, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.vex-theme-orange.dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots,
.vex-theme-orange .dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-color: rgba(239, 68, 68, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(239, 68, 68, 0.25));
}
@media (forced-colors: active) {
  .vex-theme-orange.dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots,
  .vex-theme-orange .dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .vex-theme-orange.dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots,
  .vex-theme-orange .dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(239, 68, 68, 0.25)'/%3E%3C/svg%3E");
  }
}
.vex-theme-orange.dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar,
.vex-theme-orange .dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: rgba(239, 68, 68, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(239, 68, 68, 0.25));
}
.vex-theme-orange.dark .mat-mdc-tooltip,
.vex-theme-orange .dark .mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #374151;
  --mdc-plain-tooltip-supporting-text-color: #fff;
}
.vex-theme-orange.dark, .vex-theme-orange .dark {
  --mdc-filled-text-field-caret-color: #f97316;
  --mdc-filled-text-field-focus-active-indicator-color: #f97316;
  --mdc-filled-text-field-focus-label-text-color: rgba(249, 115, 22, 0.87);
  --mdc-filled-text-field-container-color: #28323f;
  --mdc-filled-text-field-disabled-container-color: #232d3b;
  --mdc-filled-text-field-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(255, 255, 255, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(255, 255, 255, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(255, 255, 255, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-error-focus-label-text-color: #ef4444;
  --mdc-filled-text-field-error-label-text-color: #ef4444;
  --mdc-filled-text-field-error-caret-color: #ef4444;
  --mdc-filled-text-field-active-indicator-color: rgba(255, 255, 255, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(255, 255, 255, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(255, 255, 255, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #ef4444;
  --mdc-filled-text-field-error-focus-active-indicator-color: #ef4444;
  --mdc-filled-text-field-error-hover-active-indicator-color: #ef4444;
  --mdc-outlined-text-field-caret-color: #f97316;
  --mdc-outlined-text-field-focus-outline-color: #f97316;
  --mdc-outlined-text-field-focus-label-text-color: rgba(249, 115, 22, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(255, 255, 255, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(255, 255, 255, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(255, 255, 255, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-error-caret-color: #ef4444;
  --mdc-outlined-text-field-error-focus-label-text-color: #ef4444;
  --mdc-outlined-text-field-error-label-text-color: #ef4444;
  --mdc-outlined-text-field-outline-color: rgba(255, 255, 255, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(255, 255, 255, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(255, 255, 255, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #ef4444;
  --mdc-outlined-text-field-error-hover-outline-color: #ef4444;
  --mdc-outlined-text-field-error-outline-color: #ef4444;
  --mat-form-field-disabled-input-text-placeholder-color: rgba(255, 255, 255, 0.38);
}

.vex-theme-orange.dark .mat-mdc-form-field-error,
.vex-theme-orange .dark .mat-mdc-form-field-error {
  color: var(--mdc-theme-error, #ef4444);
}
.vex-theme-orange.dark .mat-mdc-form-field-subscript-wrapper,
.vex-theme-orange.dark .mat-mdc-form-field-bottom-align::before,
.vex-theme-orange .dark .mat-mdc-form-field-subscript-wrapper,
.vex-theme-orange .dark .mat-mdc-form-field-bottom-align::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mat-form-field-subscript-text-font);
  line-height: var(--mat-form-field-subscript-text-line-height);
  font-size: var(--mat-form-field-subscript-text-size);
  letter-spacing: var(--mat-form-field-subscript-text-tracking);
  font-weight: var(--mat-form-field-subscript-text-weight);
}
.vex-theme-orange.dark .mat-mdc-form-field-focus-overlay,
.vex-theme-orange .dark .mat-mdc-form-field-focus-overlay {
  background-color: rgba(255, 255, 255, 0.87);
}
.vex-theme-orange.dark .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay,
.vex-theme-orange .dark .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0.08;
}
.vex-theme-orange.dark .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay,
.vex-theme-orange .dark .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.24;
}
.vex-theme-orange.dark select.mat-mdc-form-field-input-control:not(.mat-mdc-native-select-inline) option,
.vex-theme-orange .dark select.mat-mdc-form-field-input-control:not(.mat-mdc-native-select-inline) option {
  color: rgba(0, 0, 0, 0.87);
}
.vex-theme-orange.dark select.mat-mdc-form-field-input-control:not(.mat-mdc-native-select-inline) option:disabled,
.vex-theme-orange .dark select.mat-mdc-form-field-input-control:not(.mat-mdc-native-select-inline) option:disabled {
  color: rgba(0, 0, 0, 0.38);
}
.vex-theme-orange.dark .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after,
.vex-theme-orange .dark .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
  color: rgba(255, 255, 255, 0.54);
}
.vex-theme-orange.dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after,
.vex-theme-orange .dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after {
  color: rgba(249, 115, 22, 0.87);
}
.vex-theme-orange.dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after,
.vex-theme-orange .dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after {
  color: rgba(245, 158, 11, 0.87);
}
.vex-theme-orange.dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after,
.vex-theme-orange .dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after {
  color: rgba(239, 68, 68, 0.87);
}
.vex-theme-orange.dark .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after,
.vex-theme-orange .dark .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  color: rgba(255, 255, 255, 0.38);
}
.vex-theme-orange.dark .mat-mdc-form-field.mat-accent,
.vex-theme-orange .dark .mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #f59e0b;
  --mdc-filled-text-field-focus-active-indicator-color: #f59e0b;
  --mdc-filled-text-field-focus-label-text-color: rgba(245, 158, 11, 0.87);
  --mdc-outlined-text-field-caret-color: #f59e0b;
  --mdc-outlined-text-field-focus-outline-color: #f59e0b;
  --mdc-outlined-text-field-focus-label-text-color: rgba(245, 158, 11, 0.87);
}
.vex-theme-orange.dark .mat-mdc-form-field.mat-warn,
.vex-theme-orange .dark .mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #ef4444;
  --mdc-filled-text-field-focus-active-indicator-color: #ef4444;
  --mdc-filled-text-field-focus-label-text-color: rgba(239, 68, 68, 0.87);
  --mdc-outlined-text-field-caret-color: #ef4444;
  --mdc-outlined-text-field-focus-outline-color: #ef4444;
  --mdc-outlined-text-field-focus-label-text-color: rgba(239, 68, 68, 0.87);
}
.vex-theme-orange.dark .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch,
.vex-theme-orange .dark .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: 1px solid transparent;
}
.vex-theme-orange.dark [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch,
.vex-theme-orange .dark [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none;
  border-right: 1px solid transparent;
}
.vex-theme-orange.dark, .vex-theme-orange .dark {
  --mat-select-panel-background-color: #1f2937;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(255, 255, 255, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(255, 255, 255, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(249, 115, 22, 0.87);
  --mat-select-invalid-arrow-color: rgba(239, 68, 68, 0.87);
}
.vex-theme-orange.dark .mat-mdc-form-field.mat-accent, .vex-theme-orange .dark .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: #1f2937;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(255, 255, 255, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(255, 255, 255, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(245, 158, 11, 0.87);
  --mat-select-invalid-arrow-color: rgba(239, 68, 68, 0.87);
}
.vex-theme-orange.dark .mat-mdc-form-field.mat-warn, .vex-theme-orange .dark .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: #1f2937;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(255, 255, 255, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(255, 255, 255, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(239, 68, 68, 0.87);
  --mat-select-invalid-arrow-color: rgba(239, 68, 68, 0.87);
}

.vex-theme-orange.dark, .vex-theme-orange .dark {
  --mat-autocomplete-background-color: #1f2937;
}

.vex-theme-orange.dark .mat-mdc-dialog-container,
.vex-theme-orange .dark .mat-mdc-dialog-container {
  --mdc-dialog-container-color: #1f2937;
  --mdc-dialog-subhead-color: rgba(255, 255, 255, 0.87);
  --mdc-dialog-supporting-text-color: rgba(255, 255, 255, 0.6);
}
.vex-theme-orange.dark .mat-mdc-standard-chip,
.vex-theme-orange .dark .mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #fafafa;
  --mdc-chip-elevated-container-color: #3a434f;
  --mdc-chip-elevated-disabled-container-color: #3a434f;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #fafafa;
  --mdc-chip-with-icon-icon-color: #fafafa;
  --mdc-chip-with-icon-disabled-icon-color: #fafafa;
  --mdc-chip-with-icon-selected-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #fafafa;
}
.vex-theme-orange.dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .vex-theme-orange.dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary,
.vex-theme-orange .dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary,
.vex-theme-orange .dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #f97316;
  --mdc-chip-elevated-disabled-container-color: #f97316;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.vex-theme-orange.dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .vex-theme-orange.dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent,
.vex-theme-orange .dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent,
.vex-theme-orange .dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #f59e0b;
  --mdc-chip-elevated-disabled-container-color: #f59e0b;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.vex-theme-orange.dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .vex-theme-orange.dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn,
.vex-theme-orange .dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn,
.vex-theme-orange .dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #ef4444;
  --mdc-chip-elevated-disabled-container-color: #ef4444;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.vex-theme-orange.dark .mat-mdc-slide-toggle,
.vex-theme-orange .dark .mat-mdc-slide-toggle {
  --mdc-switch-selected-focus-state-layer-color: #fdba74;
  --mdc-switch-selected-handle-color: #fdba74;
  --mdc-switch-selected-hover-state-layer-color: #fdba74;
  --mdc-switch-selected-pressed-state-layer-color: #fdba74;
  --mdc-switch-selected-focus-handle-color: #fed7aa;
  --mdc-switch-selected-hover-handle-color: #fed7aa;
  --mdc-switch-selected-pressed-handle-color: #fed7aa;
  --mdc-switch-selected-focus-track-color: #ea580c;
  --mdc-switch-selected-hover-track-color: #ea580c;
  --mdc-switch-selected-pressed-track-color: #ea580c;
  --mdc-switch-selected-track-color: #ea580c;
  --mdc-switch-disabled-selected-handle-color: #000;
  --mdc-switch-disabled-selected-icon-color: #212121;
  --mdc-switch-disabled-selected-track-color: #f5f5f5;
  --mdc-switch-disabled-unselected-handle-color: #000;
  --mdc-switch-disabled-unselected-icon-color: #212121;
  --mdc-switch-disabled-unselected-track-color: #f5f5f5;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #212121;
  --mdc-switch-unselected-focus-handle-color: #fafafa;
  --mdc-switch-unselected-focus-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-focus-track-color: #616161;
  --mdc-switch-unselected-handle-color: #9e9e9e;
  --mdc-switch-unselected-hover-handle-color: #fafafa;
  --mdc-switch-unselected-hover-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-hover-track-color: #616161;
  --mdc-switch-unselected-icon-color: #212121;
  --mdc-switch-unselected-pressed-handle-color: #fafafa;
  --mdc-switch-unselected-pressed-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-pressed-track-color: #616161;
  --mdc-switch-unselected-track-color: #616161;
}
.vex-theme-orange.dark .mat-mdc-slide-toggle .mdc-form-field,
.vex-theme-orange .dark .mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.vex-theme-orange.dark .mat-mdc-slide-toggle .mdc-switch--disabled + label,
.vex-theme-orange .dark .mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: #6b7280;
}
.vex-theme-orange.dark .mat-mdc-slide-toggle.mat-accent,
.vex-theme-orange .dark .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #fcd34d;
  --mdc-switch-selected-handle-color: #fcd34d;
  --mdc-switch-selected-hover-state-layer-color: #fcd34d;
  --mdc-switch-selected-pressed-state-layer-color: #fcd34d;
  --mdc-switch-selected-focus-handle-color: #fde68a;
  --mdc-switch-selected-hover-handle-color: #fde68a;
  --mdc-switch-selected-pressed-handle-color: #fde68a;
  --mdc-switch-selected-focus-track-color: #d97706;
  --mdc-switch-selected-hover-track-color: #d97706;
  --mdc-switch-selected-pressed-track-color: #d97706;
  --mdc-switch-selected-track-color: #d97706;
}
.vex-theme-orange.dark .mat-mdc-slide-toggle.mat-warn,
.vex-theme-orange .dark .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #fca5a5;
  --mdc-switch-selected-handle-color: #fca5a5;
  --mdc-switch-selected-hover-state-layer-color: #fca5a5;
  --mdc-switch-selected-pressed-state-layer-color: #fca5a5;
  --mdc-switch-selected-focus-handle-color: #fecaca;
  --mdc-switch-selected-hover-handle-color: #fecaca;
  --mdc-switch-selected-pressed-handle-color: #fecaca;
  --mdc-switch-selected-focus-track-color: #dc2626;
  --mdc-switch-selected-hover-track-color: #dc2626;
  --mdc-switch-selected-pressed-track-color: #dc2626;
  --mdc-switch-selected-track-color: #dc2626;
}
.vex-theme-orange.dark .mat-mdc-radio-button .mdc-form-field,
.vex-theme-orange .dark .mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.vex-theme-orange.dark .mat-mdc-radio-button.mat-primary,
.vex-theme-orange .dark .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #f97316;
  --mdc-radio-selected-hover-icon-color: #f97316;
  --mdc-radio-selected-icon-color: #f97316;
  --mdc-radio-selected-pressed-icon-color: #f97316;
  --mat-radio-ripple-color: #fff;
  --mat-radio-checked-ripple-color: #f97316;
  --mat-radio-disabled-label-color: #6b7280;
}
.vex-theme-orange.dark .mat-mdc-radio-button.mat-accent,
.vex-theme-orange .dark .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #f59e0b;
  --mdc-radio-selected-hover-icon-color: #f59e0b;
  --mdc-radio-selected-icon-color: #f59e0b;
  --mdc-radio-selected-pressed-icon-color: #f59e0b;
  --mat-radio-ripple-color: #fff;
  --mat-radio-checked-ripple-color: #f59e0b;
  --mat-radio-disabled-label-color: #6b7280;
}
.vex-theme-orange.dark .mat-mdc-radio-button.mat-warn,
.vex-theme-orange .dark .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #ef4444;
  --mdc-radio-selected-hover-icon-color: #ef4444;
  --mdc-radio-selected-icon-color: #ef4444;
  --mdc-radio-selected-pressed-icon-color: #ef4444;
  --mat-radio-ripple-color: #fff;
  --mat-radio-checked-ripple-color: #ef4444;
  --mat-radio-disabled-label-color: #6b7280;
}
.vex-theme-orange.dark .mat-mdc-slider,
.vex-theme-orange .dark .mat-mdc-slider {
  --mdc-slider-label-container-color: white;
  --mdc-slider-label-label-text-color: black;
  --mdc-slider-disabled-handle-color: #fff;
  --mdc-slider-disabled-active-track-color: #fff;
  --mdc-slider-disabled-inactive-track-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #fff;
  --mat-mdc-slider-value-indicator-opacity: 0.9;
}
.vex-theme-orange.dark .mat-mdc-slider.mat-primary,
.vex-theme-orange .dark .mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #f97316;
  --mdc-slider-focus-handle-color: #f97316;
  --mdc-slider-hover-handle-color: #f97316;
  --mdc-slider-active-track-color: #f97316;
  --mdc-slider-inactive-track-color: #f97316;
  --mdc-slider-with-tick-marks-active-container-color: #000;
  --mdc-slider-with-tick-marks-inactive-container-color: #f97316;
  --mat-mdc-slider-ripple-color: #f97316;
  --mat-mdc-slider-hover-ripple-color: rgba(249, 115, 22, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(249, 115, 22, 0.2);
}
.vex-theme-orange.dark .mat-mdc-slider.mat-accent,
.vex-theme-orange .dark .mat-mdc-slider.mat-accent {
  --mdc-slider-handle-color: #f59e0b;
  --mdc-slider-focus-handle-color: #f59e0b;
  --mdc-slider-hover-handle-color: #f59e0b;
  --mdc-slider-active-track-color: #f59e0b;
  --mdc-slider-inactive-track-color: #f59e0b;
  --mdc-slider-with-tick-marks-active-container-color: #000;
  --mdc-slider-with-tick-marks-inactive-container-color: #f59e0b;
  --mat-mdc-slider-ripple-color: #f59e0b;
  --mat-mdc-slider-hover-ripple-color: rgba(245, 158, 11, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(245, 158, 11, 0.2);
}
.vex-theme-orange.dark .mat-mdc-slider.mat-warn,
.vex-theme-orange .dark .mat-mdc-slider.mat-warn {
  --mdc-slider-handle-color: #ef4444;
  --mdc-slider-focus-handle-color: #ef4444;
  --mdc-slider-hover-handle-color: #ef4444;
  --mdc-slider-active-track-color: #ef4444;
  --mdc-slider-inactive-track-color: #ef4444;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #ef4444;
  --mat-mdc-slider-ripple-color: #ef4444;
  --mat-mdc-slider-hover-ripple-color: rgba(239, 68, 68, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(239, 68, 68, 0.2);
}
.vex-theme-orange.dark, .vex-theme-orange .dark {
  --mat-menu-item-label-text-color: white;
  --mat-menu-item-icon-color: white;
  --mat-menu-item-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-menu-item-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-menu-container-color: #1f2937;
}

.vex-theme-orange.dark .mat-mdc-list-base,
.vex-theme-orange .dark .mat-mdc-list-base {
  --mdc-list-list-item-label-text-color: white;
  --mdc-list-list-item-supporting-text-color: #9ca3af;
  --mdc-list-list-item-leading-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-trailing-supporting-text-color: #6b7280;
  --mdc-list-list-item-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-disabled-label-text-color: white;
  --mdc-list-list-item-disabled-leading-icon-color: white;
  --mdc-list-list-item-disabled-trailing-icon-color: white;
  --mdc-list-list-item-hover-label-text-color: white;
  --mdc-list-list-item-hover-leading-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-focus-label-text-color: white;
  --mdc-list-list-item-hover-state-layer-color: white;
  --mdc-list-list-item-hover-state-layer-opacity: 0.08;
  --mdc-list-list-item-focus-state-layer-color: white;
  --mdc-list-list-item-focus-state-layer-opacity: 0.24;
}
.vex-theme-orange.dark .mdc-list-item__start,
.vex-theme-orange.dark .mdc-list-item__end,
.vex-theme-orange .dark .mdc-list-item__start,
.vex-theme-orange .dark .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #f97316;
  --mdc-radio-selected-hover-icon-color: #f97316;
  --mdc-radio-selected-icon-color: #f97316;
  --mdc-radio-selected-pressed-icon-color: #f97316;
}
.vex-theme-orange.dark .mat-accent .mdc-list-item__start,
.vex-theme-orange.dark .mat-accent .mdc-list-item__end,
.vex-theme-orange .dark .mat-accent .mdc-list-item__start,
.vex-theme-orange .dark .mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #f59e0b;
  --mdc-radio-selected-hover-icon-color: #f59e0b;
  --mdc-radio-selected-icon-color: #f59e0b;
  --mdc-radio-selected-pressed-icon-color: #f59e0b;
}
.vex-theme-orange.dark .mat-warn .mdc-list-item__start,
.vex-theme-orange.dark .mat-warn .mdc-list-item__end,
.vex-theme-orange .dark .mat-warn .mdc-list-item__start,
.vex-theme-orange .dark .mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #ef4444;
  --mdc-radio-selected-hover-icon-color: #ef4444;
  --mdc-radio-selected-icon-color: #ef4444;
  --mdc-radio-selected-pressed-icon-color: #ef4444;
}
.vex-theme-orange.dark .mat-mdc-list-option,
.vex-theme-orange .dark .mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #f97316;
  --mdc-checkbox-selected-hover-icon-color: #f97316;
  --mdc-checkbox-selected-icon-color: #f97316;
  --mdc-checkbox-selected-pressed-icon-color: #f97316;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f97316;
  --mdc-checkbox-selected-hover-state-layer-color: #f97316;
  --mdc-checkbox-selected-pressed-state-layer-color: #f97316;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.vex-theme-orange.dark .mat-mdc-list-option.mat-accent,
.vex-theme-orange .dark .mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #f59e0b;
  --mdc-checkbox-selected-hover-icon-color: #f59e0b;
  --mdc-checkbox-selected-icon-color: #f59e0b;
  --mdc-checkbox-selected-pressed-icon-color: #f59e0b;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f59e0b;
  --mdc-checkbox-selected-hover-state-layer-color: #f59e0b;
  --mdc-checkbox-selected-pressed-state-layer-color: #f59e0b;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.vex-theme-orange.dark .mat-mdc-list-option.mat-warn,
.vex-theme-orange .dark .mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #ef4444;
  --mdc-checkbox-selected-hover-icon-color: #ef4444;
  --mdc-checkbox-selected-icon-color: #ef4444;
  --mdc-checkbox-selected-pressed-icon-color: #ef4444;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #ef4444;
  --mdc-checkbox-selected-hover-state-layer-color: #ef4444;
  --mdc-checkbox-selected-pressed-state-layer-color: #ef4444;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.vex-theme-orange.dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.vex-theme-orange.dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,
.vex-theme-orange .dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.vex-theme-orange .dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #f97316;
}
.vex-theme-orange.dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.vex-theme-orange.dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.vex-theme-orange .dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.vex-theme-orange .dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #f97316;
}
.vex-theme-orange.dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.vex-theme-orange.dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.vex-theme-orange.dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end,
.vex-theme-orange .dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.vex-theme-orange .dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.vex-theme-orange .dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.vex-theme-orange.dark, .vex-theme-orange .dark {
  --mat-paginator-container-text-color: rgba(255, 255, 255, 0.87);
  --mat-paginator-container-background-color: #1f2937;
  --mat-paginator-enabled-icon-color: rgba(255, 255, 255, 0.54);
  --mat-paginator-disabled-icon-color: rgba(255, 255, 255, 0.12);
}

.vex-theme-orange.dark .mat-mdc-tab-group, .vex-theme-orange.dark .mat-mdc-tab-nav-bar,
.vex-theme-orange .dark .mat-mdc-tab-group,
.vex-theme-orange .dark .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #f97316;
  --mat-tab-header-disabled-ripple-color: #6b7280;
  --mat-tab-header-pagination-icon-color: #fff;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #f97316;
  --mat-tab-header-active-ripple-color: #f97316;
  --mat-tab-header-inactive-ripple-color: #f97316;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #f97316;
  --mat-tab-header-active-hover-label-text-color: #f97316;
  --mat-tab-header-active-focus-indicator-color: #f97316;
  --mat-tab-header-active-hover-indicator-color: #f97316;
}
.vex-theme-orange.dark .mat-mdc-tab-group.mat-accent, .vex-theme-orange.dark .mat-mdc-tab-nav-bar.mat-accent,
.vex-theme-orange .dark .mat-mdc-tab-group.mat-accent,
.vex-theme-orange .dark .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #f59e0b;
  --mat-tab-header-disabled-ripple-color: #6b7280;
  --mat-tab-header-pagination-icon-color: #fff;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #f59e0b;
  --mat-tab-header-active-ripple-color: #f59e0b;
  --mat-tab-header-inactive-ripple-color: #f59e0b;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #f59e0b;
  --mat-tab-header-active-hover-label-text-color: #f59e0b;
  --mat-tab-header-active-focus-indicator-color: #f59e0b;
  --mat-tab-header-active-hover-indicator-color: #f59e0b;
}
.vex-theme-orange.dark .mat-mdc-tab-group.mat-warn, .vex-theme-orange.dark .mat-mdc-tab-nav-bar.mat-warn,
.vex-theme-orange .dark .mat-mdc-tab-group.mat-warn,
.vex-theme-orange .dark .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #ef4444;
  --mat-tab-header-disabled-ripple-color: #6b7280;
  --mat-tab-header-pagination-icon-color: #fff;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #ef4444;
  --mat-tab-header-active-ripple-color: #ef4444;
  --mat-tab-header-inactive-ripple-color: #ef4444;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #ef4444;
  --mat-tab-header-active-hover-label-text-color: #ef4444;
  --mat-tab-header-active-focus-indicator-color: #ef4444;
  --mat-tab-header-active-hover-indicator-color: #ef4444;
}
.vex-theme-orange.dark .mat-mdc-tab-group.mat-background-primary, .vex-theme-orange.dark .mat-mdc-tab-nav-bar.mat-background-primary,
.vex-theme-orange .dark .mat-mdc-tab-group.mat-background-primary,
.vex-theme-orange .dark .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #f97316;
  --mat-tab-header-with-background-foreground-color: white;
}
.vex-theme-orange.dark .mat-mdc-tab-group.mat-background-accent, .vex-theme-orange.dark .mat-mdc-tab-nav-bar.mat-background-accent,
.vex-theme-orange .dark .mat-mdc-tab-group.mat-background-accent,
.vex-theme-orange .dark .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #f59e0b;
  --mat-tab-header-with-background-foreground-color: white;
}
.vex-theme-orange.dark .mat-mdc-tab-group.mat-background-warn, .vex-theme-orange.dark .mat-mdc-tab-nav-bar.mat-background-warn,
.vex-theme-orange .dark .mat-mdc-tab-group.mat-background-warn,
.vex-theme-orange .dark .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #ef4444;
  --mat-tab-header-with-background-foreground-color: white;
}
.vex-theme-orange.dark, .vex-theme-orange .dark {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #f59e0b;
  --mdc-checkbox-selected-hover-icon-color: #f59e0b;
  --mdc-checkbox-selected-icon-color: #f59e0b;
  --mdc-checkbox-selected-pressed-icon-color: #f59e0b;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f59e0b;
  --mdc-checkbox-selected-hover-state-layer-color: #f59e0b;
  --mdc-checkbox-selected-pressed-state-layer-color: #f59e0b;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}

.vex-theme-orange.dark .mat-mdc-checkbox.mat-primary,
.vex-theme-orange .dark .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #f97316;
  --mdc-checkbox-selected-hover-icon-color: #f97316;
  --mdc-checkbox-selected-icon-color: #f97316;
  --mdc-checkbox-selected-pressed-icon-color: #f97316;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f97316;
  --mdc-checkbox-selected-hover-state-layer-color: #f97316;
  --mdc-checkbox-selected-pressed-state-layer-color: #f97316;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.vex-theme-orange.dark .mat-mdc-checkbox.mat-warn,
.vex-theme-orange .dark .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #ef4444;
  --mdc-checkbox-selected-hover-icon-color: #ef4444;
  --mdc-checkbox-selected-icon-color: #ef4444;
  --mdc-checkbox-selected-pressed-icon-color: #ef4444;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #ef4444;
  --mdc-checkbox-selected-hover-state-layer-color: #ef4444;
  --mdc-checkbox-selected-pressed-state-layer-color: #ef4444;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.vex-theme-orange.dark .mat-mdc-checkbox .mdc-form-field,
.vex-theme-orange .dark .mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.vex-theme-orange.dark .mat-mdc-checkbox.mat-mdc-checkbox-disabled label,
.vex-theme-orange .dark .mat-mdc-checkbox.mat-mdc-checkbox-disabled label {
  color: #6b7280;
}
.vex-theme-orange.dark .mat-mdc-button.mat-unthemed,
.vex-theme-orange .dark .mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #fff;
}
.vex-theme-orange.dark .mat-mdc-button.mat-primary,
.vex-theme-orange .dark .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #f97316;
}
.vex-theme-orange.dark .mat-mdc-button.mat-accent,
.vex-theme-orange .dark .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #f59e0b;
}
.vex-theme-orange.dark .mat-mdc-button.mat-warn,
.vex-theme-orange .dark .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #ef4444;
}
.vex-theme-orange.dark .mat-mdc-button[disabled][disabled],
.vex-theme-orange .dark .mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-text-button-label-text-color: rgba(255, 255, 255, 0.5);
}
.vex-theme-orange.dark .mat-mdc-unelevated-button.mat-unthemed,
.vex-theme-orange .dark .mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #1f2937;
  --mdc-filled-button-label-text-color: #fff;
}
.vex-theme-orange.dark .mat-mdc-unelevated-button.mat-primary,
.vex-theme-orange .dark .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #f97316;
  --mdc-filled-button-label-text-color: #000;
}
.vex-theme-orange.dark .mat-mdc-unelevated-button.mat-accent,
.vex-theme-orange .dark .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #f59e0b;
  --mdc-filled-button-label-text-color: #000;
}
.vex-theme-orange.dark .mat-mdc-unelevated-button.mat-warn,
.vex-theme-orange .dark .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #ef4444;
  --mdc-filled-button-label-text-color: #fff;
}
.vex-theme-orange.dark .mat-mdc-unelevated-button[disabled][disabled],
.vex-theme-orange .dark .mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(255, 255, 255, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-filled-button-container-color: rgba(255, 255, 255, 0.12);
  --mdc-filled-button-label-text-color: rgba(255, 255, 255, 0.5);
}
.vex-theme-orange.dark .mat-mdc-raised-button.mat-unthemed,
.vex-theme-orange .dark .mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #1f2937;
  --mdc-protected-button-label-text-color: #fff;
}
.vex-theme-orange.dark .mat-mdc-raised-button.mat-primary,
.vex-theme-orange .dark .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #f97316;
  --mdc-protected-button-label-text-color: #000;
}
.vex-theme-orange.dark .mat-mdc-raised-button.mat-accent,
.vex-theme-orange .dark .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #f59e0b;
  --mdc-protected-button-label-text-color: #000;
}
.vex-theme-orange.dark .mat-mdc-raised-button.mat-warn,
.vex-theme-orange .dark .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #ef4444;
  --mdc-protected-button-label-text-color: #fff;
}
.vex-theme-orange.dark .mat-mdc-raised-button[disabled][disabled],
.vex-theme-orange .dark .mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(255, 255, 255, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-protected-button-container-color: rgba(255, 255, 255, 0.12);
  --mdc-protected-button-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-protected-button-container-elevation: 0;
}
.vex-theme-orange.dark .mat-mdc-outlined-button,
.vex-theme-orange .dark .mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
}
.vex-theme-orange.dark .mat-mdc-outlined-button.mat-unthemed,
.vex-theme-orange .dark .mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #fff;
}
.vex-theme-orange.dark .mat-mdc-outlined-button.mat-primary,
.vex-theme-orange .dark .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #f97316;
}
.vex-theme-orange.dark .mat-mdc-outlined-button.mat-accent,
.vex-theme-orange .dark .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #f59e0b;
}
.vex-theme-orange.dark .mat-mdc-outlined-button.mat-warn,
.vex-theme-orange .dark .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #ef4444;
}
.vex-theme-orange.dark .mat-mdc-outlined-button[disabled][disabled],
.vex-theme-orange .dark .mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-outlined-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(255, 255, 255, 0.12);
}
.vex-theme-orange.dark .mat-mdc-button, .vex-theme-orange.dark .mat-mdc-outlined-button,
.vex-theme-orange .dark .mat-mdc-button,
.vex-theme-orange .dark .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.vex-theme-orange.dark .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .vex-theme-orange.dark .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange .dark .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange .dark .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
.vex-theme-orange.dark .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-orange.dark .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-orange.dark .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-orange.dark .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange .dark .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange .dark .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange .dark .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange .dark .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.vex-theme-orange.dark .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .vex-theme-orange.dark .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange .dark .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange .dark .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.vex-theme-orange.dark .mat-mdc-button.mat-primary, .vex-theme-orange.dark .mat-mdc-outlined-button.mat-primary,
.vex-theme-orange .dark .mat-mdc-button.mat-primary,
.vex-theme-orange .dark .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #f97316;
  --mat-mdc-button-ripple-color: rgba(249, 115, 22, 0.1);
}
.vex-theme-orange.dark .mat-mdc-button.mat-accent, .vex-theme-orange.dark .mat-mdc-outlined-button.mat-accent,
.vex-theme-orange .dark .mat-mdc-button.mat-accent,
.vex-theme-orange .dark .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #f59e0b;
  --mat-mdc-button-ripple-color: rgba(245, 158, 11, 0.1);
}
.vex-theme-orange.dark .mat-mdc-button.mat-warn, .vex-theme-orange.dark .mat-mdc-outlined-button.mat-warn,
.vex-theme-orange .dark .mat-mdc-button.mat-warn,
.vex-theme-orange .dark .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #ef4444;
  --mat-mdc-button-ripple-color: rgba(239, 68, 68, 0.1);
}
.vex-theme-orange.dark .mat-mdc-raised-button, .vex-theme-orange.dark .mat-mdc-unelevated-button,
.vex-theme-orange .dark .mat-mdc-raised-button,
.vex-theme-orange .dark .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.vex-theme-orange.dark .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .vex-theme-orange.dark .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange .dark .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange .dark .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
.vex-theme-orange.dark .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-orange.dark .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-orange.dark .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-orange.dark .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange .dark .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange .dark .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange .dark .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange .dark .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.vex-theme-orange.dark .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .vex-theme-orange.dark .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange .dark .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange .dark .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.vex-theme-orange.dark .mat-mdc-raised-button.mat-primary, .vex-theme-orange.dark .mat-mdc-unelevated-button.mat-primary,
.vex-theme-orange .dark .mat-mdc-raised-button.mat-primary,
.vex-theme-orange .dark .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.vex-theme-orange.dark .mat-mdc-raised-button.mat-accent, .vex-theme-orange.dark .mat-mdc-unelevated-button.mat-accent,
.vex-theme-orange .dark .mat-mdc-raised-button.mat-accent,
.vex-theme-orange .dark .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.vex-theme-orange.dark .mat-mdc-raised-button.mat-warn, .vex-theme-orange.dark .mat-mdc-unelevated-button.mat-warn,
.vex-theme-orange .dark .mat-mdc-raised-button.mat-warn,
.vex-theme-orange .dark .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.vex-theme-orange.dark .mat-mdc-icon-button,
.vex-theme-orange .dark .mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mdc-icon-button-icon-color: inherit;
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.vex-theme-orange.dark .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange .dark .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
.vex-theme-orange.dark .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-orange.dark .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange .dark .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange .dark .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.vex-theme-orange.dark .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange .dark .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.vex-theme-orange.dark .mat-mdc-icon-button.mat-primary,
.vex-theme-orange .dark .mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #6200ee;
  --mat-mdc-button-ripple-color: rgba(98, 0, 238, 0.1);
}
.vex-theme-orange.dark .mat-mdc-icon-button.mat-accent,
.vex-theme-orange .dark .mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #018786;
  --mat-mdc-button-ripple-color: rgba(1, 135, 134, 0.1);
}
.vex-theme-orange.dark .mat-mdc-icon-button.mat-warn,
.vex-theme-orange .dark .mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #b00020;
  --mat-mdc-button-ripple-color: rgba(176, 0, 32, 0.1);
}
.vex-theme-orange.dark .mat-mdc-icon-button.mat-primary,
.vex-theme-orange .dark .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #f97316;
  --mat-mdc-button-persistent-ripple-color: #f97316;
  --mat-mdc-button-ripple-color: rgba(249, 115, 22, 0.1);
}
.vex-theme-orange.dark .mat-mdc-icon-button.mat-accent,
.vex-theme-orange .dark .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #f59e0b;
  --mat-mdc-button-persistent-ripple-color: #f59e0b;
  --mat-mdc-button-ripple-color: rgba(245, 158, 11, 0.1);
}
.vex-theme-orange.dark .mat-mdc-icon-button.mat-warn,
.vex-theme-orange .dark .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #ef4444;
  --mat-mdc-button-persistent-ripple-color: #ef4444;
  --mat-mdc-button-ripple-color: rgba(239, 68, 68, 0.1);
}
.vex-theme-orange.dark .mat-mdc-icon-button[disabled][disabled],
.vex-theme-orange .dark .mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-icon-button-disabled-icon-color: rgba(255, 255, 255, 0.5);
}
.vex-theme-orange.dark .mat-mdc-fab,
.vex-theme-orange.dark .mat-mdc-mini-fab,
.vex-theme-orange .dark .mat-mdc-fab,
.vex-theme-orange .dark .mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.vex-theme-orange.dark .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange.dark .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange .dark .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange .dark .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
.vex-theme-orange.dark .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-theme-orange.dark .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange.dark .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange.dark .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange .dark .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange .dark .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange .dark .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange .dark .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.vex-theme-orange.dark .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange.dark .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange .dark .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before,
.vex-theme-orange .dark .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.vex-theme-orange.dark .mat-mdc-fab.mat-primary,
.vex-theme-orange.dark .mat-mdc-mini-fab.mat-primary,
.vex-theme-orange .dark .mat-mdc-fab.mat-primary,
.vex-theme-orange .dark .mat-mdc-mini-fab.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.vex-theme-orange.dark .mat-mdc-fab.mat-accent,
.vex-theme-orange.dark .mat-mdc-mini-fab.mat-accent,
.vex-theme-orange .dark .mat-mdc-fab.mat-accent,
.vex-theme-orange .dark .mat-mdc-mini-fab.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.vex-theme-orange.dark .mat-mdc-fab.mat-warn,
.vex-theme-orange.dark .mat-mdc-mini-fab.mat-warn,
.vex-theme-orange .dark .mat-mdc-fab.mat-warn,
.vex-theme-orange .dark .mat-mdc-mini-fab.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.vex-theme-orange.dark .mat-mdc-fab[disabled][disabled],
.vex-theme-orange.dark .mat-mdc-mini-fab[disabled][disabled],
.vex-theme-orange .dark .mat-mdc-fab[disabled][disabled],
.vex-theme-orange .dark .mat-mdc-mini-fab[disabled][disabled] {
  --mdc-fab-container-color: rgba(255, 255, 255, 0.12);
  --mdc-fab-icon-color: rgba(255, 255, 255, 0.5);
  --mat-mdc-fab-color: rgba(255, 255, 255, 0.5);
}
.vex-theme-orange.dark .mat-mdc-fab.mat-unthemed,
.vex-theme-orange.dark .mat-mdc-mini-fab.mat-unthemed,
.vex-theme-orange .dark .mat-mdc-fab.mat-unthemed,
.vex-theme-orange .dark .mat-mdc-mini-fab.mat-unthemed {
  --mdc-fab-container-color: #1f2937;
  --mdc-fab-icon-color: white;
  --mat-mdc-fab-color: #fff;
}
.vex-theme-orange.dark .mat-mdc-fab.mat-primary,
.vex-theme-orange.dark .mat-mdc-mini-fab.mat-primary,
.vex-theme-orange .dark .mat-mdc-fab.mat-primary,
.vex-theme-orange .dark .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #f97316;
  --mdc-fab-icon-color: black;
  --mat-mdc-fab-color: #000;
}
.vex-theme-orange.dark .mat-mdc-fab.mat-accent,
.vex-theme-orange.dark .mat-mdc-mini-fab.mat-accent,
.vex-theme-orange .dark .mat-mdc-fab.mat-accent,
.vex-theme-orange .dark .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: #f59e0b;
  --mdc-fab-icon-color: black;
  --mat-mdc-fab-color: #000;
}
.vex-theme-orange.dark .mat-mdc-fab.mat-warn,
.vex-theme-orange.dark .mat-mdc-mini-fab.mat-warn,
.vex-theme-orange .dark .mat-mdc-fab.mat-warn,
.vex-theme-orange .dark .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: #ef4444;
  --mdc-fab-icon-color: white;
  --mat-mdc-fab-color: #fff;
}
.vex-theme-orange.dark .mat-mdc-snack-bar-container,
.vex-theme-orange .dark .mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: #d2d4d7;
  --mdc-snackbar-supporting-text-color: rgba(31, 41, 55, 0.87);
  --mat-snack-bar-button-color: rgba(0, 0, 0, 0.87);
}
.vex-theme-orange.dark .mat-mdc-progress-spinner,
.vex-theme-orange .dark .mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #f97316;
}
.vex-theme-orange.dark .mat-mdc-progress-spinner.mat-accent,
.vex-theme-orange .dark .mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: #f59e0b;
}
.vex-theme-orange.dark .mat-mdc-progress-spinner.mat-warn,
.vex-theme-orange .dark .mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: #ef4444;
}
.vex-theme-orange.dark, .vex-theme-orange .dark {
  --mat-badge-background-color: #f97316;
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: #4b5563;
  --mat-badge-disabled-state-text-color: #6b7280;
}

.vex-theme-orange.dark .mat-badge-accent,
.vex-theme-orange .dark .mat-badge-accent {
  --mat-badge-background-color: #f59e0b;
  --mat-badge-text-color: white;
}
.vex-theme-orange.dark .mat-badge-warn,
.vex-theme-orange .dark .mat-badge-warn {
  --mat-badge-background-color: #ef4444;
  --mat-badge-text-color: white;
}
.vex-theme-orange.dark, .vex-theme-orange .dark {
  --mat-bottom-sheet-container-text-color: white;
  --mat-bottom-sheet-container-background-color: #1f2937;
}

.vex-theme-orange.dark, .vex-theme-orange .dark {
  --mat-legacy-button-toggle-text-color: #6b7280;
  --mat-legacy-button-toggle-state-layer-color: rgba(255, 255, 255, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: #9ca3af;
  --mat-legacy-button-toggle-selected-state-background-color: #111827;
  --mat-legacy-button-toggle-disabled-state-text-color: #4b5563;
  --mat-legacy-button-toggle-disabled-state-background-color: black;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #1f2937;
  --mat-standard-button-toggle-text-color: white;
  --mat-standard-button-toggle-background-color: #1f2937;
  --mat-standard-button-toggle-state-layer-color: white;
  --mat-standard-button-toggle-selected-state-background-color: #111827;
  --mat-standard-button-toggle-selected-state-text-color: white;
  --mat-standard-button-toggle-disabled-state-text-color: #4b5563;
  --mat-standard-button-toggle-disabled-state-background-color: #1f2937;
  --mat-standard-button-toggle-disabled-selected-state-text-color: white;
  --mat-standard-button-toggle-disabled-selected-state-background-color: #1f2937;
  --mat-standard-button-toggle-divider-color: #3a434f;
}

.vex-theme-orange.dark, .vex-theme-orange .dark {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #f97316;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(249, 115, 22, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(249, 115, 22, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(249, 115, 22, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #f97316;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(249, 115, 22, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: white;
  --mat-datepicker-calendar-body-label-text-color: #9ca3af;
  --mat-datepicker-calendar-period-button-icon-color: white;
  --mat-datepicker-calendar-navigation-button-icon-color: white;
  --mat-datepicker-calendar-header-divider-color: rgba(255, 255, 255, 0.12);
  --mat-datepicker-calendar-header-text-color: #9ca3af;
  --mat-datepicker-calendar-date-today-outline-color: #6b7280;
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(107, 114, 128, 0.8);
  --mat-datepicker-calendar-date-text-color: white;
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: #6b7280;
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(255, 255, 255, 0.24);
  --mat-datepicker-range-input-separator-color: white;
  --mat-datepicker-range-input-disabled-state-separator-color: #6b7280;
  --mat-datepicker-range-input-disabled-state-text-color: #6b7280;
  --mat-datepicker-calendar-container-background-color: #1f2937;
  --mat-datepicker-calendar-container-text-color: white;
}

.vex-theme-orange.dark .mat-datepicker-content.mat-accent,
.vex-theme-orange .dark .mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #f59e0b;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(245, 158, 11, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(245, 158, 11, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(245, 158, 11, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(245, 158, 11, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.vex-theme-orange.dark .mat-datepicker-content.mat-warn,
.vex-theme-orange .dark .mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #ef4444;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(239, 68, 68, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(239, 68, 68, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(239, 68, 68, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(239, 68, 68, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.vex-theme-orange.dark .mat-datepicker-toggle-active.mat-accent,
.vex-theme-orange .dark .mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #f59e0b;
}
.vex-theme-orange.dark .mat-datepicker-toggle-active.mat-warn,
.vex-theme-orange .dark .mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #ef4444;
}
.vex-theme-orange.dark, .vex-theme-orange .dark {
  --mat-divider-color: rgba(255, 255, 255, 0.12);
}

.vex-theme-orange.dark, .vex-theme-orange .dark {
  --mat-expansion-container-background-color: #1f2937;
  --mat-expansion-container-text-color: white;
  --mat-expansion-actions-divider-color: rgba(255, 255, 255, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(255, 255, 255, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(255, 255, 255, 0.04);
  --mat-expansion-header-disabled-state-text-color: #4b5563;
  --mat-expansion-header-text-color: white;
  --mat-expansion-header-description-color: #9ca3af;
  --mat-expansion-header-indicator-color: #9ca3af;
}

.vex-theme-orange.dark, .vex-theme-orange .dark {
  --mat-icon-color: inherit;
}

.vex-theme-orange.dark .mat-icon.mat-primary,
.vex-theme-orange .dark .mat-icon.mat-primary {
  --mat-icon-color: #f97316;
}
.vex-theme-orange.dark .mat-icon.mat-accent,
.vex-theme-orange .dark .mat-icon.mat-accent {
  --mat-icon-color: #f59e0b;
}
.vex-theme-orange.dark .mat-icon.mat-warn,
.vex-theme-orange .dark .mat-icon.mat-warn {
  --mat-icon-color: #ef4444;
}
.vex-theme-orange.dark, .vex-theme-orange .dark {
  --mat-sidenav-container-divider-color: rgba(255, 255, 255, 0.12);
  --mat-sidenav-container-background-color: #1f2937;
  --mat-sidenav-container-text-color: white;
  --mat-sidenav-content-background-color: #111827;
  --mat-sidenav-content-text-color: white;
  --mat-sidenav-scrim-color: rgba(224, 214, 200, 0.6);
}

.vex-theme-orange.dark, .vex-theme-orange .dark {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #f97316;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #f97316;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #f97316;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: #1f2937;
  --mat-stepper-line-color: rgba(255, 255, 255, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(255, 255, 255, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(255, 255, 255, 0.04);
  --mat-stepper-header-label-text-color: #9ca3af;
  --mat-stepper-header-optional-label-text-color: #9ca3af;
  --mat-stepper-header-selected-state-label-text-color: white;
  --mat-stepper-header-error-state-label-text-color: #ef4444;
  --mat-stepper-header-icon-background-color: #9ca3af;
  --mat-stepper-header-error-state-icon-foreground-color: #ef4444;
  --mat-stepper-header-error-state-icon-background-color: transparent;
}
.vex-theme-orange.dark .mat-step-header.mat-accent, .vex-theme-orange .dark .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #f59e0b;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #f59e0b;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #f59e0b;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.vex-theme-orange.dark .mat-step-header.mat-warn, .vex-theme-orange .dark .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #ef4444;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #ef4444;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #ef4444;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}

.vex-theme-orange.dark .mat-sort-header-arrow,
.vex-theme-orange .dark .mat-sort-header-arrow {
  color: #9ca3af;
}
.vex-theme-orange.dark, .vex-theme-orange .dark {
  --mat-toolbar-container-background-color: #1f2937;
  --mat-toolbar-container-text-color: white;
}

.vex-theme-orange.dark .mat-toolbar.mat-primary,
.vex-theme-orange .dark .mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #f97316;
  --mat-toolbar-container-text-color: white;
}
.vex-theme-orange.dark .mat-toolbar.mat-accent,
.vex-theme-orange .dark .mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #f59e0b;
  --mat-toolbar-container-text-color: white;
}
.vex-theme-orange.dark .mat-toolbar.mat-warn,
.vex-theme-orange .dark .mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #ef4444;
  --mat-toolbar-container-text-color: white;
}
.vex-theme-orange.dark .mat-tree,
.vex-theme-orange .dark .mat-tree {
  background: #1f2937;
}
.vex-theme-orange.dark .mat-tree-node,
.vex-theme-orange.dark .mat-nested-tree-node,
.vex-theme-orange .dark .mat-tree-node,
.vex-theme-orange .dark .mat-nested-tree-node {
  color: white;
}

/**
 * Typography
 */
.display-3 {
  font-size: 56px;
  font-weight: 400;
  line-height: 56px;
  font-family: var(--vex-font);
  letter-spacing: -0.02em;
}

.display-2 {
  font-size: 45px;
  font-weight: 400;
  line-height: 48px;
  font-family: var(--vex-font);
  letter-spacing: -0.005em;
}

.display-1,
h1 {
  font-size: 34px;
  font-weight: 400;
  line-height: 40px;
  font-family: var(--vex-font);
  letter-spacing: normal;
}

.headline,
h2 {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  font-family: var(--vex-font);
  letter-spacing: -0.019em;
}

.title,
h3 {
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  font-family: var(--vex-font);
  letter-spacing: -0.014em;
}

.subheading-2,
h4 {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  font-family: var(--vex-font);
  letter-spacing: -0.011em;
}

.subheading-1,
h5 {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  font-family: var(--vex-font);
  letter-spacing: -0.006em;
}

.body-2,
h6 {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: var(--vex-font);
  letter-spacing: -0.006em;
}

.body-1,
p {
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  font-family: var(--vex-font);
  letter-spacing: -0.009em;
}

.caption {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  font-family: var(--vex-font);
  letter-spacing: 0;
}

.text-xs {
  letter-spacing: 0;
}

.text-sm {
  letter-spacing: -0.009em;
}

.text-base {
  letter-spacing: -0.011em;
}

.text-lg {
  letter-spacing: -0.014em;
}

.text-2xl {
  letter-spacing: -0.019em;
}

.text-3xl {
  letter-spacing: -0.021em;
}

body {
  --vex-font: theme("fontFamily.sans");
  --vex-sidenav-width: 280px;
  --vex-sidenav-collapsed-width: 72px;
  --vex-sidenav-item-min-height: 48px;
  --vex-sidenav-item-padding-vertical: theme("space.2");
  --vex-sidenav-item-padding-horizontal: theme("space.6");
  --vex-sidenav-item-font-weight: 500;
  --vex-sidenav-item-icon-gap: theme("space.4");
  --vex-sidenav-item-icon-size: theme("space.6");
  --vex-sidenav-item-dropdown-gap: theme("space.3");
  --vex-toolbar-height: 64px;
  --vex-secondary-toolbar-height: 54px;
  --vex-navigation-height: 64px;
  --vex-footer-height: 56px;
  --vex-footer-z-index: 100;
  --vex-footer-elevation: 0 -10px 30px 0 rgba(82, 63, 104, 0.06);
  --vex-page-layout-header-height: 200px;
  --vex-page-layout-toolbar-height: 64px;
  --vex-blink-scrollbar-width: 12px;
  --vex-default-icon-size: 24px;
  --vex-border-radius: 0.25rem;
}

.vex-base-layout-container {
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.vex-layout-content {
  background: var(--vex-background-background);
  min-height: calc(100% - var(--vex-toolbar-height) - var(--vex-navigation-height));
  position: relative;
  width: 100%;
}

.vex-layout-footer-enabled .vex-layout-content {
  min-height: calc(100% - var(--vex-toolbar-height) - var(--vex-navigation-height) - var(--vex-footer-height));
}

.vex-layout-scroll-disabled {
  overflow: hidden;
}
.vex-layout-scroll-disabled .vex-layout-content {
  height: calc(100% - var(--vex-toolbar-height) - var(--vex-navigation-height));
  min-height: unset;
  overflow: hidden;
}
.vex-layout-scroll-disabled.vex-layout-footer-fixed .vex-layout-content, .vex-layout-scroll-disabled.vex-layout-footer-enabled .vex-layout-content {
  height: calc(100% - var(--vex-toolbar-height) - var(--vex-navigation-height) - var(--vex-footer-height));
  min-height: unset;
}

.vex-layout-sidenav-container {
  background: var(--vex-background-background);
  height: 100%;
  transition: transform 0.5s cubic-bezier(0.2, 1, 0.3, 1);
}

.vex-layout-sidenav-content {
  overflow-x: hidden;
  overflow-y: auto;
}

.vex-layout-search-overlay-open {
  overflow: hidden;
  position: fixed;
}
.vex-layout-search-overlay-open .vex-layout-sidenav-container {
  pointer-events: none;
  transform: perspective(1000px) translate3d(0, 50vh, 0) rotate3d(1, 0, 0, 30deg);
  transform-origin: 50vw 50vh;
  transition: transform 0.5s cubic-bezier(0.2, 1, 0.3, 1);
  @apply rounded shadow-2xl overflow-visible;
}

.vex-layout-toolbar-fixed .vex-toolbar {
  position: fixed;
  width: var(--vex-toolbar-width);
  z-index: 50;
}
.vex-layout-toolbar-fixed .vex-layout-content {
  margin-top: var(--vex-toolbar-height);
}
@screen lg {
  .vex-layout-toolbar-fixed .vex-layout-content {
    margin-top: calc(var(--vex-toolbar-height) + var(--vex-navigation-height));
  }
}

.vex-layout-footer-fixed .vex-footer {
  box-shadow: var(--vex-footer-elevation);
  position: fixed;
}
.vex-layout-footer-fixed .vex-layout-content {
  margin-bottom: var(--vex-footer-height);
  min-height: calc(100% - var(--vex-toolbar-height) - var(--vex-navigation-height) - var(--vex-footer-height));
}
.vex-layout-footer-fixed.vex-layout-scroll-disabled .vex-layout-content {
  height: calc(100% - var(--vex-toolbar-height) - var(--vex-navigation-height) - var(--vex-footer-height));
}

.vex-layout-horizontal {
  --vex-navigation-height: 0.00001px;
  --vex-toolbar-width: 100%;
}
@screen lg {
  .vex-layout-horizontal {
    --vex-toolbar-width: calc(100% - var(--vex-sidenav-width));
  }
  .vex-layout-horizontal.vex-layout-sidenav-collapsed {
    --vex-toolbar-width: calc(100% - var(--vex-sidenav-collapsed-width));
  }
}
.vex-layout-horizontal .vex-layout-sidenav-container {
  /* When the sidenav is not fixed, stretch the sidenav container to fill the available space. This
     causes `<mat-sidenav-content>` to act as our scrolling element for desktop layouts. */
  flex: 1;
}
.vex-layout-horizontal.vex-layout-footer-fixed.vex-layout-scroll-disabled .vex-layout-content {
  height: calc(100% - var(--vex-toolbar-height) - var(--vex-footer-height));
}

@screen lg {
  body:not([dir=rtl]) .vex-layout-horizontal.vex-layout-sidenav-collapsed .vex-layout-sidenav-content {
    margin-left: var(--vex-sidenav-collapsed-width) !important;
    margin-right: 0 !important;
  }
  body:not([dir=rtl]) .vex-layout-horizontal:not(.vex-layout-sidenav-collapsed) .vex-layout-sidenav-content {
    margin-left: var(--vex-sidenav-width) !important;
    margin-right: 0 !important;
  }
  [dir=rtl] .vex-layout-horizontal.vex-layout-sidenav-collapsed .vex-layout-sidenav-content {
    margin-right: var(--vex-sidenav-collapsed-width) !important;
    margin-left: 0 !important;
  }
  [dir=rtl] .vex-layout-horizontal:not(.vex-layout-sidenav-collapsed) .vex-layout-sidenav-content {
    margin-right: var(--vex-sidenav-width) !important;
    margin-left: 0 !important;
  }
}
/** SCROLLBARS */
body.is-blink ::-webkit-scrollbar {
  background-color: rgba(0, 0, 0, 0);
  height: var(--vex-blink-scrollbar-width);
  width: var(--vex-blink-scrollbar-width);
}
body.is-blink ::-webkit-scrollbar:hover {
  background-color: rgba(0, 0, 0, 0.12);
}
body.is-blink ::-webkit-scrollbar-thumb {
  border: 2px solid transparent;
  border-radius: var(--vex-blink-scrollbar-width);
  box-shadow: inset 0 0 0 12px rgba(0, 0, 0, 0.37);
}
body.is-blink ::-webkit-scrollbar-thumb:active {
  border-radius: var(--vex-blink-scrollbar-width);
  box-shadow: inset 0 0 0 12px rgba(0, 0, 0, 0.54);
}

/** SCROLLBLOCK */
.vex-scrollblock {
  position: fixed;
  width: 100%;
}

/** GLOSSY DIALOG */
.vex-dialog-glossy .mat-mdc-dialog-container {
  @apply rounded;
}

.is-blink .vex-dialog-glossy .mat-mdc-dialog-container .mdc-dialog__surface {
  backdrop-filter: saturate(180%) blur(20px);
  @apply bg-foreground/60;
}

.container {
  @apply px-6;
}
@screen lg {
  .container {
    margin-left: auto;
    margin-right: auto;
    max-width: theme("screens.lg");
    width: 100%;
  }
}

.card {
  @apply rounded shadow bg-foreground;
}

.avatar {
  @apply h-10 w-10 object-cover rounded-full bg-hover;
}

.vex-layout-vertical {
  --vex-toolbar-width: 100%;
}
.vex-layout-vertical vex-secondary-toolbar .fixed {
  @apply top-0 relative pt-3 -mb-4 bg-none border-t-0 shadow-none;
}
.vex-layout-vertical.vex-layout-footer-fixed.vex-layout-scroll-disabled .vex-layout-content {
  height: calc(100% - var(--vex-toolbar-height) - var(--vex-footer-height));
}

@media print {
  html,
  body {
    height: auto !important;
    overflow: initial !important;
  }
  .vex-toolbar,
  .vex-sidenav {
    display: none !important;
  }
  .vex-layout-content {
    margin-top: 0 !important;
  }
  .mat-drawer-container {
    overflow: visible !important;
  }
  .vex-layout-sidenav-content {
    margin-left: 0 !important;
    @apply bg-foreground;
  }
  .vex-footer {
    display: none !important;
  }
  .config-panel-toggle {
    display: none !important;
  }
}
:root .cdk-overlay-pane:not(.mat-mdc-autocomplete-panel-above) div.mat-mdc-autocomplete-panel {
  @apply mt-1 rounded-t;
}
:root .mat-mdc-autocomplete-panel {
  @apply rounded-b;
}
:root .mat-mdc-autocomplete-panel .mat-mdc-option {
  @apply mx-2;
}

:root .mat-mdc-button,
:root .mat-mdc-outlined-button,
:root .mat-mdc-unelevated-button,
:root .mat-mdc-raised-button {
  @apply rounded-button min-w-[6rem];
}
:root .mat-button-toggle-group:not(.mat-button-toggle-vertical) {
  @apply rounded-button;
}
:root .mat-button-toggle-group:not(.mat-button-toggle-vertical) .mat-button-toggle:first-child .mat-button-toggle-label-content {
  @apply pl-4;
}
:root .mat-button-toggle-group:not(.mat-button-toggle-vertical) .mat-button-toggle:last-child .mat-button-toggle-label-content {
  @apply pr-4;
}
:root .mat-button-toggle-button {
  @apply font-medium;
}
:root .mat-mdc-icon-button .mat-icon {
  @apply inline-flex items-center justify-center;
}

:root .mat-mdc-card {
  @apply rounded;
}

.vex-mat-dense-default .mat-mdc-form-field-infix {
  min-height: 48px;
}
.vex-mat-dense-default .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 24px;
}
.vex-mat-dense-default .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -30.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}
.vex-mat-dense-default .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 12px;
  padding-bottom: 12px;
}
.vex-mat-dense-default .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 12px;
  padding-bottom: 12px;
}
.vex-mat-dense-default .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 12px;
  padding-bottom: 12px;
}
.vex-mat-dense-default .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label {
  display: none;
}
.vex-mat-dense-default .mat-form-field-appearance-fill .mat-mdc-select-arrow-wrapper {
  transform: none;
}
.vex-mat-dense-md {
  --mat-table-header-container-height: 52px;
  --mat-table-footer-container-height: 48px;
  --mat-table-row-item-container-height: 48px;
}
.vex-mat-dense-md .mat-mdc-form-field-infix {
  min-height: 52px;
}
.vex-mat-dense-md .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 26px;
}
.vex-mat-dense-md .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -32.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}
.vex-mat-dense-md .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 14px;
  padding-bottom: 14px;
}
.vex-mat-dense-md .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 22px;
  padding-bottom: 6px;
}
.vex-mat-dense-md .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 14px;
  padding-bottom: 14px;
}
.vex-mat-dense-md {
  --mdc-checkbox-state-layer-size: 36px;
}

.vex-mat-dense-md .mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-container-height: 28px;
}
.vex-mat-dense-md .mat-mdc-slide-toggle {
  --mdc-switch-state-layer-size: 44px;
}
.vex-mat-dense-md .mat-mdc-radio-button .mdc-radio {
  --mdc-radio-state-layer-size: 36px;
}
.vex-mat-dense-md .mat-mdc-list-base {
  --mdc-list-list-item-one-line-container-height: 44px;
  --mdc-list-list-item-two-line-container-height: 60px;
  --mdc-list-list-item-three-line-container-height: 84px;
}
.vex-mat-dense-md .mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .vex-mat-dense-md .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .vex-mat-dense-md .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 52px;
}
.vex-mat-dense-md .mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .vex-mat-dense-md .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .vex-mat-dense-md .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 68px;
}
.vex-mat-dense-md {
  --mat-paginator-container-size: 52px;
}

.vex-mat-dense-md .mat-mdc-paginator .mat-mdc-form-field-infix {
  min-height: 40px;
}
.vex-mat-dense-md .mat-mdc-paginator .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 20px;
}
.vex-mat-dense-md .mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -26.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}
.vex-mat-dense-md .mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.vex-mat-dense-md .mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.vex-mat-dense-md .mat-mdc-paginator .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.vex-mat-dense-md .mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label {
  display: none;
}
.vex-mat-dense-md .mat-mdc-tab-header {
  --mdc-secondary-navigation-tab-container-height: 44px;
}
.vex-mat-dense-md .mat-mdc-button.mat-mdc-button-base,
.vex-mat-dense-md .mat-mdc-raised-button.mat-mdc-button-base,
.vex-mat-dense-md .mat-mdc-unelevated-button.mat-mdc-button-base,
.vex-mat-dense-md .mat-mdc-outlined-button.mat-mdc-button-base {
  height: 32px;
  margin-top: 0;
  margin-bottom: 0;
}
.vex-mat-dense-md .mat-mdc-button.mat-mdc-button-base .mdc-button__touch,
.vex-mat-dense-md .mat-mdc-raised-button.mat-mdc-button-base .mdc-button__touch,
.vex-mat-dense-md .mat-mdc-unelevated-button.mat-mdc-button-base .mdc-button__touch,
.vex-mat-dense-md .mat-mdc-outlined-button.mat-mdc-button-base .mdc-button__touch {
  height: 100%;
}
.vex-mat-dense-md .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 44px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 10px;
}
.vex-mat-dense-md {
  --mat-expansion-header-collapsed-state-height: 44px;
  --mat-expansion-header-expanded-state-height: 60px;
}

.vex-mat-dense-md {
  --mat-stepper-header-height: 68px;
}

.vex-mat-dense-md {
  --mat-toolbar-standard-height: 60px;
  --mat-toolbar-mobile-height: 52px;
}

.vex-mat-dense-md .mat-tree-node {
  min-height: 44px;
}
.vex-mat-dense-md {
  --mat-standard-button-toggle-height: 44px;
}

.vex-mat-dense-sm {
  --mat-table-header-container-height: 48px;
  --mat-table-footer-container-height: 44px;
  --mat-table-row-item-container-height: 44px;
}
.vex-mat-dense-sm .mat-mdc-form-field-infix {
  min-height: 48px;
}
.vex-mat-dense-sm .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 24px;
}
.vex-mat-dense-sm .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -30.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}
.vex-mat-dense-sm .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 12px;
  padding-bottom: 12px;
}
.vex-mat-dense-sm .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 12px;
  padding-bottom: 12px;
}
.vex-mat-dense-sm .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 12px;
  padding-bottom: 12px;
}
.vex-mat-dense-sm .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label {
  display: none;
}
.vex-mat-dense-sm .mat-form-field-appearance-fill .mat-mdc-select-arrow-wrapper {
  transform: none;
}
.vex-mat-dense-sm {
  --mdc-checkbox-state-layer-size: 32px;
}

.vex-mat-dense-sm .mat-mdc-checkbox-touch-target {
  display: none;
}
.vex-mat-dense-sm .mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-container-height: 24px;
}
.vex-mat-dense-sm .mat-mdc-slide-toggle {
  --mdc-switch-state-layer-size: 40px;
}
.vex-mat-dense-sm .mat-mdc-radio-button .mdc-radio {
  --mdc-radio-state-layer-size: 32px;
}
.vex-mat-dense-sm .mat-mdc-radio-touch-target {
  display: none;
}
.vex-mat-dense-sm .mat-mdc-list-base {
  --mdc-list-list-item-one-line-container-height: 40px;
  --mdc-list-list-item-two-line-container-height: 56px;
  --mdc-list-list-item-three-line-container-height: 80px;
}
.vex-mat-dense-sm .mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .vex-mat-dense-sm .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .vex-mat-dense-sm .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 48px;
}
.vex-mat-dense-sm .mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .vex-mat-dense-sm .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .vex-mat-dense-sm .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 64px;
}
.vex-mat-dense-sm {
  --mat-paginator-container-size: 48px;
}

.vex-mat-dense-sm .mat-mdc-paginator .mat-mdc-form-field-infix {
  min-height: 40px;
}
.vex-mat-dense-sm .mat-mdc-paginator .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 20px;
}
.vex-mat-dense-sm .mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -26.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}
.vex-mat-dense-sm .mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.vex-mat-dense-sm .mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.vex-mat-dense-sm .mat-mdc-paginator .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.vex-mat-dense-sm .mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label {
  display: none;
}
.vex-mat-dense-sm .mat-mdc-tab-header {
  --mdc-secondary-navigation-tab-container-height: 40px;
}
.vex-mat-dense-sm .mat-mdc-button.mat-mdc-button-base,
.vex-mat-dense-sm .mat-mdc-raised-button.mat-mdc-button-base,
.vex-mat-dense-sm .mat-mdc-unelevated-button.mat-mdc-button-base,
.vex-mat-dense-sm .mat-mdc-outlined-button.mat-mdc-button-base {
  height: 28px;
  margin-top: 0;
  margin-bottom: 0;
}
.vex-mat-dense-sm .mat-mdc-button.mat-mdc-button-base .mdc-button__touch,
.vex-mat-dense-sm .mat-mdc-raised-button.mat-mdc-button-base .mdc-button__touch,
.vex-mat-dense-sm .mat-mdc-unelevated-button.mat-mdc-button-base .mdc-button__touch,
.vex-mat-dense-sm .mat-mdc-outlined-button.mat-mdc-button-base .mdc-button__touch {
  height: 100%;
}
.vex-mat-dense-sm .mat-mdc-button.mat-mdc-button-base .mat-mdc-button-touch-target,
.vex-mat-dense-sm .mat-mdc-raised-button.mat-mdc-button-base .mat-mdc-button-touch-target,
.vex-mat-dense-sm .mat-mdc-unelevated-button.mat-mdc-button-base .mat-mdc-button-touch-target,
.vex-mat-dense-sm .mat-mdc-outlined-button.mat-mdc-button-base .mat-mdc-button-touch-target {
  display: none;
}
.vex-mat-dense-sm .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}
.vex-mat-dense-sm .mat-mdc-icon-button.mat-mdc-button-base .mat-mdc-button-touch-target {
  display: none;
}
.vex-mat-dense-sm {
  --mat-expansion-header-collapsed-state-height: 40px;
  --mat-expansion-header-expanded-state-height: 56px;
}

.vex-mat-dense-sm {
  --mat-stepper-header-height: 64px;
}

.vex-mat-dense-sm {
  --mat-toolbar-standard-height: 56px;
  --mat-toolbar-mobile-height: 48px;
}

.vex-mat-dense-sm .mat-tree-node {
  min-height: 40px;
}
.vex-mat-dense-sm {
  --mat-standard-button-toggle-height: 40px;
}

.vex-mat-dense-xs {
  --mat-table-header-container-height: 44px;
  --mat-table-footer-container-height: 40px;
  --mat-table-row-item-container-height: 40px;
}
.vex-mat-dense-xs .mat-mdc-form-field-infix {
  min-height: 44px;
}
.vex-mat-dense-xs .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 22px;
}
.vex-mat-dense-xs .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -28.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}
.vex-mat-dense-xs .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 10px;
  padding-bottom: 10px;
}
.vex-mat-dense-xs .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 10px;
  padding-bottom: 10px;
}
.vex-mat-dense-xs .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 10px;
  padding-bottom: 10px;
}
.vex-mat-dense-xs .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label {
  display: none;
}
.vex-mat-dense-xs .mat-form-field-appearance-fill .mat-mdc-select-arrow-wrapper {
  transform: none;
}
.vex-mat-dense-xs {
  --mdc-checkbox-state-layer-size: 28px;
}

.vex-mat-dense-xs .mat-mdc-checkbox-touch-target {
  display: none;
}
.vex-mat-dense-xs .mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-container-height: 24px;
}
.vex-mat-dense-xs .mat-mdc-slide-toggle {
  --mdc-switch-state-layer-size: 36px;
}
.vex-mat-dense-xs .mat-mdc-radio-button .mdc-radio {
  --mdc-radio-state-layer-size: 28px;
}
.vex-mat-dense-xs .mat-mdc-radio-touch-target {
  display: none;
}
.vex-mat-dense-xs .mat-mdc-list-base {
  --mdc-list-list-item-one-line-container-height: 36px;
  --mdc-list-list-item-two-line-container-height: 52px;
  --mdc-list-list-item-three-line-container-height: 76px;
}
.vex-mat-dense-xs .mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .vex-mat-dense-xs .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .vex-mat-dense-xs .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 44px;
}
.vex-mat-dense-xs .mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .vex-mat-dense-xs .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .vex-mat-dense-xs .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 60px;
}
.vex-mat-dense-xs {
  --mat-paginator-container-size: 40px;
}

.vex-mat-dense-xs .mat-mdc-paginator .mat-mdc-form-field-infix {
  min-height: 40px;
}
.vex-mat-dense-xs .mat-mdc-paginator .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 20px;
}
.vex-mat-dense-xs .mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -26.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}
.vex-mat-dense-xs .mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.vex-mat-dense-xs .mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.vex-mat-dense-xs .mat-mdc-paginator .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.vex-mat-dense-xs .mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label {
  display: none;
}
.vex-mat-dense-xs .mat-mdc-tab-header {
  --mdc-secondary-navigation-tab-container-height: 36px;
}
.vex-mat-dense-xs .mat-mdc-button.mat-mdc-button-base,
.vex-mat-dense-xs .mat-mdc-raised-button.mat-mdc-button-base,
.vex-mat-dense-xs .mat-mdc-unelevated-button.mat-mdc-button-base,
.vex-mat-dense-xs .mat-mdc-outlined-button.mat-mdc-button-base {
  height: 24px;
  margin-top: 0;
  margin-bottom: 0;
}
.vex-mat-dense-xs .mat-mdc-button.mat-mdc-button-base .mdc-button__touch,
.vex-mat-dense-xs .mat-mdc-raised-button.mat-mdc-button-base .mdc-button__touch,
.vex-mat-dense-xs .mat-mdc-unelevated-button.mat-mdc-button-base .mdc-button__touch,
.vex-mat-dense-xs .mat-mdc-outlined-button.mat-mdc-button-base .mdc-button__touch {
  height: 100%;
}
.vex-mat-dense-xs .mat-mdc-button.mat-mdc-button-base .mat-mdc-button-touch-target,
.vex-mat-dense-xs .mat-mdc-raised-button.mat-mdc-button-base .mat-mdc-button-touch-target,
.vex-mat-dense-xs .mat-mdc-unelevated-button.mat-mdc-button-base .mat-mdc-button-touch-target,
.vex-mat-dense-xs .mat-mdc-outlined-button.mat-mdc-button-base .mat-mdc-button-touch-target {
  display: none;
}
.vex-mat-dense-xs .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 36px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 6px;
}
.vex-mat-dense-xs .mat-mdc-icon-button.mat-mdc-button-base .mat-mdc-button-touch-target {
  display: none;
}
.vex-mat-dense-xs {
  --mat-expansion-header-collapsed-state-height: 36px;
  --mat-expansion-header-expanded-state-height: 48px;
}

.vex-mat-dense-xs {
  --mat-stepper-header-height: 60px;
}

.vex-mat-dense-xs {
  --mat-toolbar-standard-height: 52px;
  --mat-toolbar-mobile-height: 44px;
}

.vex-mat-dense-xs .mat-tree-node {
  min-height: 36px;
}
.vex-mat-dense-xs {
  --mat-standard-button-toggle-height: 36px;
}

:root {
  /** Reset default height placeholder */
}
:root .mat-mdc-dialog-container .mdc-dialog__surface {
  @apply rounded-lg;
}
:root .mat-mdc-dialog-title {
  @apply pt-3;
}
:root .mdc-dialog__title::before {
  content: none;
  height: auto;
}

:root .mat-drawer-container {
  color: inherit;
}
:root .mat-drawer-side {
  @apply border-r-0;
}
:root .dark .mat-drawer-side {
  @apply border-r;
}

:root .mat-expansion-panel:not([class*=mat-elevation-z]) {
  @apply shadow rounded;
}

.mdc-notched-outline__notch {
  border-right: none;
}

:root .mdc-text-field--outlined {
  --mdc-outlined-text-field-container-shape: theme("borderRadius.DEFAULT");
}

:root .dark .mat-mdc-menu-panel {
  @apply border-gray-600;
}
:root .mat-mdc-menu-panel {
  @apply rounded border border-gray-300;
}
:root .mat-mdc-menu-panel.mat-menu-below {
  @apply mt-1;
}
:root .mat-mdc-menu-panel.mat-menu-above {
  @apply mb-1;
}
:root .mat-mdc-menu-content {
  @apply p-2;
}
:root .mat-mdc-menu-item {
  @apply flex items-center rounded text-sm font-medium min-h-[40px] my-1 pl-2 pr-6 transition duration-100 ease-out;
}
:root .mat-mdc-menu-item:first-child {
  @apply mt-0;
}
:root .mat-mdc-menu-item:last-child {
  @apply mb-0;
}
:root .mat-mdc-menu-item:hover:not([disabled]) {
  @apply bg-primary-600/10;
}
:root .mat-mdc-menu-item:hover:not([disabled]) .mat-icon {
  @apply text-primary-600;
}
:root .mat-mdc-menu-item .mat-icon {
  @apply icon-sm transition duration-100 ease-out;
}
:root .mat-mdc-menu-item-submenu-trigger {
  @apply pr-12;
}

.mat-mdc-menu-item .mat-icon-no-color {
  @apply text-current;
}

:root .dark .mat-mdc-select-panel {
  @apply border-gray-600;
}
:root .mat-mdc-select-panel {
  @apply rounded shadow-lg border border-gray-200 p-2;
}
:root .cdk-overlay-pane:not(.mat-mdc-select-panel-above) div.mat-mdc-select-panel {
  @apply mt-1 rounded-t;
}
:root .mat-mdc-option {
  @apply my-1 rounded text-sm font-medium h-10 transition duration-100 ease-out min-h-10;
}
:root .mat-mdc-option:first-child {
  @apply mt-0;
}
:root .mat-mdc-option:last-child {
  @apply mb-0;
}
:root .mat-mdc-option:hover, :root .mat-mdc-option.mat-active {
  @apply bg-primary-600/10;
}
:root .mat-mdc-option:hover .mat-icon, :root .mat-mdc-option.mat-active .mat-icon {
  @apply text-primary-600;
}
:root .mat-mdc-option .mat-icon {
  @apply icon-sm transition duration-100 ease-out;
}
:root .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled):not(.mat-mdc-option-multiple) {
  @apply bg-primary-600/10;
}
:root .mat-mdc-menu-item-submenu-trigger {
  @apply pr-12;
}

.apexcharts-legend-text {
  margin-left: 4px;
  top: -1px;
}

body .apexcharts-tooltip.light {
  @apply bg-foreground;
}

.vex-layout-ares {
  --vex-sidenav-background: var(--vex-background-background);
  --vex-toolbar-background: var(--vex-background-background);
  --vex-sidenav-color: theme("textColor.default");
  --vex-sidenav-section-divider-color: transparent;
  --vex-sidenav-item-background-hover: rgb(var(--vex-color-primary-600) / 0.1);
  --vex-sidenav-item-background-active: var(--vex-background-card);
  --vex-sidenav-item-color: theme("textColor.default");
  --vex-sidenav-item-color-hover: theme("textColor.default");
  --vex-sidenav-item-color-active: theme("textColor.default");
  --vex-sidenav-item-dropdown-color-active: var(
      --vex-sidenav-item-color-active
  );
  --vex-sidenav-item-dropdown-background-active: var(--vex-background-hover);
  --vex-sidenav-item-dropdown-background: var(--vex-background-background);
  --vex-sidenav-item-dropdown-background-hover: var(--vex-background-hover);
  --vex-secondary-toolbar-background: var(--vex-background-background);
  --vex-sidenav-toolbar-background: var(--vex-background-background);
  --vex-secondary-toolbar-height: 64px;
}
.vex-layout-ares .vex-sidenav {
  border: none;
}
.vex-layout-ares .vex-sidenav vex-sidenav-item .vex-sidenav-item {
  width: unset;
  @apply rounded-full py-2 mx-4;
}
.vex-layout-ares .vex-sidenav vex-sidenav-item .vex-sidenav-item.vex-sidenav-item--active {
  @apply shadow;
}
.vex-layout-ares .vex-sidenav vex-sidenav-item .item-level-0 > .vex-sidenav-item {
  @apply my-1;
}
.vex-layout-ares .vex-sidenav vex-sidenav-item .item-level-1 .vex-sidenav-item.vex-sidenav-item--active {
  @apply shadow-none font-medium;
}
.vex-layout-ares .vex-sidenav vex-sidenav-item .item-level-1 .vex-sidenav-item.vex-sidenav-item--active .vex-sidenav-item__label {
  @apply text-default;
}
.vex-layout-ares .vex-sidenav vex-sidenav-item .item-level-1 .vex-sidenav-item:hover .vex-sidenav-item__label {
  @apply text-default;
}
.vex-layout-ares .vex-layout-sidenav-collapsed .vex-sidenav vex-sidenav-item .vex-sidenav-item {
  @apply mx-0;
}
.vex-layout-ares vex-toolbar {
  @apply border-b-0;
}
.vex-layout-ares vex-secondary-toolbar {
  @apply rounded-b;
}
.vex-layout-ares vex-secondary-toolbar .secondary-toolbar {
  @apply bg-transparent shadow-none border-t-0 border-b-0 -mb-6;
}
.vex-layout-ares vex-footer {
  @apply rounded-t overflow-hidden;
}
.vex-layout-ares .vex-page-layout-header {
  background: var(--vex-background-background);
  color: theme("textColor.default");
}
.vex-layout-ares.vex-style-default {
  --vex-sidenav-item-background-active: theme("backgroundColor.primary.600");
  --vex-sidenav-item-color-active: theme("textColor.on-primary.600");
  --vex-sidenav-item-dropdown-color-active: var(--vex-sidenav-item-color);
  --vex-sidenav-item-dropdown-color-hover: theme("textColor.default");
  --vex-sidenav-item-icon-color-active: theme("textColor.on-primary.600");
}

.vex-layout-hermes {
  --vex-toolbar-height: 100px;
  --vex-secondary-toolbar-background: var(--vex-background-background);
  --vex-secondary-toolbar-height: 64px;
}
.vex-layout-hermes.vex-style-light {
  --vex-toolbar-background: var(--vex-background-background);
}
.vex-layout-hermes.vex-style-dark {
  --vex-toolbar-background: var(--vex-background-background);
}
@screen lg {
  .vex-layout-hermes .vex-sidenav {
    @apply hidden;
  }
}
.vex-layout-hermes vex-secondary-toolbar .secondary-toolbar {
  @apply shadow-none -mb-6 border-t-0 border-b-0;
}

.vex-layout-ikaros {
  --vex-navigation-height: 0.00001px;
}
.vex-layout-ikaros.vex-style-default {
  --vex-navigation-color: white;
}

.vex-layout-poseidon {
  --vex-sidenav-item-min-height: 40px;
  --vex-sidenav-item-background-hover: rgb(var(--vex-color-primary-500) / 15%);
  --vex-sidenav-item-background-active: rgb(var(--vex-color-primary-500) / 20%);
  --vex-sidenav-item-dropdown-background: var(--vex-sidenav-background);
  --vex-sidenav-item-dropdown-background-hover: rgb(
      var(--vex-color-primary-600) / 10%
  );
  --vex-sidenav-item-dropdown-background-active: rgb(
      var(--vex-color-primary-600) / 20%
  );
  --vex-sidenav-item-padding-vertical: theme("space.2");
  --vex-sidenav-item-padding-horizontal: theme("space.3");
}
.vex-layout-poseidon .vex-sidenav-item {
  @apply mx-3 mb-1 rounded;
}
.vex-layout-poseidon .vex-sidenav-toolbar__divider {
  margin: 0 var(--vex-sidenav-item-padding-horizontal);
}
.vex-layout-poseidon.vex-style-light {
  --vex-sidenav-item-color: rgba(0, 0, 0, 1);
  --vex-sidenav-item-color-hover: rgba(0, 0, 0, 1);
  --vex-sidenav-item-dropdown-color-hover: rgba(0, 0, 0, 1);
  --vex-sidenav-item-dropdown-color-active: rgba(0, 0, 0, 1);
}
.vex-layout-poseidon.vex-style-default {
  --vex-sidenav-toolbar-background: var(--vex-sidenav-background);
  --vex-sidenav-item-color: rgba(255, 255, 255, 0.8);
  --vex-sidenav-item-color-hover: rgba(255, 255, 255, 1);
  --vex-sidenav-item-ripple-color: rgb(255, 255, 255, 0.06);
}
.vex-layout-poseidon.vex-style-dark {
  --vex-sidenav-item-color: rgba(255, 255, 255, 0.8);
  --vex-sidenav-item-color-hover: #ffffff;
  --vex-sidenav-item-ripple-color: rgb(255, 255, 255, 0.06);
}

/*
  You can override any CSS Variable or style here
  Example:

  :root {
   --vex-sidenav-background: #333333;
  }

  All possible variables can be found in @vex/styles/core or by simply inspecting the element you want to change
  in Chrome/Firefox DevTools
*/
/**
  Uncomment the below code and adjust the values to fit your colors, the application will automatically adjust
 */
/*Modificación de css general*/
.form-geosearch .mat-mdc-form-field-subscript-wrapper {
  width: 0 !important;
}

.map-container {
  width: 98% !important;
}

.loginform .loginForm .mat-form-field-appearance-outline .mat-form-field-outline {
  color: hsla(0, 0%, 100%, 0.5);
}

.loginform .mdc-text-field--outlined {
  --mdc-outlined-text-field-outline-color: #fff !important;
  --mdc-outlined-text-field-label-text-color: #fff !important;
  --mdc-outlined-text-field-focus-outline-color: #fff !important;
  --mdc-outlined-text-field-focus-label-text-color: #fff !important;
  --mdc-outlined-text-field-hover-outline-color: #fff !important;
  --mdc-outlined-text-field-hover-label-text-color: #fff !important;
}