@import "~simplebar/dist/simplebar.css";
@import "~highlight.js/styles/androidstudio.css";
// Vex Core
@import "./@vex/styles/core";

/*
  You can override any CSS Variable or style here
  Example:

  :root {
   --vex-sidenav-background: #333333;
  }

  All possible variables can be found in @vex/styles/core or by simply inspecting the element you want to change
  in Chrome/Firefox DevTools
*/

/**
  Uncomment the below code and adjust the values to fit your colors, the application will automatically adjust
 */
html {
  // --vex-color-primary: blue;
  // --vex-color-primary-contrast: white;

  // --vex-color-accent: yellow;
  // --vex-color-accent-contrast: black;

  // --vex-color-warn: yellow;
  // --vex-color-warn-contrast: black;
}

/*Modificación de css general*/

.form-geosearch .mat-mdc-form-field-subscript-wrapper {
  width: 0 !important;
}

.map-container{
  width: 98% !important;
}

.loginform .loginForm .mat-form-field-appearance-outline .mat-form-field-outline {
  color: hsla(0, 0%, 100%, .5);
}

.loginform .mdc-text-field--outlined {
  --mdc-outlined-text-field-outline-color: #fff !important;
  --mdc-outlined-text-field-label-text-color: #fff !important;
  --mdc-outlined-text-field-focus-outline-color: #fff !important;
  --mdc-outlined-text-field-focus-label-text-color: #fff !important;
  --mdc-outlined-text-field-hover-outline-color: #fff !important;
  --mdc-outlined-text-field-hover-label-text-color: #fff !important;
}